import { AlertDialog, Button } from 'native-base';
import {
  ForwardedRef,
  forwardRef, createContext, useImperativeHandle, useRef, useState, PropsWithChildren, useCallback, useMemo, useContext
} from 'react';
import {
  ConfirmCancelCallback, TargetDraftLocation
} from '@/infrastructure/ui/schedule-send/useCancelMessageCallback';

export type CancelMessageAlertProps = {};

export type CancelMessageAlertHandles = {
  showCancelAlert: (location: TargetDraftLocation, confirmCallback: ConfirmCancelCallback) => void;
};

export const CancelMessageAlert = forwardRef(function (_props: CancelMessageAlertProps, ref: ForwardedRef<CancelMessageAlertHandles>) {
  const cancelRef = useRef(null);
  const [isCancelAlertVisible, setIsCancelAlertVisible] = useState(false);
  const [cancelledDraftLocation, setCancelledDraftLocation] = useState('');
  const [confirmCancelCallback, setConfirmCancelCallback] = useState<ConfirmCancelCallback>(() => {});

  const handleClose = () => {
    setIsCancelAlertVisible(false);
  };
  const submit = () => {
    setIsCancelAlertVisible(false);
    confirmCancelCallback();
  };

  useImperativeHandle(ref, () => ({
    showCancelAlert: (location: TargetDraftLocation, confirmCallback: ConfirmCancelCallback) => {
      setConfirmCancelCallback(() => confirmCallback);
      setCancelledDraftLocation(location);
      setIsCancelAlertVisible(true);
    },
  }));

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isCancelAlertVisible}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton onPress={handleClose} />
        <AlertDialog.Header>
          Are you sure?
        </AlertDialog.Header>
        <AlertDialog.Body>
          {cancelledDraftLocation !== 'thread'
            ? `Cancelling this scheduled message will replace your ${cancelledDraftLocation} draft.`
            : 'Cancelling this scheduled message will replace your draft.'}
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button colorScheme="blueGray" variant="ghost" onPress={handleClose} ref={cancelRef}>
              No
            </Button>
            <Button onPress={submit}>
              Yes
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
});
type CancelMessageAlertContextProps = {
  showCancelAlert: (targetDraftLocation: TargetDraftLocation, confirmCallback: ConfirmCancelCallback) => void,
};

export const CancelMessageAlertContext = createContext<CancelMessageAlertContextProps>({
  showCancelAlert: (_targetDraftLocation: TargetDraftLocation, _confirmCallback: ConfirmCancelCallback) => {},
});

type CancelMessageAlertProviderProps = PropsWithChildren<{}>;

export const CancelMessageAlertProvider = ({ children }: CancelMessageAlertProviderProps) => {
  const alertRef = useRef<CancelMessageAlertHandles>(null);

  const showAlertCallback = useCallback((targetDraftLocation: TargetDraftLocation, confirmCallback: ConfirmCancelCallback) => {
    alertRef.current?.showCancelAlert(targetDraftLocation, confirmCallback);
  }, []);

  const value = useMemo(() => ({
    showCancelAlert: showAlertCallback,
  }), [showAlertCallback]);

  return (
    <>
      <CancelMessageAlert ref={alertRef} />
      <CancelMessageAlertContext.Provider value={value}>
        {children}
      </CancelMessageAlertContext.Provider>
    </>
  );
};

export const useCancelMessageAlertContext = () => useContext(CancelMessageAlertContext);
