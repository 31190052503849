import {
  HStack, Icon, Menu, Pressable, Skeleton,
} from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ComponentProps, useCallback } from 'react';
import { HeaderTitle } from '../../HeaderTitle';
import { CHANNEL_ICON_SETS, ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { useChannelColor } from '@/infrastructure/ui/channel-color-indicator/ChannelColorIndicator';

export type ChannelHeaderTitleProps = {
  channel?: ChannelViewModel
  tintColor?: string;
  showLoader: boolean;
  onInitiateUpdateCallback?: () => void;
  onInitiateDeleteCallback?: () => void;
  onLeave?: () => void;
};

export function ChannelScreenHeaderTitle(props: ChannelHeaderTitleProps) {
  const channelColor = useChannelColor(props.channel?.id ?? '');

  const trigger: ComponentProps<typeof Menu>['trigger'] = useCallback((triggerProps, { open }) => (
    <Pressable
      borderRadius={10}
      _hover={{ backgroundColor: 'opacityPrimaryDarker.50' }}
      _pressed={{ backgroundColor: 'opacityPrimaryDarker.100' }}
      backgroundColor={open ? 'opacityPrimaryDarker.50' : 'transparent'}
      py={2}
      px={1}
      {...triggerProps}
    >
      {({ isHovered }) => (props.channel && (
        <HStack space={1} alignItems="center">
          {props.channel.iconSetName && (
            <Icon
              as={CHANNEL_ICON_SETS[props.channel.iconSetName].default.collection}
              name={CHANNEL_ICON_SETS[props.channel.iconSetName].default.name}
              color={channelColor}
              size="sm"
            />
          )}
          <HeaderTitle
            tintColor={props.tintColor}
            borderBottomColor={channelColor}
            borderBottomWidth={2}
          >
            {props.channel.name}
          </HeaderTitle>
          <Icon
            as={MaterialCommunityIcons}
            name="chevron-down"
            variant="solid"
            display="flex"
            ml={-1}
            opacity={(isHovered || open) ? 1 : 0}
          />
        </HStack>
      ))}
    </Pressable>
  ), [channelColor, props.channel, props.tintColor]);

  if (props.showLoader) {
    return <Skeleton.Text lines={1} h={3} width="230px" />;
  }

  if (!props.channel) {
    return null;
  }

  return (
    <Menu shouldOverlapWithTrigger={false} placement="bottom left" trigger={trigger}>
      <Menu.Item onPress={props.onInitiateUpdateCallback}>Edit channel</Menu.Item>
      {props.onLeave && (<Menu.Item colorScheme="danger" onPress={props.onLeave}>Leave channel</Menu.Item>)}
      <Menu.Item colorScheme="danger" onPress={props.onInitiateDeleteCallback}>Delete channel</Menu.Item>
    </Menu>
  );
}
