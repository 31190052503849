import { ITooltipProps, Tooltip as NativeBaseTooltip } from 'native-base';

export type TooltipProps = ITooltipProps;

export function Tooltip(props: TooltipProps) {
  const { children, ...rest } = props;

  return (
    <NativeBaseTooltip openDelay={100} bgColor="dark.800" borderRadius={4} placement="top" padding="6px" shadow="none" {...rest}>
      {children}
    </NativeBaseTooltip>
  );
}
