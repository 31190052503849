import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import {
  Box, Button, Divider, HStack, IconButton, Pressable, Text, useTheme
} from 'native-base';
import React, {
  memo, RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { Hoverable } from 'react-native-web-hover';
import isEqual from 'react-fast-compare';
import { useIsFocused } from '@react-navigation/native';
import { format, intlFormat, isToday } from 'date-fns';
import { ComprehensiveMessageViewModel, ComprehensiveSendingMessageViewModel } from '@/adapters/view-models/MessageViewModel';
import { HORIZONTAL_PADDING } from '../../thread-list/ThreadList';
import { ParticipantAvatar } from '../../ParticipantAvatars';
import { MessageReactionViewModel } from '@/adapters/view-models/MessageReactionViewModel';
import { getEmojiByUnified } from '@/adapters/other/getEmojiByUnified';
import RenderedHtml from '../../RenderedHtml';
import { ComposeAreaHandles } from '../../compose';
import { withConfirmationAlert } from '../../../controllers/modals/withConfirmationAlert';
import { useAppDispatch } from '../../../state/hooks';
import { draftActions } from '@/domain/state/drafts';
import { usePostMessageMutation } from '@/adapters/api/codegen';
import { AddMoreReactionButton } from './emoji/AddMoreReactionButton';
import { AddOtherReactionButton } from './emoji/AddOtherReactionButton';
import { Emoji } from '../../emoji/popover/Emoji';
import { useThreadViewStore, useThreadViewStoreSelector } from './context';
import { useWithMutationMetadata } from '@/adapters/mutation-cancellation/mutationCancellation';
import { Tooltip } from '@/infrastructure/ui/components/Tooltip';
import { ComprehensiveThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { buildPostMessageMutationDescription } from '@/infrastructure/controllers/UndoMutationDescriptions';
import { AttachmentGallery } from '../../compose/components/attachments/AttachmentGallery';

export type MessagesListItem = ComprehensiveMessageViewModel | ComprehensiveSendingMessageViewModel;
export type ReactionSelectedCallback = (code: string, message: ComprehensiveMessageViewModel) => void;

const delayShowPendingBy = 1500;
const nbsp = '\u00A0';

interface MessageProps {
  thread: ComprehensiveThreadViewModel;
  message: MessagesListItem;
  emojiSelectedCallback?: ReactionSelectedCallback;
  replyComposeAreaRef?: RefObject<ComposeAreaHandles>;
  onRendered?: (messageId: string) => void;
  displayAsNew: boolean;
  scheduledMessageCancelledCallback: (messageId: string) => void;
}

function ThreadMessageItem(props: MessageProps) {
  const {
    thread, message, onRendered, emojiSelectedCallback, scheduledMessageCancelledCallback
  } = props;
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const theme = useTheme();

  const onRenderedMessage = useCallback(() => {
    if (onRendered) {
      onRendered(message.id);
    }
  }, [onRendered, message.id]);

  const unifiedEmojiSelectedCallback = (reactionCode: string) => {
    if (props.emojiSelectedCallback) {
      props.emojiSelectedCallback(reactionCode, message);
    }
  };
  const canAddReaction = Boolean(props.emojiSelectedCallback && !isLocalMessage(message));

  const dispatch = useAppDispatch();
  const handleDiscardFailedMessage = useCallback(() => {
    dispatch(draftActions.discardFailedSending({ threadId: thread.id, messageId: message.id }));
  }, [dispatch, message.id, thread.id]);

  const [postMessage, { isLoading: isPending }] = useWithMutationMetadata(usePostMessageMutation({
    fixedCacheKey: `postMessage:${message.id}`,
    selectFromResult: (result) => ({
      isLoading: result.isLoading,
    }),
  }));

  const handleResendFailedMessage = async () => {
    if (!isLocalMessage(message)) return;
    const messageCreateRequestBody = message.resendMessageCreateRequestBody;
    return postMessage({ threadId: thread.id, messageCreateRequestBody }, {
      initiator: 'user',
      description: buildPostMessageMutationDescription(),
      cancellable: false,
    });
  };

  const wrappingEmojiSelectedCallback = useCallback((emoji: Emoji) => {
    if (emojiSelectedCallback) {
      emojiSelectedCallback(emoji.unified, message);
    }
  }, [emojiSelectedCallback, message]);

  const isFocusedScreen = useIsFocused();
  const ref = useRef<HTMLDivElement>(null);
  const threadViewStore = useThreadViewStore();
  const isFocused = useThreadViewStoreSelector((state) => state.focusedId === message.id);
  const setFocused = useThreadViewStoreSelector((state) => state.setFocusedId);
  useLayoutEffect(() => {
    const { navigatedFromKeyboard } = threadViewStore.getState();
    if (isFocused && isFocusedScreen && ref.current && navigatedFromKeyboard) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      ref.current.focus();
    }
  }, [isFocused, isFocusedScreen, threadViewStore]);

  const handleScheduledMessageCancelled = useCallback(() => {
    scheduledMessageCancelledCallback(message.id);
  }, [scheduledMessageCancelledCallback, message.id]);

  return (
    <Hoverable onPointerMove={() => setFocused(message.id)}>
      { props.displayAsNew && <NewMessageDivider /> }
      <Box ref={ref} backgroundColor={(isFocused || isEmojiPickerVisible) ? 'opacityPrimaryDark.15' : 'transparent'} borderRadius={8}>
        <HStack px={HORIZONTAL_PADDING} py={2} space={3} alignItems="flex-start">
          <Box mt={2}>
            <ParticipantAvatar pictureInfo={message.senderInfo} size="sm" />
          </Box>
          <Box flexShrink="1">
            <MessageHeader
              message={message}
              isPending={isPending}
              handleResendFailedMessage={handleResendFailedMessage}
              handleDiscardFailedMessage={handleDiscardFailedMessage}
              handleCancelScheduledMessage={handleScheduledMessageCancelled}
              isThreadTrashed={thread.isTrashed}
            />
            <Text fontSize="sm" ellipsizeMode="tail" flexWrap="wrap">
              <RenderedHtml
                htmlContent={message.body}
                onRendered={onRenderedMessage}
                isScheduled={message.state === 'scheduled'}
                scheduledTextColor={theme.colors.dark[400]}
              />
            </Text>
            {message.attachments.length > 0 && (
              <AttachmentGallery defaultAttachments={message.attachments} pt={2} />
            )}
            {message.reactions.length > 0 && (
              <HStack flexDir="row" space={1.5} mt={1}>
                {message.reactions.map((reaction) => (
                  <MessageReaction
                    messageReaction={reaction}
                    reactionSelectedCallback={unifiedEmojiSelectedCallback}
                    key={reaction.reactionViewModel.id}
                  />
                ))}
                <AddMoreReactionButton
                  emojiSelectedCallback={wrappingEmojiSelectedCallback}
                  isShowingReactionsCallback={setIsEmojiPickerVisible}
                />
              </HStack>
            )}
          </Box>
        </HStack>
        {((isFocused || isEmojiPickerVisible) && canAddReaction) ? (
          <HStack
            position="absolute"
            right={3}
            top="-10px"
            space={2}
            borderRadius={6}
            borderWidth={1}
            borderColor="muted.300"
            shadow={1}
            p={1.5}
            backgroundColor="white"
          >
            <ReplyButtonController replyCallback={() => props.replyComposeAreaRef?.current?.quoteMessage(message)} />
            <AddSpecificReactionButton reactionSelectedCallback={unifiedEmojiSelectedCallback} code="1f44d" />
            <AddSpecificReactionButton reactionSelectedCallback={unifiedEmojiSelectedCallback} code="2705" />
            <AddSpecificReactionButton reactionSelectedCallback={unifiedEmojiSelectedCallback} code="1f440" />
            <AddSpecificReactionButton reactionSelectedCallback={unifiedEmojiSelectedCallback} code="1f64c" />
            <AddSpecificReactionButton reactionSelectedCallback={unifiedEmojiSelectedCallback} code="1f64f" />
            <AddOtherReactionButton
              emojiSelectedCallback={wrappingEmojiSelectedCallback}
              isShowingReactionsCallback={setIsEmojiPickerVisible}
            />
          </HStack>
        ) : null}
      </Box>
    </Hoverable>
  );
}

type MessageHeaderProps = {
  message: MessagesListItem;
  isPending: boolean;
  isThreadTrashed: boolean;
  handleResendFailedMessage: () => void;
  handleDiscardFailedMessage: () => void;
  handleCancelScheduledMessage: () => void;
};

function MessageHeader({
  message, isPending, handleResendFailedMessage, handleDiscardFailedMessage, isThreadTrashed, handleCancelScheduledMessage
}: MessageHeaderProps) {
  const delayedIsPending = useDelayedIsPending(isPending);

  const text = isThreadTrashed
    ? `This thread was trashed. This message won't be sent ${formatScheduledDate(new Date(message.rawDate))}`
    : `This message will be sent ${formatScheduledDate(new Date(message.rawDate))}`;

  // Logic is derived from getMessageStatusText()
  const showScheduledMessageHeader = message.state == 'scheduled'
    && (!isLocalMessage(message)
      || (isLocalMessage(message)
        && isPending
        && !(delayedIsPending || (message.resendCount ?? 0) > 0)));

  return (
    <Box flexDirection="row" alignItems="center" mb={0.5}>
      <Text fontSize="sm" bold>
        {message.senderDisplayName}
      </Text>
      {showScheduledMessageHeader && (
      <Box
        ml="6px"
        pl="6px"
        py="1px"
        pr={isThreadTrashed ? '6px' : 0}
        borderRadius={4}
        fontSize={14}
        bgColor="primary.100"
        flex={1}
        flexShrink={1}
        flexDirection="row"
      >
        <Text color="dark.600" lineHeight="20px">{text}</Text>
        {!isThreadTrashed
          && (
            <Button
              variant="ghost"
              padding="0"
              ml={1.5}
              borderRadius={4}
              px={1.5}
              _text={{ fontWeight: 600, color: 'primary.700', lineHeight: 20 }}
              onPress={() => handleCancelScheduledMessage()}
              disabled={isLocalMessage(message)}
            >
              Cancel
            </Button>
          )}
      </Box>
      )}
      {!showScheduledMessageHeader && (
        <>
          <Text fontSize="sm" flexGrow={1} color="opacityPrimaryDark.400">
            {`${nbsp}• ${getMessageStatusText(message, isPending, delayedIsPending)} `}
          </Text>
          {isLocalMessage(message) && !isPending && (
            <HStack space={1} ml={1}>
              <FailedMessageInlineIconButton onPress={handleResendFailedMessage} as={MaterialCommunityIcons} name="reload" />
              <DiscardFailedMessageButton onPress={handleDiscardFailedMessage} as={MaterialIcons} name="close" />
            </HStack>
          )}
        </>
      )}
    </Box>
  );
}

function formatScheduledDate(date: Date): string {
  const locale = navigator.language || 'en-US';
  const timeString = intlFormat(date, { hour: 'numeric', minute: 'numeric' }, { locale });
  if (isToday(date)) {
    return `Today at ${timeString}`;
  }
  const dateString = format(date, 'MMMM do');
  return `on ${dateString} at ${timeString}`;
}

function getMessageStatusText(message: MessagesListItem, isPending: boolean, delayedIsPending: boolean) {
  if (isLocalMessage(message)) {
    if (isPending) {
      if (delayedIsPending || (message.resendCount ?? 0) > 0) {
        return message.state === 'scheduled'
          ? 'Scheduling...'
          : 'Sending...';
      }
    } else {
      return `We were unable to ${message.state === 'scheduled' ? 'schedule' : 'send'} this message`;
    }
  }
  return message.date;
}

function useDelayedIsPending(isPending: boolean) {
  const [delayedIsPending, setDelayedIsPending] = useState(false);
  useEffect(() => {
    if (isPending) {
      const timeout = setTimeout(() => setDelayedIsPending(true), delayShowPendingBy);
      return () => clearTimeout(timeout);
    }
    setDelayedIsPending(false);
  }, [isPending]);
  return delayedIsPending;
}

function isLocalMessage(message: MessagesListItem): message is ComprehensiveSendingMessageViewModel {
  return Boolean((message as ComprehensiveSendingMessageViewModel).isSending);
}

function FailedMessageInlineIconButton(props: {
  as: any,
  name: string,
  onPress?: () => void,
}) {
  return (
    <IconButton
      _icon={{
        size: 3,
        as: props.as,
        name: props.name,
        color: 'dark.600'
      }}
      onPress={props.onPress}
      h={4}
      px={1}
      py={0}
      borderWidth={0}
      variant="subtle"
      colorScheme="text"
    />
  );
}

const DiscardFailedMessageButton = withConfirmationAlert(FailedMessageInlineIconButton, {
  header: 'Discard message',
  body: 'This action cannot be undone.',
  confirmButtonText: 'Discard',
});

function AddSpecificReactionButton(props: {
  reactionSelectedCallback: (emoji: any) => void;
  code: string;
}) {
  return (
    <Button size="sm" variant="ghost" onPress={() => props.reactionSelectedCallback(props.code)} p={0} px={1.5} w={8} h={8}>
      <Text fontSize="lg">{getEmojiByUnified(props.code)}</Text>
    </Button>
  );
}

const ThreadMessageItemMemo = memo(ThreadMessageItem, isEqual);

export { ThreadMessageItemMemo as ThreadMessageItem };

type MessageReactionButtonProps = {
  hint: string;
  isHighlighted: boolean;
  code: string;
  displayText: string;
  count?: number;
  reactionSelectedCallback?: (reactionCode: string) => void;
};

function MessageReactionButton(props: MessageReactionButtonProps) {
  return (
    <Tooltip label={props.hint} isDisabled={!props.hint}>
      <Pressable
        _hover={{ background: 'white', borderColor: props.isHighlighted ? 'blueGray.400' : 'muted.200' }}
        fontSize="sm"
        flexWrap="wrap"
        flexGrow={0}
        backgroundColor={props.isHighlighted ? 'blueGray.200' : 'muted.100'}
        borderRadius={4}
        p={0}
        px="1.5"
        borderWidth={2}
        borderColor={props.isHighlighted ? 'blueGray.400' : 'transparent'}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        onPress={() => props.reactionSelectedCallback?.(props.code)}
      >
        <Text fontSize="md" mr={1}>
          {props.displayText}
        </Text>
        <Text fontSize="sm" color="muted.700" fontFamily="monospace" display={props.count ? 'block' : 'none'}>
          {props.count}
        </Text>
      </Pressable>
    </Tooltip>
  );
}

type MessageReactionProps = {
  messageReaction: MessageReactionViewModel;
  reactionSelectedCallback: (reactionCode: string) => void;
};

function MessageReaction(props: MessageReactionProps) {
  const {
    hint, isHighlighted, displayText, count
  } = props.messageReaction;

  const { code } = props.messageReaction.reactionViewModel;

  return (
    <MessageReactionButton
      hint={hint}
      isHighlighted={isHighlighted}
      code={code}
      displayText={displayText}
      count={count}
      reactionSelectedCallback={props.reactionSelectedCallback}
    />
  );
}

export type ReplyButtonControllerProps = {
  replyCallback: () => void;
};

function ReplyButtonController(props: ReplyButtonControllerProps) {
  return (
    <IconButton variant="primary" onPress={props.replyCallback} size="sm" w={8} h={8} _icon={{ name: 'reply-outline', as: MaterialCommunityIcons }} />
  );
}

function NewMessageDivider() {
  return (
    <HStack alignItems="center" space={3} px={HORIZONTAL_PADDING} h="1px">
      <Divider flexShrink={1} backgroundColor="primary.200" h="1px" />
      <Text flexShrink={0} color="primary.600" fontWeight="medium">New</Text>
    </HStack>
  );
}
