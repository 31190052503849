import { Box, HStack, Text } from 'native-base';
import { DisplayShortcut } from './DisplayShortcut';

export function getTooltipLabelWithShortcut(hint?: string, shortcut?: string) {
  return (
    <HStack alignItems="center" space={2} minH={5}>
      {hint && <Box mb={0.5}><Text color="white" fontWeight={500} fontSize={14} lineHeight={5} letterSpacing="0.02em">{hint}</Text></Box>}
      {shortcut && <DisplayShortcut dark shortcut={shortcut} />}
    </HStack>
  ) as any;
}
