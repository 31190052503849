import { MantineProvider } from '@mantine/core';
import { NativeBaseProvider } from 'native-base';
import { type ReactNode, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { getMantineTheme } from '@/infrastructure/styles/mantine-theme';
import { getMainTheme } from '@/infrastructure/styles/main-theme/main-theme';
import { typedKeys } from '@/infrastructure/global/typedKeys';
import { colorThemes } from '@/infrastructure/styles/main-theme/colors';

const themeNames = typedKeys(colorThemes);

export function ThemeProvider(
  { children }: { children: ReactNode }
) {
  const [themeIndex, setThemeIndex] = useState(0);
  const toggleTheme = () => setThemeIndex((themeIndex + 1) % themeNames.length);
  useHotkeys('shift+t', toggleTheme);

  const theme = useMemo(() => {
    const nativeBaseTheme = getMainTheme(themeNames[themeIndex]);
    // console.log(nativeBaseTheme);
    const mantineTheme = getMantineTheme(nativeBaseTheme);
    return { nativeBaseTheme, mantineTheme };
  }, [themeIndex]);

  // const css = (() => {
  //   const colors = theme.nativeBaseTheme.colors;
  //
  // })();

  return (
    <>
      {/* <style></style> */}
      <MantineProvider theme={theme.mantineTheme}>
        <NativeBaseProvider theme={theme.nativeBaseTheme}>
          {children}
        </NativeBaseProvider>
      </MantineProvider>
    </>
  );
}
