import React, { useCallback, useRef, useState } from 'react';

import { RouteProp, useRoute } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';
import { ThreadList } from '../../ui/thread-list/ThreadList';
import {
  useArchiveThreadCallback,
  useChangeUnreadStatusCallback,
  useCreateThreadCallback,
  useMoveToInboxCallback,
  usePrefetchThreadCallback,
  useSelectThreadCallback,
  useStarThreadCallback,
  useUnstarThreadCallback
} from '../hooks/channelScreenHooks';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';
import { useCurrentChannel } from '../hooks/routes/useCurrentChannel';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { useGetChannelThreads } from '../hooks/api/useGetChannelThreads';
import { useGetSelfUser } from '../hooks/api/useGetSelfUser';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useGetSelfOrganizationMembers } from '../hooks/api/useGetSelfOrganizationMembers';
import { useGetAllAccessibleChannels } from '../hooks/api/useGetAllAccessibleChannels';
import { createStandardErrorIfAny } from '@/adapters/other/createStandardError';
import { ComposeAreaHandles, ComposeAreaSubmitCallback } from '../../ui/compose';
import { useSnoozeThreadCallback } from '../hooks/channelScreenHooks/useSnoozeThreadCallback';
import { EmptyScreenSection } from '../../ui/EmptyScreenSection';

import {
  useCancelMessageCallback
} from '@/infrastructure/ui/schedule-send/useCancelMessageCallback';

export function ChannelScreenSectionController() {
  const route = useRoute<RouteProp<RootStackParamList, 'Channel'>>();
  const { channelId, page } = route.params ?? {};
  const { currentData: selfUser } = useGetSelfUser();
  const { currentData: selfOrganizations, organizationId } = useGetSelfOrganizations();
  const { currentData: organizationMembers } = useGetSelfOrganizationMembers(organizationId);
  const { allRawChannels } = useGetAllAccessibleChannels(selfOrganizations);
  const currentChannel = useCurrentChannel();
  const [participants, setParticipants] = useState<ParticipantViewModel[]>([]);

  const {
    currentData, isLoading, error
  } = useGetChannelThreads(channelId, selfUser?.id, organizationMembers?.users, allRawChannels, page);

  const composeAreaRef = useRef<ComposeAreaHandles>(null);
  const draftKey = `channel:${channelId}`;
  const cancelMessageCallback = useCancelMessageCallback(draftKey, composeAreaRef, channelId);

  const threadHoveredCallback = usePrefetchThreadCallback();
  const baseCreateThreadCallback = useCreateThreadCallback(channelId, cancelMessageCallback);
  const selectThreadCallback = useSelectThreadCallback(currentData?.threads);
  const archiveThreadCallback = useArchiveThreadCallback();
  const moveToInboxCallback = useMoveToInboxCallback();
  const changeUnreadStatusCallback = useChangeUnreadStatusCallback();
  const setSnoozeEndDateCallback = useSnoozeThreadCallback();
  const starThreadCallback = useStarThreadCallback();
  const unstarThreadCallback = useUnstarThreadCallback();

  const createThreadCallback: ComposeAreaSubmitCallback = useCallback((props) => {
    setParticipants([]);
    return baseCreateThreadCallback(props);
  }, [baseCreateThreadCallback, setParticipants]);

  const showComposeArea = !currentChannel?.channel?.isFolder;
  const errorToDisplay = createStandardErrorIfAny(error);
  const isLoadingForFirstTime = isLoading && !currentData?.threads;

  return (
    <ThreadList
      showSpinner={isLoadingForFirstTime && !errorToDisplay}
      submitCallback={createThreadCallback}
      items={currentData?.threads}
      errorToDisplay={errorToDisplay?.displayMessage}
      selectThreadCallback={selectThreadCallback}
      archiveThreadCallback={archiveThreadCallback}
      showComposeArea={showComposeArea}
      changeUnreadStatusCallback={changeUnreadStatusCallback}
      threadHoveredCallback={threadHoveredCallback}
      moveThreadToInboxCallback={moveToInboxCallback}
      starThreadCallback={starThreadCallback}
      unstarThreadCallback={unstarThreadCallback}
      setSnoozeEndDateCallback={setSnoozeEndDateCallback}
      participants={participants}
      participantsChangedCallback={setParticipants}
      composeDraftKey={draftKey}
      ListEmptyComponent={<EmptyScreenSection text="This channel is empty, start a new thread by typing a message below." iconCollection={MaterialIcons} iconName="chat-bubble-outline" />}
      composeAreaRef={composeAreaRef}
    />
  );
}
