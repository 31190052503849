import { draftActions, isSendingPayload } from '@/domain/state/drafts';
import {
  ConversationParticipant, Message, PostMessageApiArg, PostMessageApiResponse, User
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { createMessageViewModel, createPendingParticipants, MessageViewModel } from '../view-models/MessageViewModel';
import {
  ThreadViewModel,
  addThreadViewModelParticipants,
  updateEarliestScheduledDate
} from '../view-models/ThreadViewModel';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';
import { apiClient } from '@/adapters/api';
import { store } from '@/domain/state/store';
import {
  createThreadUpdatePatchesForThreadId
} from '@/adapters/mutation-handlers/thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { insertThreadInScheduledFolder } from '@/adapters/mutation-handlers/utils/insertThreadInScheduledFolder';

export class PostMessageMutationHandler extends MutationHandler<PostMessageApiArg, PostMessageApiResponse> {
  constructor(props: MutationHandlerProps<PostMessageApiArg, PostMessageApiResponse>) {
    super({
      ...props,
      skipUndoOnFailure: true,
    });
  }

  protected createOptimisticUpdatePatchWrappers(arg: PostMessageApiArg, _patchId: string) {
    return [
      ...(isSendingPayload(arg) ? [draftActions.sending(arg)] : []),
      this.updateQueryData('getThreadMessages', { threadId: arg.threadId }, () => {}),
      this.createThreadInScheduledFolder(arg),
      ...createThreadUpdatePatchesForThreadId(arg.threadId, (draftedThread) => this.addScheduledMessageCreationDateToThread(draftedThread, arg)),
    ];
  }

  protected createRequestCompletedPatchWrappers(arg: PostMessageApiArg, data: PostMessageApiResponse, patchId: string) {
    return [
      ...(isSendingPayload(arg) ? [draftActions.sendSuccess(arg)] : []),
      this.createCompleteMessagePatch(arg, data, patchId),
      this.createAddParticipantInThreadsListPatch(arg),
    ];
  }

  private addScheduledMessageCreationDateToThread(draftedThread: ThreadViewModel, patch: PostMessageApiArg) {
    if (draftedThread.id !== patch.threadId || !patch.messageCreateRequestBody.scheduled_date) {
      return;
    }

    draftedThread.scheduledMessagesCreationDates[patch.messageCreateRequestBody.id!] = (new Date(patch.messageCreateRequestBody.scheduled_date)).getTime();
    updateEarliestScheduledDate(draftedThread);
  }

  private createThreadInScheduledFolder(patch: PostMessageApiArg) {
    const currentThread = this.getThreadInCurrentContext(patch.threadId);

    return this.updateQueryData('getSelfScheduledThreads', {}, (draft) => {
      if (!draft || !patch.messageCreateRequestBody.scheduled_date || !currentThread) {
        return;
      }

      const existingThread = draft.threads.find((thread) => thread.id === currentThread.id);
      if (!existingThread) {
        insertThreadInScheduledFolder(currentThread, draft);
      }
    });
  }

  private createCompleteMessagePatch(patch: PostMessageApiArg, newMessage: Message, _patchId: string) {
    const { data: organizations } = apiClient.endpoints.getSelfOrganizations.select()(store.getState());
    const { data: members } = apiClient.endpoints.getOrganizationMembers.select({ organizationId: organizations?.organizations[0].id || '' })(store.getState());

    return this.updateQueryData('getThreadMessages', { threadId: patch.threadId }, (draft) => {
      draft.messages.unshift(createMessageViewModel({
        ...newMessage,
        // This method is called after the request has completed. Given that pending recipients are only returned for
        // scheduled messages, we need to rebuild the list of participants from the initial request for instant messages.
        pending_participants: newMessage.pending_participants?.length > 0
          ? newMessage.pending_participants
          : createPendingParticipants(patch.messageCreateRequestBody, members),
      }));
      draft.messages.sort((a: MessageViewModel, b: MessageViewModel): number => {
        return b.rawDate - a.rawDate;
      });
    });
  }

  private createAddParticipantInThreadsListPatch(patch: PostMessageApiArg) {
    // todo update all channels from thread.channels and other threads lists
    return this.updateQueryData('getChannelThreads', getCurrentChannelParams()!, (draftedThreads) => {
      for (const thread of draftedThreads.threads) {
        if (thread.id === patch.threadId) {
          this.addSelfUserAsParticipantToThread(thread);
        }
      }
    });
  }

  private addSelfUserAsParticipantToThread(draftedThread: ThreadViewModel) {
    const selfUser = this.getSelfUser();
    addThreadViewModelParticipants(draftedThread, [this.createConversationParticipant(selfUser!.rawUser)]);
  }

  private createConversationParticipant(user: User): ConversationParticipant {
    return {
      type: 'user',
      user
    };
  }

  protected generateInvalidationTags(arg: PostMessageApiArg) {
    return [
      { type: 'Thread' as const, id: arg.threadId },
    ];
  }
}
