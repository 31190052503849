import { Box, Text } from 'native-base';
import { ComprehensiveReactionsInboxItemViewModel } from '@/adapters/view-models/InboxItemViewModel';

type ThreadReactionsProps = {
  inboxItem: ComprehensiveReactionsInboxItemViewModel;
};

export function ThreadReactionsBlock({ inboxItem }: ThreadReactionsProps) {
  return (
    <>
      <Box
        bgColor="opacityPrimaryDark.50"
        borderRadius={4}
        mr={3}
        px={1}
      >
        <Text fontSize="sm" letterSpacing={1}>
          {inboxItem.reactionSymbols}
        </Text>
      </Box>
      <Text
        fontSize="sm"
        numberOfLines={1}
        selectable={false}
        color="dark.900"
        flexShrink={1}
        isTruncated
      >
        {inboxItem.reactionText}
      </Text>
    </>
  );
}
