import React from 'react';
import {
  HStack, Center, Pressable, Skeleton
} from 'native-base';
import { ParticipantAvatars } from '../../ParticipantAvatars';
import { JoinButton } from '../../JoinButton';
import { PictureViewModel } from '@/adapters/view-models/PictureViewModel';
import { ManageParticipantsModalController, ManageParticipantsModalControllerHandles } from '../../../controllers/modals/ManageParticipantsModalController';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';

type ThreadScreenHeaderProps = {
  showSpinner?: boolean;
  participantsListPressedCallback?: () => void;
  participants: PictureViewModel[];
  selfUserJoinedThread: boolean;
  joinCallback: () => void;
  manageParticipantsModalControllerRef: React.RefObject<ManageParticipantsModalControllerHandles>;
  didSubmitNewParticipantsCallback: (participants: ParticipantViewModel[]) => void;
  didDeleteParticipantsCallback: (participants: ParticipantViewModel[]) => void;
  isDisabled?: boolean;
};

export function ThreadScreenParticipantsHeader(props: ThreadScreenHeaderProps) {
  if (props.showSpinner) {
    return (
      <Center flex={1} mx={6}>
        <Skeleton.Text lines={1} h={3} width="230px" />
      </Center>
    );
  }

  return (
    <HStack space={4} alignItems="center">
      <Pressable onPress={props.participantsListPressedCallback} disabled={props.isDisabled ?? false}>
        <ParticipantAvatars participants={props.participants} maxNumberOfAvatars={5} size="sm" />
      </Pressable>
      <JoinButton type="thread" joined={props.selfUserJoinedThread} subscribeCallback={props.joinCallback} isDisabled={props.isDisabled} />

      <ManageParticipantsModalController
        participants={props.participants}
        ref={props.manageParticipantsModalControllerRef}
        didSubmitNewParticipantsCallback={props.didSubmitNewParticipantsCallback}
        didDeleteParticipantsCallback={props.didDeleteParticipantsCallback}
        participantsType="users"
      />

    </HStack>
  );
}
