import { AuthorizationParams } from '@auth0/auth0-react';
import { EnvirontmentVariables } from '@/domain/services/environment-variables/EnvirontmentVariables';

export function generateAuthParams(env: EnvirontmentVariables): AuthorizationParams {
  return {
    redirect_uri: `${window.location.origin}/signin`,
    audience: env.AUTH0_AUDIENCE,
    connection: env.AUTH0_CONNECTION,
    scope: 'openid profile',
  };
}
