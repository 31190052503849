import React from 'react';
import {
  useRoute, RouteProp, NavigationProp, useNavigation
} from '@react-navigation/native';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';
import { useGetAllAccessibleChannels } from '../hooks/api/useGetAllAccessibleChannels';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useGetSelfInboxEvents } from '../hooks/api/useGetSelfInboxEvents';
import { useGetSelfUser } from '../hooks/api/useGetSelfUser';
import { useGetSelfOrganizationMembers } from '../hooks/api/useGetSelfOrganizationMembers';
import { getCategoryFromSlug } from '@/adapters/view-models/ChannelViewModel';
import { Paginator } from '../../ui/Paginator';
import { apiClient } from '@/adapters/api';

export function InboxHeaderController() {
  const route = useRoute<RouteProp<RootStackParamList, 'Inbox'>>();
  const { category: categorySlug, page } = route.params ?? {};
  const navigation = useNavigation<NavigationProp<RootStackParamList, 'Inbox'>>();
  const { currentData: selfUser } = useGetSelfUser();
  const { currentData: selfOrganizations, organizationId } = useGetSelfOrganizations();
  const { currentData: organizationMembers } = useGetSelfOrganizationMembers(organizationId);
  const { allRawChannels } = useGetAllAccessibleChannels(selfOrganizations);
  const { currentData: events } = useGetSelfInboxEvents(selfUser?.id, organizationMembers?.users, categorySlug, page, allRawChannels);
  const prefetchEvents = apiClient.usePrefetch('getSelfInboxEvents');
  const category = getCategoryFromSlug(categorySlug);

  if (!events) {
    return null;
  }

  return (
    <Paginator
      meta={events.meta}
      onPageChange={(pageToken) => navigation.setParams({ page: pageToken })}
      onPrefetchPage={(pageToken) => prefetchEvents({ category, pageToken })}
    />
  );
}
