import Quill from 'quill';

export const convertHtmlToDeltaOps = (html: string) => {
  const container = document.createElement('div');
  const quill = new Quill(container);
  quill.clipboard.dangerouslyPasteHTML(html);
  const deltaOps = quill.getContents().ops;
  container.remove();
  return deltaOps;
};
