import React, { useCallback } from 'react';
import { useUpdateThreadMutation } from '@/adapters/api/codegen';
import { SCREEN_SECTION_DEFAULT_QUERY_PARAMETERS, THREAD_POLLING_INTERVAL } from '../../../api/query-parameters';
import { ComprehensiveThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { InputUncontrolled } from '@/infrastructure/ui/InputUncontrolled';

export const THREAD_QUERY_OPTIONS = { ...SCREEN_SECTION_DEFAULT_QUERY_PARAMETERS, pollingInterval: THREAD_POLLING_INTERVAL };

type ThreadScreenHeaderTitleProps = {
  thread?: ComprehensiveThreadViewModel;
  showLoader: boolean;
};

export function ThreadScreenHeaderTitle(props: ThreadScreenHeaderTitleProps) {
  const [updateThread, { isLoading: isUpdatingThread }] = useUpdateThreadMutation();

  const updateThreadTitle = useCallback(
    (title: string) => {
      const actualTitle = title.length > 0 ? title : null;

      if (actualTitle === props.thread?.title) {
        return;
      }

      void updateThread({
        threadId: props.thread!.id,
        threadUpdateRequestBody: {
          title: actualTitle,
        }
      });
    },
    [updateThread, props.thread]
  );

  if (props.showLoader) {
    return null;
    // return <Skeleton.Text lines={1} h={3} width="230px" />;
  }

  if (!props.thread) {
    return null;
  }

  const defaultTitle = props.thread?.title ?? undefined;

  return (
    <InputUncontrolled
      my={1}
      onBlur={(e) => updateThreadTitle(e.nativeEvent.text)}
      placeholder="Untitled thread"
      defaultValue={defaultTitle}
      placeholderTextColor="gray.400"
      fontFamily="heading"
      readOnly={isUpdatingThread || props.thread.isTrashed}
      flexGrow={1}
      fontSize="xl"
      fontWeight="bold"
      bgColor="transparent"
      borderColor="transparent"
      _focus={{ borderColor: 'opacityPrimaryDarker.100' }}
      _hover={{ borderColor: 'opacityPrimaryDarker.100' }}
      outlineColor="transparent"
      focusOutlineColor="opacityPrimaryDarker.100"
      borderRadius={8}
    />
  );
}
