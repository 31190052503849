import { RefObject, useCallback } from 'react';
import { RouteProp, StackNavigationState, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useCancelMessageAlertContext } from '@/infrastructure/ui/schedule-send/CancelMessageAlert';
import { ComposeAreaHandles } from '@/infrastructure/ui/compose';
import { useAppDispatch } from '@/infrastructure/state/hooks';
import { RootStackParamList } from '@/infrastructure/navigation/navigators/root/RootStackProps';
import { closeUndoToast, useWithMutationMetadata } from '@/adapters/mutation-cancellation/mutationCancellation';
import { useDeleteMessageMutation } from '@/adapters/api/codegen';
import { apiClient } from '@/adapters/api';
import { store } from '@/domain/state/store';
import {
  draftActions, DraftViewModel, selectSendingDraft, selectThreadDraft
} from '@/domain/state/drafts';
import { AppScreenStackParamList } from '@/infrastructure/navigation/navigators/app/AppScreenProps';
import { convertHtmlToDeltaOps } from '@/infrastructure/ui/schedule-send/convertHtmlToDeltaOps';
import { createPendingParticipants, MessageViewModel } from '@/adapters/view-models/MessageViewModel';
import { createParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { CreateMessageContext } from '@/adapters/mutation-cancellation/contexts/CreateMessageContext';
import { ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { useGetAllAccessibleChannels } from '@/infrastructure/controllers/hooks/api/useGetAllAccessibleChannels';
import { useGetSelfOrganizations } from '@/infrastructure/controllers/hooks/api/useGetSelfOrganizations';

export type CancelSource = 'undo' | 'inlineButton';

export type CancelCallbackOptions = {
  channelId?: string;
  force?: boolean;
  finalizeUndoCallback?: () => void;
  context?: CreateMessageContext;
};

export type CancelResult = {
  pendingConfirmation: boolean;
};

export type CancelCallback = (messageId: string, source: CancelSource, threadId: string, options?: CancelCallbackOptions) => CancelResult;
export type ConfirmCancelCallback = () => void;

export function useCancelMessageCallback(
  draftKey: string,
  composeAreaRef?: RefObject<ComposeAreaHandles>,
  contextChannelId?: string,
) {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const [deleteMessage] = useWithMutationMetadata(useDeleteMessageMutation());
  const { currentData: selfOrganizations, organizationId } = useGetSelfOrganizations();
  const { allChannelsById } = useGetAllAccessibleChannels(selfOrganizations);
  const cancelMessageAlertContext = useCancelMessageAlertContext();

  const cancelCallback: CancelCallback = useCallback(function innerCancelCallback(messageId: string, source: CancelSource, threadId: string, options?: CancelCallbackOptions): CancelResult {
    // This callback will be given to the undo.onBeforeCancel method, and thus will be called with stale data.
    // We need to get fresh data manually during the execution.
    const { data: members } = apiClient.endpoints.getOrganizationMembers.select({ organizationId: organizationId || '' })(store.getState());
    const { data: thread } = apiClient.endpoints.getThread.select({ threadId })(store.getState());
    const { data: messagesViewModel } = apiClient.endpoints.getThreadMessages.select({ threadId })(store.getState());
    if (!messagesViewModel) return { pendingConfirmation: false };
    const { messages } = messagesViewModel;

    const channelId = options?.channelId ?? contextChannelId;
    const navState = navigation.getState();
    const { targetDraftLocation, resetToDraft } = getTargetDraftLocation(messages, navState, source, threadId, channelId);
    const targetDraftKey = targetDraftLocation === 'compose' ? 'compose' : draftKey;

    const foundDraft = selectThreadDraft(store.getState(), targetDraftKey);
    if (foundDraft && !options?.force) {
      cancelMessageAlertContext.showCancelAlert(
        targetDraftLocation,
        () => { innerCancelCallback(messageId, source, threadId, { ...options, force: true }); },
      );
      return { pendingConfirmation: true };
    }
    // Wait for the confirmation before closing the undo toast.
    if (source !== 'undo') {
      closeUndoToast();
    }
    if (options?.force && options?.finalizeUndoCallback) {
      options?.finalizeUndoCallback();
    }

    const cancelledMessage = messages?.find((message) => message.id === messageId);
    const cancelledDraft = selectSendingDraft(store.getState(), threadId, messageId);
    if (!cancelledMessage && !cancelledDraft) {
      return { pendingConfirmation: false };
    }

    // When sending a message, the optimistically created version of might come from the draft system instead. This is
    // only true for PostMessage, not CreateThread which works the usual way by optimistically inserting directly inside
    // the rtk query cache.
    const baseDraft: Pick<DraftViewModel, 'id' | 'title' | 'updatedAt'> = {
      id: targetDraftKey,
      title: thread?.title && ['compose', 'channel'].includes(targetDraftLocation) ? thread?.title : '', // title is only necessary for new threads
      updatedAt: Date.now(),
    };
    const newDraft: DraftViewModel = cancelledMessage ? {
      ...baseDraft,
      content: convertHtmlToDeltaOps(cancelledMessage.body),
      participants: cancelledMessage.pending_recipients,
      committedAttachments: cancelledMessage.attachments,
    } : {
      ...baseDraft,
      content: convertHtmlToDeltaOps(cancelledDraft!.messageCreateRequestBody.message_body),
      participants: createPendingParticipants(cancelledDraft!.messageCreateRequestBody, members).map(createParticipantViewModel),
      stagedAttachments: cancelledDraft!.stagedAttachments,
    };

    // When using the compose screen section, users can add channels as participants. To support cancelling and putting
    // back the channels inside the participant bar, we need to get them from the mutation context directly as they
    // aren't returned by the backend.
    if (options?.context?.channelIds) {
      const channelParticipants = options.context.channelIds
        .map((channelId: string): ChannelViewModel | undefined => allChannelsById?.get(channelId))
        .filter((channel: ChannelViewModel | undefined): channel is ChannelViewModel => Boolean(channel));
      newDraft.participants = (newDraft.participants || []).concat(...channelParticipants);
    }
    dispatch(draftActions.saveDraft(newDraft));
    if (resetToDraft) {
      composeAreaRef?.current?.resetToDraft();
    }

    // The undo command will take care of deleting the message by itself.
    if (source !== 'undo') {
      void deleteMessage({ messageId }, {
        initiator: 'user',
        cancellable: false,
      });
    }

    // When resetting the draft, we stay on the same view, meaning we don't have to navigate the user to its draft.
    if (resetToDraft) {
      return { pendingConfirmation: false };
    }

    if (targetDraftLocation === 'compose') {
      // - remove the deleted thread from the navigation stack.
      // - refresh the "compose" screen if the user is already on it.
      navigation.reset({
        index: 0,
        routes: [{ name: 'Compose' }],
      });
    } else if (targetDraftLocation === 'thread') {
      navigation.reset({
        index: 0,
        routes: [
          channelId
            ? { name: 'Channel', params: { channelId } }
            : { name: 'Inbox' },
          { name: 'Thread', params: { threadId } },
        ],
      });
    }
    return { pendingConfirmation: false };
  }, [
    draftKey,
    composeAreaRef,
    dispatch,
    deleteMessage,
    navigation,
    contextChannelId,
    allChannelsById,
    organizationId,
    cancelMessageAlertContext,
  ]);

  return cancelCallback;
}

export type TargetDraftLocation = 'thread' | 'channel' | 'compose';

function getTargetDraftLocation(
  messages: MessageViewModel[],
  navState: StackNavigationState<RootStackParamList>,
  source: CancelSource,
  threadId: string,
  channelId?: string,
): { targetDraftLocation: TargetDraftLocation, resetToDraft: boolean } {
  const lastRoute = navState.routes[navState.routes.length - 1];
  const isCreateThread = messages.length === 1;

  if (isCreateThread) {
    if (source === 'inlineButton') {
      return {
        targetDraftLocation: 'compose',
        resetToDraft: false,
      };
    }
    // source === 'undo'
    if (lastRoute.name === 'Channel' && (lastRoute as RouteProp<AppScreenStackParamList, 'Channel'>).params.channelId === channelId) {
      return {
        targetDraftLocation: 'channel',
        resetToDraft: true,
      };
    }
    return {
      targetDraftLocation: 'compose',
      resetToDraft: false,
    };
  }

  // PostMessage
  if (source === 'inlineButton') {
    return {
      targetDraftLocation: 'thread',
      resetToDraft: true,
    };
  }
  // source === 'undo'
  if (lastRoute.name === 'Thread' && (lastRoute as RouteProp<AppScreenStackParamList, 'Thread'>).params.threadId === threadId) {
    return {
      targetDraftLocation: 'thread',
      resetToDraft: true,
    };
  }
  return {
    targetDraftLocation: 'thread',
    resetToDraft: false,
  };
}
