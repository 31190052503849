import { User } from '@auth0/auth0-react';
import {
  ConversationParticipant, GetSelfUserApiResponse, Message, ThreadCreateRequestBody
} from '../../api/codegen';
import { UsersViewModel } from '@/adapters/view-models/UsersViewModel';
import { UserViewModel } from '@/adapters/view-models/UserViewModel';

export function createMessage(request: ThreadCreateRequestBody, members: UsersViewModel | undefined, user: GetSelfUserApiResponse): Message {
  return {
    id: request.message_id,
    is_unread: false,
    body: request.message_body,
    creation_date: request.scheduled_date || new Date().toISOString(),
    sender: {
      type: 'user',
      user: user || {
        display_name: 'Loading...',
      } as User
    },
    reactions: [],
    attachments: [],
    state: request.scheduled_date ? 'scheduled' : 'staged',
    pending_participants: [
      ...(request.external_participant_emails || []).map((email) => ({
        type: 'email',
        email_participant: { email },
      } satisfies ConversationParticipant)),
      ...(request.participant_ids || []).map((participant_id) => {
        const participant = members?.users.find((user: UserViewModel) => user.id === participant_id);
        return {
          type: 'user',
          user: {
            id: participant_id,
            email: participant?.email || '',
            display_name: participant?.displayName || '',
            features: participant?.features || [],
            picture_url: participant?.pictureInfo.url || '',
          },
        } satisfies ConversationParticipant;
      })
    ],
  } as Message;
}
