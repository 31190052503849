import { useState, useCallback, useRef } from 'react';
import { Status, getStatusFlags } from '../Status';

function useUploadFile() {
  const [progress, setProgress] = useState<number>(0);
  const [status, setStatus] = useState<Status>('not_started');
  const xhrRef = useRef<XMLHttpRequest | null>(null);

  const uploadFile = useCallback((file: File, presignedUrl: string, headers: object) => {
    return new Promise<void>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhrRef.current = xhr;

      xhr.open('PUT', presignedUrl, true);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded / event.total) * 100);
          setProgress(percentCompleted);
        }
      };

      xhr.timeout = 2 * 60 * 1000;

      xhr.onload = () => {
        if (xhr.status === 200) {
          setStatus('complete');
          resolve();
        } else {
          reject(new Error(`Upload failed with status: ${xhr.status}`));
          setStatus('error');
          setProgress(0);
        }
      };

      xhr.onerror = () => {
        reject(new Error('Upload failed due to an error.'));
        setStatus('error');
        setProgress(0);
      };

      xhr.onabort = () => {
        reject(new Error('Upload was cancelled.'));
        setStatus('not_started');
        setProgress(0);
      };

      xhr.ontimeout = () => {
        reject(new Error('Upload failed due to a timeout.'));
        setStatus('error');
        setProgress(0);
      };

      Object.entries(headers).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value);
      });

      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
      setStatus('in_progress');
    });
  }, []);

  const cancelFileUpload = useCallback(() => {
    if (xhrRef.current) {
      xhrRef.current.abort();
    }
  }, []);

  return {
    uploadFile, cancelFileUpload, progress, status, ...getStatusFlags(status)
  };
}

export default useUploadFile;
