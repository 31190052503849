import {
  Box, HStack, Text, Icon, Button, IIconButtonProps
} from 'native-base';
import { ReactNode, memo, useState } from 'react';
import { InterfaceIconProps } from 'native-base/lib/typescript/components/primitives/Icon/types';
import { CHANNEL_ICON_SETS, ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { UserViewModel } from '@/adapters/view-models/UserViewModel';
import { ParticipantAvatar } from './ParticipantAvatars';
import { PressableListElement } from './PressableListElement';
import { PictureViewModel } from '@/adapters/view-models/PictureViewModel';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';

export type ParticipantCallback = (recipient: ParticipantViewModel) => void;

export type ParticipantListItemProps = {
  recipient: ParticipantViewModel;
  selectParticipantCallback?: ParticipantCallback;
  hoverParticipantCallback?: ParticipantCallback;
  interactingWithParticipantsListCallback?: (isInteracting: boolean) => void;
  bold?: boolean;
  sideElementFixedWidth?: number;
  actions?: {
    icon?: InterfaceIconProps;
    variant? : IIconButtonProps['variant'];
    text?: string;
    pressCallback: () => void;
  }[];
  children?: ReactNode;
};

export const ParticipantListItem = memo((props: ParticipantListItemProps) => {
  const { recipient } = props;
  const isMember = 'displayName' in recipient || ('user' in recipient && recipient.user !== null);
  const isChannel = recipient._type === 'channel';

  const channel = recipient as ChannelViewModel;
  const member = (recipient as UserViewModel);
  const picture = (recipient as PictureViewModel);

  const [isItemHovered, setIsItemHovered] = useState(false);
  const [isAnyActionButtonHovered, setIsAnyActionButtonHovered] = useState(false);
  const displayActionButton = isItemHovered || isAnyActionButtonHovered;

  const selectCallback = () => {
    if (props.selectParticipantCallback) {
      props.selectParticipantCallback(recipient);
    }
  };

  const hoverCallback = () => {
    if (props.hoverParticipantCallback) {
      props.hoverParticipantCallback(recipient);
    }

    setIsItemHovered(true);
  };

  const containerProps: RecipientContainerProps = {
    onPress: selectCallback,
    selectable: Boolean(props.selectParticipantCallback || props.actions?.length),
    onPressIn: () => props.interactingWithParticipantsListCallback && props.interactingWithParticipantsListCallback(true),
    onPressOut: () => props.interactingWithParticipantsListCallback && props.interactingWithParticipantsListCallback(false),
    onHoverIn: () => hoverCallback(),
    onHoverOut: () => setIsItemHovered(false),
  };

  const actionButtons = props.actions?.map((action) => (
    <Button
      key={`${action?.text}-${action.icon?.name}`}
      display={displayActionButton ? 'flex' : 'none'}
      variant={action.variant || 'text'}
      size="xs"
      _text={{ bold: true }}
      onPress={action.pressCallback}
      onHoverIn={() => setIsAnyActionButtonHovered(true)}
      onHoverOut={() => setIsAnyActionButtonHovered(false)}
      leftIcon={action.icon ? <Icon as={action.icon.as} name={action.icon.name} size="sm" /> : undefined}
      my={-2}
    >
      {action.text}
    </Button>
  ));

  const children = props.children ? (
    <Box display={!displayActionButton ? 'flex' : 'none'}>{ props.children }</Box>
  ) : null;

  const sideElements = (
    <HStack width={props.sideElementFixedWidth} justifyContent="center">
      {actionButtons}
      {children}
    </HStack>
  );

  if (isMember) {
    return (
      <RecipientContainer {...containerProps}>
        <RecipientIcon>
          <ParticipantAvatar pictureInfo={member.pictureInfo} size="xs" />
        </RecipientIcon>
        <Text bold={props.bold} flexGrow={1}>{truncateName(member.displayName)}</Text>
        {sideElements}
      </RecipientContainer>
    );
  }

  if (isChannel) {
    const channelIconSet = CHANNEL_ICON_SETS[channel.iconSetName];
    return (
      <RecipientContainer {...containerProps}>
        <RecipientIcon>
          <Icon name={channelIconSet.default.name} as={channelIconSet.default.collection} color="muted.600" />
        </RecipientIcon>
        <Text bold={props.bold} flexGrow={1}>{truncateName(channel.name)}</Text>
        {sideElements}
      </RecipientContainer>
    );
  }

  return (
    <RecipientContainer {...containerProps}>
      <RecipientIcon>
        <ParticipantAvatar pictureInfo={picture} size="xs" />
      </RecipientIcon>
      <Text bold={props.bold} flexGrow={1}>{truncateName(picture.name)}</Text>
      {sideElements}
    </RecipientContainer>
  );
}, areEqual);

function areEqual(prevProps: ParticipantListItemProps, nextProps: ParticipantListItemProps) {
  if (prevProps.recipient.id !== nextProps.recipient.id
      || prevProps.bold !== nextProps.bold
      || prevProps.children !== nextProps.children
      || prevProps.selectParticipantCallback !== nextProps.selectParticipantCallback
      || prevProps.hoverParticipantCallback !== nextProps.hoverParticipantCallback
      || prevProps.interactingWithParticipantsListCallback !== nextProps.interactingWithParticipantsListCallback
      || (prevProps.actions?.length !== nextProps.actions?.length)) {
    return false;
  }

  if (prevProps.actions && nextProps.actions) {
    for (let i = 0; i < prevProps.actions.length; i++) {
      if (prevProps.actions[i].text !== nextProps.actions[i].text
          || prevProps.actions[i].icon?.name !== nextProps.actions[i].icon?.name) {
        return false;
      }
    }
  }

  return true;
}

export type RecipientContainerProps = Parameters<typeof PressableListElement>[0] & {
  selectable: boolean;
};

export function RecipientContainer(props: RecipientContainerProps) {
  // PressableElement adds a mx={2} to the container, thus to keep items aligned we need to add 2 to the left padding when not pressable.
  const recipient = (
    <HStack pr={3} pl={props.selectable ? 3 : 5} py={2} space={2} alignItems="center" flexGrow={1}>
      {props.children}
    </HStack>
  );

  if (props.selectable) {
    return (
      <PressableListElement {...props}>
        {recipient}
      </PressableListElement>
    );
  }

  return recipient;
}

export function RecipientIcon(props: { children: ReactNode; }) {
  return (
    <Box w={7} flexDirection="row" justifyContent="center">
      {props.children}
    </Box>
  );
}

function truncateName(name: string) {
  if (name.includes('@')) {
    return truncateEmail(name);
  }
  return name.length <= 50 ? name : `${name.slice(0, 30)}...`;
}

function truncateEmail(email: string) {
  const [name, domain] = email.split('@');
  if (name.length <= 30) {
    return email;
  }
  return `${name.slice(0, 30)}...@${domain}`;
}
