import { GetChannelThreadsApiArg } from '../../api/codegen';
import { getCurrentScreenParams } from '@/infrastructure/navigation/getCurrentScreenParams';
import { rootNavigationContainerRef } from '@/infrastructure/navigation/navigators/root/RootNavigationContainerRef';

export function getCurrentChannelParams(): GetChannelThreadsApiArg | undefined {
  const channelParams = getCurrentScreenParams(rootNavigationContainerRef.current?.getState(), 'Channel');

  if (channelParams) {
    return { channelId: channelParams.channelId, pageToken: channelParams.page };
  }

  return undefined;
}
