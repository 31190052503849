import { useCallback } from 'react';
import {
  buildMoveToInboxMutationDescription,
  buildThreadSnoozedMutationDescription
} from '../../UndoMutationDescriptions';
import {
  MutationInitiator,
  useWithMutationMetadata
} from '@/adapters/mutation-cancellation/mutationCancellation';
import { ThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { useAddThreadToInboxMutation, useSnoozeThreadMutation } from '@/adapters/api/codegen';

export function useSnoozeThreadCallback() {
  const [snoozeThread] = useWithMutationMetadata(useSnoozeThreadMutation());
  const [moveToInbox] = useWithMutationMetadata(useAddThreadToInboxMutation());

  return useCallback((thread: ThreadViewModel, snoozeEndDate: Date | null, initiator?: MutationInitiator) => {
    if (snoozeEndDate === null) {
      void moveToInbox({ threadId: thread.id }, {
        initiator: initiator || 'user',
        description: buildMoveToInboxMutationDescription(),
      });
    } else {
      void snoozeThread(
        {
          threadId: thread.id,
          snoozeThreadRequestBody: {
            snooze_end_date: Math.floor(snoozeEndDate.getTime() / 1000),
          },
        },
        {
          initiator: initiator || 'user',
          description: buildThreadSnoozedMutationDescription(snoozeEndDate),
          cancellable: false, // Remove this when cancelling snoozes works again.
        }
      );
    }
  }, [moveToInbox, snoozeThread]);
}
