import { CHANNEL_PARTICIPANTS_FETCH_INTERVAL } from '../../../api/query-parameters';
import { ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { apiClient, useGetChannelParticipantsQuery } from '@/adapters/api';

export const useGetChannelParticipants = (channel: ChannelViewModel | undefined) => {
  return useGetChannelParticipantsQuery({ channelId: channel?.id ?? '' }, {
    pollingInterval: CHANNEL_PARTICIPANTS_FETCH_INTERVAL,
    skip: !channel,
  });
};

export const usePrefetchGetChannelParticipants = () => apiClient.usePrefetch('getChannelParticipants');
