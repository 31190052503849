export type Status = 'not_started' | 'in_progress' | 'complete' | 'error';

export function getStatusFlags(status: string) {
  const isComplete = status === 'complete';
  const isInProgress = status === 'in_progress';
  const isError = status === 'error';
  const isNotStarted = status === 'not_started';

  return {
    isComplete, isInProgress, isError, isNotStarted
  };
}
