import { getCurrentScreenParams, RouteInStackWithNoParametersDefined } from '../../../infrastructure/navigation/getCurrentScreenParams';
import { rootNavigationContainerRef } from '../../../infrastructure/navigation/navigators/root/RootNavigationContainerRef';
import { GetSelfScheduledThreadsApiArg } from '../../api/codegen';

export function getScheduledParams(): GetSelfScheduledThreadsApiArg | undefined {
  const scheduledParams = getCurrentScreenParams(rootNavigationContainerRef.current?.getState(), 'Scheduled');

  if (scheduledParams) {
    return { pageToken: scheduledParams.page };
  }

  if (scheduledParams === RouteInStackWithNoParametersDefined) {
    return {};
  }
}
