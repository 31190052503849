/* eslint-disable no-irregular-whitespace */
import { useLayoutEffect, useRef, useState } from 'react';
import {
  Box, Button, Divider, IDividerProps, ITextProps, Text
} from 'native-base';
import { Animated } from 'react-native';
import { ShortcutTooltip } from '@/infrastructure/ui/components/ShortcutTooltip';

export type SendButtonProps = {
  enabled: boolean;
  containerHovered: boolean;
  datePickerOpen: boolean;
  isSendLaterPressed: boolean;
  onSendPressed: () => void;
};

export function SendButton(props: SendButtonProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const widthAnim = useRef(new Animated.Value(0)).current;
  const textOpacityAnim = useRef(new Animated.Value(1)).current;

  const sendLaterHovered = props.enabled && props.containerHovered && !isHovered;
  const showSendLaterText = sendLaterHovered || props.isSendLaterPressed || props.datePickerOpen;

  const usePressedStyle = isPressed || props.isSendLaterPressed;
  const applyHoverStyle = (props.enabled && (isHovered || sendLaterHovered)) || props.datePickerOpen;
  const useWhiteText = applyHoverStyle || usePressedStyle;

  const targetWidth = showSendLaterText ? 106 : 74;
  const targetOpacity = showSendLaterText ? 1 : 0;

  useLayoutEffect(() => {
    Animated.timing(widthAnim, {
      toValue: targetWidth,
      duration: 200,
      useNativeDriver: false,
    }).start();

    Animated.timing(textOpacityAnim, {
      toValue: targetOpacity,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, [showSendLaterText, targetWidth, widthAnim, textOpacityAnim, targetOpacity]);

  const textWidthAnim = textOpacityAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 32],
  });

  return (
    <ShortcutTooltip
      hint="Send"
      shortcut="mod+enter"
      placement="top"
      style={{ marginBottom: 2 }}
    >
      <Animated.View style={{ width: widthAnim }}>
        <Button
          pl="19px"
          py="7px"
          pr={0}
          borderWidth={1}
          borderRightWidth={0}
          borderRadius={8}
          borderTopRightRadius={0}
          borderBottomRightRadius={0}
          backgroundColor="white"
          _hover={{
            backgroundColor: 'primary.700',
            borderColor: 'primary.700',
          }}
          _pressed={{
            backgroundColor: 'primary.800',
            py: '8px',
            borderWidth: 0,
            pl: '20px',
          }}
          borderColor={props.enabled ? 'dark.100' : 'transparent'}
          disabled={!props.enabled}
          onHoverIn={() => setIsHovered(true)}
          onHoverOut={() => setIsHovered(false)}
          onPressIn={() => setIsPressed(true)}
          onPressOut={() => setIsPressed(false)}
          onPress={props.onSendPressed}
          isHovered={applyHoverStyle}
          isPressed={usePressedStyle}
        >
          <Box flex={1} flexDirection="row" alignItems="center" height={5}>
            <Text {...getTextProps(props.enabled, useWhiteText)}>
              Send
            </Text>
            <Animated.View
              style={{
                opacity: textOpacityAnim,
                width: textWidthAnim,
              }}
            >
              <Text {...getTextProps(props.enabled, useWhiteText)} overflow="hidden" flexWrap="nowrap" w="500px"> later</Text>
            </Animated.View>
            <Divider orientation="vertical" {...getDividerColorProps(props.enabled, sendLaterHovered)} ml="20px" width="1px" height={4} zIndex="10" />
          </Box>
        </Button>
      </Animated.View>
    </ShortcutTooltip>
  );
}

function getDividerColorProps(enabled: boolean, sendLaterHovered: boolean): IDividerProps {
  return {
    bg: enabled && sendLaterHovered ? 'white' : 'black',
    opacity: 0.05,
  };
}

function getTextProps(enabled: boolean, hoveredOrPressed: boolean): ITextProps {
  const baseProps: ITextProps = {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  };

  if (enabled) {
    return {
      ...baseProps,
      color: hoveredOrPressed ? 'white' : 'dark.700',
    };
  }

  return {
    ...baseProps,
    color: 'dark.200',
  };
}
