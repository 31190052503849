import { Text } from 'native-base';

type ScheduledHintTextProps = {
  children: React.ReactNode;
};
export function ScheduledHintText({ children }: ScheduledHintTextProps) {
  return (
    <Text selectable={false} fontSize="sm" color="scheduled.500" fontWeight={600}>
      {children}
    </Text>
  );
}
