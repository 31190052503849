import { useCallback } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { ChannelViewModel, isInboxCategory } from '@/adapters/view-models/ChannelViewModel';
import { AppDispatch, store } from '@/domain/state/store';
import { apiClient } from '@/adapters/api';

export function usePrefetchChannelCallback() {
  const prefetchChannelThreads = apiClient.usePrefetch('getChannelThreads');
  const prefetchChannelParticipants = apiClient.usePrefetch('getChannelParticipants');
  const prefetchInboxEvents = apiClient.usePrefetch('getSelfInboxEvents');
  const prefetchSentThreads = apiClient.usePrefetch('getSelfSentThreads');
  const prefetchSpamThreads = apiClient.usePrefetch('getSelfSpamThreads');
  const prefetchSnoozedThreads = apiClient.usePrefetch('getSelfSnoozedThreads');
  const prefetchScheduledThreads = apiClient.usePrefetch('getSelfScheduledThreads');
  const prefetchTrashedThreads = apiClient.usePrefetch('getSelfTrashedThreads');
  const prefetchStarredThreads = apiClient.usePrefetch('getSelfStarredThreads');
  const prefetchAllThreads = apiClient.usePrefetch('getSelfAllThreads');
  const dispatch = useAppDispatch();

  const callback = useCallback(
    (channel: ChannelViewModel) => {
      if (channel.id == 'inbox') {
        void prefetchInboxEvents({ category: 'CATEGORY_PRIMARY' });
      } else if (isInboxCategory(channel.id)) {
        void prefetchInboxEvents({ category: channel.id });
      } else if (channel.id == 'sent') {
        void prefetchSentThreads({});
      } else if (channel.id == 'snoozed') {
        void prefetchSnoozedThreads({});
      } else if (channel.id == 'scheduled') {
        void prefetchScheduledThreads({});
      } else if (channel.id == 'trash') {
        void prefetchTrashedThreads({});
      } else if (channel.id == 'starred') {
        void prefetchStarredThreads({});
      } else if (channel.id == 'all') {
        void prefetchAllThreads({});
      } else if (channel.id == 'drafts') {
        // don't prefetch draft threads for now
      } else if (channel.id == 'spam') {
        void prefetchSpamThreads({});
      } else {
        void prefetchChannelThreads({ channelId: channel.id });
        void prefetchChannelParticipants({ channelId: channel.id });
        upsertChannelInCache(getAllAccessibleChannels(), channel, dispatch);
      }
    },
    [
      prefetchInboxEvents,
      prefetchSentThreads,
      prefetchSnoozedThreads,
      prefetchScheduledThreads,
      prefetchTrashedThreads,
      prefetchStarredThreads,
      prefetchAllThreads,
      prefetchSpamThreads,
      prefetchChannelThreads,
      prefetchChannelParticipants,
      dispatch
    ]
  );

  return callback;
}

function upsertChannelInCache(channels: ChannelViewModel[] | undefined, channel: ChannelViewModel, dispatch: AppDispatch) {
  const foundChannel = channels?.find((t) => t.id === channel.id);
  const cachedChannelData = !!apiClient.endpoints.getChannel.select({ channelId: channel.id })(store.getState()).data;

  if (foundChannel && !cachedChannelData) {
    void dispatch(
      apiClient.util.upsertQueryData('getChannel', { channelId: channel.id }, foundChannel)
    );
  }
}

function getAllAccessibleChannels() {
  const state = store.getState();
  const orgs = apiClient.endpoints.getSelfOrganizations.select()(state).data;

  if (!orgs?.organizations.length) {
    return [];
  }

  const selfChannels = apiClient.endpoints.getSelfChannels.select()(state).data?.channels ?? [];
  const orgChannels = apiClient.endpoints.getOrganizationChannels.select({ organizationId: orgs.organizations[0].id })(state).data?.channels ?? [];

  return [...selfChannels, ...orgChannels];
}
