import { ApiEndpointQuery, QuerySubState } from '@reduxjs/toolkit/query';
import { MutationHandler, MutationHandlerProps } from '@/adapters/mutation-handlers/MutationHandler';
import { SendMessageNowApiArg, SendMessageNowApiResponse } from '@/adapters/api/codegen';
import { formatDate } from '@/adapters/view-models/utilities';
import { store } from '@/domain/state/store';
import {
  createThreadUpdatePatchesForThreadId
} from '@/adapters/mutation-handlers/thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { updateEarliestScheduledDate } from '@/adapters/view-models/ThreadViewModel';

type ExtractQueryDefinition<T> = T extends ApiEndpointQuery<infer QD, any> ? QD : never;

export class SendMessageNowMutationHandler extends MutationHandler<SendMessageNowApiArg, SendMessageNowApiResponse> {
  constructor(props: MutationHandlerProps<SendMessageNowApiArg, SendMessageNowApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: SendMessageNowApiArg) {
    const threadId = this.findMessageThread(patch);
    if (!threadId) {
      return [];
    }

    const {
      removeThreadFromScheduledFolder,
      messageCommitted,
    } = this.computeWhichPatchesToApply(patch, threadId);

    return [
      this.createMessageListPatch(patch, threadId),
      ...(removeThreadFromScheduledFolder ? [this.removeThreadFromScheduledFolder(threadId)] : []),
      ...(messageCommitted ? this.createRemoveScheduledMessageCreationDateFromThreadPatches(patch) : [])
    ];
  }

  private computeWhichPatchesToApply(patch: SendMessageNowApiArg, threadId: string) {
    const { messages } = (this.apiClient.endpoints.getThreadMessages.select({ threadId })(this.state).data!);

    let scheduledMessagesCount = 0;
    let committedScheduledMessage = false;
    let messageCommitted = false;
    for (const message of messages) {
      if (message.state === 'scheduled') {
        scheduledMessagesCount++;
      }
      if (patch.messageId === message.id) {
        messageCommitted = true;
        if (message.state === 'scheduled') {
          committedScheduledMessage = true;
        }
      }
    }

    return {
      removeThreadFromScheduledFolder: scheduledMessagesCount === 1 && committedScheduledMessage,
      messageCommitted,
    };
  }

  private* createRemoveScheduledMessageCreationDateFromThreadPatches(patch: SendMessageNowApiArg) {
    const updateThreadPatches = createThreadUpdatePatchesForThreadId(
      this.getCurrentThreadId()!,
      (draftedThread) => {
        delete draftedThread.scheduledMessagesCreationDates[patch.messageId];
        updateEarliestScheduledDate(draftedThread);
      }
    );
    for (const updateThreadPatch of updateThreadPatches) {
      yield updateThreadPatch;
    }
  }

  private createMessageListPatch(patch: SendMessageNowApiArg, threadId: string) {
    return this.updateQueryData('getThreadMessages', { threadId }, (draftedMessages) => {
      const now = new Date();
      for (const message of draftedMessages.messages) {
        if (patch.messageId === message.id) {
          message.state = 'committed';
          message.rawDate = now.getTime();
          message.date = formatDate(now.toISOString());
          break;
        }
      }
    });
  }

  private removeThreadFromScheduledFolder(threadId: string) {
    return this.updateQueryData('getSelfScheduledThreads', {}, (draftedThreads) => {
      for (let i = 0; i < draftedThreads.threads.length; i++) {
        if (draftedThreads.threads[i].id === threadId) {
          draftedThreads.threads.splice(i, 1);
          break;
        }
      }
    });
  }

  /*
  * It may happen that when calling sendNow, the user has navigated away and "getCurrentThreadId" then returns
  * an invalid value (either another thread or nothing). Thus, in that event, we need to look around to find the linked
  * thread.
  *
  * This function makes the following assumptions:
  * - The message is still there.
  * - There are not enough getThreadMessages queries / messages to freeze the main thread.
  * */
  private findMessageThread(patch: SendMessageNowApiArg): string | undefined {
    // 1. Check if the current thread id is the right one.
    const state = store.getState();
    const currentThreadId = this.getCurrentThreadId();
    if (currentThreadId) {
      const output = this.apiClient.endpoints.getThreadMessages.select({ threadId: currentThreadId })(state);
      if (output && output.data?.messages) {
        const message = output.data?.messages.find((message) => message.id === patch.messageId);
        if (message) {
          return currentThreadId;
        }
      }
    }

    // 2. If not, look at the whole query cache.
    for (const key of Object.keys(state.api.queries)) {
      if (state.api.queries[key]?.endpointName !== 'getThreadMessages') {
        continue;
      }

      const query = state.api.queries[key] as QuerySubState<ExtractQueryDefinition<typeof this.apiClient.endpoints.getThreadMessages>>;

      for (const message of (query.data?.messages || [])) {
        if (message.id === patch.messageId) {
          return query.originalArgs!.threadId;
        }
      }
    }
  }
}
