import React, { type ComponentProps, useMemo } from 'react';
import { useToken } from 'native-base';
import { getHash } from '@/infrastructure/global/hash';

import './ChannelColorIndicator.css';

export const useChannelColor = (channelId: string) => {
  const channelColors = useToken('colors', 'channel');
  return useMemo(() => {
    if (channelId) {
      const hash = getHash(channelId);
      const colorNames = Object.keys(channelColors);
      return `channel.${colorNames[hash % colorNames.length]}`;
    }
  }, [channelColors, channelId]);
};

export function ChannelColorIndicator({ channelId, ...rest }: { channelId: string; } & ComponentProps<'div'>) {
  const channelColor = useChannelColor(channelId);
  const color = useToken('colors', channelColor || 'transparent');
  if (!channelColor) return null;
  return (
    <div
      className="channel-color-indicator"
      {...rest}
      style={{
        backgroundColor: color,
        ...rest.style,
      }}
    />
  );
}
