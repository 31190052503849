import {
  Box, Divider, FlatList, Icon, Spinner
} from 'native-base';
import React, {
  ComponentProps, memo, RefObject, useCallback
} from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { useSnoozeElapsedTimeHint } from '@/infrastructure/ui/screen-sections/channel/hooks/useSnoozeElapsedTimeHint';
import { ComposeArea, ComposeAreaHandles, ComposeAreaSubmitCallback } from '../compose';
import SnippetThreadListItem from '../thread-list-items/SnippetThreadListItem';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { ComprehensiveThreadOrInboxItemViewModel, isInboxItemViewModel } from '@/adapters/view-models/InboxItemViewModel';
import { ComprehensiveThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { ReactionsThreadListItem } from '../thread-list-items/ReactionsThreadListItem';
import SnippetAndReactionsThreadListItem from '../thread-list-items/SnippetAndReactionsThreadListItem';
import { ThreadListContainer } from './ThreadListContainer';
import { ActiveThreadBackground } from './ActiveThreadBackground';
import { ThreadFlatList, ThreadListRenderItemInfo } from './ThreadFlatList';
import { EmptyScreenSection } from '../EmptyScreenSection';
import { ThreadActionBarProps } from '../ThreadActionBar';
import { DropZone } from '../DropZone';

export const HORIZONTAL_PADDING = 4;

type ThreadListProps = ThreadListItemCallbackParams & {
  showSpinner: boolean;
  submitCallback?: ComposeAreaSubmitCallback;
  items: ComprehensiveThreadOrInboxItemViewModel[] | undefined;
  errorToDisplay: string | undefined;
  showComposeArea: boolean;
  participants?: ParticipantViewModel[];
  participantsChangedCallback?: (participants: ParticipantViewModel[]) => void;
  composeDraftKey?: ComponentProps<typeof ComposeArea>['draftKey'];
  ListEmptyComponent?: ComponentProps<typeof FlatList>['ListEmptyComponent'];
  composeAreaRef?: RefObject<ComposeAreaHandles>;
};

export const ThreadList = memo(function ThreadList(props: ThreadListProps) {
  const threadListItem = useThreadListItemCallback(props);

  const listEmptyComponent = useListEmptyComponent(props.errorToDisplay, props.ListEmptyComponent);
  const showComposeArea = props.showComposeArea && !props.errorToDisplay;
  const maybeWithDropZone = (children: React.ReactNode) => {
    return children;
  };

  return (
    <Box flex={1} minHeight={300}>
      <Divider />
      <Spinner size="lg" flexGrow={1} display={props.showSpinner ? undefined : 'none'} />
      {!props.showSpinner && maybeWithDropZone(
        <ThreadListContainer items={props.items}>
          <ThreadFlatList
            renderItem={threadListItem}
            data={props.items}
            ListEmptyComponent={listEmptyComponent}
            ListHeaderComponent={<ActiveThreadBackground />}
            ListFooterComponent={<Box mb={4} />}
            keyExtractor={(item) => (isInboxItemViewModel(item) ? item.thread.id : item.id)}
          />
        </ThreadListContainer>,
      )}
      {showComposeArea && (
      <Box px={2}>
        <DropZone attachFilesCallback={props.composeAreaRef?.current?.attachFilesCallback} flexGrow={1} flexShrink={1} underlyingDivStyle={{ paddingBottom: 8 }}>
          <ComposeArea
            bodyPlaceholder="Type a message to create a new thread"
            submitCallback={props.submitCallback}
            showTitle
            participantsChangedCallback={props.participantsChangedCallback}
            draftKey={props.composeDraftKey}
            ref={props.composeAreaRef}
            participants={props.participants}
          />
        </DropZone>
      </Box>
      )}
    </Box>
  );
});

export type EmptyChannelScreenSectionProps = {
  text: string;
  iconCollection: Parameters<typeof Icon>[0]['as'];
  iconName: string;
};

function useListEmptyComponent(error: string | undefined, defaultListEmptyComponent: ComponentProps<typeof FlatList>['ListEmptyComponent']) {
  return error ? <EmptyScreenSection text={error} iconCollection={MaterialIcons} iconName="error-outline" /> : defaultListEmptyComponent;
}

type ThreadListItemCallbackParams = Omit<ThreadActionBarProps, 'thread'> & {
  selectThreadCallback: (thread: ComprehensiveThreadOrInboxItemViewModel) => void,
  threadHoveredCallback: (thread: ComprehensiveThreadViewModel) => void,
};

function useThreadListItemCallback(
  threadActionsProps: ThreadListItemCallbackParams,
) {
  const getSnoozeElapsedTimeHint = useSnoozeElapsedTimeHint();

  return useCallback(
    (listItem: ThreadListRenderItemInfo<ComprehensiveThreadOrInboxItemViewModel>) => {
      const { item } = listItem;
      const snoozeElapsedTimeHint = getSnoozeElapsedTimeHint(item);

      if (!isInboxItemViewModel(item) || item.displayType === 'snippet') {
        const thread = isInboxItemViewModel(item) ? item.thread : item;

        return (
          <SnippetThreadListItem
            thread={thread}
            snoozeElapsedTimeHint={snoozeElapsedTimeHint}
            archiveThreadCallback={threadActionsProps.archiveThreadCallback}
            moveThreadToInboxCallback={threadActionsProps.moveThreadToInboxCallback}
            changeUnreadStatusCallback={threadActionsProps.changeUnreadStatusCallback}
            threadHoveredCallback={threadActionsProps.threadHoveredCallback}
            selectThreadCallback={threadActionsProps.selectThreadCallback}
            starThreadCallback={threadActionsProps.starThreadCallback}
            unstarThreadCallback={threadActionsProps.unstarThreadCallback}
            setSnoozeEndDateCallback={threadActionsProps.setSnoozeEndDateCallback}
          />
        );
      }

      if (item.displayType === 'reactions') {
        return (
          <ReactionsThreadListItem
            inboxItem={item}
            snoozeElapsedTimeHint={snoozeElapsedTimeHint}
            archiveThreadCallback={threadActionsProps.archiveThreadCallback}
            moveThreadToInboxCallback={threadActionsProps.moveThreadToInboxCallback}
            changeUnreadStatusCallback={threadActionsProps.changeUnreadStatusCallback}
            threadHoveredCallback={threadActionsProps.threadHoveredCallback}
            selectThreadCallback={threadActionsProps.selectThreadCallback}
            starThreadCallback={threadActionsProps.starThreadCallback}
            unstarThreadCallback={threadActionsProps.unstarThreadCallback}
            setSnoozeEndDateCallback={threadActionsProps.setSnoozeEndDateCallback}
          />
        );
      }

      return (
        <SnippetAndReactionsThreadListItem
          inboxItem={item}
          snoozeElapsedTimeHint={snoozeElapsedTimeHint}
          archiveThreadCallback={threadActionsProps.archiveThreadCallback}
          moveThreadToInboxCallback={threadActionsProps.moveThreadToInboxCallback}
          changeUnreadStatusCallback={threadActionsProps.changeUnreadStatusCallback}
          threadHoveredCallback={threadActionsProps.threadHoveredCallback}
          selectThreadCallback={threadActionsProps.selectThreadCallback}
          starThreadCallback={threadActionsProps.starThreadCallback}
          unstarThreadCallback={threadActionsProps.unstarThreadCallback}
          setSnoozeEndDateCallback={threadActionsProps.setSnoozeEndDateCallback}
        />
      );
    },
    [
      getSnoozeElapsedTimeHint,
      threadActionsProps.archiveThreadCallback,
      threadActionsProps.moveThreadToInboxCallback,
      threadActionsProps.changeUnreadStatusCallback,
      threadActionsProps.threadHoveredCallback,
      threadActionsProps.selectThreadCallback,
      threadActionsProps.starThreadCallback,
      threadActionsProps.unstarThreadCallback,
      threadActionsProps.setSnoozeEndDateCallback
    ]
  );
}
