import { updateQueryData } from '../../utils/updateQueryData';
import { getInboxParams } from '../../utils/getInboxParams';
import { allUserInboxCategories } from '@/adapters/view-models/ChannelViewModel';
import { InboxItemsUpdateHandler } from '../InboxItemsUpdateHandler';

export function* createInboxPatches(inboxItemUpdateHandler: InboxItemsUpdateHandler) {
  const inboxParams = getInboxParams();

  /**
   * If inboxParams is defined, it means that user is currently viewing
   * the inbox, so we assume he performed the action on a thread that is
   * in the category and page they are currently viewing. So we update the
   * thread on this category.
   */
  if (inboxParams) {
    yield updateQueryData('getSelfInboxEvents', inboxParams, (draftedEvents) => {
      inboxItemUpdateHandler(draftedEvents.inboxItems);
    });
  }

  /**
   * Othserwise, we just run the update for all categories with no other
   * params (first page of the category by default).
   */
  for (const category of allUserInboxCategories) {
    yield updateQueryData('getSelfInboxEvents', { category }, (draftedEvents) => {
      inboxItemUpdateHandler(draftedEvents.inboxItems);
    });
  }
}
