import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  HStack, Text, Icon, Pressable, Box, Menu
} from 'native-base';
import React, { memo, useCallback } from 'react';

import { ManageChannelsModalControllerHandles, ManageChannelsModalController } from '../../../controllers/modals/ManageChannelsModalController';
import { MENU_HORIZONTAL_PADDING } from './Constants';
import { CreateChannelModalControllerHandles, CreateChannelModalController } from '../../../controllers/modals/CreateChannelModalController';
import { CustomMenuItem } from '../../menu/CustomMenuItem';

export const AddChannelButton = memo(() => {
  const manageChannelsModalControllerRef = React.useRef<ManageChannelsModalControllerHandles>(null);
  const createChannelModalControllerRef = React.useRef<CreateChannelModalControllerHandles>(null);
  const textColor = 'dark.700';

  const trigger = useCallback((triggerProps: any, { open }: { open: boolean; }) => (
    <Box px={MENU_HORIZONTAL_PADDING} key="add_channel" alignSelf="flex-start">
      <Pressable
        variant="menu"
        borderRadius={4}
        mb={1}
        mt={0}
        ml={2.5}
        p={0.25}
        bg={open ? 'opacityPrimaryDarker.50' : undefined}
        {...triggerProps}
      >
        {({ isHovered }) => (
          <HStack space={0} alignItems="center" pl={1.5} pr={0.5}>
            <Text color={textColor} fontSize="xs" fontWeight={600}>Channels</Text>
            <Icon
              as={MaterialCommunityIcons}
              name="chevron-down"
              size="xs"
              opacity={(isHovered || open) ? 1 : 0}
            />
          </HStack>
        )}
      </Pressable>
      <ManageChannelsModalController ref={manageChannelsModalControllerRef} />
      <CreateChannelModalController ref={createChannelModalControllerRef} />
    </Box>
  ), []);

  return (
    <Menu
      shouldOverlapWithTrigger={false}
      placement="bottom left"
      trigger={trigger}
    >
      <CustomMenuItem onPress={() => createChannelModalControllerRef.current?.show()}><Text>Create channel</Text></CustomMenuItem>
      <CustomMenuItem onPress={() => manageChannelsModalControllerRef.current?.show()}><Text>Browse channels</Text></CustomMenuItem>
    </Menu>
  );
});
