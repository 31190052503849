import { useMemo } from 'react';
import { useGetSelfUser } from './useGetSelfUser';
import { useGetSelfOrganizations } from './useGetSelfOrganizations';
import { useGetSelfOrganizationMembers } from './useGetSelfOrganizationMembers';
import { useGetAllAccessibleChannels } from './useGetAllAccessibleChannels';
import { createComprehensiveThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { THREAD_QUERY_OPTIONS } from '../../../ui/screen-sections/thread/ThreadScreenHeaderTitle';
import { apiClient, useGetThreadQuery } from '@/adapters/api';

export const useGetThread = (threadId: string | undefined) => {
  const { currentData: selfUser } = useGetSelfUser();
  const { currentData: selfOrganizations, organizationId } = useGetSelfOrganizations();
  const { currentData: organizationMembers } = useGetSelfOrganizationMembers(organizationId);
  const { allRawChannels } = useGetAllAccessibleChannels(selfOrganizations);

  const params = useMemo(() => ({
    selfUserId: selfUser?.id,
    users: organizationMembers?.users,
    channels: allRawChannels
  }), [selfUser?.id, organizationMembers?.users, allRawChannels]);

  const rawThread = useGetThreadQuery({ threadId: threadId ?? '' }, { ...THREAD_QUERY_OPTIONS, skip: threadId === undefined });

  const thread = useMemo(() => (rawThread.currentData ? createComprehensiveThreadViewModel(rawThread.currentData, params) : undefined), [rawThread.currentData, params]);

  return {
    ...rawThread,
    currentData: thread
  };
};

export const usePrefetchThread = () => apiClient.usePrefetch('getThread');
