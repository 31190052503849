import {
  useCallback,
  useRef
} from 'react';
import useUploadFile from '@/infrastructure/ui/compose/components/attachments/hooks/useUploadFile';
import { getServerFilename } from '../utils/getServerFilename';
import { DidUploadAttachmentCallback } from '../AttachmentThumbnail';
import { useGeneratePresignedUrl } from './useGeneratePresignedUrl';
import { getStatusFlags } from '../Status';

export function useUploadAttachment(file: File | undefined, attachmentName: string, onDidUploadAttachment: DidUploadAttachmentCallback | undefined) {
  const stagedDownloadUrl = useRef<string | null>(null);
  const expiryDate = useRef<string | null>(null);
  const aborted = useRef(false);
  const { generatePresignedUrl, status: presignedUrlStatus } = useGeneratePresignedUrl(attachmentName, file);

  const {
    uploadFile, cancelFileUpload, progress, ...uploadResult
  } = useUploadFile();

  const cancelUpload = useCallback(() => {
    aborted.current = true;
    cancelFileUpload();
  }, [cancelFileUpload]);

  const upload = useCallback(async () => {
    if (file) {
      aborted.current = false;
      const presignedUrlResponse = await generatePresignedUrl();

      if (aborted.current) {
        return;
      }

      await uploadFile(file, presignedUrlResponse.upload_url, presignedUrlResponse.headers);
      const serverFilename = await getServerFilename(file);
      if (onDidUploadAttachment) {
        onDidUploadAttachment({
          request: {
            download_file_name: attachmentName,
            mime_type: file.type,
            file_size: file.size,
          },
          response: presignedUrlResponse,
          size: file.size,
          serverFilename,
        }, file);
      }

      stagedDownloadUrl.current = presignedUrlResponse.download_url;
      expiryDate.current = presignedUrlResponse.expiry_date;
    }
  }, [file, generatePresignedUrl, uploadFile, onDidUploadAttachment, attachmentName]);

  const resolvedStatus = presignedUrlStatus === 'complete' ? uploadResult.status : presignedUrlStatus;

  return {
    upload, cancelUpload, expiryDate: expiryDate.current, stagedDownloadUrl: stagedDownloadUrl.current, progress, status: resolvedStatus, ...getStatusFlags(resolvedStatus)
  };
}
