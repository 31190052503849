import { MessageCreateRequestBodyWithId, SendingViewModel } from '@/domain/state/drafts';
import {
  User,
  ConversationParticipant,
  Message, MessageCreateRequestBody, MessageReaction
} from '../api/codegen';
import { createPictureViewModelFromParticipant, PictureViewModel } from './PictureViewModel';
import { formatDate } from './utilities';
import { getConversationParticipantDisplayName } from './utilities/getConversationParticipantDisplayName';
import { MessageReactionViewModel, MessageReactionViewModelAdditionalParams, createMessageReactionViewModel } from './MessageReactionViewModel';
import { AttachmentViewModel, createAttachmentViewModel, createAttachmentViewModelFromUploadedAttachment } from './AttachmentViewModel';
import { createExtendedMessageReactionViewModelParams } from './utilities/createExtendedMessageReactionViewModelParams';
import { UsersViewModel } from '@/adapters/view-models/UsersViewModel';
import { UserViewModel } from '@/adapters/view-models/UserViewModel';
import { createParticipantViewModel, ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';

export type MessageViewModel = {
  senderDisplayName: string;
  body: string;
  date: string;
  rawDate: number;
  id: string;
  senderInfo: PictureViewModel;
  isUnread: boolean;
  attachments: AttachmentViewModel[];
  rawReactions: MessageReaction[];
  state: 'staged' | 'committed' | 'scheduled';
  pending_recipients: ParticipantViewModel[];
};

export type ComprehensiveMessageViewModel = MessageViewModel & {
  reactions: MessageReactionViewModel[];
};

export type MessageViewModelAdditionalParams = MessageReactionViewModelAdditionalParams;

export function createMessageViewModel(message: Message): MessageViewModel {
  return {
    senderDisplayName: getConversationParticipantDisplayName(message.sender),
    senderInfo: createPictureViewModelFromParticipant(message.sender),
    body: message.body,
    date: formatDate(message.creation_date),
    id: message.id,
    isUnread: message.is_unread,
    rawReactions: message.reactions,
    attachments: message.attachments.map(createAttachmentViewModel),
    rawDate: new Date(message.creation_date).getTime(),
    state: message.state,
    pending_recipients: message.pending_participants.map(createParticipantViewModel),
  };
}

export function createComprehensiveMessageViewModel(message: MessageViewModel, params: MessageViewModelAdditionalParams): ComprehensiveMessageViewModel {
  const extendedParams = createExtendedMessageReactionViewModelParams(params);
  const curriedCreateMessageReactionViewModel = (messageReaction: MessageReaction) => createMessageReactionViewModel(messageReaction, extendedParams);

  return {
    ...message,
    reactions: message.rawReactions.map(curriedCreateMessageReactionViewModel),
  };
}

export interface ComprehensiveSendingMessageViewModel extends ComprehensiveMessageViewModel {
  isSending: boolean;
  resendMessageCreateRequestBody: MessageCreateRequestBodyWithId;
  resendCount?: SendingViewModel['resendCount'];
}

export function createComprehensiveSendingMessageViewModel(message: SendingViewModel, user: User, organizationUsers: UsersViewModel | undefined): ComprehensiveSendingMessageViewModel {
  const stagedAttachments = message.stagedAttachments?.map(createAttachmentViewModelFromUploadedAttachment) ?? [];

  const messageViewModel = createMessageViewModel({
    id: message.id,
    body: message.messageCreateRequestBody.message_body,
    is_unread: false,
    creation_date: message.messageCreateRequestBody.scheduled_date ?? new Date().toISOString(),
    sender: { type: 'user', user },
    reactions: [],
    attachments: [],
    state: message.messageCreateRequestBody.scheduled_date ? 'scheduled' : 'staged',
    pending_participants: createPendingParticipants(message.messageCreateRequestBody, organizationUsers),
  });

  return {
    ...messageViewModel,
    attachments: (message.committedAttachments ?? []).concat(stagedAttachments),
    reactions: [],
    isSending: true,
    resendCount: message.resendCount,
    resendMessageCreateRequestBody: message.messageCreateRequestBody,
  };
}

export function createPendingParticipants(request: MessageCreateRequestBody, organizationUsers: UsersViewModel | undefined) {
  return [
    ...(request.external_participant_emails || []).map((email) => ({
      type: 'email',
      email_participant: { email },
    } satisfies ConversationParticipant)),
    ...(request.participant_public_ids || []).map((participant_id) => {
      const participant = organizationUsers?.users.find((user: UserViewModel) => user.id === participant_id);
      return {
        type: 'user',
        user: {
          id: participant_id,
          email: participant?.email || '',
          display_name: participant?.displayName || '',
          features: participant?.features || [],
          picture_url: participant?.pictureInfo.url || '',
        },
      } satisfies ConversationParticipant;
    })
  ] as ConversationParticipant[];
}
