import { Heading } from 'native-base';
import React, { type ComponentProps } from 'react';

export function HeaderTitle(props: ComponentProps<typeof Heading>) {
  return (
    <Heading
      size="md"
      color={props.tintColor}
      allowFontScaling={props.allowFontScaling}
      flexDirection="row"
      alignItems="center"
      {...props}
    >
      {props.children}
    </Heading>
  );
}
