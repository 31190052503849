import {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import { buildThreadChannelsMutationDescription } from '../UndoMutationDescriptions';
import {
  MessageCreateRequestBody,
  PostMessageApiArg,
  Status,
  useAddMessageReactionMutation,
  useAddThreadChannelsMutation,
  useMarkEventAsReadMutation,
  useMarkMessagesAsReadMutation,
  usePostMessageMutation,
  useRemoveMessageReactionMutation,
  useRemoveThreadChannelsMutation, useSendMessageNowMutation,
  useUpdateThreadMutation
} from '@/adapters/api/codegen';
import { ComprehensiveMessageViewModel, MessageViewModel } from '@/adapters/view-models/MessageViewModel';
import { ThreadScreenSection } from '../../ui/screen-sections/thread/ThreadScreenSection';
import { useCurrentThread } from '../hooks/routes/useCurrentThread';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { useDeepMemo } from '../hooks/useDeepMemo';
import { ComposeAreaHandles, ComposeAreaSubmitCallback } from '../../ui/compose';
import { participantsPerCategory } from '../hooks/participantsUtil';
import { useGetSelfUser } from '../hooks/api/useGetSelfUser';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useGetSelfOrganizationMembers } from '../hooks/api/useGetSelfOrganizationMembers';
import { createStandardErrorIfAny } from '@/adapters/other/createStandardError';
import { useGetThreadMessages } from '../hooks/api/useGetThreadMessages';
import { ReactionSelectedCallback } from '../../ui/screen-sections/thread/ThreadMessageItem';
import {
  MutationMetadata,
  useWithMutationMetadata
} from '@/adapters/mutation-cancellation/mutationCancellation';
import { threadDraftIdPrefix } from '@/domain/state/drafts';

import {
  useCancelMessageCallback
} from '@/infrastructure/ui/schedule-send/useCancelMessageCallback';
import { useCurrentChannel } from '@/infrastructure/controllers/hooks/routes/useCurrentChannel';
import { createMessageMutationMetadata } from '@/infrastructure/controllers/CreateMessageMutationMetadata';

export function ThreadScreenSectionController() {
  const { thread, threadId } = useCurrentThread();
  const { currentData: currentUser } = useGetSelfUser();
  const { organizationId } = useGetSelfOrganizations();
  const { currentData: organizationMembers } = useGetSelfOrganizationMembers(organizationId);

  const [participants, setParticipants] = useState<ParticipantViewModel[] | null>(null);
  useEffect(() => {
    if (!thread || !currentUser) return;
    setParticipants((prev) => {
      const newParticipants = [...(prev || [])];
      if (thread.participants?.length) {
        newParticipants.push(...thread.participants.filter((participant) => {
          if (participant.id === currentUser.id) return false;
          return !newParticipants.find((prevParticipant) => prevParticipant._type === participant._type && prevParticipant.id === participant.id);
        }));
      }
      if (thread?.emailRecipientsForNextMessage?.length) {
        newParticipants.push(...thread.emailRecipientsForNextMessage.filter((participant) => !newParticipants.find((prevParticipant) => prevParticipant.id === participant.id
          || (prevParticipant._type === 'user' && prevParticipant.email === participant.email))));
      }
      return newParticipants;
    });
  }, [thread, currentUser]);

  const reactionSelectedCallback = useReactionSelectedCallback();

  const [addThreadChannels] = useWithMutationMetadata(useAddThreadChannelsMutation());
  const [removeThreadChannels] = useWithMutationMetadata(useRemoveThreadChannelsMutation());

  const addChannelsCallback = useCallback(async (channels: ParticipantViewModel[]) => {
    const channelIds = channels.map((channel) => channel.id);
    await addThreadChannels({ threadId, body: channelIds }, {
      initiator: 'user',
      description: buildThreadChannelsMutationDescription(true, channels),
    });
  }, [addThreadChannels, threadId]);

  const removeChannelsCallback = useCallback(async (channels: ParticipantViewModel[]) => {
    const channelIds = channels.map((channel) => channel.id);
    await removeThreadChannels({ threadId, body: channelIds }, {
      initiator: 'user',
      description: buildThreadChannelsMutationDescription(false, channels),
    });
  }, [removeThreadChannels, threadId]);

  const [updateThread] = useUpdateThreadMutation();
  const setThreadStatusCallback = useCallback(async (threadId: string, status: Status) => {
    await updateThread({ threadId, threadUpdateRequestBody: { status } });
  }, [updateThread]);

  const updateThreadDueDateCallback = useCallback(async (threadId: string, dueDate: Date | null) => {
    await updateThread({ threadId, threadUpdateRequestBody: { due_date: dueDate !== null ? format(dueDate, 'yyyy-MM-dd') : null, }, });
  }, [updateThread]);

  const updateThreadAssigneeCallback = useCallback(async (threadId: string, assigneeId: string | null) => {
    await updateThread({ threadId, threadUpdateRequestBody: { assignee_id: assigneeId } });
  }, [updateThread]);

  const users = useMemo(() => [...organizationMembers?.users ?? [], ...thread?.participants ?? []], [organizationMembers, thread]);
  const {
    currentData: threadMessages, isLoading, isUninitialized, error
  } = useGetThreadMessages(threadId, currentUser?.id, users);

  const { channelId } = useCurrentChannel();
  const composeAreaRef = useRef<ComposeAreaHandles>(null);
  const draftKey = `${threadDraftIdPrefix}${thread?.id}`;
  const cancelMessageCallback = useCancelMessageCallback(draftKey, composeAreaRef, channelId);

  const isLoadingForFirstTime = isLoading && !threadMessages?.messages;
  const errorToDisplay = createStandardErrorIfAny(error);

  useMarkMessagesAsRead(threadMessages?.messages, thread?.inboxItemId, thread?.inboxItemIsUnread);

  const [nextMessageId, setNextMessageId] = useState(uuidv4);
  const [postMessage] = useWithMutationMetadata(usePostMessageMutation({
    fixedCacheKey: `postMessage:${nextMessageId}`,
  }));
  const [sendMessageNow] = useSendMessageNowMutation();
  const submitCallback: ComposeAreaSubmitCallback = useCallback(async (content) => {
    const {
      body, scheduledDate, stagedAttachmentIds: attachmentIds, committedAttachments
    } = content;
    const { internal: participantPublicIds, external: externalParticipantEmails } = participantsPerCategory(participants ?? []);
    const deduplicatedExternalParticipantEmails = Array.from(new Set(externalParticipantEmails));
    const messageCreateRequestBody: MessageCreateRequestBody = {
      id: nextMessageId,
      message_body: body,
      participant_public_ids: participantPublicIds,
      external_participant_emails: deduplicatedExternalParticipantEmails,
      staged_attachment_ids: attachmentIds,
      scheduled_date: scheduledDate?.toISOString(),
      committed_attachments: committedAttachments.map((attachment) => ({
        download_file_name: attachment.name,
        mime_type: attachment.mimeType,
        server_filename: attachment.serverFilename!,
      })),
    };
    const postMessageArg: PostMessageApiArg = { threadId, messageCreateRequestBody };
    setNextMessageId(uuidv4());
    return postMessage(postMessageArg, createMessageMutationMetadata({
      type: 'message',
      cancelMessageCallback,
      sendMessageNow,
      messageId: nextMessageId,
      threadId,
      scheduledDate,
    }));
  }, [participants, nextMessageId, postMessage, threadId, cancelMessageCallback, sendMessageNow]);

  const cancelScheduledMessageCallback = useCallback((messageId: string) => {
    cancelMessageCallback(messageId, 'inlineButton', threadId);
  }, [cancelMessageCallback, threadId]);

  if (!thread) return null;

  return (
    <ThreadScreenSection
      showSpinner={isLoadingForFirstTime || isUninitialized || !thread}
      errorToDisplay={errorToDisplay?.displayMessage}
      submitCallback={submitCallback}
      messages={threadMessages?.messages}
      participants={participants}
      setParticipants={setParticipants}
      reactionSelectedCallback={reactionSelectedCallback}
      thread={thread}
      setThreadStatusCallback={setThreadStatusCallback}
      updateThreadDueDateCallback={updateThreadDueDateCallback}
      updateThreadAssigneeCallback={updateThreadAssigneeCallback}
      didDeleteChannelsCallback={removeChannelsCallback}
      didSubmitNewChannelsCallback={addChannelsCallback}
      scheduledMessageCancelledCallback={cancelScheduledMessageCallback}
      draftKey={draftKey}
      composeAreaRef={composeAreaRef}
    />
  );
}

function useReactionSelectedCallback() {
  const [addReaction] = useAddMessageReactionMutation();
  const [removeReaction] = useRemoveMessageReactionMutation();

  const reactionSelectedCallback: ReactionSelectedCallback = useCallback((code: string, message: ComprehensiveMessageViewModel) => {
    const selfUserHasAlreadyReacted = message.reactions?.some(({ isHighlighted, reactionViewModel }) => isHighlighted && reactionViewModel.code === code);

    if (selfUserHasAlreadyReacted) {
      void removeReaction({ messageId: message.id, reactionRequestBody: { reaction_code: code } });
    } else {
      void addReaction({ messageId: message.id, reactionRequestBody: { reaction_code: code } });
    }
  }, [addReaction, removeReaction]);

  return reactionSelectedCallback;
}

const DELAY_TO_MARK_MESSAGES_AS_READ_MS = 500;

function useMarkMessagesAsRead(messages: MessageViewModel[] | undefined, inboxItemId?: string, inboxItemIsUnread?: boolean) {
  const [markMessagesAsRead] = useWithMutationMetadata(useMarkMessagesAsReadMutation());
  const [markInboxItemAsRead] = useWithMutationMetadata(useMarkEventAsReadMutation());
  const shouldNotApplyNextDelay = useRef<boolean>(false);
  const messagesIdsAlreadyMarkedAsRead = useRef<string[]>([]);
  const alreadyTriedToMarkInboxItemAsRead = useRef<boolean>(false);

  const unreadMessageIds = useDeepMemo(
    messages?.filter((message) => message.isUnread ?? false)
      .map((message) => message.id) ?? []
  );

  const scheduleMutation = useCallback((callback: () => void) => {
    const timeoutId = setTimeout(() => {
      callback();
    }, shouldNotApplyNextDelay.current ? 0 : DELAY_TO_MARK_MESSAGES_AS_READ_MS);

    shouldNotApplyNextDelay.current = false;

    return () => clearTimeout(timeoutId);
  }, []);

  const mutationMetadata: MutationMetadata = useMemo(() => ({
    initiator: 'app',
  }), []);

  useEffect(() => {
    /* We need to make sure that inbox and message unread status changes are not triggered
     * in parallel because this may decrement the unread count twice. */
    const isRelatedInboxItemAlreadyMarkedAsRead = inboxItemIsUnread === undefined || inboxItemIsUnread === false;

    if (unreadMessageIds.length > 0 && isRelatedInboxItemAlreadyMarkedAsRead && !unreadMessageIds.every((messageId) => messagesIdsAlreadyMarkedAsRead.current.includes(messageId))) {
      return scheduleMutation(() => {
        const newUnreadMessageIds = unreadMessageIds.filter((messageId) => !messagesIdsAlreadyMarkedAsRead.current.includes(messageId));
        messagesIdsAlreadyMarkedAsRead.current.push(...newUnreadMessageIds);
        void markMessagesAsRead({ messageIdsRequestBody: { message_ids: unreadMessageIds } }, mutationMetadata);
      });
    }
  }, [unreadMessageIds, inboxItemIsUnread, markMessagesAsRead, scheduleMutation, mutationMetadata]);

  useEffect(() => {
    if (inboxItemId && inboxItemIsUnread && !alreadyTriedToMarkInboxItemAsRead.current) {
      alreadyTriedToMarkInboxItemAsRead.current = true;

      const scheduleMutationReturn = scheduleMutation(() => {
        void markInboxItemAsRead({ inboxItemId }, mutationMetadata);
      });

      shouldNotApplyNextDelay.current = true;

      return scheduleMutationReturn;
    }
  }, [inboxItemId, inboxItemIsUnread, markInboxItemAsRead, scheduleMutation, mutationMetadata]);
}
