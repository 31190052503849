import { Box } from 'native-base';
import type { ComponentProps } from 'react';
import { HeaderTitle } from './HeaderTitle';

export function SectionHeaderTitle(props: ComponentProps<typeof HeaderTitle>) {
  return (
    <Box marginLeft={6}>
      <HeaderTitle
        borderBottomColor="primary.200"
        borderBottomWidth={2}
        {...props}
      >
        {props.children}
      </HeaderTitle>
    </Box>
  );
}
