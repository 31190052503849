import React, { useCallback } from 'react';

import { useNavigation } from '@react-navigation/native';
import { Toast } from 'native-base';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  buildDeleteMutationDescription,
  buildMarkAsSpamMutationDescription,
  buildTrashMutationDescription
} from '../UndoMutationDescriptions';
import { useWithMutationMetadata } from '@/adapters/mutation-cancellation/mutationCancellation';
import { useCurrentThread } from '../hooks/routes/useCurrentThread';

import { ThreadActionBar } from '../../ui/ThreadActionBar';
import { useArchiveThreadCallback, useMoveToInboxCallback, useChangeUnreadStatusCallback } from '../hooks/channelScreenHooks';
import { ComprehensiveThreadViewModel, ThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { useArchiveThreadHotkey } from '../hooks/useArchiveThreadHotkey';
import { useGoBackToThreadListHotkey } from '../hooks/useGoBackToThreadListHotkey';
import { useMarkThreadAsUnreadHotkey } from '../hooks/useMarkThreadAsUnreadHotkey';
import {
  useDeleteThreadsMutation, useMarkThreadAsNotSpamMutation, useMarkThreadAsSpamMutation, useTrashThreadMutation, useUntrashThreadMutation
} from '@/adapters/api/codegen';
import { useSnoozeThreadCallback } from '../hooks/channelScreenHooks/useSnoozeThreadCallback';
import { useUnstarThreadCallback } from '../hooks/channelScreenHooks/useUnstarThreadCallback';
import { useStarThreadCallback } from '../hooks/channelScreenHooks/useStarThreadCallback';

export function ThreadScreenActionsHeaderController() {
  const { thread } = useCurrentThread();
  const shouldGoBackUponMoveToInbox = thread && thread.snoozedUntil !== null;

  const archiveThreadCallback = useArchiveThreadCallback();
  const moveToInboxCallback = useMoveToInboxCallback(shouldGoBackUponMoveToInbox);
  const changeUnreadStatusCallback = useChangeUnreadStatusCallback();
  const snoozeThreadCallback = useSnoozeThreadCallback();
  const starThreadCallback = useStarThreadCallback();
  const unstarThreadCallback = useUnstarThreadCallback();
  const [markAsSpam] = useWithMutationMetadata(useMarkThreadAsSpamMutation());
  const [markAsNotSpam] = useWithMutationMetadata(useMarkThreadAsNotSpamMutation());
  const [trashThread] = useWithMutationMetadata(useTrashThreadMutation());
  const [untrashThread] = useWithMutationMetadata(useUntrashThreadMutation());
  const [deleteThread] = useWithMutationMetadata(useDeleteThreadsMutation());

  const navigation = useNavigation();

  const archiveThreadAndGoBackCallback = useCallback((thread: ComprehensiveThreadViewModel) => {
    void archiveThreadCallback(thread, 'user');
    navigation.goBack();
  }, [archiveThreadCallback, navigation]);

  const changeUnreadStatusAndGoBackCallback = useCallback((thread: ThreadViewModel, status: 'read' | 'unread') => {
    void changeUnreadStatusCallback(thread, status, 'user');
    navigation.goBack();
  }, [changeUnreadStatusCallback, navigation]);

  const markAsNotSpamAndGoBackCallback = useCallback((thread: ThreadViewModel) => {
    void markAsNotSpam({ threadId: thread.id }, {
      initiator: 'user',
      description: buildMarkAsSpamMutationDescription(false),
    });
    navigation.goBack();
  }, [markAsNotSpam, navigation]);

  const markAsSpamAndGoBackCallback = useCallback((thread: ThreadViewModel) => {
    void markAsSpam({ threadId: thread.id }, {
      initiator: 'user',
      description: buildMarkAsSpamMutationDescription(true),
    });
    navigation.goBack();
  }, [markAsSpam, navigation]);

  const trashThreadAndGoBackCallback = useCallback((thread: ThreadViewModel) => {
    void trashThread({ threadId: thread.id }, {
      initiator: 'user',
      description: buildTrashMutationDescription(true),
    });
    navigation.goBack();
  }, [trashThread, navigation]);

  const untrashThreadAndGoBackCallback = useCallback((thread: ThreadViewModel) => {
    void untrashThread({ threadId: thread.id }, {
      initiator: 'user',
      description: buildTrashMutationDescription(false),
    });
    navigation.goBack();
  }, [untrashThread, navigation]);

  const deleteThreadAndGoBackCallback = useCallback((thread: ThreadViewModel) => {
    void deleteThread({ threadIdsRequestBody: { thread_ids: [thread.id] } }, {
      initiator: 'user',
      description: buildDeleteMutationDescription(),
    });
    navigation.goBack();
  }, [deleteThread, navigation]);

  const snoozeThreadAndGoBackCallback = useCallback((thread: ThreadViewModel, snoozeEndDate: Date | null) => {
    snoozeThreadCallback(thread, snoozeEndDate, 'user');
    navigation.goBack();
  }, [snoozeThreadCallback, navigation]);

  useArchiveThreadHotkey(thread, archiveThreadAndGoBackCallback);
  useMarkThreadAsUnreadHotkey(thread, changeUnreadStatusAndGoBackCallback);
  useMarkThreadAsReadHotkey(thread, changeUnreadStatusAndGoBackCallback);
  useGoBackToThreadListHotkey();

  if (!thread) {
    return null;
  }

  return (
    <ThreadActionBar
      thread={thread}
      archiveThreadCallback={archiveThreadAndGoBackCallback}
      moveThreadToInboxCallback={moveToInboxCallback}
      changeUnreadStatusCallback={changeUnreadStatusAndGoBackCallback}
      markThreadAsNotSpamCallback={markAsNotSpamAndGoBackCallback}
      markThreadAsSpamCallback={markAsSpamAndGoBackCallback}
      setSnoozeEndDateCallback={snoozeThreadAndGoBackCallback}
      trashThreadCallback={trashThreadAndGoBackCallback}
      untrashThreadCallback={untrashThreadAndGoBackCallback}
      deleteThreadCallback={deleteThreadAndGoBackCallback}
      starThreadCallback={starThreadCallback}
      unstarThreadCallback={unstarThreadCallback}
      showAdditionalActionsButton
    />
  );
}

export function useMarkThreadAsReadHotkey(thread: ThreadViewModel | undefined, changeUnreadStatusAndGoBackCallback: (thread: ThreadViewModel, status: 'read') => void) {
  const navigation = useNavigation();
  useHotkeys(
    'shift+i',
    () => {
      if (!thread) return;
      const canMarkAsRead = thread.isUnread;
      const canGoBack = navigation.canGoBack();
      changeUnreadStatusAndGoBackCallback(thread, 'read');

      if (!canMarkAsRead || !canGoBack) {
        Toast.show({
          title: 'Conversation marked as read',
        });
      }
    },
    [thread?.id, thread?.isUnread, changeUnreadStatusAndGoBackCallback],
    {
      enabled: Boolean(thread),
      enableOnFormTags: false,
      enableOnContentEditable: false
    }
  );
}
