// Values from Figma
export const colorThemes = {
  blue: [
    '#0A1929',
    '#24598F',
    '#3D99F5',
    '#D6E5F5',
    '#EBF2FA',
  ],
  sand: [
    '#291C0A',
    '#8F6224',
    '#F5A83D',
    '#F5E8D6',
    '#FAF4EB',
  ],
  purple: [
    '#1F0A29',
    '#6B248F',
    '#B83DF5',
    '#EBD6F5',
    '#F5EBFA',
  ],
  rose: [
    '#290A0A',
    '#8F2424',
    '#DE3A3A',
    '#F5D6D6',
    '#FAEBEB',
  ],
};

export type ColorTheme = keyof typeof colorThemes;

const grades = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900] as const;

type Grades = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type RGB = [number, number, number];

function hexToRgb(hex: string): RGB {
  if (!/^#[A-Fa-f0-9]{6}$/.test(hex)) {
    throw new Error('Invalid hex color code');
  }
  return [
    parseInt(hex.slice(1, 3), 16),
    parseInt(hex.slice(3, 5), 16),
    parseInt(hex.slice(5, 7), 16),
  ];
}

export function hexColorToRgba(hex: string, alpha: number) {
  const [r, g, b] = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function rgbToHex([r, g, b]: RGB) {
  return `#${[r, g, b].map((x) => `0${x.toString(16)}`.slice(-2)).join('')}`;
}

function interpolateColors(startHex: string, endHex: string, stopPoints = 3) {
  const startRgb = hexToRgb(startHex);
  const endRgb = hexToRgb(endHex);
  const colorArray = [];
  for (let i = 1; i < stopPoints; i++) {
    const ratio = i / stopPoints;
    const interpolatedRgb = startRgb.map((start, index) => Math.round(start + ratio * (endRgb[index] - start))) as RGB;
    colorArray.push(rgbToHex(interpolatedRgb));
  }
  return colorArray;
}

function getPalette(colors: string[]) {
  colors = [...colors].reverse();
  const palette = {
    50: colors[0],
    100: colors[1],
    500: colors[2],
    700: colors[3],
    900: colors[4],
  } as Record<Grades, string>;
  if (!palette[50]) throw new Error('Palette must have 50');
  if (!palette[900]) throw new Error('Palette must have 900');
  let lastI: number = 0;
  let lastColor: string;
  for (let i = 0; i < grades.length; i++) {
    const grade = grades[i];
    const currentColor = palette[grade];
    // eslint-disable-next-line no-continue
    if (!currentColor) continue;
    const toInterpolate = grades.slice(lastI! + 1, i);
    if (toInterpolate.length) {
      const colors = interpolateColors(lastColor!, currentColor, toInterpolate.length + 1);
      toInterpolate.forEach((g, j) => {
        palette[g] = colors[j];
      });
    }
    lastI = i;
    lastColor = palette[grade];
  }
  return palette;
}

function opacityPalette(baseColor: string, extra: number[] = []) {
  const [r, g, b] = hexToRgb(baseColor);
  const alpha = (a: number) => `rgba(${r}, ${g}, ${b}, ${a})`;
  const palette = {
    50: alpha(0.05),
    100: alpha(0.1),
    200: alpha(0.2),
    300: alpha(0.3),
    400: alpha(0.4),
    500: alpha(0.5),
    600: alpha(0.6),
    700: alpha(0.7),
    800: alpha(0.8),
    900: alpha(0.9),
  } as Record<Grades, string> & { [key: number]: string };
  extra.forEach((g) => {
    palette[g] = alpha(g / 1000);
  });
  return palette;
}

export const getColors = (theme: ColorTheme) => {
  const primary = getPalette(colorThemes[theme]);
  return {
    primary,
    dark: {
      2: 'rgba(9, 32, 34, 0.02)',
      3: 'rgba(9, 32, 34, 0.03)',
      5: 'rgba(9, 32, 34, 0.05)',
      10: 'rgba(9, 32, 34, 0.10)',
      50: '#FAFBFB',
      100: '#DFE2E3',
      200: '#C5CACB',
      300: '#8F999A',
      400: '#748182',
      500: '#59696A',
      600: '#59696A',
      700: '#3F5152',
      800: '#24383A',
      900: '#092022',
    },
    opacityWhite: opacityPalette('#FFFFFF', [20]),
    opacityBlack: opacityPalette('#000000', [35]),
    opacityPrimary: opacityPalette(primary[500], [15, 35]),
    opacityPrimaryDarker: opacityPalette(primary[700], [15, 35, 75, 150]),
    opacityPrimaryDark: opacityPalette(primary[900], [15, 35, 75, 150]),
    // muted: opacityPalette(primary[900], [15, 35, 75, 150]),
    green: {
      600: '#34BE8C',
    },
    snooze: {
      500: '#DB8231'
    },
    starred: {
      500: '#FFBA22'
    },
    scheduled: {
      500: '#3C8DD8',
    },
    channel: {
      blue: '#5072CB',
      teal: '#72C8C3',
      mint: '#93C7AB',
      lime: '#BCDE9E',
      coral: '#DF9277',
      rose: '#E0726E',
      peach: '#E1A997',
      magenta: '#E277B0',
    },
  };
};

export const colors = getColors('blue');

export const avatarColors = [
  '#FFB74D',
  '#FF8A65',
  '#81C784',
  '#4DD0E1',
  '#7986CB',
  '#BA68C8',
  '#FF80AB',
  '#A1887F',
  '#90A4AE',
];
