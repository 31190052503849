/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { MaterialIcons } from '@expo/vector-icons';
import {
  HStack, Icon, IconButton
} from 'native-base';
import { StyleSheet } from 'react-native';
import { useHotkeys } from 'react-hotkeys-hook';
import { useRef } from 'react';
import { SendButtonGroup } from './send-button-group/SendButtonGroup';
import { ComposeToolBarDivider } from './ComposeToolBarDivider';
import { EmojiPickerPopoverProps } from '../../emoji/popover/EmojiPickerPopover';
import { EmojiSelectedCallback } from '../../emoji/popover/EmojiSelectedCallback';
import { AddReactionButton } from './AddReactionButton';
import { withTooltip } from '../../shortcuts/withTooltip';
import { ToolbarButton } from './ToolbarButton';
import { ShortcutTooltip } from '../../components/ShortcutTooltip';

const noop = () => { };

const ComposeToolbarButton = withTooltip('button');
const BoxWithTooltip = withTooltip('div');

export type ComposeToolbarProps = {
  displayed: boolean;
  enabled: boolean;
  sendButtonDisabled: boolean;
  submit?: (scheduledDate?: Date) => void;
  uniqueId: string;
  hotkeysEnabled: boolean;
  allowModifyParticipants?: boolean;
  onModifyParticipants?: () => void;
  emojiSelectedCallback: EmojiSelectedCallback;
  emojiPickerOpenCallback?: (isOpen: boolean) => void;
  attachFileCallback?: () => void;
  emojiPickerPlacement?: EmojiPickerPopoverProps['placement'];
};

export function ComposeToolbar(props: ComposeToolbarProps) {
  const hotkeysEnabled = props.hotkeysEnabled && !props.sendButtonDisabled;

  const submit = props.submit ?? noop;

  useHotkeys('mod+enter', () => { submit(); }, [submit], {
    enabled: hotkeysEnabled,
    enableOnFormTags: true,
    enableOnContentEditable: true
  });

  return (
    <HStack alignItems="center" alignContent="center" p={props.displayed ? '2' : null} pl={props.displayed ? '14px' : null} display={props.displayed ? undefined : 'none'} borderBottomRadius={8}>
      <div id={`toolbar-${props.uniqueId}`} style={{ padding: 0, margin: 0 }}>
        <HStack h="20px" alignItems="center" space={4} display={!props.displayed ? 'none' : null}>
          <ComposeToolbarButton tooltip={['Bold', 'mod+b']} className="ql-bold" disabled={!props.enabled} style={styles.composeToolbarButton} />
          <ComposeToolbarButton tooltip={['Italic', 'mod+i']} className="ql-italic" disabled={!props.enabled} style={styles.composeToolbarButton} />
          <ComposeToolbarButton tooltip={['Underline', 'mod+u']} className="ql-underline" disabled={!props.enabled} style={styles.composeToolbarButton} />
          <ComposeToolbarButton tooltip={['Strikethrough', 'mod+shift+x']} className="ql-strike" disabled={!props.enabled} style={styles.composeToolbarButton} />
          <ComposeToolBarDivider />
          <ComposeToolbarButton tooltip={['Insert image']} className="ql-image" disabled={!props.enabled} style={styles.composeToolbarButton} />
          <ComposeToolbarButton tooltip={['Link', 'mod+k']} className="ql-link" disabled={!props.enabled} style={styles.composeToolbarButton} />
          <ComposeToolBarDivider />
          <ComposeToolbarButton tooltip={['Unordered List', 'mod+shift+8']} className="ql-list" value="bullet" disabled={!props.enabled} style={styles.composeToolbarButton} />
          <ComposeToolbarButton tooltip={['Ordered List', 'mod+shift+7']} className="ql-list" value="ordered" disabled={!props.enabled} style={styles.composeToolbarButton} />
          <ComposeToolbarButton tooltip={['Quote', 'mod+shift+9']} className="ql-blockquote" disabled={!props.enabled} style={styles.composeToolbarButton} />
          <ComposeToolbarButton tooltip={['Code', 'mod+shift+m']} className="ql-code-block" disabled={!props.enabled} style={styles.composeToolbarButton} />
          <ComposeToolBarDivider />
          {props.attachFileCallback && <AttachFilesButton enabled={props.enabled} attachFileCallback={props.attachFileCallback} />}
          <BoxWithTooltip tooltip={['Emoji']}>
            <AddReactionButton emojiPickerOpenCallback={props.emojiPickerOpenCallback} emojiSelectedCallback={props.emojiSelectedCallback} enabled={props.enabled} emojiPickerPlacement={props.emojiPickerPlacement} />
          </BoxWithTooltip>
          <ComposeToolBarDivider />
          <ComposeToolbarButton tooltip={['Remove formatting', 'mod+\\']} className="ql-clean" disabled={!props.enabled} style={styles.composeToolbarButton}>
            <Icon as={MaterialIcons} name="format-clear" />
          </ComposeToolbarButton>
          {props.allowModifyParticipants && (
            <>
              <ComposeToolBarDivider />
              <IconButton
                onPress={props.onModifyParticipants}
                _icon={{ color: 'primary.300' }}
                _hover={{ _icon: { color: 'primary.500' } }}
                marginRight={1}
                p={2}
                icon={<Icon name="people" />}
              />
            </>
          )}
        </HStack>
      </div>
      <HStack flexGrow={1} justifyContent="flex-end" space={1} display={!props.displayed ? 'none' : null} alignItems="center" alignContent="center">
        {props.submit ? <SendButtonGroup enabled={!props.sendButtonDisabled} onSend={props.submit} /> : null}
      </HStack>
    </HStack>
  );
}

const styles = StyleSheet.create({
  composeToolbarButton: {
    width: 20,
    height: 20,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 0,
    paddingRight: 0,
  }
});

type AttachFilesButtonProps = {
  enabled: boolean;
  attachFileCallback: () => void;
};

function AttachFilesButton({ enabled, attachFileCallback }: AttachFilesButtonProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    buttonRef.current?.blur();
    attachFileCallback();
  };

  return (
    <ShortcutTooltip hint="Attach files" shortcut="mod+shift+a">
      <ToolbarButton
        ref={buttonRef}
        _icon={{
          name: 'attach-file'
        }}
        isDisabled={!enabled}
        onPress={handleClick}
      />
    </ShortcutTooltip>
  );
}
