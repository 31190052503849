import { intlFormat } from 'date-fns';
import {
  Status, Thread, InboxItem, ConversationParticipant, User
} from '../api/codegen';
import {
  createPictureViewModelFromParticipant,
  createPictureViewModelFromUser,
  PictureViewModel
} from './PictureViewModel';
import { formatDate } from './utilities';
import { getConversationParticipantDisplayName } from './utilities/getConversationParticipantDisplayName';
import { createUserViewModel, UserViewModel } from './UserViewModel';
import { ChannelViewModel } from './ChannelViewModel';
import { STATUSES, NonNullStatus, StatusDetail } from '../other/statuses';
import {
  ComposeNewExternalParticipantViewModel,
  createComposeNewExternalParticipantViewModelFromConversationParticipant
} from './ParticipantViewModel';

export type ThreadViewModel = {
  id: string;
  lastSenderMessageDate?: string;
  numberOfRepliesInfo: string;
  description: string;
  titleGrayedOut: boolean;
  memberPictures: PictureViewModel[];
  emailRecipientsForNextMessage: ComposeNewExternalParticipantViewModel[] | null;
  participants: UserViewModel[];
  isUnread: boolean;
  isStarred: boolean;
  showMarkAsSpamButton: boolean;
  showMarkAsNotSpamButton: boolean;
  lastMessageId?: string;
  showMoveToInboxButton: boolean;
  showRemoveFromInboxButton: boolean;
  showSnoozeButton: boolean;
  key: string;
  eventId?: string;
  status?: StatusDetail & {
    key: Status,
  };
  rawDueDate: string | null;
  assignee?: UserViewModel;
  assigneeDisplayName?: string;
  assigneePicture?: PictureViewModel;
  lastActivityDate: string;
  channelIds: string[];
  lastActivity: Thread['last_activity'];
  title: string | null;
  untitled: boolean;
  event?: ThreadEvent;
  lastMessageDateTimestamp: number;
  inboxItemId?: string;
  inboxItemIsUnread?: boolean;
  numberOfUnreadMessages: number;
  labels?: Thread['labels'];
  snoozedUntil: string | null;
  rawSnoozeEndDate: number | string | null;
  isTrashed: boolean;
  scheduledMessagesCreationDates: { [key: string]: number };
  earliestScheduledDate?: string;
};

export type RegularThreadEvent = {
  date: string;
  type: Omit<InboxItem['type'], 'self_added_as_participant' | 'channel_added'>;
};

export type SelfAddedAsParticipantThreadEvent = {
  date: string;
  type: 'self_added_as_participant';
  userWhoAddedSelf: User;
};

export type ChannelAddedThreadEvent = {
  date: string;
  type: 'channel_added';
  userWhoAddedChannel: User;
  addedChannelId: string;
};

export type ThreadEvent = RegularThreadEvent | SelfAddedAsParticipantThreadEvent | ChannelAddedThreadEvent;

export type ComprehensiveThreadViewModel = ThreadViewModel & {
  snippet: string;
  displayedTitle: string;
  relevantChannels?: ChannelViewModel[];
  selfUserJoined: boolean;
  showJoinButton: boolean;
  showLeaveButton: boolean;
  dueDate: string | null;
};

function getNumberOfRepliesInfo(numberOfReplies: number) {
  if (numberOfReplies > 1) {
    return `${numberOfReplies} replies`;
  } if (numberOfReplies == 1) {
    return `${numberOfReplies} reply`;
  }
  return 'No replies yet';
}

export type ThreadViewModelAdditionalParams = {
  selfUserId: string | undefined;
  users: UserViewModel[] | undefined;
  channels: ChannelViewModel[] | undefined;
  channelIdsToRemove?: string[];
};

export function createComprehensiveThreadViewModel(thread: ThreadViewModel, params: ThreadViewModelAdditionalParams): ComprehensiveThreadViewModel {
  const snippet = getThreadSnippet(thread.lastActivity, thread.event, params.channels);

  const allThreadChannels = thread.channelIds.map((channelId) => params.channels?.find((channel) => channel.id === channelId)).filter((channel): channel is ChannelViewModel => !!channel);
  const relevantChannels = allThreadChannels?.filter((channel) => !channel.isFolder).filter((channel) => !params.channelIdsToRemove?.includes(channel.id));

  const selfUserJoined = thread.participants.some((p) => p.id === params.selfUserId) ?? false;
  const showJoinButton = !selfUserJoined;
  const showLeaveButton = selfUserJoined;
  const displayedTitle = thread.title ?? 'Untitled thread';

  const comprehensiveThreadViewModel: ComprehensiveThreadViewModel = {
    ...thread,
    snippet,
    displayedTitle,
    relevantChannels,
    selfUserJoined,
    showJoinButton,
    showLeaveButton,
    dueDate: thread.rawDueDate,
  };

  return comprehensiveThreadViewModel;
}

export function createThreadViewModel(thread: Thread, event?: ThreadEvent): ThreadViewModel {
  const key = thread.id;
  const itemDate = event?.date ?? thread.last_activity.date;
  const formattedDate = formatDate(itemDate, false);
  const numberOfReplies = Math.max(thread.number_of_messages - 1, 0);
  const emailRecipientsForNextMessage = thread.email_recipients_for_next_message?.map(createComposeNewExternalParticipantViewModelFromConversationParticipant) ?? null;
  const isUnread = thread.number_of_unread_messages > 0 || (thread.inbox_event_is_unread ?? false);

  const showMarkAsSpamButton = thread.isSpam !== null && !thread.isSpam;
  const showMarkAsNotSpamButton = thread.isSpam !== null && thread.isSpam;

  const showMoveToInboxButton = !thread.inbox_event_id && !showMarkAsNotSpamButton;
  const showRemoveFromInboxButton = !!thread.inbox_event_id;

  const snoozedUntil = thread.snooze_end_date ? formatThreadDate(thread.snooze_end_date) : null;
  const showSnoozeButton = !showMarkAsNotSpamButton || !!snoozedUntil;

  const scheduledMessagesCreationDates: { [key: string]: number } = {};
  thread.scheduled_messages_creation_dates.forEach((response) => {
    scheduledMessagesCreationDates[response.message_id] = (new Date(response.scheduled_date)).getTime();
  });

  const threadViewModel: ThreadViewModel = {
    key,
    id: thread.id,
    description: thread.description ?? '',
    eventId: thread.inbox_event_id,
    lastSenderMessageDate: formattedDate,
    numberOfRepliesInfo: getNumberOfRepliesInfo(numberOfReplies),
    ...createThreadViewModelTitleParams(thread.title),
    isUnread,
    lastMessageId: thread.last_activity.message_id,
    ...createThreadViewModelParticipants(thread.participants),
    emailRecipientsForNextMessage,
    showMoveToInboxButton,
    showRemoveFromInboxButton,
    lastActivityDate: itemDate,
    rawDueDate: thread.due_date,
    ...createThreadViewModelStatusParams(thread.status),
    ...createThreadViewModelAssigneeParams(thread.assignee),
    channelIds: thread.channel_ids,
    lastActivity: thread.last_activity,
    event,
    lastMessageDateTimestamp: new Date(thread.last_activity.date).getTime(),
    inboxItemId: thread.inbox_event_id,
    inboxItemIsUnread: thread.inbox_event_is_unread,
    numberOfUnreadMessages: thread.number_of_unread_messages,
    labels: thread.labels,
    showMarkAsSpamButton,
    showMarkAsNotSpamButton,
    snoozedUntil,
    rawSnoozeEndDate: thread.snooze_end_date,
    showSnoozeButton,
    isTrashed: !!thread.trashed_date,
    isStarred: thread.is_starred,
    scheduledMessagesCreationDates,
  };

  updateEarliestScheduledDate(threadViewModel);

  return threadViewModel;
}

export function updateEarliestScheduledDate(thread: ThreadViewModel) {
  const scheduledDates = Object.values(thread.scheduledMessagesCreationDates).sort();
  if (scheduledDates.length === 0) {
    thread.earliestScheduledDate = undefined;
  } else {
    thread.earliestScheduledDate = formatThreadDate(scheduledDates[0], false, true)!;
  }
}

export function setThreadViewModelIsSpam(thread: ThreadViewModel, isSpam: boolean) {
  thread.showMarkAsSpamButton = !isSpam;
  thread.showMarkAsNotSpamButton = isSpam;
  thread.showRemoveFromInboxButton = thread.showMarkAsSpamButton;
  thread.showMoveToInboxButton = false;
  thread.showSnoozeButton = !thread.showMarkAsNotSpamButton || !!thread.snoozedUntil;
}

export function setThreadViewModelSnoozeDate(thread: ThreadViewModel, snoozeEndDate: number) {
  thread.showMoveToInboxButton = true;
  thread.showRemoveFromInboxButton = false;
  thread.snoozedUntil = formatThreadDate(snoozeEndDate);
  thread.rawSnoozeEndDate = snoozeEndDate;
  thread.eventId = undefined;
  thread.event = undefined;
}

export function formatThreadDate(rawSnoozeEndDate: string | number, withTime = false, withToday = false): string | null {
  const locale = navigator.language || 'en-US';
  const snoozeEndDate = new Date(rawSnoozeEndDate);
  const now = new Date();

  const isSameYear = snoozeEndDate.getFullYear() === now.getFullYear();
  const isSameMonth = snoozeEndDate.getMonth() === now.getMonth();
  const isSameDay = snoozeEndDate.getDate() === now.getDate();

  const isToday = isSameDay && isSameMonth && isSameYear;

  if (isToday) {
    return (withToday ? 'Today at ' : '') + intlFormat(snoozeEndDate, {
      hour: 'numeric',
      minute: 'numeric'
    }, { locale });
  }

  if (isSameYear) {
    return intlFormat(snoozeEndDate, {
      day: 'numeric',
      month: 'short',
      hour: withTime ? 'numeric' : undefined,
      minute: withTime ? 'numeric' : undefined
    }, { locale });
  }

  return intlFormat(snoozeEndDate, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: withTime ? 'numeric' : undefined,
    minute: withTime ? 'numeric' : undefined
  }, { locale });
}

export function setThreadViewModelInboxItemId(thread: ThreadViewModel, inboxItemId: string | undefined) {
  thread.eventId = inboxItemId;
  thread.showMoveToInboxButton = !inboxItemId && !thread.showMarkAsNotSpamButton;
  thread.showRemoveFromInboxButton = !!inboxItemId;
  thread.snoozedUntil = null;
  thread.rawSnoozeEndDate = null;
}

export function removeThreadViewModelParticipants(thread: ThreadViewModel, participantIds: string[]) {
  const filteredParticipants = thread.participants.filter((participant) => !participantIds.includes(participant.id));
  const filteredMemberPictures = thread.memberPictures.filter((picture) => !participantIds.includes(picture.id));

  Object.assign(thread, {
    participants: filteredParticipants,
    memberPictures: filteredMemberPictures,
  });
}

export function addThreadViewModelParticipants(thread: ThreadViewModel, participants: ConversationParticipant[]) {
  const actuallyNewParticipants = participants.filter((participant) => !thread.memberPictures.some((picture) => picture.id === participant.user?.id));
  const newParams = createThreadViewModelParticipants(actuallyNewParticipants);

  Object.assign(thread, {
    participants: [...thread.participants, ...newParams.participants],
    memberPictures: [...thread.memberPictures, ...newParams.memberPictures],
  });
}

export function createThreadViewModelParticipants(threadParticipants: ConversationParticipant[]): Pick<ThreadViewModel, 'memberPictures' | 'participants'> {
  const participants = threadParticipants.filter((participant) => participant.type == 'user').map((participant) => participant.user!).map(createUserViewModel);
  const memberPictures = threadParticipants.map(createPictureViewModelFromParticipant);
  memberPictures.sort((a, b) => a.name.localeCompare(b.name));

  return { memberPictures, participants };
}

export function updateThreadViewModelAssigneeParams(thread: ThreadViewModel, assignee: UserViewModel | undefined) {
  const params = createThreadViewModelAssigneeParams(assignee?.rawUser ?? null);
  Object.assign(thread, params);
}

export function createThreadViewModelAssigneeParams(user: User | null): Pick<ThreadViewModel, 'assignee' | 'assigneeDisplayName' | 'assigneePicture'> {
  return {
    assignee: user ? createUserViewModel(user) : undefined,
    assigneeDisplayName: user?.display_name,
    assigneePicture: user ? createPictureViewModelFromUser(user) : undefined,
  };
}

export function updateThreadViewModelStatusParams(thread: ThreadViewModel, newStatus: Status) {
  const params = createThreadViewModelStatusParams(newStatus);
  Object.assign(thread, params);
}

export function updateThreadViewModelDescriptionParams(thread: ThreadViewModel, description: string | null) {
  thread.description = description ?? '';
}

export function createThreadViewModelStatusParams(newStatus: Status): Pick<ThreadViewModel, 'status'> {
  const status = newStatus ? STATUSES[newStatus as NonNullStatus] : null;

  return {
    status: status ? {
      key: newStatus,
      ...status,
    } : undefined,
  };
}

export function updateThreadViewModelDueDateParams(thread: ThreadViewModel, dueDate: string | null) {
  thread.rawDueDate = dueDate;
}

export function updateThreadViewModelTitleParams(thread: ThreadViewModel, title: string | null) {
  const params = createThreadViewModelTitleParams(title);
  Object.assign(thread, params);
}

export function createThreadViewModelTitleParams(title: string | null): Pick<ThreadViewModel, 'title' | 'titleGrayedOut' | 'untitled'> {
  return {
    title,
    untitled: !title,
    titleGrayedOut: !title,
  };
}

function getThreadSnippet(lastActivity: Thread['last_activity'], event: ThreadEvent | undefined, channels: ChannelViewModel[] | undefined): string {
  if (event?.type === 'self_added_as_participant' && 'userWhoAddedSelf' in event) {
    const otherUser = event.userWhoAddedSelf;
    return `${otherUser.display_name} added you to this thread`;
  }

  if (event?.type === 'added_to_inbox') {
    return 'You added this thread to your inbox';
  }

  if (event?.type === 'channel_added' && 'userWhoAddedChannel' in event) {
    const channelName = channels?.find((channel) => channel.id === event.addedChannelId)?.name;
    const channelDesignation = channelName ? `#${channelName}` : 'a channel you follow';
    return `${event.userWhoAddedChannel.display_name} added this thread to ${channelDesignation}`;
  }

  return `${getConversationParticipantDisplayName(lastActivity.author)}: ${lastActivity.snippet}`;
}
