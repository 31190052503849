import { v4 as uuidv4 } from 'uuid';
import { useCallback, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { StackActions, useNavigation } from '@react-navigation/native';
import { CreateThreadApiArg, useCreateThreadMutation, useSendMessageNowMutation } from '@/adapters/api/codegen';
import { ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { UserViewModel } from '@/adapters/view-models/UserViewModel';
import { AppScreenStackScreenProps } from '../../navigation/navigators/app/AppScreenProps';
import { ComposeScreenSection } from '../../ui/screen-sections/ComposeScreenSection';
import { ComposeNewExternalParticipantViewModel, ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { ComposeAreaHandles, ComposeAreaSubmitCallback } from '../../ui/compose';
import {
  CancelCallback,
  useCancelMessageCallback
} from '@/infrastructure/ui/schedule-send/useCancelMessageCallback';

import { useWithMutationMetadata } from '@/adapters/mutation-cancellation/mutationCancellation';
import { createMessageMutationMetadata } from '@/infrastructure/controllers/CreateMessageMutationMetadata';

type Recipient = UserViewModel | ChannelViewModel | ComposeNewExternalParticipantViewModel;

export function ComposeScreenSectionController(props : AppScreenStackScreenProps<'Compose'>) {
  const [selectedRecipients, setSelectedRecipients] = useState<ParticipantViewModel[] | null>(null);

  const navigation = useNavigation();
  useHotkeys('Escape', () => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.dispatch(StackActions.replace('Inbox'));
  }, [navigation]);

  const composeAreaRef = useRef<ComposeAreaHandles>(null);
  const cancelScheduledMessageCallback = useCancelMessageCallback('compose', composeAreaRef);
  const createThreadCallback = useCreateThreadCallback(selectedRecipients as Recipient[], props.navigation, cancelScheduledMessageCallback);

  return (
    <ComposeScreenSection
      submitCallback={createThreadCallback}
      selectedParticipantsListChangedCallback={setSelectedRecipients}
      selectedParticipants={selectedRecipients}
      composeAreaRef={composeAreaRef}
    />
  );
}

function useCreateThreadCallback(recipients: Recipient[], navigation: AppScreenStackScreenProps<'Compose'>['navigation'], cancelScheduledMessageCallback: CancelCallback) {
  const [createThread] = useWithMutationMetadata(useCreateThreadMutation());
  const [sendMessageNow] = useSendMessageNowMutation();

  const createThreadCallback: ComposeAreaSubmitCallback = useCallback(async ({
    body, title, scheduledDate, stagedAttachmentIds: attachmentIds, committedAttachments
  }) => {
    const participantIds = recipients.filter((recipient) => 'displayName' in recipient && !('external' in recipient)).map((recipient) => recipient.id);
    const externalParticipantEmails = recipients.filter((recipient) => 'email' in recipient && 'external' in recipient).map((recipient) => (recipient as ComposeNewExternalParticipantViewModel).email);
    const channelIds = recipients.filter((recipient) => !participantIds.includes(recipient.id) && !externalParticipantEmails.includes(recipient.id)).map((recipient) => recipient.id);

    const threadId = uuidv4();
    const messageId = uuidv4();
    const threadRequest: CreateThreadApiArg = {
      threadCreateRequestBody: {
        thread_id: threadId,
        message_id: messageId,
        message_body: body,
        thread_title: title || undefined,
        channel_ids: channelIds,
        participant_ids: participantIds,
        external_participant_emails: externalParticipantEmails,
        staged_attachment_ids: attachmentIds,
        scheduled_date: scheduledDate?.toISOString(),
        committed_attachments: committedAttachments.map((attachment) => ({
          download_file_name: attachment.name,
          mime_type: attachment.mimeType,
          server_filename: attachment.serverFilename!,
        })),
      }
    };

    const createThreadPromise = createThread(threadRequest, createMessageMutationMetadata({
      type: 'message',
      cancelMessageCallback: cancelScheduledMessageCallback,
      sendMessageNow,
      messageId,
      threadId,
      scheduledDate,
      context: {
        channelIds,
        _type: 'create_message_context',
      }
    }));
    navigation.navigate('Thread', { threadId, from: 'compose' });
    return await createThreadPromise;
  }, [createThread, navigation, recipients, cancelScheduledMessageCallback, sendMessageNow]);

  return createThreadCallback;
}
