import { FlatList as RNFlatList } from 'react-native';
import {
  MutableRefObject, useCallback, useRef, useState
} from 'react';
import { IFlatListProps } from 'native-base/lib/typescript/components/basic/FlatList';
import { ComprehensiveMessageViewModel } from '@/adapters/view-models/MessageViewModel';
import { findLastIndex } from '../global/findLastIndex';

const MAXIMUM_ALLOWED_TIMEFRAME_FOR_AUTOSCROLL_ADJUSTMENT_MS = 300;
const MINIMUM_INITIAL_NUMBER_OF_MESSAGES_TO_RENDER = 10;

export function useThreadMessagesAutoScroll(messages: ComprehensiveMessageViewModel[] | undefined) {
  const [canDisplayMessages, setCanDisplayMessages] = useState(false);
  const flatListRef = useRef<RNFlatList<ComprehensiveMessageViewModel> | null>(null);
  const idsOfRenderedMessagesRef = useRef<string[]>([]);
  const dateSinceInitialScrollRef = useRef<Date | null>(null);
  const areMessagesDefined = messages !== undefined;
  const indexOfEarliestUnreadMessage = messages ? findLastIndex(messages, (message) => message.isUnread) : undefined;
  const messageIdsBeforeEarliestUnreadMessage = indexOfEarliestUnreadMessage !== undefined && indexOfEarliestUnreadMessage !== -1 ? messages?.slice(0, indexOfEarliestUnreadMessage + 1).map((message) => message.id) : undefined;
  const earliestUnreadMessage = indexOfEarliestUnreadMessage ? messages?.[indexOfEarliestUnreadMessage] : undefined;
  const messageCount = messages?.length;
  const flatListDidStartRenderingRef = useRef(false);

  const itemIndexToScrollTo = determineIndexToScrollTo(indexOfEarliestUnreadMessage, messageCount);

  const flatListInitialNumberOfMessagesToRender = Math.max(
    itemIndexToScrollTo !== undefined ? itemIndexToScrollTo + 1 : messages?.length ?? 1,
    MINIMUM_INITIAL_NUMBER_OF_MESSAGES_TO_RENDER
  );

  const scrollToFlatListIndexCallback = useScrollToFlatListIndexCallback(flatListRef);

  const onContentSizeChange = useCallback((_width: number, height: number) => {
    if (height > 0) {
      flatListDidStartRenderingRef.current = true;
    }
  }, []);

  const onRenderedMessageCallback = useCallback((messageId: string) => {
    const elapsedTimeSinceInitialScroll = dateSinceInitialScrollRef.current ? new Date().getTime() - dateSinceInitialScrollRef.current.getTime() : undefined;

    if ((elapsedTimeSinceInitialScroll && elapsedTimeSinceInitialScroll > MAXIMUM_ALLOWED_TIMEFRAME_FOR_AUTOSCROLL_ADJUSTMENT_MS) || !areMessagesDefined) {
      return;
    }

    if (!idsOfRenderedMessagesRef.current.includes(messageId)) {
      idsOfRenderedMessagesRef.current.push(messageId);
    }

    const didRenderedAllMessagesAfterEarliestUnreadMessage = messageIdsBeforeEarliestUnreadMessage?.every((id) => idsOfRenderedMessagesRef.current.includes(id));

    if ((!earliestUnreadMessage || didRenderedAllMessagesAfterEarliestUnreadMessage) && flatListDidStartRenderingRef.current) {
      setCanDisplayMessages(true);

      if (!dateSinceInitialScrollRef.current) {
        dateSinceInitialScrollRef.current = new Date();
      }

      scrollToFlatListIndexCallback(itemIndexToScrollTo);
    }
  }, [areMessagesDefined, messageIdsBeforeEarliestUnreadMessage, earliestUnreadMessage, scrollToFlatListIndexCallback, itemIndexToScrollTo]);

  const flatListProps: Partial<IFlatListProps<ComprehensiveMessageViewModel>> = {
    initialNumToRender: flatListInitialNumberOfMessagesToRender,
    opacity: canDisplayMessages ? 1 : 0,
    onScrollToIndexFailed(info) {
      console.error(`Failed to scroll to index ${info.index} in thread messages flat list, itemIndexToScrollTo: ${itemIndexToScrollTo}, messageCount: ${messageCount}, indexOfEarliestUnreadMessage: ${indexOfEarliestUnreadMessage}, messageIdsBeforeEarliestUnreadMessageCount: ${messageIdsBeforeEarliestUnreadMessage?.length}, idsOfRenderedMessagesRefCount: ${idsOfRenderedMessagesRef.current.length}, highestMeasuredFrameIndex: ${info.highestMeasuredFrameIndex}`);
    },
    onContentSizeChange,
  };

  return {
    onRenderedMessageCallback,
    flatListRef,
    flatListProps,
  };
}

export const SCROLL_TO_TOP_INDEX = undefined;

function determineIndexToScrollTo(indexOfEarliestUnreadMessage: number | undefined, messageCount: number | undefined) {
  if (indexOfEarliestUnreadMessage === undefined || indexOfEarliestUnreadMessage === -1) {
    if (messageCount && messageCount > 1) {
      return 0;
    }

    return SCROLL_TO_TOP_INDEX;
  } if (messageCount && indexOfEarliestUnreadMessage === messageCount - 1) {
    return SCROLL_TO_TOP_INDEX;
  }

  return indexOfEarliestUnreadMessage;
}

function useScrollToFlatListIndexCallback(flatListRef: MutableRefObject<RNFlatList<ComprehensiveMessageViewModel> | null>) {
  return useCallback((index: number | undefined) => {
    if (index !== undefined) {
      flatListRef.current!.scrollToIndex({
        index, animated: false, viewPosition: 1
      });
    } else {
      flatListRef.current!.scrollToEnd({ animated: false });
    }
  }, [flatListRef]);
}
