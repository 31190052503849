import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCreateThreadMutation, useSendMessageNowMutation } from '@/adapters/api/codegen';
import { ComposeAreaSubmitCallback } from '../../../ui/compose';
import { participantsPerCategory } from '../participantsUtil';
import { useWithMutationMetadata } from '@/adapters/mutation-cancellation/mutationCancellation';
import { CancelCallback } from '@/infrastructure/ui/schedule-send/useCancelMessageCallback';
import { createMessageMutationMetadata } from '@/infrastructure/controllers/CreateMessageMutationMetadata';

export function useCreateThreadCallback(channelId: string, cancelCallback: CancelCallback) {
  const [createThread] = useWithMutationMetadata(useCreateThreadMutation());
  const [sendMessageNow] = useSendMessageNowMutation();

  const createThreadCallback: ComposeAreaSubmitCallback = useCallback(async ({
    body, title, recipients, scheduledDate, stagedAttachmentIds, committedAttachments
  }) => {
    const {
      internal: participantPublicIds,
      external: externalParticipantEmails
    } = participantsPerCategory(recipients ?? []);

    const threadId = uuidv4();
    const messageId = uuidv4();

    return createThread({
      threadCreateRequestBody: {
        thread_id: threadId,
        message_id: messageId,
        message_body: body,
        thread_title: title != '' ? title : undefined,
        channel_ids: [channelId],
        participant_ids: participantPublicIds,
        external_participant_emails: externalParticipantEmails,
        staged_attachment_ids: stagedAttachmentIds,
        scheduled_date: scheduledDate?.toISOString(),
        committed_attachments: committedAttachments.map((attachment) => ({
          download_file_name: attachment.name,
          mime_type: attachment.mimeType,
          server_filename: attachment.serverFilename!,
        })),
      }
    }, createMessageMutationMetadata({
      type: 'message',
      cancelMessageCallback: cancelCallback,
      sendMessageNow,
      messageId,
      threadId,
      scheduledDate,
    }));
  }, [createThread, channelId, cancelCallback, sendMessageNow]);

  return createThreadCallback;
}
