import { IconButton } from 'native-base';
import { HeaderBackButtonProps } from '@react-navigation/elements';
import { type ReactNode, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Stack } from '../Stack';

type CustomStackNavigatorProps = {
  children: ReactNode;
};

export const CustomStackNavigator = function (props: CustomStackNavigatorProps) {
  const navigation = useNavigation();
  const headerLeft = useCallback((props: HeaderBackButtonProps) => (props.canGoBack ? <IconButton ml={3} variant="primary" _icon={{ name: 'arrow-back' }} onPress={() => navigation.goBack()} /> : null), [navigation]);
  return (
    <Stack.Navigator
      screenOptions={{
        title: 'Upstream',
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: 'transparent',
        },
        headerLeft,
      }}
    >
      {props.children}
    </Stack.Navigator>
  );
};
