import { Box, HStack, Text } from 'native-base';
import React, { ReactNode, useMemo, useState } from 'react';
import { useNavigationState } from '@react-navigation/native';
import { ParticipantAvatars } from '../ParticipantAvatars';
import { ChannelBadgesList } from '../ChannelBadgesList';
import { ThreadActionBar, ThreadActionBarProps } from '../ThreadActionBar';
import { StatusIcon } from '../StatusIcon';
import { useMarkJs } from '../MarkJs';
import { useThreadDraft } from '@/domain/state/drafts';
import { ThreadSnippetText } from './blocks/ThreadSnippetText';
import { SnoozeHintText } from './blocks/SnoozeHintText';
import { VERTICAL_PADDING } from './constants';
import { ThreadListItemCheckbox } from './blocks/ThreadListItemCheckbox';
import { ThreadListItemUnreadBubble } from './blocks/ThreadListItemUnreadBubble';
import { useIsThreadListItemHovered } from './ThreadListItemPressableContainer';
import { useThreadListStoreSelector } from '../thread-list/context';
import { ThreadListItemStar } from './blocks/ThreadListItemStar';
import { Tooltip } from '@/infrastructure/ui/components/Tooltip';
import { ScheduledHintText } from '@/infrastructure/ui/thread-list-items/blocks/ScheduledHintText';
import { RootStackParamList } from '@/infrastructure/navigation/navigators/root/RootStackProps';
import { getCurrentScreenParams, RouteInStackWithNoParametersDefined, RouteParams } from '@/infrastructure/navigation/getCurrentScreenParams';

export type ThreadListItemAbstractContainerProps = ThreadActionBarProps & {
  snoozeElapsedTimeHint?: string;
};

const nbsp = '\u00A0';

export function ThreadListItemAbstractContainer(props: ThreadListItemAbstractContainerProps) {
  const { thread, children } = props;
  const { memberPictures, participants } = thread;
  const markRef = useMarkJs();
  const draft = useThreadDraft(thread);
  const draftSnippet: string | null = useMemo(() => {
    if (!draft) {
      return null;
    }
    // todo move all quill related code to rich text component
    return (draft.content || []).map((op: any) => {
      if (typeof op.insert === 'string') return op.insert.trim();
      if (op.insert?.image) return '[Uploaded image]';
      return null;
    }).filter(Boolean).join(nbsp.repeat(2));
  }, [draft]);
  const showDraftSnippet = draftSnippet && !thread.isUnread;

  const names = useMemo(() => {
    // todo: better names extraction, and move to a helper fn or vm
    const getParticipantsNames = () => {
      if (participants.length === 0) return '';
      if (participants.length === 1) return participants[0].displayName;
      const getFirstName = (participant: typeof participants[number]) => participant?.displayName.split(/\s+/)[0] ?? 'undefined';
      if (participants.length === 2) return participants.map(getFirstName).join(', ');
      return [participants[0], participants[participants.length - 1]].map(getFirstName).join(' … ');
    };
    const getPicturesNames = () => {
      if (memberPictures.length === 0) return '';
      if (memberPictures.length === 1) return memberPictures[0].name;
      const getFirstName = (pic: typeof memberPictures[number]) => pic?.name.split(/\s+|@/)[0] ?? 'undefined';
      if (memberPictures.length === 2) return memberPictures.map(getFirstName).join(', ');
      return [memberPictures[0], memberPictures[memberPictures.length - 1]].map(getFirstName).join(' … ');
    };
    return getParticipantsNames() || getPicturesNames();
  }, [participants, memberPictures]);

  return (
    <HStack flexGrow={1} space={0} pr={3} alignItems="center" maxW="100%">
      <ThreadListItemCheckbox threadId={thread.id} />
      <HStack w={5} mr={1} bgColor="amber.100" alignSelf="center">
        <ThreadListItemStar isStarred={thread.isStarred} />
        <ThreadListItemUnreadBubble isUnread={thread.isUnread && !thread.isStarred} />
      </HStack>
      <Box w="72px" flexGrow={0} flexShrink={0} alignItems="center" justifyContent="flex-start" flexDir="row" mr={3}>
        <ParticipantAvatars participants={memberPictures} maxNumberOfAvatars={3} size="xs" />
      </Box>
      <Box w="184px" flexGrow={0} flexShrink={0} alignItems="center" justifyContent="flex-start" flexDir="row" mr={3}>
        <Text
          bold={thread.isUnread}
          numberOfLines={1}
          selectable={false}
          color={thread.titleGrayedOut ? 'dark.600' : 'dark.900'}
          isTruncated
        >
          {names}
        </Text>
      </Box>
      <HStack flexGrow={1} flexShrink={1} space={3} alignItems="center">
        {draft && <Text color="green.600">Draft</Text>}
        <HStack ref={markRef} alignItems="center" flexGrow={0} flexShrink={1} space={3} pr={3}>
          <Text
            bold={thread.isUnread}
            numberOfLines={1}
            selectable={false}
            color={thread.titleGrayedOut ? 'dark.600' : 'dark.900'}
            flexShrink={1}
            py={VERTICAL_PADDING}
            isTruncated
            aria-label="Thread title"
          >
            {thread.displayedTitle}
          </Text>
          {thread.relevantChannels && (
            <ChannelBadgesList channels={thread.relevantChannels} threadId={thread.id} />
          )}
          {showDraftSnippet ? <ThreadSnippetText text={draftSnippet} /> : children}
        </HStack>
        <HStack flexGrow={1} flexShrink={0} justifyContent="flex-end">
          {thread.status && (
            <Tooltip label={thread.status.text} placement="top" openDelay={40}>
              <Box alignSelf="center" mr={5} py={1} px={2}>
                <StatusIcon status={thread.status.key} />
              </Box>
            </Tooltip>
          )}
          <Box flexGrow={0} flexShrink={0} alignItems="center" flexDir="row" w="150px" justifyContent="flex-end">
            <ActionBarOrMetaOnTheRight {...props} />
          </Box>
        </HStack>
      </HStack>
    </HStack>
  );
}

function ActionBarOrMetaOnTheRight(props: ThreadListItemAbstractContainerProps) {
  const [snoozeOpen, setSnoozeOpen] = useState(false);
  const { thread, snoozeElapsedTimeHint } = props;
  const threadHovered = useIsThreadListItemHovered();
  const isFocused = useThreadListStoreSelector((state) => state.focusedId === thread.id);
  const showActionBar = threadHovered || snoozeOpen;
  const shouldRenderActionBar = showActionBar || isFocused;
  const isScheduledFolderScreen = useNavigationState<RootStackParamList, RouteParams<'Scheduled'> | null | undefined>((state) => getCurrentScreenParams(state, 'Scheduled')) === RouteInStackWithNoParametersDefined;

  const child = useMemo((): ReactNode => {
    if (thread.snoozedUntil && !isScheduledFolderScreen) {
      return (
        <SnoozeHintText>
          {thread.snoozedUntil}
        </SnoozeHintText>
      );
    } if (thread.earliestScheduledDate) {
      return (
        <ScheduledHintText>
          {thread.earliestScheduledDate}
        </ScheduledHintText>
      );
    } if (snoozeElapsedTimeHint) {
      // Remember, the time at which a snooze was started (and thus the elapsed time) is borne by the inbox item and not
      // the thread.
      return (
        <SnoozeHintText>
          {snoozeElapsedTimeHint}
        </SnoozeHintText>
      );
    }
    return (
      <Text
        fontSize="sm"
        selectable={false}
        color="muted.400"
        flexGrow={0}
        flexShrink={0}
        overflow="hidden"
        numberOfLines={1}
      >
        {thread.lastSenderMessageDate}
      </Text>
    );
  }, [thread.snoozedUntil, thread.earliestScheduledDate, thread.lastSenderMessageDate, isScheduledFolderScreen, snoozeElapsedTimeHint]);

  return (
    <>
      <Box display={showActionBar ? undefined : 'none'}>
        {shouldRenderActionBar && (<ThreadActionBar {...props} setSnoozeOpen={setSnoozeOpen} />)}
      </Box>
      <Box display={showActionBar ? 'none' : undefined}>
        {child}
      </Box>
    </>
  );
}
