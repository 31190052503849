import { apiClient } from '../../api';
import { store } from '@/domain/state/store';
import { getSelfOrganization } from './getSelfOrganization';

export function getAllAccessibleChannels() {
  const selfChannels = apiClient.endpoints.getSelfChannels.select()(store.getState()).data?.channels ?? [];
  const orgChannels = apiClient.endpoints.getOrganizationChannels.select({ organizationId: (getSelfOrganization()?.id)! })(store.getState()).data?.channels ?? [];

  return [...selfChannels, ...orgChannels];
}
