import { getFileHash } from './getFileHash';
import { getExtension } from './getExtension';

export async function getServerFilename(file: File): Promise<string> {
  const hash = await getFileHash(file);
  const extension = getExtension(file.name);

  if (extension) {
    return `${hash}.${extension}`;
  }

  return hash;
}
