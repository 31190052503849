import {
  Box, HStack, IconButton, Image, Pressable, Skeleton, Text,
} from 'native-base';
import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useGetSelfOrganizations } from '@/infrastructure/controllers/hooks/api/useGetSelfOrganizations';

type AppLogoProps = {
  navigateToInbox?: () => void;
};

export function AppLogo(props: AppLogoProps) {
  const { currentData: organizations, isLoading: isLoadingOrganization } = useGetSelfOrganizations();

  function determineOrganizationName() {
    const name = organizations?.organizations[0]?.name;
    if (name) return name;
    if (!isLoadingOrganization) return 'Upstream';
  }

  const organizationName = determineOrganizationName();

  const navigation = useNavigation();

  return (
    <HStack
      w="216px"
      maxW="216px"
      h="100%"
      alignItems="center"
    >
      <Pressable
        onPress={props.navigateToInbox}
        borderWidth={0}
        padding={0}
        h="100%"
        flexGrow={0}
        flexShrink={1}
        flexDir="row"
        justifyContent="flex-start"
        py={2}
        ml={3}
        alignItems="center"
        minW="0"
      >
        <Box
          h="18px"
          w="18px"
          bgColor="primary.600"
          rounded="sm"
          mr={2}
          flexGrow={0}
          flexShrink={0}
        >
          <Image
            source={{ uri: '/images/upstream-logo.svg' }}
            alt={organizationName}
            resizeMode="center"
            h="18px"
            w="18px"
            flexGrow={0}
            flexShrink={0}
          />
        </Box>
        {organizationName ? (
          <Text
            fontWeight="600"
            isTruncated
            numberOfLines={1}
            flexShrink={1}
            minW="0"
            pr={2}
          >{organizationName}
          </Text>
        ) : <Skeleton.Text lines={1} width="90px" />}
      </Pressable>
      <IconButton
        variant="primary"
        _icon={{
          as: MaterialCommunityIcons,
          name: 'square-edit-outline',
          size: '4',
        }}
        onPress={() => navigation.dispatch(StackActions.replace('Compose'))}
        p={1}
        w={8}
        h={8}
        bg="opacityWhite.700"
        ml="auto"
        mr={2}
      />
    </HStack>
  );
}
