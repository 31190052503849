import { useMemo } from 'react';
import { THREADS_QUERY_OPTIONS } from '../../../api/query-parameters';
import { UserViewModel } from '@/adapters/view-models/UserViewModel';
import { ThreadViewModelAdditionalParams } from '@/adapters/view-models/ThreadViewModel';
import { ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { useGetChannelThreadsQuery } from '@/adapters/api';
import { createComprehensiveThreadsViewModel } from '@/adapters/view-models/ThreadsViewModel';

export const useGetChannelThreads = (channelId: string | undefined, selfUserId?: string, users?: UserViewModel[], channels?: ChannelViewModel[], pageToken?: string) => {
  const params: ThreadViewModelAdditionalParams = useMemo(() => ({
    selfUserId, users, channels, channelIdsToRemove: channelId ? [channelId] : undefined,
  }), [selfUserId, users, channels, channelId]);

  const rawThreads = useGetChannelThreadsQuery({ channelId: channelId ?? '', pageToken }, { ...THREADS_QUERY_OPTIONS, skip: !channelId });

  return {
    ...rawThreads,
    currentData: rawThreads.currentData ? createComprehensiveThreadsViewModel(rawThreads.currentData, params) : undefined
  };
};
