import { useCallback, useState } from 'react';
import { AttachmentUploadPresignedUrl, useGenerateAttachmentPresignedUrlMutation } from '@/adapters/api/codegen';
import { Status, getStatusFlags } from '../Status';

export function useGeneratePresignedUrl(attachmentName: string, file: File | undefined) {
  const [doGeneratePresignedUrl] = useGenerateAttachmentPresignedUrlMutation();
  const [status, setStatus] = useState<Status>('not_started');
  const [presignedUrlResponse, setPresignedUrlResponse] = useState<AttachmentUploadPresignedUrl | null>(null);

  const generatePresignedUrl = useCallback(async () => {
    if (!file) {
      throw new Error('No file to upload');
    }

    setStatus('in_progress');

    const presignResponse = await doGeneratePresignedUrl({
      stagedAttachmentDataRequestParameter: {
        download_file_name: attachmentName,
        mime_type: file.type,
        file_size: file.size,
      }
    });

    if (presignResponse.error) {
      setStatus('error');
      throw presignResponse.error;
    }

    setStatus('complete');
    setPresignedUrlResponse(presignResponse.data);

    return presignResponse.data;
  }, [file, doGeneratePresignedUrl, attachmentName]);

  return {
    generatePresignedUrl, presignedUrlResponse, status, ...getStatusFlags(status)
  };
}
