import React, {
  useState, useRef, useCallback, useImperativeHandle, forwardRef
} from 'react';
import { ParticipantScreenSectionControllerHandles } from '../screen-sections/ParticipantScreenSectionController';

import { AddParticipantsModal, AddParticipantsModalProps } from '../../ui/AddParticipantsModal';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';

export type AddParticipantsModalControllerProps = {
  participants: ParticipantViewModel[] ;
  didCancelCallback: () => void;
  didSubmitNewParticipantsCallback?: (newParticipants: ParticipantViewModel[]) => void;
  participantsType: AddParticipantsModalProps['participantsType'];
};

export interface AddParticipantsModalControllerHandles {
  show: () => void;
}

export const AddParticipantsModalController = forwardRef<AddParticipantsModalControllerHandles, AddParticipantsModalControllerProps>(({
  didCancelCallback, didSubmitNewParticipantsCallback, participants, participantsType
}, ref) => {
  const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
  const [newParticipants, setNewParticipants] = useState<ParticipantViewModel[]>([]);
  const addParticipantScreenSectionControllerRef = useRef<ParticipantScreenSectionControllerHandles>(null);

  useImperativeHandle(ref, () => ({
    show: () => {
      addParticipantScreenSectionControllerRef.current?.focus();
      setShowAddParticipantModal(true);
    },
  }));

  const closeAddParticipantModal = useCallback(() => {
    setShowAddParticipantModal(false);
    setNewParticipants([]);

    setTimeout(() => {
      addParticipantScreenSectionControllerRef.current?.clear();
    }, 300);
  }, []);

  const submitNewParticipants = useCallback(() => {
    didSubmitNewParticipantsCallback?.(newParticipants);
    closeAddParticipantModal();
  }, [closeAddParticipantModal, newParticipants, didSubmitNewParticipantsCallback]);

  const cancelAddParticipantModal = useCallback(() => {
    didCancelCallback();
    closeAddParticipantModal();
  }, [closeAddParticipantModal, didCancelCallback]);

  if (!showAddParticipantModal) {
    return null;
  }

  return (
    <AddParticipantsModal modalDisplayed={showAddParticipantModal} submitButtonDisabled={newParticipants.length === 0} selectedParticipantsListChangedCallback={setNewParticipants} addParticipantScreenSectionControllerRef={addParticipantScreenSectionControllerRef} didSubmitCallback={submitNewParticipants} participants={participants} didCancelCallback={cancelAddParticipantModal} participantsType={participantsType} />
  );
});
