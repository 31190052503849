import { Box, useTheme } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Animated, Easing } from 'react-native';

export function ProgressBar({ progress }: { progress: number }) {
  const [animatedWidth] = useState(() => new Animated.Value(progress));

  useEffect(() => {
    Animated.timing(animatedWidth, {
      toValue: Math.min(progress, 100),
      duration: 100,
      easing: Easing.inOut(Easing.ease),
      useNativeDriver: false,
    }).start();
  }, [progress, animatedWidth]);

  const theme = useTheme();

  return (
    <Box w="100%" h="4px" bgColor="white" rounded="2">
      <Animated.View
        style={{
          width: animatedWidth.interpolate({
            inputRange: [0, 100],
            outputRange: ['0%', '100%'],
          }),
          height: '100%',
          backgroundColor: theme.colors.primary[500],
          borderRadius: 2,
        }}
      />
    </Box>
  );
}
