import { ConversationParticipant, User } from '../api/codegen';
import { getConversationParticipantDisplayName, getConversationParticipantDisplayNameForInitials } from './utilities/getConversationParticipantDisplayName';
import { avatarColors } from '@/infrastructure/styles/main-theme/colors';
import { getHash } from '@/infrastructure/global/hash';

export type PictureViewModel = {
  url: string | null;
  initials: string;
  name: string;
  backgroundColor?: string;
  userId: string | null;
  id: string;
  canBeRemoved: boolean;
  _type: 'picture';
};

export function createPictureViewModelFromParticipant(participant: ConversationParticipant): PictureViewModel {
  const displayNameForInitial = getConversationParticipantDisplayNameForInitials(participant);
  const displayName = getConversationParticipantDisplayName(participant);
  return {
    url: participant.user?.picture_url ?? null,
    initials: getNameInitials(displayNameForInitial),
    backgroundColor: getAvatarColor(displayName),
    name: displayName,
    userId: participant.user?.id ?? null,
    id: participant.user?.id ?? participant.email_participant?.email!,
    canBeRemoved: !!participant.user,
    _type: 'picture',
  };
}

export function createPictureViewModelFromUser(user: User): PictureViewModel {
  return {
    url: user.picture_url ?? null,
    initials: getNameInitials(user.display_name),
    backgroundColor: getAvatarColor(user.display_name),
    name: user.display_name,
    userId: user.id,
    id: user.id,
    canBeRemoved: true,
    _type: 'picture',
  };
}

function getAvatarColor(displayName: string) {
  const hash = getHash(displayName);
  return avatarColors[hash % avatarColors.length];
}

function getNameInitials(name: string) {
  const nameParts = name.trim().split(/\s+/);
  const firstInitial = nameParts[0]?.charAt(0) ?? '';
  const secondInitial = nameParts[1]?.charAt(0) ?? '';

  return (firstInitial + secondInitial).toUpperCase();
}
