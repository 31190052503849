import { getCurrentScreenParams, RouteInStackWithNoParametersDefined } from '@/infrastructure/navigation/getCurrentScreenParams';
import { rootNavigationContainerRef } from '@/infrastructure/navigation/navigators/root/RootNavigationContainerRef';
import { GetSelfSentThreadsApiArg } from '../../api/codegen';

export function getSnoozedParams(): GetSelfSentThreadsApiArg | undefined {
  const snoozedParams = getCurrentScreenParams(rootNavigationContainerRef.current?.getState(), 'Snoozed');

  if (snoozedParams) {
    return { pageToken: snoozedParams.page };
  }

  if (snoozedParams === RouteInStackWithNoParametersDefined) {
    return {};
  }
}
