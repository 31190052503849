import { Icon, Text } from 'native-base';
import React from 'react';
import { StatusIcon } from '../StatusIcon';
import { NonNullStatus, STATUSES } from '@/adapters/other/statuses';
import { CustomMenuItem } from '../menu/CustomMenuItem';
import { PopoverMenuButton } from './PopoverMenuButton';
import { Status } from '@/adapters/api/codegen';
import { ThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { SideMenuButtonProps } from './SideMenuButton';

export type ThreadStatusSideMenuButtonProps = {
  paddingLeft: SideMenuButtonProps['paddingLeft'];
  open: SideMenuButtonProps['open'];
  animationDuration: SideMenuButtonProps['animationDuration'];
  thread: ThreadViewModel;
  setThreadStatusCallback: (threadId: string, status: Status) => void;
};

export function ThreadStatusSideMenuButton(props: ThreadStatusSideMenuButtonProps) {
  const buttonTitle = props.thread.status?.key ? STATUSES[props.thread.status.key]!.text : 'Set status';

  return (
    <PopoverMenuButton
      isDisabled={props.thread.isTrashed}
      paddingLeft={props.paddingLeft}
      open={props.open}
      animationDuration={props.animationDuration}
      buttonTitle={buttonTitle}
      icon={<StatusIcon status={props.thread.status?.key ?? null} />}
    >
      {STATUSES && Object.keys(STATUSES).map((status) => {
        const statusDetail = STATUSES[status as NonNullStatus];
        return (
          <CustomMenuItem
            key={status}
            disabled={status === props.thread.status?.key}
            onPress={() => props.setThreadStatusCallback(props.thread.id, status as NonNullStatus)}
            _text={{
              bold: status === props.thread.status?.key,
            }}
          >
            <>
              <Icon>
                <StatusIcon status={status as NonNullStatus} />
              </Icon>
              <Text>
                {statusDetail?.text || ''}
              </Text>
            </>
          </CustomMenuItem>
        );
      })}
      <CustomMenuItem disabled={!props.thread.status} onPress={() => props.setThreadStatusCallback(props.thread.id, null)} _text={{ bold: !props.thread.status }}>
        <Icon name="close" />
        <Text>
          No status
        </Text>
      </CustomMenuItem>
    </PopoverMenuButton>
  );
}
