import {
  CreateThreadApiArg, CreateThreadApiResponse, GetSelfUserApiResponse, Thread
} from '../api/codegen';
import { createThread } from './model-factories/createThread';
import { createMessage } from './model-factories/createMessage';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { createMessageViewModel } from '../view-models/MessageViewModel';
import { ThreadViewModel, createThreadViewModel } from '../view-models/ThreadViewModel';
import { AttachmentViewModel, UploadedAttachment, createAttachmentViewModelFromUploadedAttachment } from '../view-models/AttachmentViewModel';
import { apiClient } from '@/adapters/api';
import { store } from '@/domain/state/store';
import { insertThreadInScheduledFolder } from '@/adapters/mutation-handlers/utils/insertThreadInScheduledFolder';

export class CreateThreadMutationHandler extends MutationHandler<CreateThreadApiArg, CreateThreadApiResponse> {
  constructor(props: MutationHandlerProps<CreateThreadApiArg, CreateThreadApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: CreateThreadApiArg, _patchId: string) {
    const user = this.getSelfUser();

    if (!user) {
      return [];
    }

    const thread = createThreadViewModel(this.createThread(patch));

    return [
      ...this.createChannelThreadPatches(patch, thread),
      this.createSentFolderThreadPatch(patch, thread),
      this.createScheduledFolderThreadPatch(patch, thread),
      this.createThreadMessagesPatch(patch, user.rawUser),
      this.createThreadPatch(patch, thread),
    ];
  }

  private createChannelThreadPatches(patch: CreateThreadApiArg, thread: ThreadViewModel) {
    const channelIds = [...patch.threadCreateRequestBody.channel_ids];

    const patches = channelIds.map((channelId) => this.updateQueryData('getChannelThreads', { channelId }, (draft) => {
      if (draft) {
        draft.threads.unshift(thread);
      }
    }));

    return patches;
  }

  private createSentFolderThreadPatch(patch: CreateThreadApiArg, thread: ThreadViewModel) {
    return this.updateQueryData('getSelfSentThreads', {}, (draft) => {
      if (draft && !patch.threadCreateRequestBody.scheduled_date) {
        draft.threads.unshift(thread);
      }
    });
  }

  private createScheduledFolderThreadPatch(patch: CreateThreadApiArg, thread: ThreadViewModel) {
    return this.updateQueryData('getSelfScheduledThreads', {}, (draft) => {
      if (!draft || !patch.threadCreateRequestBody.scheduled_date) {
        return;
      }

      insertThreadInScheduledFolder(thread, draft);
    });
  }

  private createThread(patch: CreateThreadApiArg): Thread {
    const user = this.getSelfUser()!;
    return createThread(patch, user.rawUser, this.getOrganizationMembers().map((member) => member.rawUser));
  }

  private createThreadMessagesPatch(patch: CreateThreadApiArg, user: GetSelfUserApiResponse) {
    const stagedAttachmentsInProgress = this.getState().drafts.stagedAttachmentsInProgress ?? {} as Record<string, UploadedAttachment>;
    const committedAttachmentsInProgress = this.getState().drafts.committedAttachmentsInProgress ?? {} as Record<string, AttachmentViewModel>;

    const stagedAttachmentViewModels = patch.threadCreateRequestBody.staged_attachment_ids!.map((attachmentId) => {
      return createAttachmentViewModelFromUploadedAttachment(stagedAttachmentsInProgress[attachmentId]);
    });

    const committedAttachmentViewModels = patch.threadCreateRequestBody.committed_attachments!.map((attachment) => {
      return committedAttachmentsInProgress[attachment.server_filename];
    });

    const { data: organizations } = apiClient.endpoints.getSelfOrganizations.select()(store.getState());
    const { data: members } = apiClient.endpoints.getOrganizationMembers.select({ organizationId: organizations?.organizations[0].id || '' })(store.getState());

    return this.upsertQueryData('getThreadMessages', { threadId: patch.threadCreateRequestBody.thread_id! }, {
      messages: [{
        ...createMessageViewModel(createMessage(
          patch.threadCreateRequestBody,
          members,
          user,
        )),
        attachments: committedAttachmentViewModels.concat(stagedAttachmentViewModels),
      }]
    });
  }

  private createThreadPatch(patch: CreateThreadApiArg, thread: ThreadViewModel) {
    return this.upsertQueryData('getThread', { threadId: patch.threadCreateRequestBody.thread_id! }, thread);
  }

  protected generateInvalidationTags(patch: CreateThreadApiArg) {
    const channelIds = [...patch.threadCreateRequestBody.channel_ids];

    return [
      { type: 'SelfSentThreadsList' as const },
      ...channelIds.map((channelId) => ({ type: 'Channel' as const, id: channelId })),
    ];
  }
}
