import React, {
  useRef, useCallback, useImperativeHandle, forwardRef
} from 'react';
import { Text } from 'native-base';
import { AntDesign, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { AddParticipantsModalControllerHandles, AddParticipantsModalController, AddParticipantsModalControllerProps } from './AddParticipantsModalController';
import { ParticipantsListModalControllerHandles, ParticipantsListModalController } from './ParticipantsListModalController';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { ParticipantListItem } from '../../ui/ParticipantListItem';
import { closeCommandMenu, CommandItem, ProvideCommands } from '../../ui/CommandMenu';

export type ManageParticipantsModalControllerProps = {
  participants: ParticipantViewModel[];
  didSubmitNewParticipantsCallback?: AddParticipantsModalControllerProps['didSubmitNewParticipantsCallback'];
  didDeleteParticipantsCallback: (participantsToRemove: ParticipantViewModel[]) => void;
  participantsType: AddParticipantsModalControllerProps['participantsType'];
};

export interface ManageParticipantsModalControllerHandles {
  show: () => void;
}

export const ManageParticipantsModalController = forwardRef<ManageParticipantsModalControllerHandles, ManageParticipantsModalControllerProps>(({
  participants, didSubmitNewParticipantsCallback, didDeleteParticipantsCallback, participantsType
}, ref) => {
  const addParticipantModalControllerRef = useRef<AddParticipantsModalControllerHandles>(null);
  const participantsListModalControllerRef = useRef<ParticipantsListModalControllerHandles>(null);
  const addParticipantsModalOpenedDirectlyRef = useRef(false);

  useImperativeHandle(ref, () => ({
    show: () => {
      participantsListModalControllerRef.current?.show();
    },
  }));

  const addParticipantsButtonClickedCallback = useCallback(() => {
    participantsListModalControllerRef.current?.close();
    addParticipantModalControllerRef.current?.show();
  }, []);

  const participantListItem = useCallback(({ item }: { item: ParticipantViewModel }) => {
    const canBeRemoved = (item._type == 'picture' && item.canBeRemoved) || item._type === 'channel';

    return (
      <ParticipantListItem
        recipient={item}
        actions={!canBeRemoved ? [] : [{
          icon: { as: AntDesign, name: 'close' },
          variant: 'ghost',
          pressCallback: () => {
            didDeleteParticipantsCallback([item]);
          },
        }]}
      />
    );
  }, [didDeleteParticipantsCallback]);

  const modalTitle = participantsType === 'channels' ? 'Channels' : 'Participants';
  const buttonTitle = participantsType === 'channels' ? 'Add channels' : 'Add participants';

  const participantListEmptyComponent = (
    <Text flexGrow={1} textAlign="center" mx={5}>
      {participantsType === 'channels' ? "There aren't any channels attached to this thread. You can add some by pressing the button below." : "There aren't any participants in this thread. You can add some by pressing the button below."}
    </Text>
  );

  return (
    <>
      <ParticipantsListModalController
        ref={participantsListModalControllerRef}
        renderItem={participantListItem}
        participants={participants}
        listEmptyComponent={participantListEmptyComponent}
        addParticipantsButtonClickedCallback={addParticipantsButtonClickedCallback}
        modalTitle={modalTitle}
        buttonTitle={buttonTitle}
      />

      <AddParticipantsModalController
        ref={addParticipantModalControllerRef}
        participants={participants}
        didSubmitNewParticipantsCallback={didSubmitNewParticipantsCallback}
        didCancelCallback={() => {
          if (!addParticipantsModalOpenedDirectlyRef.current) {
            participantsListModalControllerRef.current?.show();
          } else {
            addParticipantsModalOpenedDirectlyRef.current = false;
          }
        }}
        participantsType={participantsType}
      />

      <ProvideCommands id={participantsType === 'channels' ? 'add-channels' : 'add-participants'}>
        <CommandItem
          icon={participantsType === 'channels' ? {
            name: 'plus',
            collection: MaterialCommunityIcons,
          } : {
            name: 'person-add-alt',
            collection: MaterialIcons,
          }}
          onSelect={() => {
            addParticipantsButtonClickedCallback();
            addParticipantsModalOpenedDirectlyRef.current = true;
            closeCommandMenu();
          }}
          value={buttonTitle}
        >
          {buttonTitle}
        </CommandItem>
      </ProvideCommands>
    </>
  );
});
