import {
  Box, HStack, Icon, IconButton, Pressable, Text
} from 'native-base';
import {
  useCallback, useEffect, useRef, useState
} from 'react';
import { Linking } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { AttachmentViewModel } from '@/adapters/view-models/AttachmentViewModel';
import { UploadedAttachment } from '@/adapters/view-models/AttachmentViewModel';
import { getExtensionColor } from './utils/getExtensionColor';
import { ProgressBar } from './ProgressBar';
import { useUploadAttachment } from './hooks/useUploadAttachment';
import { AttachmentThumbnailContainer } from './AttachmentThumbnailContainer';

export type DidUploadAttachmentCallback = (attachment: UploadedAttachment, file: File) => void;

export type AttachmentThumbnailProps = {
  attachment: AttachmentViewModel;
  file?: File;
  onDidUploadAttachment?: DidUploadAttachmentCallback;
  onDidDeleteAttachment?: (attachment: AttachmentViewModel) => void;
  canDelete: boolean;
};

export function AttachmentThumbnail(props: AttachmentThumbnailProps) {
  const { attachment, file, onDidUploadAttachment } = props;
  const [isThumbnailHovered, setIsHovered] = useState(false);
  const [isDeleteHovered, setIsDeleteHovered] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isExpired, setIsExpired] = useState(isAttachmentRawDateExpired(attachment.expiryDate));
  const autoUploadTriggered = useRef(false);

  const extension = attachment.name.split('.').pop()?.toLowerCase();
  const extensionColor = getExtensionColor(extension);

  const {
    upload,
    cancelUpload,
    stagedDownloadUrl,
    expiryDate,
    isInProgress,
    isError,
    isComplete,
    progress,
  } = useUploadAttachment(file, attachment.name, onDidUploadAttachment);

  useEffect(
    () => {
      const actualExpiryDate = expiryDate ?? attachment.expiryDate;
      if (actualExpiryDate) {
        const delayBeforeCheckingExpiration = calculateDelayBeforeCheckingExpiryDate(new Date(actualExpiryDate));
        const timeoutId = setTimeout(() => {
          setIsExpired(isAttachmentRawDateExpired(actualExpiryDate));
        }, delayBeforeCheckingExpiration);

        return () => {
          clearTimeout(timeoutId);
        };
      }
    },
    [attachment.expiryDate, expiryDate, isThumbnailHovered]
  );

  useEffect(() => {
    if (!file) {
      return;
    }

    if (!autoUploadTriggered.current) {
      autoUploadTriggered.current = true;
      void upload();
    }

    return () => {
      cancelUpload();
    };
  }, [cancelUpload, file, upload]);

  const handleOnPress = async () => {
    if (isInProgress || isExpired) {
      return;
    }

    const downloadUrl = attachment.downloadUrl ?? stagedDownloadUrl;
    if (downloadUrl) {
      await Linking.openURL(downloadUrl);
    }
  };

  const handleCancel = () => {
    setIsCancelled(true);
    cancelUpload();
  };

  const { onDidDeleteAttachment } = props;

  const onHide = useCallback(() => {
    onDidDeleteAttachment?.(attachment);
  }, [attachment, onDidDeleteAttachment]);

  return (
    <AttachmentThumbnailContainer isVisible={!isCancelled} onHide={onHide} isAnimated={!!file}>
      <Pressable
        w="200px"
        h="75px"
        mr="8px"
        bgColor="#F2F2F2"
        _hover={{
          bgColor: 'dark.100',
        }}
        style={{ cursor: ((!file || isComplete) && !isExpired) ? 'pointer' : 'auto' }}
        flexDirection="row"
        rounded="4"
        p="8px"
        mb={2}
        flexDir="column"
        justifyContent="space-between"
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => setIsHovered(false)}
        onPress={handleOnPress}
      >
        <HStack alignItems="center" mb="8px" justifyContent="space-between" h="24px">
          <Text fontSize="m" mr={2} noOfLines={1} color="dark.700" fontWeight="500">
            {`${attachment.name}`}
          </Text>
          {(props.canDelete && isThumbnailHovered) || isDeleteHovered ? (
            <IconButton
              _hover={{
                bgColor: 'dark.100',
              }}
              onHoverIn={() => setIsDeleteHovered(true)}
              onHoverOut={() => setIsDeleteHovered(false)}
              onPress={handleCancel}
              p="2px"
              rounded="4"
              icon={<Icon color="dark.300" as={MaterialIcons} name="close" />}
            />
          ) : null}

          {!props.canDelete && isThumbnailHovered ? (
            <Icon color="dark.300" as={MaterialIcons} name="download" />
          ) : null}
        </HStack>
        <HStack alignItems="center" space="8px" h="27px">
          {isInProgress ? (
            <ProgressBar progress={progress} />
          ) : (
            <>
              {extension ? (
                <Box bgColor="dark.50" px="5px" py="3px" rounded="2">
                  <Text color={extensionColor} fontWeight="600">
                    {extension.toUpperCase()}
                  </Text>
                </Box>
              ) : null}
              <Text fontSize="sm" color="dark.400">
                {attachment.size}
              </Text>

              {isExpired && (
                <Text fontSize="sm" color="#CA3A31" selectable={false} textAlign="right" flexGrow={1}>
                  Expired
                </Text>
              )}

              {!isExpired && isError ? (
                <HStack flexGrow={1} justifyContent="flex-end" space="8px" alignItems="center">
                  <Text fontSize="sm" color="#CA3A31" selectable={false}>
                    Failed
                  </Text>

                  <IconButton
                    _hover={{
                      bgColor: 'dark.100',
                    }}
                    onHoverIn={() => setIsDeleteHovered(true)}
                    onHoverOut={() => setIsDeleteHovered(false)}
                    onPress={upload}
                    p="2px"
                    rounded="4"
                    icon={<Icon color="dark.300" as={MaterialIcons} name="refresh" />}
                  />
                </HStack>
              ) : null}
            </>
          )}
        </HStack>
      </Pressable>
    </AttachmentThumbnailContainer>
  );
}

export const STAGED_ATTACHMENT_EXPIRY_DATE_BUFFER = 5 * 60 * 1000;

export function isAttachmentRawDateExpired(expiryDate: string | undefined) {
  return expiryDate ? isAttachmentDateExpired(new Date(expiryDate)) : false;
}

export function isAttachmentDateExpired(expiryDate: Date) {
  return (expiryDate.getTime() - STAGED_ATTACHMENT_EXPIRY_DATE_BUFFER) < Date.now();
}

export function calculateDelayBeforeCheckingExpiryDate(expiryDate: Date) {
  return Math.max(new Date(expiryDate).getTime() - Date.now() - STAGED_ATTACHMENT_EXPIRY_DATE_BUFFER, 0) + 5;
}
