import { GetSelfInboxEventsApiArg } from '../../api/codegen';
import { getCurrentScreenParams, RouteInStackWithNoParametersDefined } from '@/infrastructure/navigation/getCurrentScreenParams';
import { rootNavigationContainerRef } from '@/infrastructure/navigation/navigators/root/RootNavigationContainerRef';
import { getCategoryFromSlug } from '../../view-models/ChannelViewModel';

export function getInboxParams(): GetSelfInboxEventsApiArg | undefined {
  const inboxParams = getCurrentScreenParams(rootNavigationContainerRef.current?.getState(), 'Inbox');

  if (inboxParams) {
    return {
      pageToken: inboxParams.page,
      category: getCategoryFromSlug(inboxParams.category),
    };
  }

  /**
   * In this case, it means that we are displaying /inbox
   * which means that the category is CATEGORY_PRIMARY
   */
  if (inboxParams === RouteInStackWithNoParametersDefined) {
    return {
      category: getCategoryFromSlug(undefined),
    };
  }
}
