import React, { useEffect, useMemo } from 'react';
import { ThreadList } from '../../ui/thread-list/ThreadList';
import {
  useArchiveThreadCallback,
  useChangeUnreadStatusCallback,
  useMoveToInboxCallback,
  usePrefetchThreadCallback,
  useSelectThreadCallback,
  useStarThreadCallback,
  useUnstarThreadCallback,
  useSnoozeThreadCallback,
} from '../hooks/channelScreenHooks';
import { useGetSelfUser } from '../hooks/api/useGetSelfUser';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useGetSelfOrganizationMembers } from '../hooks/api/useGetSelfOrganizationMembers';
import { useGetAllAccessibleChannels } from '../hooks/api/useGetAllAccessibleChannels';
import { createStandardErrorIfAny } from '@/adapters/other/createStandardError';
import { draftActions, threadDraftIdPrefix, useThreadDrafts } from '@/domain/state/drafts';
import { useGetSelfFilteredThreadsQuery } from '@/adapters/api';
import { THREADS_QUERY_OPTIONS } from '../../api/query-parameters';
import { ThreadsViewModel, createComprehensiveThreadsViewModel } from '@/adapters/view-models/ThreadsViewModel';
import { EmptyFolderScreenSection } from '../../ui/EmptyFolderScreenSection';
import { useAppDispatch } from '../../state/hooks';

export function DraftsScreenSectionController() {
  const { currentData: selfUser } = useGetSelfUser();
  const { currentData: selfOrganizations, organizationId } = useGetSelfOrganizations();
  const { currentData: organizationMembers } = useGetSelfOrganizationMembers(organizationId);
  const { allRawChannels } = useGetAllAccessibleChannels(selfOrganizations);
  const threadDrafts = useThreadDrafts();
  const threadIds = useMemo(() => threadDrafts.map(({ id }) => id.slice(threadDraftIdPrefix.length)), [threadDrafts]);

  const threadsQuery = useGetSelfFilteredThreadsQuery({ threadIds }, {
    // can we add THREADS_QUERY_OPTIONS into default options for this query at the api level, so that we don't have to pass it here?
    ...THREADS_QUERY_OPTIONS,
    skip: threadIds.length === 0,
  });

  useRemoveObsoleteDrafts(threadsQuery.currentData, threadIds);

  const threadsViewModel = useMemo(() => {
    if (threadIds.length === 0) {
      return { threads: [] };
    }
    if (threadsQuery.currentData) {
      const threads = createComprehensiveThreadsViewModel(threadsQuery.currentData, {
        selfUserId: selfUser?.id,
        // todo remove users, we only need them for Inbox view model, not for Threads view model
        users: organizationMembers?.users,
        channels: allRawChannels,
        channelIdsToRemove: undefined,
      });

      return {
        ...threads,
        threads: threads.threads.filter((thread) => !thread.isTrashed),
      };
    }
    return undefined;
  }, [threadIds.length, threadsQuery.currentData, selfUser?.id, organizationMembers?.users, allRawChannels]);

  const threadHoveredCallback = usePrefetchThreadCallback();
  const selectThreadCallback = useSelectThreadCallback(threadsViewModel?.threads);
  const archiveThreadCallback = useArchiveThreadCallback();
  const moveToInboxCallback = useMoveToInboxCallback();
  const changeUnreadStatusCallback = useChangeUnreadStatusCallback();
  const setSnoozeEndDateCallback = useSnoozeThreadCallback();
  const starThreadCallback = useStarThreadCallback();
  const unstarThreadCallback = useUnstarThreadCallback();

  const errorToDisplay = createStandardErrorIfAny(threadsQuery.error);
  const isLoadingForFirstTime = threadsQuery.isLoading && !threadsViewModel?.threads;

  return (
    <ThreadList
      showSpinner={isLoadingForFirstTime && !errorToDisplay}
      showComposeArea={false}
      items={threadsViewModel?.threads}
      errorToDisplay={errorToDisplay?.displayMessage}
      selectThreadCallback={selectThreadCallback}
      archiveThreadCallback={archiveThreadCallback}
      changeUnreadStatusCallback={changeUnreadStatusCallback}
      starThreadCallback={starThreadCallback}
      unstarThreadCallback={unstarThreadCallback}
      threadHoveredCallback={threadHoveredCallback}
      moveThreadToInboxCallback={moveToInboxCallback}
      setSnoozeEndDateCallback={setSnoozeEndDateCallback}
      ListEmptyComponent={<EmptyFolderScreenSection iconSetName="drafts" text="Drafts are saved automatically as you compose them." />}
    />
  );
}

function useRemoveObsoleteDrafts(threads: ThreadsViewModel | undefined, threadIds: string[]) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (threads) {
      const trashedThreadIds = threads.threads.filter((thread) => thread.isTrashed).map((thread) => thread.id);
      const deletedThreadIds = threadIds.filter((threadId) => !threads!.threads.some((thread) => thread.id === threadId));

      const threadIdsToRemove = [...trashedThreadIds, ...deletedThreadIds];

      threadIdsToRemove.forEach((threadId) => {
        dispatch(draftActions.deleteDraft(`${threadDraftIdPrefix}${threadId}`));
      });
    }
  }, [threads, threadIds, dispatch]);
}
