import React, {
  useRef, forwardRef, useImperativeHandle, useCallback, ComponentProps, useState, useEffect
} from 'react';
import { HStack, ScrollView } from 'native-base';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { MaterialIcons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';
import { AttachmentViewModel, StagedAttachment, createAttachmentViewModel } from '@/adapters/view-models/AttachmentViewModel';
import { AttachmentThumbnail } from './AttachmentThumbnail';
import { UploadedAttachment } from '@/adapters/view-models/AttachmentViewModel';
import { filterSuccessFullyUploadedAttachments } from './utils/filterSuccessFullyUploadedAttachments';
import { useUploadAttachmentCallback } from './hooks/useUploadAttachmentCallback';
import { useDeleteAttachmentCallback } from './hooks/useDeleteAttachmentCallback';
import { useDefaultAttachmentViewModels } from './hooks/useDefaultAttachmentViewModels';
import { ATTACHMENT_TRANSITION_MS } from './AttachmentThumbnailContainer';
import { AttachmentGalleryContainer, AttachmentGalleryContainerHandles } from './AttachmentGalleryContainer';
import { ErrorType, AttachmentGalleryErrorAlertDialog, MAX_TOTAL_FILE_SIZE_BYTES } from './AttachmentGalleryErrorAlertDialog';
import { getExtension } from './utils/getExtension';
import { useUpdateLoadingState } from './hooks/useUpdateLoadingState';
import { CommandItem, ProvideCommands, closeCommandMenu } from '@/infrastructure/ui/CommandMenu';

type AttachmentGalleryProps = ComponentProps<typeof ScrollView> & {
  onDidUpdateAttachments?: (attachments: (UploadedAttachment | AttachmentViewModel)[]) => void;
  onPerformedAction?: () => void;
  onLoadingStateChanged?: (isLoading: boolean) => void;
  defaultAttachments: (UploadedAttachment | AttachmentViewModel)[];
};

export interface AttachmentGalleryHandles {
  triggerFileUpload: () => void;
  uploadFiles: (files: File[]) => void;
  clearAttachments: () => void;
}

export type AddedAttachment = [AttachmentViewModel, File, UploadedAttachment | undefined];

export const AttachmentGallery = forwardRef<AttachmentGalleryHandles, AttachmentGalleryProps>(({
  onDidUpdateAttachments, onLoadingStateChanged, onPerformedAction, ...props
}, ref) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const attachmentGalleryContainerRef = useRef<AttachmentGalleryContainerHandles | null>(null);
  const [addedAttachments, setAddedAttachments] = React.useState<AddedAttachment[]>([]);
  const [defaultAttachments, setDefaultAttachments] = useState<(UploadedAttachment | AttachmentViewModel)[]>(props.defaultAttachments);

  const [errorType, setErrorType] = useState<ErrorType>(null);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState<boolean>(false);
  const [errorFileName, setErrorFileName] = useState<string | null>(null);

  const openFileSelector = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  useHotkeys('mod+shift+a', () => {
    openFileSelector();
  }, [openFileSelector], {
    enabled: useIsFocused() && !!onDidUpdateAttachments,
    enableOnContentEditable: true,
    preventDefault: true
  });

  const didCloseErrorAlertDiablog = useCallback(() => {
    setIsAlertDialogOpen(false);
  }, []);

  const triggerAddedAttachmentsUpdate = useCallback((addedAttachments: AddedAttachment[]) => {
    onDidUpdateAttachments?.(defaultAttachments.concat(filterSuccessFullyUploadedAttachments(addedAttachments)));
  }, [defaultAttachments, onDidUpdateAttachments]);

  const triggerDefaultAttachmentsUpdate = useCallback((defaultAttachments: (UploadedAttachment | AttachmentViewModel)[]) => {
    onDidUpdateAttachments?.(defaultAttachments.concat(filterSuccessFullyUploadedAttachments(addedAttachments)));
  }, [addedAttachments, onDidUpdateAttachments]);

  useEffect(() => {
    setDefaultAttachments(props.defaultAttachments);
  }, [props.defaultAttachments]);

  const addAttachments = (newAttachments: [AttachmentViewModel, File, UploadedAttachment | undefined][]) => {
    setAddedAttachments((prevAttachments) => [...prevAttachments, ...newAttachments]);
  };

  useEffect(() => {
    setTimeout(() => {
      attachmentGalleryContainerRef.current?.scrollToEnd();
    }, ATTACHMENT_TRANSITION_MS);
  }, [addedAttachments]);

  useUpdateLoadingState(onLoadingStateChanged, addedAttachments, defaultAttachments);

  useImperativeHandle(ref, () => ({
    triggerFileUpload: () => {
      openFileSelector();
    },

    clearAttachments: () => {
      setAddedAttachments([]);
      triggerAddedAttachmentsUpdate([]);
    },

    uploadFiles
  }));

  const defaultAttachmentViewModels = useDefaultAttachmentViewModels(defaultAttachments);

  const isMultiline = !onDidUpdateAttachments;

  const onDidUploadAttachment = useUploadAttachmentCallback(setAddedAttachments, triggerAddedAttachmentsUpdate);
  const onDidDeleteAttachment = useDeleteAttachmentCallback(defaultAttachmentViewModels, setDefaultAttachments, triggerDefaultAttachmentsUpdate, setAddedAttachments, onPerformedAction, triggerAddedAttachmentsUpdate);

  const uploadFiles = useCallback((files: File[]) => {
    onPerformedAction?.();

    const totalSize = addedAttachments
      .map((attachment) => attachment[1])
      .concat(files)
      .map((file) => file.size)
      .concat(defaultAttachments.map((attachment) => ('rawSize' in attachment ? attachment.rawSize : attachment.size)))
      .reduce((sizeAcc, size) => {
        return sizeAcc + size;
      }, 0);

    if (totalSize > MAX_TOTAL_FILE_SIZE_BYTES) {
      setErrorType('max_size_exceeded');
      setErrorFileName(files[0].name);
      setIsAlertDialogOpen(true);
      return;
    }

    const forbiddenFile = files.find((file) => {
      const extension = getExtension(file.name);
      const isExtensionForbidden = extension && FORBIDDEN_EXTENSIONS.includes(extension.toLocaleLowerCase());
      const isMimeTypeForbidden = FORBIDDEN_MIME_TYPES.includes(file.type.toLocaleLowerCase());
      return isExtensionForbidden || isMimeTypeForbidden;
    });

    if (forbiddenFile) {
      setErrorType('security_violation');
      setErrorFileName(forbiddenFile.name);
      setIsAlertDialogOpen(true);
      return;
    }

    const newAttachments: StagedAttachment[] = files.map((file) => {
      return {
        name: file.name,
        size: file.size,
        mime_type: file.type,
        id: uuid()
      };
    });

    const newAttachmentViewModels = newAttachments.map((attachment, index) => createAttachmentViewModel(attachment, index));
    addAttachments(newAttachmentViewModels.map((attachment, index) => [attachment, files[index], undefined]));

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [addedAttachments, defaultAttachments, onPerformedAction]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      uploadFiles(Array.from(event.target.files));
    }
  };

  return (
    <>
      <ProvideCommands id="add-attachment">
        <CommandItem
          icon={{ name: 'attach-file', collection: MaterialIcons }}
          shortcut="mod+shift+a"
          onSelect={() => {
            openFileSelector();
            closeCommandMenu();
          }}
        >
          Attach files
        </CommandItem>
      </ProvideCommands>
      <AttachmentGalleryContainer
        ref={attachmentGalleryContainerRef}
        displayAsScrollView={!isMultiline}
        pl={props.pl}
        pt={props.pt}
      >
        <HStack flexWrap={isMultiline ? 'wrap' : 'nowrap'}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            multiple
          />
          {defaultAttachmentViewModels.map((attachment) => <AttachmentThumbnail key={attachment.uniqueKey} attachment={attachment} onDidDeleteAttachment={onDidDeleteAttachment} canDelete={!!onDidUpdateAttachments} />)}
          {addedAttachments.map((attachment) => <AttachmentThumbnail key={attachment[0].uniqueKey} attachment={attachment[0]} file={attachment[1]} onDidDeleteAttachment={onDidDeleteAttachment} onDidUploadAttachment={onDidUploadAttachment} canDelete={!!onDidUpdateAttachments} />)}
        </HStack>

        { onDidUpdateAttachments && (
          <AttachmentGalleryErrorAlertDialog isOpen={isAlertDialogOpen} errorType={errorType} fileName={errorFileName} onClose={didCloseErrorAlertDiablog} />
        )}

      </AttachmentGalleryContainer>
    </>
  );
});

// Should be shared with the backend at some point
const FORBIDDEN_MIME_TYPES = ['application/javascript'];
const FORBIDDEN_EXTENSIONS = [
  'ade',
  'adp',
  'apk',
  'appx',
  'appxbundle',
  'bat',
  'cab',
  'chm',
  'cmd',
  'com',
  'cpl',
  'diagcab',
  'diagcfg',
  'diagpkg',
  'dll',
  'dmg',
  'ex',
  'ex_',
  'exe',
  'hta',
  'img',
  'ins',
  'iso',
  'isp',
  'jar',
  'jnlp',
  'js',
  'jse',
  'lib',
  'lnk',
  'mde',
  'mjs',
  'msc',
  'msi',
  'msix',
  'msixbundle',
  'msp',
  'mst',
  'nsh',
  'pif',
  'ps1',
  'scr',
  'sct',
  'shb',
  'sys',
  'vb',
  'vbe',
  'vbs',
  'vhd',
  'vxd',
  'wsc',
  'wsf',
  'wsh',
  'xll'
];
