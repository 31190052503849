import { HStack, IconButton, Text } from 'native-base';
import { useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { noop } from 'lodash';
import { PaginationMeta } from '@/adapters/api/codegen';
import { formatNumber } from '@/adapters/view-models/utilities/formatNumber';
import { useEffectEvent } from '../hooks/useEffectEvent';
import { useTwoHotkeys } from '../hooks/useTwoHotkeys';

export interface PaginatorProps {
  meta: PaginationMeta;
  onPageChange?: (pageToken: string) => void;
  onPrefetchPage?: (pageToken: string) => void;
}

export function Paginator({ meta, onPageChange, ...props }: PaginatorProps) {
  const onPrefetch = useEffectEvent(props.onPrefetchPage);
  useEffect(() => {
    if (meta.next_page_token) {
      onPrefetch?.(meta.next_page_token);
    }
    if (meta.previous_page_token) {
      onPrefetch?.(meta.previous_page_token);
    }
  }, [meta]);
  if (meta.total_count <= 0) return null;
  const start = formatNumber(meta.start_index + 1);
  const end = formatNumber(meta.start_index + meta.returned_count);
  const total = formatNumber(meta.total_count);
  const prevToken = meta.previous_page_token;
  const nextToken = meta.next_page_token;
  return (
    <HStack mx={3} space={2} alignItems="center">
      <Text color="muted.500">{` ${start} - ${end} of ${total} `}</Text>
      <NavigationButton
        iconName="navigate-before"
        onPress={prevToken ? () => onPageChange?.(prevToken) : undefined}
        shortcut={['g', 'p']}
      />
      <NavigationButton
        iconName="navigate-next"
        onPress={nextToken ? () => onPageChange?.(nextToken) : undefined}
        shortcut={['g', 'n']}
      />
    </HStack>
  );
}

function NavigationButton({ iconName, onPress, shortcut }: {
  iconName: string;
  onPress?: () => void;
  shortcut: [string, string];
}) {
  const isFocusedScreen = useIsFocused();
  useTwoHotkeys(
    shortcut[0],
    shortcut[1],
    onPress || noop,
    { enabled: Boolean(onPress) && isFocusedScreen },
  );
  return (
    <IconButton
      variant="primary"
      _icon={{ name: iconName }}
      _focus={{ borderColor: 'transparent' }}
      size="sm"
      isDisabled={!onPress}
      onPress={onPress}
    />
  );
}
