import {
  Box, Icon, Text, VStack
} from 'native-base';
import { ComponentPropsWithoutRef, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MaterialIcons } from '@expo/vector-icons';

type BoxProps = ComponentPropsWithoutRef<typeof Box>;

export type DropZoneProps = BoxProps & {
  attachFilesCallback: ((files: File[]) => void) | undefined;
  underlyingDivStyle?: React.HTMLAttributes<HTMLDivElement>['style'];
};

export function DropZone({
  attachFilesCallback, underlyingDivStyle, ...props
}: DropZoneProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: attachFilesCallback,
    noClick: true,
  });

  const [dropZoneEnabled, setDropZoneEnabled] = useState(false);

  // May be useful when we improve the Quill experience
  // const isImageFile = (file: DataTransferItem) => {
  //   return file.type.startsWith('image/');
  // };

  useEffect(() => {
    const handleDragOver = (e: DragEvent) => {
      // May be useful when we improve the Quill experience
      // const items = e.dataTransfer?.items;
      // if (items && items.length === 1 && isImageFile(items[0])) {
      //   setDropZoneEnabled(false);
      //   return;
      // }

      if (e.dataTransfer?.types.includes('Files')) {
        setDropZoneEnabled(true);
      }
    };

    const handleDrop = () => {
      setDropZoneEnabled(false);
    };

    const handleDragLeave = () => {
      setDropZoneEnabled(false);
    };

    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);
    window.addEventListener('dragleave', handleDragLeave);

    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('dragleave', handleDragLeave);
    };
  }, []);

  const overlayBackgroundColor = 'rgba(255,255,255,0.9)';
  const overlayBorderColor = isDragActive ? 'dark.300' : 'dark.100';
  const textColor = isDragActive ? 'dark.400' : 'dark.200';

  return (
    <Box {...props}>
      <div
        {...getRootProps(
          {
            style: {
              position: 'absolute',
              zIndex: 2,
              width: '100%',
              height: '100%',
              pointerEvents: dropZoneEnabled ? 'all' : 'none',
              backgroundColor: dropZoneEnabled ? overlayBackgroundColor : 'transparent',
              ...underlyingDivStyle,
            },
          }
        )}
      >
        <input {...getInputProps()} style={{ display: 'none', visibility: 'hidden' }} disabled />
        <VStack
          zIndex={1}
          width="100%"
          height="100%"
          display={dropZoneEnabled ? 'flex' : 'none'}
          alignItems="center"
          justifyContent="center"
          pointerEvents="none"
          borderWidth={2}
          borderColor={overlayBorderColor}
          borderStyle="dashed"
          borderRadius={16}
          space="8px"
        >
          <Icon color={textColor} name="attach-file" size="xl" as={MaterialIcons} />
          <Text color={textColor} fontSize="lg" fontWeight="medium">
            Drop files here
          </Text>
        </VStack>
      </div>
      {props.children}
    </Box>
  );
}
