import { GetSelfAllThreadsApiArg } from '../../api/codegen';
import { getCurrentScreenParams, RouteInStackWithNoParametersDefined } from '@/infrastructure/navigation/getCurrentScreenParams';
import { rootNavigationContainerRef } from '@/infrastructure/navigation/navigators/root/RootNavigationContainerRef';

export function getAllMessagesParams(): GetSelfAllThreadsApiArg | undefined {
  const allMessagesParams = getCurrentScreenParams(rootNavigationContainerRef.current?.getState(), 'AllMessages');

  if (allMessagesParams) {
    return { pageToken: allMessagesParams.page };
  }

  if (allMessagesParams === RouteInStackWithNoParametersDefined) {
    return {};
  }
}
