import {
  HStack, IconButton, type Icon, useToken
} from 'native-base';
import React, {
  type ReactNode, useRef, useState, useEffect, ComponentProps,
} from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { Group, ScrollArea, Stack } from '@mantine/core';
import { CHANNEL_ICON_SETS } from '@/adapters/view-models/ChannelViewModel';

import './SidebarTabs.css';

type IconDefinition = {
  name: string;
  collection: ComponentProps<typeof Icon>['as'];
};

const tabIcons: IconDefinition[] = [
  CHANNEL_ICON_SETS.publicChannel.default,
  { name: 'mail-outline', collection: MaterialIcons },
];

export function SidebarTabs<T>({ tabs, renderTab }: {
  tabs: T[];
  renderTab: (tab: T, index: number) => ReactNode;
}) {
  const viewportRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState(0);
  return (
    <Stack
      // mt={-8}
      flex={1}
      style={{ minHeight: 0 }}
      gap={0}
    >
      <ScrollArea
        type="never"
        scrollbarSize={0}
        scrollbars="x"
        style={{
          scrollSnapType: 'x mandatory',
          scrollBehavior: 'smooth',
        }}
        classNames={{
          root: 'sidebar-tabs-scroll-area-root',
          viewport: 'scroll-area-viewport sidebar-tabs-scroll-area-viewport',
        }}
        viewportRef={viewportRef}
        onScrollPositionChange={(position) => {
          const newActiveTab = Math.round(position.x / viewportRef.current!.clientWidth);
          if (newActiveTab !== activeTab) {
            setActiveTab(newActiveTab);
          }
        }}
      >
        <HStack
          space={0}
          flexGrow={1}
          w={`${100 * tabs.length}%`}
          maxHeight="100%"
        >
          {tabs.map((tab, index) => (
            <ScrollAreaWithShadows
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              type="scroll"
              scrollbarSize={4}
              scrollbars="y"
              style={{
                width: `calc(100% / ${tabs.length})`,
                scrollSnapAlign: 'start',
                // height: '100%',
              }}
              classNames={{
                viewport: 'scroll-area-viewport',
              }}
            >
              {renderTab(tab, index)}
            </ScrollAreaWithShadows>
          ))}
        </HStack>
      </ScrollArea>
      <Group
        gap={4}
        justify="center"
        p={8}
      >
        {tabs.map((_, index) => {
          const icon = tabIcons[index] || tabIcons[0];
          return (
            <IconButton
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              variant="primary"
              _icon={{
                size: '4',
                as: icon.collection,
                name: icon.name,
              }}
              borderRadius="full"
              w={7}
              h={7}
              bg={index === activeTab ? 'white' : 'transparent'}
              onPress={() => {
                viewportRef.current!.scrollTo({
                  left: index * viewportRef.current!.clientWidth,
                  behavior: 'smooth',
                });
              }}
            />
          );
        })}
      </Group>
    </Stack>
  );
}

interface ScrollAreaWithShadowsProps extends React.ComponentProps<typeof ScrollArea> {}

function ScrollAreaWithShadows({ children, ...props }: ScrollAreaWithShadowsProps) {
  const viewportRef = useRef<HTMLDivElement>(null);
  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(false);

  const shadowColor = useToken('colors', 'opacityPrimaryDarker.100');

  const handleScroll = () => {
    const viewport = viewportRef.current;
    if (viewport) {
      const { scrollTop, scrollHeight, clientHeight } = viewport;
      setShowTopShadow(scrollTop > 0);
      setShowBottomShadow(scrollTop + clientHeight < scrollHeight);
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  return (
    <ScrollArea
      {...props}
      viewportRef={viewportRef}
      onScrollPositionChange={handleScroll}
      style={{
        '--scroll-overflows-shadow-color': shadowColor,
        ...props.style,
      }}
    >
      {showTopShadow && (
        <div className="scroll-top-overflow-shadow" role="presentation" />
      )}
      {children}
      {showBottomShadow && (
        <div className="scroll-bottom-overflow-shadow" role="presentation" />
      )}
    </ScrollArea>
  );
}
