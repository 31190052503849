import '@expo/metro-runtime';
import './infrastructure/gesture-handler';

// 1. import `NativeBaseProvider` component
import { StatusBar } from 'native-base';
import { Provider as ReduxProvider } from 'react-redux';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { ThemeProvider } from '@/infrastructure/styles/ThemeProvider';
import useCachedResources from './infrastructure/hooks/useCachedResources';
import RootNavigationContainer from './infrastructure/navigation/navigators/root/RootNavigationContainer';
import { useAppDependencies } from './infrastructure/dependencies';
import { store } from './domain/state/store';
import { accessTokenHandler } from './infrastructure/api/accessTokenHandler';

import { generateAuthParams } from './infrastructure/generateAuthParams';
import { usePrefetchSelfUser } from './infrastructure/controllers/hooks/api/useGetSelfUser';
import { usePrefetchSelfOrganizations } from './infrastructure/controllers/hooks/api/useGetSelfOrganizations';
import { usePrefetchSelfChannels } from './infrastructure/controllers/hooks/api/useGetSelfChannels';
import { usePrefetchSelfAccount } from './infrastructure/controllers/hooks/api/useGetSelfAccount';

import { configureApiClient } from './infrastructure/api/configure-api-client';
import { buildApiClientWithMutationHandlers } from './adapters/api/buildApiClientWithMutationHandlers';

import './infrastructure/styles/app.css';
import { usePrefetchSelfInboxEvents } from '@/infrastructure/controllers/hooks/api/useGetSelfInboxEvents';
import { allUserInboxCategories } from '@/adapters/view-models/ChannelViewModel';

export default function App() {
  const isLoadingComplete = useCachedResources();
  configureApiClient();
  buildApiClientWithMutationHandlers();

  return (
    <ReduxProvider store={store}>
      <ThemeProvider>
        <ConfiguredAuth0Provider>
          <GlobalContainer>
            {isLoadingComplete ? (<RootNavigationContainer />) : null}
          </GlobalContainer>
          <StatusBar />
        </ConfiguredAuth0Provider>
      </ThemeProvider>
    </ReduxProvider>
  );
}

function GlobalContainer({ children }: { children: React.ReactNode }) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const prefetchSelfUser = usePrefetchSelfUser();
  const prefetchSelfOrganizations = usePrefetchSelfOrganizations();
  const prefetchSelfChannels = usePrefetchSelfChannels();
  const prefetchSelfInboxEvents = usePrefetchSelfInboxEvents();
  const prefetchSelfAccount = usePrefetchSelfAccount();

  useEffect(() => {
    if (isAuthenticated) {
      prefetchSelfUser();
      prefetchSelfOrganizations();
      prefetchSelfChannels();
      allUserInboxCategories.forEach((category) => prefetchSelfInboxEvents({ category }));
      prefetchSelfAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // if (isAuthenticated) {
  useEffect(() => {
    accessTokenHandler.setAccessTokenGetter(getAccessTokenSilently);
  }, [getAccessTokenSilently]);
  // }

  return children;
}

function ConfiguredAuth0Provider({ children }: { children: React.ReactNode }) {
  const { env } = useAppDependencies().environmentVariablesStore;

  return (
    <Auth0Provider
      domain={env.AUTH0_DOMAIN}
      clientId={env.AUTH0_CLIENT_ID}
      cacheLocation="localstorage"
      useRefreshTokens
      useRefreshTokensFallback
      authorizationParams={generateAuthParams(env)}
    >
      {children}
    </Auth0Provider>
  );
}
