import { MaterialIcons } from '@expo/vector-icons';
import { IconButton } from 'native-base';
import React, { ComponentProps, ForwardedRef, forwardRef } from 'react';

type ToolbarButtonProps = ComponentProps<typeof IconButton>;

/**
 * This component manually handles the disabled state by passing
 * isDisabled={false} to the IconButton component and setting pointerEvents
 * to 'none' when the button is disabled. This is because the _disabled prop
 * in IconButton does not work as expected (bug in native-base) which leaves
 * the button with the default light grey color when disabled, which is not
 * customizable. Doing it this way and passing a specific color when disabled
 * allows us to customize the color of the icon when the button is disabled.
 */
export const ToolbarButton = forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  (props: ToolbarButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <IconButton
        {...props}
        isDisabled={false}
        ref={ref}
        mb={0.5}
        ml={-0.5}
        size="sm"
        colorScheme="blue"
        _icon={{
          as: MaterialIcons,
          color: props.isDisabled ? '#ccc' : '#444',
          ...props._icon,
        }}
        pointerEvents={props.isDisabled ? 'none' : 'auto'}
        _hover={{
          _icon: {
            color: 'rgba(33, 104, 200, 1)',
          },
        }}
      />
    );
  }
);
