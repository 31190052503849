import { useMemo } from 'react';
import { ORGANIZATIONS_QUERY_OPTIONS } from '../../../api/query-parameters';
import { OrganizationsViewModel } from '@/adapters/view-models/OrganizationsViewModel';
import { useGetSelfChannels } from './useGetSelfChannels';
import { ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { useGetOrganizationChannelsQuery } from '@/adapters/api';

export function useGetAllAccessibleChannels(selfOrganizations: OrganizationsViewModel | undefined) {
  const { currentData: selfChannels, isLoading: isLoadingSelfChannels } = useGetSelfChannels();
  const hasOrganization = !!selfOrganizations && (selfOrganizations.organizations.length > 0);
  const { currentData: organizationChannels, isLoading: isLoadingOrgChannels } = useGetOrganizationChannelsQuery({ organizationId: hasOrganization ? selfOrganizations.organizations[0].id : '' }, { ...ORGANIZATIONS_QUERY_OPTIONS, skip: !hasOrganization });

  const isLoading = isLoadingSelfChannels || selfOrganizations === undefined || isLoadingOrgChannels;

  return useMemo(() => {
    const allRawChannels = isLoading ? undefined : [...(selfChannels?.channels ?? []), ...(organizationChannels?.channels ?? [])].reduce((acc, channel) => {
      acc.set(channel.id, channel);
      return acc;
    }, new Map<string, ChannelViewModel>());

    const allChannels = isLoading ? undefined : [...(selfChannels?.channels ?? []), ...(organizationChannels?.channels ?? [])].reduce((acc, channel) => {
      acc.set(channel.id, channel);
      return acc;
    }, new Map<string, ChannelViewModel>());

    return ({
      allRawChannels: Array.from(allRawChannels?.values() ?? []),
      allChannels: Array.from(allChannels?.values() ?? []),
      allChannelsById: allChannels,
      isLoading,
    });
  }, [selfChannels, organizationChannels, isLoading]);
}
