import { updateQueryData } from '../../utils/updateQueryData';
import { allUserInboxCategories } from '@/adapters/view-models/ChannelViewModel';
import { isThreadInInboxCategory } from '../../utils/isThreadInInboxCategory';
import { InboxItemViewModel } from '@/adapters/view-models/InboxItemViewModel';
import { typedKeys } from '@/infrastructure/global/typedKeys';

export function* createAddThreadToInboxPatches(inboxItem: InboxItemViewModel) {
  for (const category of allUserInboxCategories) {
    if (isThreadInInboxCategory(inboxItem.thread.labels, category)) {
      yield updateQueryData('getSelfInboxEvents', { category }, (draftedEvents) => {
        if (draftedEvents.inboxItems) {
          draftedEvents.inboxItems = draftedEvents.inboxItems.filter((item) => item.thread.id !== inboxItem.thread.id);
          draftedEvents.inboxItems.unshift(inboxItem);
        }
      });
    }
  }

  if (inboxItem.thread.isUnread) {
    yield updateQueryData('getSelfAccount', undefined, (draftedAccount) => {
      draftedAccount.numberOfUnreadEvents += 1;
      for (const inboxCategory of typedKeys(draftedAccount.numberOfUnreadEventsByCategory)) {
        if (isThreadInInboxCategory(inboxItem.thread.labels, inboxCategory)) {
          draftedAccount.numberOfUnreadEventsByCategory[inboxCategory] += 1;
        }
      }
    });
  }
}
