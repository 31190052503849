import { useCallback, useState } from 'react';
import { Button, Icon } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';
import { useHotkeys } from 'react-hotkeys-hook';
import { DatePickerMenu } from '@/infrastructure/ui/date-picker-menu/menu/DatePickerMenu';
import { ShortcutTooltip } from '@/infrastructure/ui/components/ShortcutTooltip';

export type SendLaterButtonProps = {
  enabled: boolean;
  datePickerOpen: boolean;
  onSubmit: (scheduledDate: Date) => void;
  onOpenStateChange: (open: boolean) => void;
  onPressChange: (pressed: boolean) => void;
};

export function SendLaterButton({
  enabled, onOpenStateChange, onSubmit, datePickerOpen, onPressChange
}: SendLaterButtonProps) {
  const trigger = useCallback((triggerProps: any, { open }: { open: boolean; }) => {
    onOpenStateChange(open);
    return (
      <SendLaterButtonInner
        enabled={enabled}
        triggerProps={triggerProps}
        datePickerOpen={datePickerOpen}
        onPressChange={onPressChange}
      />
    );
  }, [enabled, onOpenStateChange, datePickerOpen, onPressChange]);

  return (
    <DatePickerMenu
      onSubmitDate={(date: Date | null) => onSubmit(date!)}
      title="Schedule message"
      trigger={trigger}
      menuPlacement="top right"
    />
  );
}

type SendLaterButtonInnerProps = {
  enabled: boolean;
  datePickerOpen: boolean;
  onPressChange: (pressed: boolean) => void;
  triggerProps?: any;
  onPress?: () => void;
};

function SendLaterButtonInner(props: SendLaterButtonInnerProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const onPress = () => {
    if (props.triggerProps) {
      props.triggerProps.ref.current?.blur();
      props.triggerProps.onPress();
    }
    props.onPress?.();
  };

  const isFocusedScreen = useIsFocused();
  useHotkeys('mod+shift+L', onPress, {
    enabled: isFocusedScreen,
    enableOnFormTags: true,
    enableOnContentEditable: true
  });

  function handlePressStatusChange(pressed: boolean) {
    setIsPressed(pressed);
    props.onPressChange(pressed);
  }

  const applyHoverStyle = (props.enabled && isHovered) || props.datePickerOpen;
  const useWhiteIcon = applyHoverStyle || isPressed;

  return (
    <ShortcutTooltip
      hint="Send later"
      shortcut="mod+shift+L"
      placement="top right"
      style={{ marginBottom: 2 }}
    >
      <Button
        px="10px"
        py="7px"
        borderWidth={1}
        borderLeftWidth={0}
        borderRadius={8}
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        backgroundColor="white"
        _hover={{
          backgroundColor: 'primary.700',
          borderColor: 'primary.700',
        }}
        _pressed={{
          backgroundColor: 'primary.800',
          py: '8px',
          borderWidth: 0,
          pr: '11px',
        }}
        borderColor={props.enabled ? 'dark.100' : 'transparent'}
        disabled={!props.enabled}
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => setIsHovered(false)}
        onPressIn={() => handlePressStatusChange(true)}
        onPressOut={() => handlePressStatusChange(false)}
        isHovered={applyHoverStyle}
        isPressed={isPressed}
        {...props.triggerProps}
        onPress={() => onPress()}
      >
        <Icon name="schedule-send" as={MaterialIcons} color={getIconColor(props.enabled, useWhiteIcon)} size="12px" />
      </Button>
    </ShortcutTooltip>
  );
}

function getIconColor(enabled: boolean, hovered: boolean): string {
  if (enabled && hovered) {
    return 'white';
  } if (enabled) {
    return 'dark.300';
  }
  return 'dark.200';
}
