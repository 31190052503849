import { HStack, Menu, useToken } from 'native-base';
import React, { useState, forwardRef, ComponentProps } from 'react';

export type CustomMenuItemProps = ComponentProps<typeof Menu.Item> & {
  focused?: boolean;
  onFocusedChange?: (focused: boolean, ref: any) => void;
};

const CustomMenuItem = forwardRef((props: CustomMenuItemProps, ref: any) => {
  const [isActuallyFocused, setIsActuallyFocused] = useState(false);
  const isFocused = props.focused ?? isActuallyFocused;
  const focusedBg = useToken('components.MenuItem.baseStyle', '_hover.backgroundColor');

  return (
    <Menu.Item
      {...props}
      ref={ref}
      bgColor={isFocused ? focusedBg : undefined}
      onFocus={() => {
        props.onFocusedChange?.(true, ref);
        setIsActuallyFocused(true);
      }}
      onBlur={() => {
        props.onFocusedChange?.(false, ref);
        setIsActuallyFocused(false);
      }}
    >
      <HStack space={3} mx={-2} alignItems="center" flexGrow={1}>
        {props.children}
      </HStack>
    </Menu.Item>
  );
});

export { CustomMenuItem };
