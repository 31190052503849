import React, {
  useCallback
} from 'react';
import { EmojiPickerPopover, EmojiPickerPopoverProps } from '../../emoji/popover/EmojiPickerPopover';
import { EmojiSelectedCallback } from '../../emoji/popover/EmojiSelectedCallback';
import { ToolbarButton } from './ToolbarButton';

export type AddReactionButtonControllerProps = {
  emojiSelectedCallback: EmojiSelectedCallback;
  emojiPickerOpenCallback?: (isOpen: boolean) => void;
  emojiPickerPlacement?: EmojiPickerPopoverProps['placement'];
  enabled: boolean;
};

export function AddReactionButton({
  emojiPickerPlacement, emojiPickerOpenCallback, emojiSelectedCallback, enabled
}: AddReactionButtonControllerProps) {
  const trigger = useCallback((triggerProps: any) => (
    <ToolbarButton
      {...triggerProps}
      isDisabled={!enabled}
      _icon={{
        name: 'insert-emoticon',
      }}
    />
  ), [enabled]);

  return (
    <EmojiPickerPopover
      emojiSelectedCallback={emojiSelectedCallback}
      trigger={trigger}
      emojiPickerOpenCallback={emojiPickerOpenCallback}
      placement={emojiPickerPlacement}
    />
  );
}
