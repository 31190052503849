import { Avatar } from 'native-base';
import { ThemeComponentSizeType } from 'native-base/lib/typescript/components/types';
import { InterfaceAvatarProps } from 'native-base/lib/typescript/components/composites/Avatar/types';
import { memo } from 'react';
import { PictureViewModel } from '@/adapters/view-models/PictureViewModel';

export const ParticipantAvatars = memo(function ParticipantAvatars(props: {
  participants: PictureViewModel[];
  maxNumberOfAvatars: number;
  size: ThemeComponentSizeType<'Avatar'>;
}) {
  return (
    <Avatar.Group
      space={-2}
      _avatar={{
        size: props.size,
      }}
      size={props.size}
      max={props.maxNumberOfAvatars}
      w="auto"
      h="auto"
    >
      {props.participants.map((pictureInfo) => (
        <Avatar
          key={pictureInfo.id}
          _image={{
            alt: pictureInfo.name
          }}
          bg={pictureInfo.backgroundColor}
          _text={{ color: 'white' }}
          source={{
            uri: pictureInfo.url ?? undefined,
          }}
          size={props.size}
        >
          {pictureInfo.initials}
        </Avatar>
      ))}
    </Avatar.Group>
  );
});

type ParticipantAvatarProps = InterfaceAvatarProps & {
  pictureInfo: PictureViewModel;
};

export const ParticipantAvatar = memo(function ParticipantAvatar({ pictureInfo, ...props }: ParticipantAvatarProps) {
  return (
    <Avatar
      _image={{
        alt: pictureInfo.name,
      }}
      bg={pictureInfo.backgroundColor}
      _text={{ color: 'white' }}
      source={{ uri: pictureInfo.url ?? undefined }}
      {...props}
    >
      {pictureInfo.initials}
    </Avatar>
  );
});
