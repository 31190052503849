import { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

export const ATTACHMENT_TRANSITION_MS = 200;

type AttachmentThumbnailContainerProps = {
  children: React.ReactNode;
  isVisible: boolean;
  onHide: () => void;
  isAnimated: boolean;
};

export function AttachmentThumbnailContainer({
  children, isVisible, onHide, isAnimated
}: AttachmentThumbnailContainerProps) {
  const scale = useRef(new Animated.Value(isAnimated ? 0 : 1)).current;

  useEffect(() => {
    if (isVisible && isAnimated) {
      Animated.timing(scale, {
        toValue: 1,
        duration: ATTACHMENT_TRANSITION_MS,
        useNativeDriver: true,
      }).start();
    } else if (!isVisible) {
      Animated.timing(scale, {
        toValue: 0,
        duration: ATTACHMENT_TRANSITION_MS,
        useNativeDriver: true,
      }).start(onHide);
    }
  }, [isAnimated, isVisible, onHide, scale]);

  return (
    <Animated.View
      style={(!isVisible || isAnimated) ? {
        transform: [{ scale }],
        opacity: scale,
        width: scale.interpolate({
          inputRange: [0, 1],
          outputRange: ['0px', '208px'],
        }),
        height: scale.interpolate({
          inputRange: [0, 1],
          outputRange: ['0px', '83px'],
        }),
      } : {
        width: 208,
        height: 83,
        opacity: 1,
      }}
    >
      {children}
    </Animated.View>
  );
}
