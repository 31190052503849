import React, { useEffect, useMemo } from 'react';
import { useNavigationState } from '@react-navigation/native';
import { MenuScreenSection } from '../../ui/screen-sections/menu/MenuScreenSection';

import { OrganizationsViewModel } from '@/adapters/view-models/OrganizationsViewModel';
import { usePrefetchChannelCallback } from '../hooks/usePrefetchChannelCallback';
import { useOpenChannelCallback } from '../hooks/useOpenChannelCallback';
import { createChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useGetSelfChannels } from '../hooks/api/useGetSelfChannels';
import { useGetSelfAccount } from '../hooks/api/useGetSelfAccount';
import { apiClient } from '@/adapters/api';
import { getCurrentScreenParams, RouteNotInStack } from '../../navigation/getCurrentScreenParams';
import { useHasEmailSync } from '../../hooks/useHasEmailSync';
import { useThreadDraftsCount } from '@/domain/state/drafts';
import { useTwoHotkeys } from '../../hooks/useTwoHotkeys';

export function MenuScreenSectionController() {
  const currentChannelId = useActiveMenuItemId();

  const { currentData: selfChannels, isLoading } = useGetSelfChannels();
  const { currentData: organizations } = useGetSelfOrganizations();
  const { shouldHideEmailFeatures } = useHasEmailSync(organizations);
  const { currentData: account } = useGetSelfAccount();
  const channelHoveredCallback = usePrefetchChannelCallback();
  const channelSelectedCallback = useOpenChannelCallback();
  const numberOfDrafts = useThreadDraftsCount();

  const extendedSelfChannels = useMemo(() => {
    if (!selfChannels) {
      return undefined;
    }

    const inbox = createChannelViewModel({
      id: 'inbox',
      name: 'Inbox',
      folderType: 'inbox',
      is_public: false,
      number_of_unread_threads: Object.values(account?.numberOfUnreadEventsByCategory || {}).reduce((acc, count) => acc + count, 0),
    });

    const snoozed = createChannelViewModel({
      id: 'snoozed',
      name: 'Snoozed',
      folderType: 'snoozed',
      is_public: false,
      number_of_unread_threads: 0,
    });

    const scheduled = createChannelViewModel({
      id: 'scheduled',
      name: 'Scheduled',
      folderType: 'scheduled',
      is_public: false,
      number_of_unread_threads: 0,
    });

    const trash = createChannelViewModel({
      id: 'trash',
      name: 'Trash',
      folderType: 'trash',
      is_public: false,
      number_of_unread_threads: 0,
    });

    const sent = createChannelViewModel({
      id: 'sent',
      name: 'Sent',
      folderType: 'sent',
      is_public: false,
      number_of_unread_threads: 0,
    });

    const drafts = createChannelViewModel({
      id: 'drafts',
      name: 'Drafts',
      folderType: 'drafts',
      is_public: false,
      number_of_unread_threads: numberOfDrafts,
    });

    const spam = shouldHideEmailFeatures ? [] : [createChannelViewModel({
      id: 'spam',
      name: 'Spam',
      folderType: 'spam',
      is_public: false,
      number_of_unread_threads: account?.numberOfUnreadSpamThreads ?? 0,
    })];

    const starred = createChannelViewModel({
      id: 'starred',
      name: 'Starred',
      folderType: 'starred',
      is_public: false,
      number_of_unread_threads: 0,
    });

    const allMessages = createChannelViewModel({
      id: 'all',
      name: 'All messages',
      folderType: 'all',
      is_public: false,
      number_of_unread_threads: 0,
    });

    return [
      inbox,
      starred,
      snoozed,
      sent,
      drafts,
      scheduled,
      allMessages,
      ...spam,
      trash,
      ...selfChannels.channels,
    ];
  }, [selfChannels, account?.numberOfUnreadEventsByCategory, account?.numberOfUnreadSpamThreads, shouldHideEmailFeatures, numberOfDrafts]);

  usePrefetchOrganizationMembers(organizations);

  useTwoHotkeys('g', 'i', () => {
    const channelOrFolder = extendedSelfChannels?.find((channel) => channel.folderType == 'inbox');
    if (!channelOrFolder) return;
    channelSelectedCallback(channelOrFolder);
  });

  useTwoHotkeys('g', 's', () => {
    const channelOrFolder = extendedSelfChannels?.find((channel) => channel.folderType == 'starred');
    if (!channelOrFolder) return;
    channelSelectedCallback(channelOrFolder);
  });

  useTwoHotkeys('g', 'b', () => {
    const channelOrFolder = extendedSelfChannels?.find((channel) => channel.folderType == 'snoozed');
    if (!channelOrFolder) return;
    channelSelectedCallback(channelOrFolder);
  });

  useTwoHotkeys('g', 't', () => {
    const channelOrFolder = extendedSelfChannels?.find((channel) => channel.folderType == 'sent');
    if (!channelOrFolder) return;
    channelSelectedCallback(channelOrFolder);
  });

  useTwoHotkeys('g', 'd', () => {
    const channelOrFolder = extendedSelfChannels?.find((channel) => channel.folderType == 'drafts');
    if (!channelOrFolder) return;
    channelSelectedCallback(channelOrFolder);
  });

  useTwoHotkeys('g', 'a', () => {
    const channelOrFolder = extendedSelfChannels?.find((channel) => channel.folderType == 'all');
    if (!channelOrFolder) return;
    channelSelectedCallback(channelOrFolder);
  });

  return (
    <MenuScreenSection
      showSpinner={isLoading && !extendedSelfChannels}
      channelSelectedCallback={channelSelectedCallback}
      channels={extendedSelfChannels}
      selectedChannelId={currentChannelId}
      channelHoveredCallback={channelHoveredCallback}
      isLoadingChannels={isLoading}
    />
  );
}

function useActiveMenuItemId() {
  return useNavigationState((navState) => {
    const channelParams = getCurrentScreenParams(navState, 'Channel');
    if (channelParams?.channelId) {
      return channelParams.channelId;
    }

    const sentParams = getCurrentScreenParams(navState, 'Sent');
    if (sentParams !== RouteNotInStack) {
      return 'sent';
    }

    const snoozedParams = getCurrentScreenParams(navState, 'Snoozed');
    if (snoozedParams !== RouteNotInStack) {
      return 'snoozed';
    }

    const scheduledParams = getCurrentScreenParams(navState, 'Scheduled');
    if (scheduledParams !== RouteNotInStack) {
      return 'scheduled';
    }

    const draftsParams = getCurrentScreenParams(navState, 'Drafts');
    if (draftsParams !== RouteNotInStack) {
      return 'drafts';
    }

    const spamParams = getCurrentScreenParams(navState, 'Spam');
    if (spamParams !== RouteNotInStack) {
      return 'spam';
    }

    const starredParams = getCurrentScreenParams(navState, 'Starred');
    if (starredParams !== RouteNotInStack) {
      return 'starred';
    }

    const trashParams = getCurrentScreenParams(navState, 'Trash');
    if (trashParams !== RouteNotInStack) {
      return 'trash';
    }

    const allMessagesParams = getCurrentScreenParams(navState, 'AllMessages');
    if (allMessagesParams !== RouteNotInStack) {
      return 'all';
    }

    const inboxParams = getCurrentScreenParams(navState, 'Inbox');
    if (inboxParams !== RouteNotInStack) {
      // if (inboxParams?.category) {
      //   return getCategoryFromSlug(inboxParams.category);
      // }
      return 'inbox';
    }
    return undefined;
  });
}

function usePrefetchOrganizationMembers(organizations: OrganizationsViewModel | undefined) {
  const prefetchOrganizationMembers = apiClient.usePrefetch('getOrganizationMembers');

  useEffect(
    () => {
      if (organizations?.organizations[0]) {
        void prefetchOrganizationMembers({ organizationId: organizations.organizations[0].id });
      }
    },
    [organizations, prefetchOrganizationMembers]
  );
}
