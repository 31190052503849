import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { HStack } from 'native-base';
import { InterfaceHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { ComprehensiveThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { ChangeUnreadStatusCallback } from './thread-list-items/SnippetThreadListItem';
import { ItemActionButton } from './thread-actions/ItemActionButton';
import { SnoozeItemActionButton } from './thread-actions/SnoozeItemActionButton';
import { useThreadListStoreSelectorMaybe } from './thread-list/context';
import { ThreadTextActionButton } from './thread-actions/ThreadTextActionButton';
import { TrashActionButton } from './thread-actions/TrashActionButton';
import { MoreActionsButton } from './thread-actions/other-actions/MoreActionsButton';
import { ToggleStarCommand } from './thread-actions/other-actions/ToggleStarMenuItem';

export type ThreadActionBarProps = InterfaceHStackProps & {
  archiveThreadCallback: (thread: ComprehensiveThreadViewModel) => void;
  moveThreadToInboxCallback: (thread: ComprehensiveThreadViewModel) => void;
  markThreadAsSpamCallback?: (thread: ComprehensiveThreadViewModel) => void;
  markThreadAsNotSpamCallback?: (thread: ComprehensiveThreadViewModel) => void;
  trashThreadCallback?: (thread: ComprehensiveThreadViewModel) => void;
  untrashThreadCallback?: (thread: ComprehensiveThreadViewModel) => void;
  deleteThreadCallback?: (thread: ComprehensiveThreadViewModel) => void;
  toggleSelfUserJoinThreadCallback?: (thread: ComprehensiveThreadViewModel) => void;
  setSnoozeEndDateCallback: (thread: ComprehensiveThreadViewModel, snoozeEndDate: Date | null) => void;
  starThreadCallback: (thread: ComprehensiveThreadViewModel) => void;
  unstarThreadCallback: (thread: ComprehensiveThreadViewModel) => void;
  changeUnreadStatusCallback: ChangeUnreadStatusCallback;
  showAdditionalActionsButton?: boolean;
  thread: ComprehensiveThreadViewModel;
  setSnoozeOpen?: (isOpen: boolean) => void;
};

function ThreadActionBar(props: ThreadActionBarProps) {
  const view = props.showAdditionalActionsButton ? 'thread-view' : 'thread-list';
  const noMultiSelected = useThreadListStoreSelectorMaybe((state) => (state?.selectedIds.length ?? 0) === 0);
  const focusedInList = useThreadListStoreSelectorMaybe((state) => props.thread.id === state?.focusedId);
  const focused = focusedInList || view === 'thread-view';
  return (
    <>
      <HStack flexGrow={0} flexShrink={0} space={0.5} alignItems="center" justifyContent="flex-end" {...props}>
        {props.deleteThreadCallback && props.thread.isTrashed ? (
          <ThreadTextActionButton
            onPress={() => props.deleteThreadCallback?.(props.thread)}
          >
            Delete forever
          </ThreadTextActionButton>
        ) : null}
        {props.markThreadAsNotSpamCallback && props.thread.showMarkAsNotSpamButton ? (
          <ThreadTextActionButton
            shortcut="shift+1"
            onPress={() => props.markThreadAsNotSpamCallback?.(props.thread)}
          >
            Not spam
          </ThreadTextActionButton>
        ) : null}
        {!props.thread.isTrashed && props.thread.showRemoveFromInboxButton ? (
          <ItemActionButton
            smaller={!props.showAdditionalActionsButton}
            shortcut="e"
            shortcutEnabled={focused && noMultiSelected}
            hint="Remove from inbox"
            provideCommandId="remove-from-inbox"
            onPress={() => props.archiveThreadCallback(props.thread)}
            iconCollection={MaterialCommunityIcons}
            iconName="archive-arrow-down-outline"
          />
        ) : null}
        {!props.thread.isTrashed && props.thread.showMoveToInboxButton ? (
          <ItemActionButton
            smaller={!props.showAdditionalActionsButton}
            hint="Move to inbox"
            provideCommandId="move-to-inbox"
            onPress={() => props.moveThreadToInboxCallback(props.thread)}
            iconName="move-to-inbox"
          />
        ) : null}
        {(props.thread.isUnread) ? (
          <ItemActionButton
            smaller={!props.showAdditionalActionsButton}
            shortcut="shift+i"
            shortcutEnabled={focused && noMultiSelected}
            hint="Mark as read"
            provideCommandId="mark-as-read"
            onPress={() => props.changeUnreadStatusCallback(props.thread, 'read')}
            iconCollection={MaterialCommunityIcons}
            iconName="email-open-outline"
          />
        ) : null}
        {(!props.thread.isUnread) ? (
          <ItemActionButton
            smaller={!props.showAdditionalActionsButton}
            shortcut="shift+u"
            shortcutEnabled={focused && noMultiSelected}
            hint="Mark as unread"
            provideCommandId="mark-as-unread"
            onPress={() => props.changeUnreadStatusCallback(props.thread, 'unread')}
            iconCollection={MaterialCommunityIcons}
            iconName="email-outline"
          />
        ) : null}
        {(props.thread.showLeaveButton && props.toggleSelfUserJoinThreadCallback) ? (
          <ItemActionButton
            smaller={!props.showAdditionalActionsButton}
            shortcut="m"
            shortcutEnabled={focused}
            hint="Unfollow thread"
            onPress={() => props.toggleSelfUserJoinThreadCallback!(props.thread)}
            iconCollection={MaterialCommunityIcons}
            iconName="bell-off-outline"
          />
        ) : null}
        {(props.thread.showJoinButton && props.toggleSelfUserJoinThreadCallback) ? (
          <ItemActionButton
            smaller={!props.showAdditionalActionsButton}
            shortcut="m"
            shortcutEnabled={focused}
            hint="Follow thread"
            onPress={() => props.toggleSelfUserJoinThreadCallback!(props.thread)}
            iconCollection={MaterialCommunityIcons}
            iconName="bell-ring-outline"
          />
        ) : null}
        {!props.thread.isTrashed && props.thread.showMarkAsSpamButton && props.markThreadAsSpamCallback ? (
          <ItemActionButton
            smaller={!props.showAdditionalActionsButton}
            shortcut="shift+1"
            shortcutEnabled={focused && noMultiSelected}
            hint="Mark as spam"
            provideCommandId="mark-as-spam"
            onPress={() => props.markThreadAsSpamCallback!(props.thread)}
            iconCollection={MaterialIcons}
            iconName="report-gmailerrorred"
          />
        ) : null}
        {!props.thread.isTrashed && props.thread.showSnoozeButton && props.setSnoozeEndDateCallback ? (
          <SnoozeItemActionButton
            shortcutEnabled={focused}
            menuPlacement={props.showAdditionalActionsButton ? 'bottom' : 'left'}
            smaller={!props.showAdditionalActionsButton}
            thread={props.thread}
            updateSnoozeEndDateCallback={props.setSnoozeEndDateCallback}
            setSnoozeOpen={props.setSnoozeOpen}
          />
        ) : null}
        {!props.thread.isTrashed && props.trashThreadCallback ? (
          <TrashActionButton
            smaller={!props.showAdditionalActionsButton}
            onPress={() => props.trashThreadCallback!(props.thread)}
            thread={props.thread}
          />
        ) : null}
        {props.thread.isTrashed && props.untrashThreadCallback ? (
          <ItemActionButton
            smaller={!props.showAdditionalActionsButton}
            hint="Move to inbox"
            onPress={() => props.untrashThreadCallback!(props.thread)}
            iconCollection={MaterialIcons}
            iconName="move-to-inbox"
          />
        ) : null}
        {props.showAdditionalActionsButton ? (
          <MoreActionsButton
            starThreadCallback={props.starThreadCallback}
            unstarThreadCallback={props.unstarThreadCallback}
            thread={props.thread}
          />
        ) : null}
      </HStack>
      {focused && noMultiSelected && (<ToggleStarCommand {...props} />)}
    </>
  );
}

const ThreadActionBarMemo = memo(ThreadActionBar, isEqual);
export { ThreadActionBarMemo as ThreadActionBar };
