import { setCustomFetchBaseQueryArguments, setMutationTrackingService } from '@/adapters/api/codegen';
import { environmentVariablesStore } from '../dependencies';
import { accessTokenHandler } from './accessTokenHandler';
import { mutationTrackingService } from '@/adapters/mutation-tracking/MutationTrackingService';

export function configureApiClient() {
  setCustomFetchBaseQueryArguments({
    baseUrl: environmentVariablesStore.env.API_URL,
    prepareHeaders: async (headers) => {
      const token = await accessTokenHandler.getAccessTokenGetter()();
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  });

  setMutationTrackingService(mutationTrackingService);
}
