import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { apiClient } from '@/adapters/api';

export function useLogoutCallback() {
  const { logout } = useAuth0();

  const logoutCallback = useCallback((returnUrlPath?: string) => {
    apiClient.util.resetApiState();
    void logout({
      logoutParams: {
        returnTo: window.location.origin + (typeof returnUrlPath === 'string' ? returnUrlPath : ''),
      }
    });
  }, [logout]);

  return logoutCallback;
}
