import {
  Text, HStack, Icon, useToken,
} from 'native-base';
import type { ComponentProps } from 'react';
import { ChannelViewModel, CHANNEL_ICON_SETS } from '@/adapters/view-models/ChannelViewModel';
import { useChannelColor } from '@/infrastructure/ui/channel-color-indicator/ChannelColorIndicator';
import { hexColorToRgba } from '@/infrastructure/styles/main-theme/colors';

type HStackProps = ComponentProps<typeof HStack>;

type ChannelBadgesListProps = HStackProps & {
  channels: ChannelViewModel[];
  badgeProps?: HStackProps;
  threadId?: string;
};

export function ChannelBadgesList({
  channels, badgeProps, threadId, ...rest
}: ChannelBadgesListProps) {
  // const isFocusedOrSelectedInList = useThreadListStoreSelectorMaybe((state) => {
  //   if (state && threadId) {
  //     return state.focusedId === threadId || state.selectedIds.includes(threadId);
  //   }
  // });
  return (
    <HStack space={1} {...rest} flexShrink={1}>
      {channels.map((channel: ChannelViewModel) => (
        <ChannelBadgesListItem key={channel.id} channel={channel} {...badgeProps} />
      ))}
    </HStack>
  );
}

function ChannelBadgesListItem({ channel, ...rest }: HStackProps & { channel: ChannelViewModel; }) {
  const bg = useChannelColor(channel.id);
  const bgHex = useToken('colors', bg || 'transparent');
  const bgTransparentHex = hexColorToRgba(bgHex, 0.35);
  const borderColor = hexColorToRgba(bgHex, 0.2);
  const color = 'opacityBlack.700';
  return (
    <HStack
      key={channel.id}
      flexShrink={1}
      borderRadius={4}
      bgColor={bgTransparentHex}
      pl={1}
      pr={1.5}
      space={0.5}
      borderWidth={1}
      borderColor={borderColor}
      {...rest}
    >
      <Icon
        alignSelf="center"
        name={CHANNEL_ICON_SETS[channel.iconSetName].default.name}
        as={CHANNEL_ICON_SETS[channel.iconSetName].default.collection}
        size="xs"
        color={color}
        flexShrink={1}
      />
      <Text fontSize="xs" color={color} flexShrink={1} numberOfLines={1} fontWeight={500}>
        {channel.name}
      </Text>
    </HStack>
  );
}
