/// <reference lib="dom" />
import DOMPurify from 'dompurify';
import React, {
  memo, useEffect, useLayoutEffect, useRef
} from 'react';

type RenderedHtmlProps = {
  isScheduled?: boolean;
  htmlContent: string;
  onRendered?: () => void;
  scheduledTextColor?: string,
};

function RenderedHtml({
  htmlContent, onRendered, isScheduled, scheduledTextColor
}: RenderedHtmlProps) {
  const ref = useRef<HTMLDivElement>(null);
  const shadowRootDivRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const { current } = ref;
    if (current) {
      const intermediate = document.createElement('div');
      intermediate.setAttribute('data-testid', 'RenderedHtmlIntermediate');
      const shadowRoot = intermediate.attachShadow({ mode: 'open' });
      current.appendChild(intermediate);

      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = '/styles/message.css';
      shadowRoot.appendChild(link);

      const div = document.createElement('div');
      shadowRootDivRef.current = div;
      div.innerHTML = DOMPurify.sanitize(htmlContent);
      prepareRoot(div);
      div.style.whiteSpace = 'normal';
      if (isScheduled && scheduledTextColor) {
        // eslint-disable-next-line prefer-destructuring
        div.style.color = scheduledTextColor;
      }
      div.className = 'message-display';
      shadowRoot.appendChild(div);

      return () => {
        current.innerHTML = '';
        shadowRootDivRef.current = null;
      };
    }
  }, [htmlContent, isScheduled, scheduledTextColor]);

  useEffect(() => {
    const { current } = shadowRootDivRef;
    if (!window.ResizeObserver || !current) {
      return undefined;
    }
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        onRendered?.();
      }, 0);
      onRendered?.();
    });
    resizeObserver.observe(current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [onRendered]);

  return <div data-testid="RenderedHtml" ref={ref} />;
}

function prepareRoot(root: HTMLDivElement) {
  makeLinksClickable(root);
  for (const link of root.querySelectorAll('a')) {
    if (link.href) {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
      ensureNotRelative(link);
    }
  }
}

const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig;

function makeLinksClickable(node: Node): void {
  if (node.nodeType === Node.TEXT_NODE) {
    const textContent = node.textContent ?? '';
    const matches = textContent.match(urlRegex);
    if (matches) {
      const frag = document.createDocumentFragment();
      let lastIndex = 0;

      textContent.replace(urlRegex, (match: string, ...args: any[]): string => {
        const url = args[0];
        const offset = args[args.length - 2];
        const textNodeBefore = document.createTextNode(textContent.slice(lastIndex, offset));
        frag.appendChild(textNodeBefore);

        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.textContent = url;
        frag.appendChild(anchor);

        lastIndex = offset + match.length;
        return '';
      });

      const textNodeAfter = document.createTextNode(textContent.slice(lastIndex));
      frag.appendChild(textNodeAfter);

      node.parentNode?.replaceChild(frag, node);
    }
  } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== 'A') {
    Array.from(node.childNodes).forEach(makeLinksClickable);
  }
}

function ensureNotRelative(link: HTMLAnchorElement) {
  const hrefPropertyUrl = parseURL(link.href);
  const sameOrigin = hrefPropertyUrl?.origin === window.location.origin;
  if (sameOrigin) {
    let hrefAttribute = link.getAttribute('href');
    if (hrefAttribute?.startsWith('//')) {
      // The Protocol-relative URL
      hrefAttribute = `${window.location.protocol}${hrefAttribute}`;
    }
    const isAbsolute = parseURL(hrefAttribute)?.origin === window.location.origin;
    if (!isAbsolute) {
      link.setAttribute('href', `https://${hrefAttribute}`);
    }
  }
}

function parseURL(url?: string | null) {
  if (url) {
    try {
      return new URL(url);
    } catch {
      //
    }
  }
  return null;
}

const RenderedHtmlMemo = memo(RenderedHtml);

export default RenderedHtmlMemo;
