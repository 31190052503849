import {
  RemoveEventFromInboxApiArg,
  RemoveEventFromInboxApiResponse
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel, setThreadViewModelInboxItemId } from '../view-models/ThreadViewModel';
import { typedKeys } from '@/infrastructure/global/typedKeys';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';
import { createInboxPatches } from './thread-patch-factory/factories/createInboxPatches';
import { isThreadInInboxCategory } from './utils/isThreadInInboxCategory';

export class RemoveEventFromInboxMutationHandler extends MutationHandler<RemoveEventFromInboxApiArg, RemoveEventFromInboxApiResponse> {
  constructor(props: MutationHandlerProps<RemoveEventFromInboxApiArg, RemoveEventFromInboxApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: RemoveEventFromInboxApiArg) {
    return [
      ...this.createRemoveThreadInInboxPatches(patch),
      ...this.createOtherPatches(patch),
      ...createThreadUpdatePatchesForThreadId(this.getThreadInCurrentContextFromEventId(patch.inboxItemId)?.id!, this.updateThread),
    ];
  }

  private* createOtherPatches(patch: RemoveEventFromInboxApiArg) {
    const thread = this.getThreadInCurrentContextFromEventId(patch.inboxItemId);
    if (thread && thread.isUnread) {
      yield this.updateQueryData('getSelfAccount', undefined, (draftedAccount) => {
        draftedAccount.numberOfUnreadEvents -= 1;
        if (draftedAccount.numberOfUnreadEvents < 0) {
          draftedAccount.numberOfUnreadEvents = 0;
        }
        for (const inboxCategory of typedKeys(draftedAccount.numberOfUnreadEventsByCategory)) {
          if (isThreadInInboxCategory(thread.labels, inboxCategory)) {
            draftedAccount.numberOfUnreadEventsByCategory[inboxCategory] -= 1;
          }
          if ((draftedAccount.numberOfUnreadEventsByCategory[inboxCategory] ?? 0) < 0) {
            draftedAccount.numberOfUnreadEventsByCategory[inboxCategory] = 0;
          }
        }
      });
    }
    if (thread) {
      yield this.updateQueryData('getThread', { threadId: thread.id }, (draftedThread) => {
        setThreadViewModelInboxItemId(draftedThread, undefined);
      });
    }
  }

  private updateThread(thread: ThreadViewModel) {
    setThreadViewModelInboxItemId(thread, undefined);
  }

  private createRemoveThreadInInboxPatches(patch: RemoveEventFromInboxApiArg) {
    return createInboxPatches((inboxItems) => {
      const itemIndex = inboxItems.findIndex((item) => item.id === patch.inboxItemId);

      if (itemIndex !== -1) {
        inboxItems.splice(itemIndex, 1);
      }
    });
  }

  protected generateInvalidationTags(arg: RemoveEventFromInboxApiArg) {
    return [
      {
        type: 'Event' as const,
        id: arg.inboxItemId,
        schedule: {
          delayMs: 10000,
          uniqueKey: `ChannelThreadListManagement-${getCurrentChannelParams()}`,
        }
      },
      {
        type: 'SelfAccount' as const,
        schedule: {
          delayMs: 5000,
          uniqueKey: 'InboxUnreadThreadCount',
        }
      },
      {
        schedule: {
          delayMs: 5000,
          uniqueKey: 'InboxRemoveEvents',
        },
        type: 'InboxEventsList' as const,
      }
    ];
  }
}
