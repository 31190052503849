import { useCallback } from 'react';
import { Toast } from 'native-base';
import { buildMarkAsReadMutationDescription } from '../../UndoMutationDescriptions';
import {
  MutationInitiator,
  useWithMutationMetadata
} from '@/adapters/mutation-cancellation/mutationCancellation';
import { useMarkAllThreadMessagesAsReadMutation, useMarkMessagesAsUnreadMutation } from '@/adapters/api/codegen';
import { ThreadViewModel } from '@/adapters/view-models/ThreadViewModel';

export function useChangeUnreadStatusCallback() {
  const [markAllThreadMessagesAsRead] = useWithMutationMetadata(useMarkAllThreadMessagesAsReadMutation());
  const [markMessageAsUnread] = useWithMutationMetadata(useMarkMessagesAsUnreadMutation());

  return useCallback(async (thread: ThreadViewModel, status: 'read' | 'unread', initiator?: MutationInitiator) => {
    if (status == 'read') {
      await markAllThreadMessagesAsRead({ threadId: thread.id }, {
        initiator: initiator || 'user',
        description: buildMarkAsReadMutationDescription(true),
      });
    } else if (thread.lastMessageId) {
      await markMessageAsUnread({ messageIdsRequestBody: { message_ids: [thread.lastMessageId] } }, {
        initiator: initiator || 'user',
        description: buildMarkAsReadMutationDescription(false),
      });
    } else {
      Toast.show({
        description: 'Notifications cannot yet be marked as unread.', duration: 3000,
      });
    }
  }, [markAllThreadMessagesAsRead, markMessageAsUnread]);
}
