import { useEffect } from 'react';
import { AttachmentViewModel, isUploadedAttachment } from '@/adapters/view-models/AttachmentViewModel';
import { isAttachmentDateExpired } from '../AttachmentThumbnail';
import { UploadedAttachment } from '@/adapters/view-models/AttachmentViewModel';
import { AddedAttachment } from '../AttachmentGallery';
import { calculateDelayBeforeCheckingExpiryDate } from '../AttachmentThumbnail';

export function useUpdateLoadingState(onLoadingStateChanged: ((isLoading: boolean) => void) | undefined, addedAttachments: AddedAttachment[], defaultAttachments: (UploadedAttachment | AttachmentViewModel)[]) {
  useEffect(() => {
    if (!onLoadingStateChanged) {
      return;
    }

    const earliestExpiryDate = getEarliestExpiryDate();

    updateLoadingState();

    if (earliestExpiryDate) {
      const delayBeforeCheckingLoadingState = calculateDelayBeforeCheckingExpiryDate(earliestExpiryDate);

      const timeoutId = setTimeout(() => {
        updateLoadingState();
      }, delayBeforeCheckingLoadingState);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    function updateLoadingState() {
      const numberOfLoadingAttachments = addedAttachments.filter((attachment) => attachment[2] === undefined).length;
      const hasExpiredAttachments = earliestExpiryDate && isAttachmentDateExpired(earliestExpiryDate);
      const isLoading = numberOfLoadingAttachments > 0 || hasExpiredAttachments;
      onLoadingStateChanged?.(isLoading);
    }

    function getEarliestExpiryDate() {
      const defaultAttachmentExpiryDates = defaultAttachments.map((attachment) => (isUploadedAttachment(attachment) ? attachment.response.expiry_date : attachment.expiryDate))
        .filter((expiryDate) => expiryDate) as string[];
      const addedAttachmentExpiryDates = addedAttachments.map((attachment) => attachment[2]?.response.expiry_date) as string[];
      const allExpiryDates = addedAttachmentExpiryDates
        .concat(defaultAttachmentExpiryDates)
        .map((expiryDate) => new Date(expiryDate))
        .sort((a, b) => a.getTime() - b.getTime());

      const earliestExpiryDate = allExpiryDates[0];
      return earliestExpiryDate;
    }
  }, [addedAttachments, defaultAttachments, onLoadingStateChanged]);
}
