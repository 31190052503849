import { useMemo } from 'react';
import { AttachmentViewModel, createAttachmentViewModelFromUploadedAttachment, isUploadedAttachment } from '@/adapters/view-models/AttachmentViewModel';
import { UploadedAttachment } from '@/adapters/view-models/AttachmentViewModel';

export function useDefaultAttachmentViewModels(defaultAttachments: (UploadedAttachment | AttachmentViewModel)[]) {
  return useMemo(() => {
    return defaultAttachments.map(
      (attachment) => {
        if (isUploadedAttachment(attachment)) {
          return createAttachmentViewModelFromUploadedAttachment(attachment);
        }

        return attachment;
      }
    );
  }, [defaultAttachments]);
}
