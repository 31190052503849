import {
  AddThreadChannelsApiArg, AddThreadChannelsApiResponse, RemoveThreadChannelsApiArg, RemoveThreadChannelsApiResponse
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';

type Arg = RemoveThreadChannelsApiArg | AddThreadChannelsApiArg;
type Response = RemoveThreadChannelsApiResponse | AddThreadChannelsApiResponse;

export class ChangeThreadChannelsMutationHandler extends MutationHandler<Arg, Response> {
  constructor(props: MutationHandlerProps<Arg, Response>, private readonly type: 'add' | 'remove') {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: Arg) {
    const updateThread = (draftedThread: ThreadViewModel) => this.updateThread(draftedThread, patch);
    return [
      ...createThreadUpdatePatchesForThreadId(patch.threadId, updateThread),
    ];
  }

  private updateThread(draftedThread: ThreadViewModel, patch: Arg) {
    draftedThread.channelIds = this.getUpdatedParticipants(draftedThread.channelIds, patch);
  }

  private getUpdatedParticipants(channels: ThreadViewModel['channelIds'], patch: Arg) {
    if (this.type === 'remove') {
      return this.buildListWithRemovedParticipants(channels, patch);
    }

    return this.buildListWithNewParticipants(patch, channels);
  }

  private buildListWithRemovedParticipants(channelIds: string[], patch: Arg) {
    return channelIds.filter((theChannelId) => !patch.body.some((channelId) => channelId === theChannelId));
  }

  private buildListWithNewParticipants(patch: Arg, channelIds: string[]) {
    const newChannelIds = patch.body.filter((channelId) => !channelIds.some((theChannelId) => theChannelId === channelId));

    return [
      ...channelIds,
      ...newChannelIds,
    ];
  }

  protected generateInvalidationTags(arg: Arg) {
    const threadTag = {
      type: 'Thread' as const,
      id: arg.threadId,
      schedule: {
        delayMs: 20000,
        uniqueKey: `ChangeThreadChannels${arg.threadId}}`,
      }
    };

    return [
      threadTag,
    ];
  }
}
