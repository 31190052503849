import { CategoryLabel } from '@monorepo/utilities';
import { UnreadEventsByCategory } from '../../api/codegen';
import { enabledInboxCategories } from '../../view-models/ChannelViewModel';
import { ThreadViewModel } from '../../view-models/ThreadViewModel';

export function isThreadInInboxCategory(threadLabels: ThreadViewModel['labels'], category: keyof UnreadEventsByCategory): boolean {
  if (category === 'CATEGORY_PRIMARY') {
    if (threadLabels?.includes(CategoryLabel.PERSONAL)) {
      return true;
    }
    const isInOtherCategory = enabledInboxCategories.some((enabledCategory) => threadLabels?.includes(enabledCategory));
    return !isInOtherCategory;
  }
  return Boolean(threadLabels?.includes(category));
}
