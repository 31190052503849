import React from 'react';
import { Button, Icon, Modal } from 'native-base';
import { AntDesign } from '@expo/vector-icons';
import { FlatListProps } from 'react-native';
import { ParticipantsList } from './ParticipantsList';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';

export type ParticipantsListModalProps = {
  modalDisplayed: boolean;
  participants: ParticipantViewModel[];
  closeCallback: () => void;
  addParticipantsButtonClickedCallback?: () => void;
  renderItem?: FlatListProps<ParticipantViewModel>['renderItem'];
  listEmptyComponent?: FlatListProps<ParticipantViewModel>['ListEmptyComponent'];
  modalTitle: string;
  buttonTitle?: string;
};

export function ParticipantsListModal(props: ParticipantsListModalProps) {
  return (
    <Modal isOpen={props.modalDisplayed} onClose={props.closeCallback} _backdropFade={{ exitDuration: 1 }} _fade={{ exitDuration: 1 }}>
      <Modal.Content maxWidth="500px" maxHeight="500px">
        <Modal.CloseButton />
        <Modal.Header>{props.modalTitle}</Modal.Header>
        <Modal.Body>
          <ParticipantsList users={props.participants} renderItem={props.renderItem} listEmptyComponent={props.listEmptyComponent} />
        </Modal.Body>
        {props.addParticipantsButtonClickedCallback ? (
          <Modal.Footer justifyContent="center">
            <Button.Group space={2}>
              <Button
                colorScheme="blueGray"
                size="sm"
                _text={{
                  bold: true
                }}
                onPress={props.addParticipantsButtonClickedCallback}
                leftIcon={<Icon as={AntDesign} name="adduser" size="sm" color="white" />}
              >
                {props.buttonTitle}
              </Button>
            </Button.Group>
          </Modal.Footer>
        ) : null}
      </Modal.Content>
    </Modal>
  );
}
