import { Text } from 'native-base';
import { ComponentProps } from 'react';

export function getExtensionColor(extension: string | undefined): ComponentProps<typeof Text>['color'] {
  if (extension === 'pdf') {
    return '#D5414A';
  } if (extension && ['doc', 'docx'].includes(extension)) {
    return '#5AA9F3';
  }
  return 'dark.600';
}
