import type { ReactNode } from 'react';
import type { MentionBlotData } from './types';

const pillStyle = {
  padding: '0 6px 0 2px',
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: '6px',
  display: 'inline-block',
  lineHeight: '20px',
  fontWeight: '500',
  color: 'inherit',
  textDecoration: 'none',
};

const pillStyleRecipient = {
  ...pillStyle,
  fontSize: '14px',
  margin: '4px 8px 4px 0',
};

const imgStyle = {
  // display: 'inline-block',
  verticalAlign: 'top',
  width: '16px',
  height: '16px',
  borderRadius: '4px',
  // transform: 'translateY(2px)',
  marginTop: '2px',
};

interface Props {
  children?: ReactNode;
  data: MentionBlotData;
  isRecipient?: boolean;
  leftIcon?: ReactNode;
}

export const MentionPill = ({
  children, data, leftIcon, isRecipient
}: Props) => (
  <Pill url={data.url} isRecipient={isRecipient}>
    {leftIcon}
    {data.pictureInfo?.url && (
      <img src={data.pictureInfo.url || ''} alt="" style={imgStyle} />
    )}
    <span style={{ opacity: 0.8, marginLeft: '4px' }}>
      {data.denotationChar && (
        <span style={{ margin: '0 4px 0 1px' }}>{data.denotationChar}</span>
      )}
      {data.value}
    </span>
    {children}
  </Pill>
);

const Pill = ({ children, url, isRecipient }: {
  children: ReactNode;
  url?: string;
  isRecipient?: boolean;
}) => {
  const style = isRecipient ? pillStyleRecipient : pillStyle;
  if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer" style={style}>
        {children}
      </a>
    );
  }
  return <span style={style}>{children}</span>;
};
