import {
  autoPlacement, autoUpdate, type ClientRectObject, FloatingPortal, offset, type ReferenceType, shift, size, useFloating, useInteractions, useListNavigation, type VirtualElement,
} from '@floating-ui/react';
import {
  type RefObject, useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { debounce } from 'lodash';
import Quill from 'quill';
import Mark from 'mark.js';
import type { KeyboardBindingHandler, SelectionChangeHandler, TextChangeHandler } from '../types';
import { KEYS, maxMentionLength } from '../constants';
import { useGetSelfOrganizations } from '@/infrastructure/controllers/hooks/api/useGetSelfOrganizations';
import { useGetSelfOrganizationMembers } from '@/infrastructure/controllers/hooks/api/useGetSelfOrganizationMembers';
import { ScrollArea } from '@/infrastructure/ui/scroll-area';
import './QuillMention.css';
import type { ReactQuillHandle } from '../ReactQuill';
import { useEffectEvent } from '@/infrastructure/hooks/useEffectEvent';
import { useMentionEmitEvent } from './MentionEventsProvider';
import { useGetAllAccessibleChannels } from '@/infrastructure/controllers/hooks/api/useGetAllAccessibleChannels';
import type { MentionBlotData } from './types';
import { Item, ItemWrapper } from '@/infrastructure/ui/floating-list-picker/FloatingListItem';

interface PopoverState {
  query: string;
  reference: ReferenceType;
}

export const QuillMention = (props: {
  quillRef: RefObject<ReactQuillHandle | null>;
}) => {
  const { quillRef } = props;

  const [popover, setPopover] = useState<PopoverState | null>(null);
  const query = popover?.query;

  const isOpenRef = useRef(false);

  const { currentData: selfOrganizations, organizationId } = useGetSelfOrganizations();
  const { currentData: members } = useGetSelfOrganizationMembers(organizationId);
  const { allChannels } = useGetAllAccessibleChannels(selfOrganizations);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const listRef = useRef<Array<HTMLElement | null>>([]);

  const { refs, floatingStyles, context } = useFloating({
    placement: 'top-start',
    open: Boolean(popover),
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: 4,
        crossAxis: -27,
      }),
      autoPlacement({
        allowedPlacements: ['top-start', 'bottom-start'],
      }),
      size({
        padding: 8,
        apply({ availableHeight, elements }) {
          elements.floating.style.maxHeight = `${availableHeight}px`;
        },
      }),
      shift({ padding: 8 }),
    ],
  });

  useLayoutEffect(() => {
    refs.setPositionReference(popover?.reference || null);
  }, [popover?.reference, refs]);

  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
    focusItemOnOpen: true,
  });

  const { getFloatingProps, getItemProps } = useInteractions([listNav]);

  useLayoutEffect(() => {
    const { current } = refs.floating;
    if (current && query) {
      const mark = new Mark(current);
      mark.mark(query, {
        exclude: ['[data-no-mark]'],
      });
      return () => mark.unmark();
    }
  }, [query, refs.floating]);

  const optionsList = useMemo(() => {
    if (popover === null) return [];
    const query = popover.query.toLowerCase();
    function filterUsers() {
      if (!members?.users) return [];
      if (query === '') return members.users;
      return members.users.filter(({ displayName, email }) => [displayName, email].some((str) => str.toLowerCase().includes(query)));
    }
    function filterChannels() {
      if (!allChannels) return [];
      if (query === '') return allChannels;
      return allChannels.filter(({ name }) => name.toLowerCase().includes(query));
    }
    return [...filterUsers(), ...filterChannels()];
  }, [allChannels, members?.users, popover]);

  const emitMentioned = useMentionEmitEvent();

  const onSelect = useEffectEvent((item?: (typeof optionsList)[number]) => {
    item = item ?? (activeIndex !== null ? optionsList[activeIndex] : undefined);
    if (!item) return;
    console.log('mention selected:', item);
    const quill = quillRef.current;
    if (!quill) return;
    const query = popover?.query ?? null;
    if (query === null) return;

    const range = quill.getSelection(true);
    const i = range.index - query.length - 1;
    quill.deleteText(i, query.length + 1, Quill.sources.USER);
    const mentionData: MentionBlotData = item._type === 'user' ? {
      value: item.displayName,
      pictureInfo: item.pictureInfo,
      userId: item.id,
    } : {
      denotationChar: '#',
      value: item.name,
      channelId: item.id,
      url: `${window.location.origin}/channels/${item.id}`,
    };
    const addSpace: 0 | 1 = 1;
    quill.insertEmbed(i, 'mention', mentionData, Quill.sources.USER);
    if (addSpace) quill.insertText(i + 1, ' ', Quill.sources.USER);
    quill.setSelection(i + 1 + addSpace, 0, Quill.sources.USER);
    emitMentioned(item);
  });

  const onNavigationKey = useEffectEvent(((_, { event }) => {
    // @ts-ignore
    getFloatingProps().onKeyDown?.(event);
    return undefined;
  }) satisfies KeyboardBindingHandler);

  useEffect(() => {
    if (!quillRef.current) return;
    const quill = quillRef.current;
    const quillContainer = quill.container;

    const onChange = () => {
      const range = quill.getSelection();
      if (range === null) return;
      const length = Math.min(range.index, maxMentionLength);
      const textBeforeCursor = quill.getText(range.index - length, length);
      const match = textBeforeCursor.match(/(?:^|\s)@(\w*)$/);
      if (match) {
        const query = match[1];
        const colonPos = range.index - query.length - 1;
        const virtualElement: VirtualElement = {
          getBoundingClientRect: (): ClientRectObject => {
            const rect = quillContainer.getBoundingClientRect();
            const bounds = quill.getBounds(colonPos || 0, query.length + 1);
            const x = rect.left + (bounds?.left || 0);
            const y = rect.top + (bounds?.top || 0);
            return {
              x,
              y,
              top: y,
              left: x,
              bottom: rect.top + (bounds?.bottom || 0),
              right: rect.left + (bounds?.right || 0),
              width: (bounds?.width || 0),
              height: (bounds?.height || 0),
            };
          },
        };
        open({
          query,
          reference: virtualElement,
        });
      } else {
        close();
      }
    };
    const onChangeDebounced = debounce(onChange, 100);
    let onChangeCurrent: () => void = onChangeDebounced;

    const open = (popoverState: PopoverState) => {
      onChangeCurrent = onChange;
      setPopover(popoverState);
      isOpenRef.current = true;
    };
    const close = () => {
      onChangeCurrent = onChangeDebounced;
      setPopover(null);
      isOpenRef.current = false;
    };

    const textChangeHandler: TextChangeHandler = (_delta, _oldDelta, source) => {
      if (source === 'user') {
        onChangeCurrent();
      }
    };
    const selectionChangeHandler: SelectionChangeHandler = (range) => {
      if (range?.length === 0) {
        onChangeCurrent();
      } else {
        close();
      }
    };

    quill.on('text-change', textChangeHandler);
    quill.on('selection-change', selectionChangeHandler);

    const whenOpen = (handler: KeyboardBindingHandler): KeyboardBindingHandler => (...args) => {
      if (!isOpenRef.current) return true;
      return handler.call({ quill }, ...args);
    };

    const moveKeyboardBindingToStart = (key: string) => {
      const addedBinding = quill.keyboard.bindings[key].pop();
      quill.keyboard.bindings[key].unshift(addedBinding!);
    };

    quill.keyboard.addBinding({ key: KEYS.ENTER }, whenOpen(() => onSelect()));
    moveKeyboardBindingToStart(KEYS.ENTER);

    quill.keyboard.addBinding({ key: KEYS.TAB }, whenOpen(() => onSelect()));
    moveKeyboardBindingToStart(KEYS.TAB);

    quill.keyboard.addBinding({ key: KEYS.ESCAPE }, whenOpen(() => close()));
    moveKeyboardBindingToStart(KEYS.ESCAPE);

    const navigationKeys = [KEYS.UP, KEYS.DOWN, 'Home', 'End'];
    quill.keyboard.addBinding({ key: navigationKeys }, whenOpen(onNavigationKey));

    return () => {
      quill.off('text-change', textChangeHandler);
      quill.off('selection-change', selectionChangeHandler);
    };
  }, [quillRef]);

  return (
    <FloatingPortal>
      {Boolean(popover) && (
        <div
          {...getFloatingProps({
            className: 'ql-mention-popover',
            ref: refs.setFloating,
            style: floatingStyles,
          })}
        >
          <ScrollArea>
            {optionsList?.map((item, index) => (
              <ItemWrapper
                className="ql-mention-popover-item"
                {...getItemProps({
                  key: item.id,
                  ref(node) {
                    listRef.current[index] = node;
                  },
                  onPointerDown(e) {
                    // not to lose focus from the editor
                    e.preventDefault();
                  },
                  onClick(e) {
                    e.preventDefault();
                    onSelect(item);
                  },
                })}
                active={activeIndex === index}
              >
                <Item item={item} />
              </ItemWrapper>
            ))}
          </ScrollArea>
        </div>
      )}
    </FloatingPortal>
  );
};
