import React, { useRef } from 'react';
import {
  Text, AlertDialog, Button, VStack
} from 'native-base';

export type ErrorType = 'max_size_exceeded' | 'security_violation' | null;

type AttachmentGalleryErrorAlertDialogProps = {
  errorType: ErrorType;
  fileName: string | null;
  isOpen: boolean;
  onClose: () => void;
};

// This could be shared with the backend
export const MAX_TOTAL_FILE_SIZE_MB = 25;
export const MAX_TOTAL_FILE_SIZE_BYTES = MAX_TOTAL_FILE_SIZE_MB * 1024 * 1024;

export function AttachmentGalleryErrorAlertDialog({
  errorType, onClose, fileName, isOpen
}: AttachmentGalleryErrorAlertDialogProps) {
  const closeErrorAlertButtonRef = useRef(null);

  return (
    <AlertDialog leastDestructiveRef={closeErrorAlertButtonRef} isOpen={isOpen} onClose={onClose} closeOnOverlayClick>
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>{getErrorTitle(errorType)}</AlertDialog.Header>
        <AlertDialog.Body>
          <VStack space={2}>
            <Text>{getErrorBody(errorType, fileName)}</Text>
          </VStack>
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button ref={closeErrorAlertButtonRef} onPress={onClose}>
              OK
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
}

function getErrorBody(errorType: ErrorType, fileName: string | null): string | undefined {
  switch (errorType) {
    case 'security_violation':
      return `"${fileName}" has been blocked for security reasons.`;

    case 'max_size_exceeded':
      return `Attachments cannot be larger than ${MAX_TOTAL_FILE_SIZE_MB} MB total.`;
  }
}

function getErrorTitle(errorType: ErrorType): string | undefined {
  switch (errorType) {
    case 'security_violation':
      return 'File type not allowed';

    case 'max_size_exceeded':
      return 'File is too large';
  }
}
