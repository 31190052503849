import { Attachment, StagedAttachmentDataRequestParameter, AttachmentUploadPresignedUrl } from '../api/codegen';

export type AttachmentViewModel = {
  name: string;
  downloadUrl?: string;
  size: string;
  iconName: string;
  uniqueKey: string;
  serverFilename?: string;
  mimeType: string;
  rawSize: number;
  expiryDate?: string;
};

export type UploadedAttachment = {
  request: StagedAttachmentDataRequestParameter;
  response: AttachmentUploadPresignedUrl;
  size: number;
  serverFilename: string;
};

export function isUploadedAttachment(attachment: AttachmentViewModel | UploadedAttachment): attachment is UploadedAttachment {
  return 'request' in attachment;
}

export function isAttachmentViewModel(attachment: AttachmentViewModel | UploadedAttachment): attachment is AttachmentViewModel {
  return 'name' in attachment;
}

export type StagedAttachment = Omit<Attachment, 'download_url' | 'server_filename'> & {
  id: string;
};

export function createAttachmentViewModel(attachment: Attachment | StagedAttachment, index: number): AttachmentViewModel {
  return {
    name: attachment.name,
    downloadUrl: 'download_url' in attachment ? attachment.download_url : undefined,
    size: getDisplayableSizeFromNumberOfBytes(attachment.size),
    iconName: determineIconNameForMimeType(attachment.mime_type),
    uniqueKey: 'download_url' in attachment ? `${attachment.download_url}-${index}` : attachment.id,
    serverFilename: 'server_filename' in attachment ? attachment.server_filename : undefined,
    mimeType: attachment.mime_type,
    rawSize: attachment.size,
  };
}

export function createAttachmentViewModelFromUploadedAttachment(attachment: UploadedAttachment): AttachmentViewModel {
  return {
    name: attachment.request.download_file_name,
    size: getDisplayableSizeFromNumberOfBytes(attachment.size),
    iconName: determineIconNameForMimeType(attachment.request.mime_type),
    uniqueKey: attachment.response.staged_attachment_id,
    mimeType: attachment.request.mime_type,
    serverFilename: attachment.serverFilename,
    downloadUrl: attachment.response.download_url,
    rawSize: attachment.size,
    expiryDate: attachment.response.expiry_date,
  };
}

function getDisplayableSizeFromNumberOfBytes(numberOfBytes: number): string {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;

  if (numberOfBytes < kilobyte) {
    return `${numberOfBytes} B`;
  }

  if (numberOfBytes < megabyte) {
    return `${(numberOfBytes / kilobyte).toFixed(0)} KB`;
  }

  if (numberOfBytes < gigabyte) {
    return `${(numberOfBytes / megabyte).toFixed(2)} MB`;
  }

  return `${(numberOfBytes / gigabyte).toFixed(2)} GB`;
}

function determineIconNameForMimeType(mimeType: string): string {
  if (mimeType.startsWith('image/')) {
    return 'image';
  }

  switch (mimeType) {
    case 'application/pdf':
      return 'file-pdf-box';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'file-word-box';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'file-excel-box';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'file-powerpoint-box';
    default:
      return 'file';
  }
}
