import { Box, KeyboardAvoidingView } from 'native-base';
import {
  forwardRef, useCallback, useEffect, useId, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState
} from 'react';
import { Platform } from 'react-native';
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box';
import { throttle } from 'lodash';
import isEqual from 'react-fast-compare';
import { useHotkeys } from 'react-hotkeys-hook';
import { Range } from 'quill';
import { ComposeToolbar } from './ComposeToolbar';
import { MessageViewModel } from '@/adapters/view-models/MessageViewModel';
import { ParticipantScreenSectionController, ParticipantScreenSectionControllerHandles } from '../../../controllers/screen-sections/ParticipantScreenSectionController';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { useHasEmailSync } from '../../../hooks/useHasEmailSync';
import { useGetSelfOrganizations } from '../../../controllers/hooks/api/useGetSelfOrganizations';
import { isEmptyDeltaOps, RichTextEditor, type RichTextEditorHandle } from '../../rich-text-editor';
import { useAppDispatch } from '../../../state/hooks';
import {
  DraftViewModel, addCommitedAttachmentsInProgress, addStagedAttachmentsInProgress, draftActions, selectThreadDraft
} from '@/domain/state/drafts';
import { store } from '@/domain/state/store';
import { InputUncontrolled, InputUncontrolledHandle } from '../../InputUncontrolled';
import { preventUnload } from '../../../global/preventUnload';
import { addLineBreaks } from '../utils/addLineBreaks';
import { useInsertEmoji } from '../../rich-text-editor/useInsertEmoji';
import MentionEventsProvider from '@/infrastructure/ui/rich-text-editor/mention/MentionEventsProvider';
import { AttachmentGallery, AttachmentGalleryHandles } from './attachments/AttachmentGallery';
import {
  AttachmentViewModel, UploadedAttachment, isAttachmentViewModel, isUploadedAttachment
} from '@/adapters/view-models/AttachmentViewModel';

export type QuoteMessageCallback = (message: MessageViewModel) => void;
export type ResetToDraftCallback = () => void;

export interface ComposeAreaHandles {
  quoteMessage: QuoteMessageCallback;
  resetToDraft: ResetToDraftCallback;
  attachFilesCallback: (files: File[]) => void;
}

export type ComposeAreaSubmitCallback = (props: {
  body: string,
  title?: string,
  recipients?: ParticipantViewModel[],
  scheduledDate?: Date,
  stagedAttachmentIds: string[],
  committedAttachments: AttachmentViewModel[],
}) => Promise<{
  data?: any;
  error?: any;
}>;

type ComposeAreaProps = Omit<InterfaceBoxProps, 'flexGrow'> & {
  bodyPlaceholder?: string;
  titlePlaceholder?: string;
  submitCallback?: ComposeAreaSubmitCallback;
  showTitle: boolean;
  defaultBody?: string;
  defaultTitle?: string;
  titleHasHeaderStyle?: boolean;
  onTitleBlur?: (title: string) => void;
  readOnly?: boolean;
  fontFamily?: string;
  flexGrow?: number;
  freezeParticipants?: boolean;
  participants: ParticipantViewModel[] | null | undefined;
  participantsChangedCallback?: (participants: ParticipantViewModel[]) => void;
  draftKey?: string;
};

export const ComposeArea = forwardRef<ComposeAreaHandles, ComposeAreaProps>(function ComposeArea({ onTitleBlur, ...props }, ref) {
  const dispatch = useAppDispatch();
  const titleRef = useRef<InputUncontrolledHandle>(null);
  const bodyRef = useRef<RichTextEditorHandle>(null);
  const participantSelectorRef = useRef<ParticipantScreenSectionControllerHandles>(null);
  const toolbarUniqueId = useId();
  const [displayParticipantsList, setDisplayParticipantsList] = useState(false);
  const { currentData: selfOrganizations } = useGetSelfOrganizations();
  const { shouldHideEmailFeatures } = useHasEmailSync(selfOrganizations);
  const allowModifyParticipantList = !shouldHideEmailFeatures && !props.freezeParticipants;
  const { onTextChange, onSelectionChange, emojiSelectedCallback } = useInsertEmoji(bodyRef);
  const attachmentGalleryRef = useRef<AttachmentGalleryHandles>(null);
  const uploadedAttachments = useRef<(UploadedAttachment | AttachmentViewModel)[]>([]);
  const [defaultAttachments, setDefaultAttachments] = useState<(UploadedAttachment | AttachmentViewModel)[]>([]);
  const [isAttachmentsGalleryLoading, setIsAttachmentsGalleryLoading] = useState(false);
  const { draftKey } = props;
  const lastBodySelection = useRef<Range | undefined>(undefined);

  const [draftParticipants, setDraftParticipants] = useState<ParticipantViewModel[] | null>(null);
  const [participants, setParticipants] = useState<ParticipantViewModel[] | null>(null);

  const setParticipantsFromDraftOrProps = useCallback((draftParticipantsOverride?: ParticipantViewModel[]) => {
    const finalDraftParticipants = draftParticipantsOverride ?? draftParticipants;
    if (!finalDraftParticipants) {
      return;
    }
    // Once starting a draft, it saves the whole list, including the initial props.participants (= next email message participants).
    setParticipants(finalDraftParticipants.length > 0
      ? finalDraftParticipants
      : props.participants ?? null);

    if (finalDraftParticipants.length > 0 && allowModifyParticipantList) {
      participantSelectorRef.current?.setParticipants(finalDraftParticipants);
      setDisplayParticipantsList(true);
    }
  }, [setParticipants, draftParticipants, props.participants, setDisplayParticipantsList, allowModifyParticipantList]);

  // Wait for both draftParticipants & props participants to initialize overall participants *once*.
  useEffect(() => {
    if (!props.participantsChangedCallback || !draftParticipants || Boolean(participants)) {
      return;
    }
    setParticipantsFromDraftOrProps();
  }, [participants, props.participantsChangedCallback, setParticipantsFromDraftOrProps, draftParticipants]);

  const resetToDraft = useCallback(() => {
    if (!draftKey) return;
    const foundDraft = selectThreadDraft(store.getState(), draftKey);
    if (foundDraft) {
      uploadedAttachments.current = (foundDraft.committedAttachments ?? [] as (UploadedAttachment | AttachmentViewModel)[]).concat(foundDraft.stagedAttachments ?? []); // This line should be placed before setting the title and body, because these actions will trigger handleSaveDraft (which will save the draft without attachments)
      setDefaultAttachments(uploadedAttachments.current);
      titleRef.current?.setValue(foundDraft.title);
      bodyRef.current?.setContents(foundDraft.content);
      bodyRef.current?.history.clear();

      dispatch(addStagedAttachmentsInProgress(uploadedAttachments.current.filter(isUploadedAttachment)));
      dispatch(addCommitedAttachmentsInProgress(uploadedAttachments.current.filter(isAttachmentViewModel)));
    }
    const participants = foundDraft?.participants ?? [];
    setDraftParticipants(participants);
    if (participants.length > 0) {
      setParticipantsFromDraftOrProps(participants);
    }
  }, [draftKey, setDraftParticipants, setParticipantsFromDraftOrProps, dispatch]);

  useImperativeHandle(ref, () => ({
    quoteMessage: (message: MessageViewModel) => {
      const quill = bodyRef.current;
      if (!quill) return;
      const { Delta } = quill;
      const messageDelta = quill.clipboard.convert({ html: `<blockquote>${message.body}</blockquote>` });
      const length = quill.getLength();
      const empty = length === 1 && quill.getText().trim() === '';
      const startDelta = empty ? new Delta() : new Delta().retain(length);
      const addBlockquote = startDelta.concat(messageDelta).insert(empty ? '' : '\n');
      quill.updateContents(addBlockquote);
      quill.setSelection(quill.getLength() - 1, 0);
    },
    resetToDraft,

    attachFilesCallback: (files: File[]) => {
      attachmentGalleryRef.current?.uploadFiles(files);
    }
  }));

  const [titleIsFocused, setTitleIsFocused] = useState(false);
  const [bodyIsFocused, setBodyIsFocused] = useState(false);

  const COMPOSE_AREA_BG_COLOR = 'white';
  const composeAreaIsFocused = (bodyIsFocused || titleIsFocused) && !props.readOnly;

  // Pass updatedParticipants when called during the same cycle as setParticipants, meaning the callback is not yet up to date.
  const handleSaveDraft = useCallback((updatedParticipants?: ParticipantViewModel[]) => {
    if (!draftKey || !titleRef.current || !bodyRef.current || !participants || !uploadedAttachments.current) return;
    const draftPayload: DraftViewModel = {
      id: draftKey,
      title: titleRef.current.value ?? '',
      content: bodyRef.current.getContents().ops,
      updatedAt: Date.now(),
      stagedAttachments: uploadedAttachments.current.filter(isUploadedAttachment),
      committedAttachments: uploadedAttachments.current.filter(isAttachmentViewModel),
      participants: [...(updatedParticipants ?? participants ?? [])],
    };

    const isDraftContentEmpty = isEmptyDeltaOps(draftPayload.content);
    const isEqualToSavedDraft = () => {
      const savedDraft = selectThreadDraft(store.getState(), draftKey);
      const savedTitle = savedDraft?.title ?? '';
      const isSameTitle = draftPayload.title === savedTitle;
      const areSameParticipants = isEqual(savedDraft?.participants, draftPayload.participants);
      const isSameContent = () => {
        const savedContent = savedDraft?.content ?? [];
        const isSavedContentEmpty = isEmptyDeltaOps(savedContent);
        if (isSavedContentEmpty && isDraftContentEmpty) return true;
        if (isSavedContentEmpty || isDraftContentEmpty) return false;
        return isEqual(savedContent, draftPayload.content);
      };

      const isSameAttachments = () => {
        const stagedAttachments = savedDraft?.stagedAttachments ?? [];
        const committedAttachments = savedDraft?.committedAttachments ?? [];
        return isEqual(stagedAttachments, uploadedAttachments.current.filter(isUploadedAttachment)) && isEqual(committedAttachments, uploadedAttachments.current.filter(isAttachmentViewModel));
      };

      return isSameTitle && isSameContent() && areSameParticipants && isSameAttachments();
    };

    if (isEqualToSavedDraft()) {
      return;
    }

    const isEmptyDraft = !draftPayload.title && isDraftContentEmpty && uploadedAttachments.current.length === 0;
    if (isEmptyDraft) {
      dispatch(draftActions.deleteDraft(draftKey));
    } else {
      dispatch(draftActions.saveDraft(draftPayload));
    }
  }, [dispatch, draftKey, titleRef, bodyRef, participants]);

  const onDidUpdateAttachments = useCallback((attachments: (UploadedAttachment | AttachmentViewModel)[]) => {
    uploadedAttachments.current = attachments;
    dispatch(addStagedAttachmentsInProgress(uploadedAttachments.current.filter(isUploadedAttachment)));
    dispatch(addCommitedAttachmentsInProgress(uploadedAttachments.current.filter(isAttachmentViewModel)));
    handleSaveDraft();
  }, [dispatch, handleSaveDraft]);

  const onPerformedAttachmentAction = useCallback(() => {
    const cursorIndex = lastBodySelection.current?.index;
    if (cursorIndex) {
      bodyRef.current?.setSelection(cursorIndex, 'user');
    }
  }, [bodyRef, lastBodySelection]);

  // Once the draft has been loaded, save the draft on every participant change when participants are controlled externally.
  useEffect(() => {
    if (!props.participantsChangedCallback && Boolean(draftParticipants) && Boolean(participants)) {
      handleSaveDraft();
    }
  }, [handleSaveDraft, participants, draftParticipants, props.participantsChangedCallback]);

  useLayoutEffect(() => {
    if (!draftParticipants) {
      resetToDraft();
    }
  }, [resetToDraft, draftParticipants]);

  // When the compose area doesn't make use of its own participant list (like for the compose screen where the participant
  // list is displayed somewhere else), we need to propagate the new participants to save the draft correctly.
  useEffect(() => {
    if (props.participantsChangedCallback || !props.participants) {
      return;
    }
    setParticipants(props.participants);
  }, [props.participantsChangedCallback, props.participants, setParticipants]);

  const [isEmptyBody, setIsEmptyBody] = useState(true);
  useLayoutEffect(() => {
    const quill = bodyRef.current;
    if (!quill) return;
    const onTextChange = throttle(() => {
      setIsEmptyBody(isEmptyDeltaOps(quill.getContents().ops));
    }, 100);
    setIsEmptyBody(isEmptyDeltaOps(quill.getContents().ops));
    quill.on('text-change', onTextChange);
    return () => {
      quill.off('text-change', onTextChange);
    };
  }, []);

  const sendButtonDisabled = isEmptyBody || isAttachmentsGalleryLoading;

  const handleSubmit = async (scheduledDate?: Date) => {
    await preventUnload(async () => {
      setDisplayParticipantsList(false);
      const submitPromise = props.submitCallback?.({
        title: titleRef.current?.value ?? '',
        body: addLineBreaks(bodyRef.current?.getSemanticHTML() ?? ''),
        stagedAttachmentIds: uploadedAttachments.current.filter(isUploadedAttachment).map((attachment) => attachment.response.staged_attachment_id),
        committedAttachments: uploadedAttachments.current.filter(isAttachmentViewModel),
        recipients: participants || [],
        scheduledDate,
      });

      attachmentGalleryRef.current?.clearAttachments(); // should be positioned before otherwise the draft may not be deleted
      uploadedAttachments.current = [];
      setDefaultAttachments([]);
      titleRef.current?.setValue('');
      bodyRef.current?.setText('');
      bodyRef.current?.blur();
      participantSelectorRef.current?.clear();
      setParticipants([]);
      return submitPromise;
    });
  };

  useHotkeys(['r', 'a', 'Enter'], () => bodyRef.current?.focus(), { preventDefault: true });

  const participantsChangedCallback = useMemo(() => {
    const original = props.participantsChangedCallback;
    if (!original) return undefined;
    return ((participants: ParticipantViewModel[]) => {
      original(participants);
      setDisplayParticipantsList(true); // Display the participant list after a mention.
      setParticipants(participants);
      handleSaveDraft(participants);
    }) satisfies typeof original;
  }, [props.participantsChangedCallback, handleSaveDraft, setDisplayParticipantsList, setParticipants]);

  return (
    <>
      <Box
        pb={2}
        pt={0}
        flexDirection="row"
        alignItems="center"
        {...props}
      >
        <Box
          flexGrow={1}
          borderRadius={8}
          borderColor={composeAreaIsFocused ? 'opacityPrimaryDarker.100' : 'dark.5'}
          borderWidth={1}
          bgColor={COMPOSE_AREA_BG_COLOR}
          width="100%"
          overflow="hidden"
        >
          <MentionEventsProvider>
            {participantsChangedCallback && participants && (
              <Box display={displayParticipantsList ? undefined : 'none'}>
                <ParticipantScreenSectionController
                  selectedParticipantsListChangedCallback={participantsChangedCallback}
                  includeChannels={false}
                  includeUsers
                  type="recipients"
                  initialParticipants={participants}
                  suggestionsOnTop
                  ref={participantSelectorRef}
                />
              </Box>
            )}
            <InputUncontrolled
              ref={titleRef}
              fontFamily={props.fontFamily}
              defaultValue={props.defaultTitle}
              bgColor="transparent"
              outlineColor="transparent"
              focusOutlineColor="transparent"
              borderStyle="none"
              _focus={{ borderWidth: 0, borderColor: 'transparent' }}
              _hover={{ borderWidth: 0, borderColor: 'transparent' }}
              _input={{ borderWidth: 0, borderColor: 'transparent', _focus: { borderWidth: 0, borderColor: 'transparent' } }}
              borderWidth={0}
              isReadOnly={props.readOnly}
              borderColor="transparent"
              onFocus={() => setTitleIsFocused(true)}
              onBlur={(e) => {
                setTitleIsFocused(false);
                onTitleBlur?.(e.nativeEvent.text);
              }}
              display={props.showTitle ? null : 'none'}
              onChangeText={() => handleSaveDraft()}
              fontSize={!props.titleHasHeaderStyle ? 'sm' : 'xl'}
              fontWeight={!props.titleHasHeaderStyle ? 'normal' : 'bold'}
              placeholder={props.titlePlaceholder ?? 'Optional thread title'}
              placeholderTextColor="gray.400"
              mt={1}
              mb={0}
              backgroundColor="light.50"
              onKeyPress={(event) => {
                if (event.nativeEvent.key === 'Enter') {
                  event.preventDefault();
                  bodyRef.current?.focus();
                }
                if (event.nativeEvent.key === 'Escape') {
                  titleRef.current?.blur();
                }
              }}
            />
            <>
              <Box ml="2px">
                <RichTextEditor
                  style={{ borderWidth: 0, borderColor: 'transparent' }}
                  ref={bodyRef}
                  onTextChange={() => {
                    lastBodySelection.current = bodyRef.current?.getSelection() ?? undefined;
                    onTextChange();
                    handleSaveDraft();
                  }}
                  onFocus={() => {
                    lastBodySelection.current = bodyRef.current?.getSelection() ?? undefined;
                    setBodyIsFocused(true);
                  }}
                  onBlur={() => {
                    lastBodySelection.current = undefined;
                    setBodyIsFocused(false);
                  }}
                  onSelectionChange={onSelectionChange}
                  readOnly={props.readOnly}
                  defaultValueHTML={props.defaultBody}
                  placeholder={props.bodyPlaceholder}
                  modules={{
                    toolbar: {
                      container: `#toolbar-${CSS.escape(toolbarUniqueId)}`,
                    },
                    clipboard: {
                      matchVisual: false,
                    },
                    emoji: true,
                  }}
                  formats={[
                    // 'header',
                    'font',
                    // 'size',
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'blockquote',
                    'code-block',
                    'list',
                    'indent',
                    'link',
                    'image',
                    'mention',
                    // 'color',
                  ]}
                  theme="snow"
                />
              </Box>
              <AttachmentGallery
                ref={attachmentGalleryRef}
                onDidUpdateAttachments={onDidUpdateAttachments}
                defaultAttachments={defaultAttachments}
                onLoadingStateChanged={setIsAttachmentsGalleryLoading}
                onPerformedAction={onPerformedAttachmentAction}
                pl="15px"
              />
              <ComposeToolbar
                displayed
                enabled={bodyIsFocused && !props.readOnly}
                submit={handleSubmit}
                sendButtonDisabled={sendButtonDisabled}
                uniqueId={toolbarUniqueId}
                hotkeysEnabled={bodyIsFocused}
                allowModifyParticipants={allowModifyParticipantList}
                onModifyParticipants={() => setDisplayParticipantsList((previous) => !previous)}
                emojiSelectedCallback={emojiSelectedCallback}
                emojiPickerPlacement="top"
                attachFileCallback={attachmentGalleryRef.current?.triggerFileUpload}
              />
            </>
          </MentionEventsProvider>
        </Box>
      </Box>
      <KeyboardAvoidingView keyboardVerticalOffset={90} behavior={Platform.OS === 'ios' ? 'padding' : 'height'} />
    </>
  );
});
