import React from 'react';

import { RouteProp, useRoute } from '@react-navigation/native';
import { ThreadList } from '../../ui/thread-list/ThreadList';

import {
  useStarThreadCallback,
  useUnstarThreadCallback,
  useSnoozeThreadCallback,
  useArchiveThreadCallback,
  useChangeUnreadStatusCallback,
  useMoveToInboxCallback,
  usePrefetchThreadCallback,
  useSelectThreadCallback
} from '../hooks/channelScreenHooks';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';
import { useGetSelfUser } from '../hooks/api/useGetSelfUser';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useGetSelfOrganizationMembers } from '../hooks/api/useGetSelfOrganizationMembers';
import { useGetAllAccessibleChannels } from '../hooks/api/useGetAllAccessibleChannels';
import { createStandardErrorIfAny } from '@/adapters/other/createStandardError';
import { useGetFilteredThreads } from '../hooks/api/useGetFilteredThreads';
import { MarkJsProvider } from '../../ui/MarkJs';
import { EmptyFolderScreenSection } from '../../ui/EmptyFolderScreenSection';

export function SearchScreenSectionController() {
  const route = useRoute<RouteProp<RootStackParamList, 'Search'>>();
  const { page, query } = route.params ?? {};
  const { currentData: selfUser } = useGetSelfUser();
  const { currentData: selfOrganizations, organizationId } = useGetSelfOrganizations();
  const { currentData: organizationMembers } = useGetSelfOrganizationMembers(organizationId);
  const { allRawChannels } = useGetAllAccessibleChannels(selfOrganizations);

  const {
    currentData, isLoading, error
  } = useGetFilteredThreads(query, selfUser?.id, organizationMembers?.users, allRawChannels, page);

  const threadHoveredCallback = usePrefetchThreadCallback();
  const selectThreadCallback = useSelectThreadCallback(currentData?.threads);
  const archiveThreadCallback = useArchiveThreadCallback();
  const moveToInboxCallback = useMoveToInboxCallback();
  const changeUnreadStatusCallback = useChangeUnreadStatusCallback();
  const setSnoozeEndDateCallback = useSnoozeThreadCallback();
  const starThreadCallback = useStarThreadCallback();
  const unstarThreadCallback = useUnstarThreadCallback();

  const errorToDisplay = createStandardErrorIfAny(error);
  const isLoadingForFirstTime = isLoading && !currentData?.threads;

  return (
    <MarkJsProvider value={{ query }}>
      <ThreadList
        showSpinner={isLoadingForFirstTime && !errorToDisplay}
        showComposeArea={false}
        items={currentData?.threads}
        errorToDisplay={errorToDisplay?.displayMessage}
        selectThreadCallback={selectThreadCallback}
        archiveThreadCallback={archiveThreadCallback}
        changeUnreadStatusCallback={changeUnreadStatusCallback}
        threadHoveredCallback={threadHoveredCallback}
        starThreadCallback={starThreadCallback}
        unstarThreadCallback={unstarThreadCallback}
        moveThreadToInboxCallback={moveToInboxCallback}
        setSnoozeEndDateCallback={setSnoozeEndDateCallback}
        ListEmptyComponent={<EmptyFolderScreenSection iconSetName="search" text="We couldn't find any threads matching your query." />}
      />
    </MarkJsProvider>
  );
}
