import {
  IconButton, IIconButtonProps, IIconProps
} from 'native-base';
import { useHotkeys } from 'react-hotkeys-hook';
import { useIsFocused } from '@react-navigation/native';
import {
  closeCommandMenu, CommandItem, ProvideCommands, ProvidedCommandIds
} from '../CommandMenu';
import { ShortcutTooltip } from '@/infrastructure/ui/components/ShortcutTooltip';

export type ItemActionButtonProps = IIconButtonProps & {
  iconCollection?: any;
  iconName: string;
  iconColor?: IIconProps['color'];
  onPress?: () => void;
  triggerProps?: any;
  hint: string;
  smaller: boolean;
  hasDelay?: boolean;
  shortcut?: string;
  shortcutEnabled?: boolean;
  provideCommandId?: ProvidedCommandIds;
};

export function ItemActionButton(props: ItemActionButtonProps) {
  const { hasDelay = true, shortcut, shortcutEnabled = true } = props;

  const onPress = () => {
    if (props.triggerProps) {
      props.triggerProps.ref.current?.blur();
      props.triggerProps.onPress();
    }
    props.onPress?.();
  };

  const isFocusedScreen = useIsFocused();
  useHotkeys(shortcut || '', onPress, {
    enabled: Boolean(shortcut) && isFocusedScreen && shortcutEnabled,
  });

  return (
    <>
      {isFocusedScreen && shortcutEnabled && props.provideCommandId && (
        <ProvideCommands id={props.provideCommandId}>
          <CommandItem
            icon={{ name: props.iconName, collection: props.iconCollection }}
            shortcut={shortcut}
            onSelect={() => {
              closeCommandMenu();
              onPress();
            }}
          >
            {props.hint}
          </CommandItem>
        </ProvideCommands>
      )}
      <ShortcutTooltip
        hint={props.hint}
        shortcut={shortcut}
        openDelay={!hasDelay ? 0 : undefined}
      >
        <IconButton
          variant="primary"
          p={props.smaller ? 0.5 : undefined}
          my={-1}
          size="md"
          _icon={{
            m: props.smaller ? 1 : undefined,
            as: props.iconCollection,
            name: props.iconName,
            color: props.iconColor,
          }}
          {...props}
          {...props.triggerProps}
          onPress={onPress}
        />
      </ShortcutTooltip>
    </>
  );
}
