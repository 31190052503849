import React, {
  forwardRef, useImperativeHandle, useMemo, useRef, useState
} from 'react';
import {
  AlertDialog, Button, VStack, Text, Icon, IIconProps
} from 'native-base';
import { useGetAllAccessibleChannels } from '../hooks/api/useGetAllAccessibleChannels';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useUpdateChannelMutation } from '@/adapters/api/codegen';
import { ChannelData } from '../../ui/modals/ConfigureChannelModal';
import { ConfigureChannelModalControllerHandles, ConfigureChannelModalController } from './ConfigureChannelModalController';
import { ChannelViewModel, CHANNEL_ICON_SETS } from '@/adapters/view-models/ChannelViewModel';
import { List, ListItem } from '../../ui/form/List';

export interface UpdateChannelModalControllerHandles {
  show: () => void;
}

export type UpdateChannelModalControllerProps = {
  channel: ChannelViewModel | undefined;
};

export const UpdateChannelModalController = forwardRef<UpdateChannelModalControllerHandles, UpdateChannelModalControllerProps>((props, ref) => {
  const configureModalRef = React.useRef<ConfigureChannelModalControllerHandles>(null);
  const [updateChannel] = useUpdateChannelMutation();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [submittedChannelData, setSubmittedChannelData] = useState<ChannelData | null>(null);
  const cancelRef = useRef(null);
  const { currentData: selfOrganizations } = useGetSelfOrganizations();

  const channels = useGetAllAccessibleChannels(selfOrganizations);
  const channelNames = useMemo(() => {
    return channels.allChannels?.map((channel) => channel.name) || [];
  }, [channels]);

  useImperativeHandle(ref, () => ({
    show: () => {
      configureModalRef.current?.show();
    },
  }));

  const handleClose = () => {
    setIsAlertVisible(false);
  };

  const showVisibilityWarningOrHandleSubmit = (channelData: ChannelData) => {
    if (props.channel?.isPublic !== channelData.isPublic) {
      setSubmittedChannelData(channelData);
      setIsAlertVisible(true);
    } else {
      handleSubmit(channelData);
    }
  };

  const handleSubmitAfterVisibilityWarning = () => {
    handleSubmit(submittedChannelData!);
    setIsAlertVisible(false);
  };

  const handleSubmit = (channelData: ChannelData) => {
    void updateChannel({ channelId: props.channel!.id, channelUpdateRequestBody: { name: channelData.channelName, is_public: channelData.isPublic } });
    configureModalRef.current?.close();
  };

  const defaultChannelData: ChannelData = { channelName: props.channel?.name ?? '', isPublic: props.channel?.isPublic ?? true };
  const channelIconSet = CHANNEL_ICON_SETS[props.channel?.iconSetName ?? 'publicChannel'];

  const channelDisplayName = (
    <>
      {' '}
      <Icon
        size="xs"
        style={{ verticalAlign: 'middle' } as IIconProps['style']}
        name={channelIconSet.default.name}
        as={channelIconSet.default.collection}
        mr={0.5}
      />
      {props.channel?.name}
      {' '}
    </>
  );

  const bodyFunction = submittedChannelData?.isPublic ? privateToPublic : publicToPrivate;

  return (
    <>
      <ConfigureChannelModalController
        ref={configureModalRef}
        onSubmit={showVisibilityWarningOrHandleSubmit}
        defaultChannelData={defaultChannelData}
        existingChannelNames={channelNames}
        type="update"
      />

      <AlertDialog leastDestructiveRef={cancelRef} isOpen={isAlertVisible}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton onPress={handleClose} />
          <AlertDialog.Header>
            {!submittedChannelData?.isPublic ? 'Change to a private channel?' : 'Change to a public channel?'}
          </AlertDialog.Header>
          <AlertDialog.Body>
            {bodyFunction()}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button colorScheme="blueGray" variant="ghost" onPress={handleClose} ref={cancelRef}>
                Cancel
              </Button>
              <Button colorScheme="danger" onPress={handleSubmitAfterVisibilityWarning}>
                {!submittedChannelData?.isPublic ? 'Change to private' : 'Change to public'}
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  );

  function privateToPublic() {
    return (
      <VStack space={2}>
        <Text>
          Keep in mind that when you make
          {channelDisplayName}
          a public channel:
        </Text>
        <List>
          <ListItem>Anyone from your workspace can see its message history and join.</ListItem>
          <ListItem>If you make the channel private again, it’ll be visible to anyone who’s joined the channel up until that point.</ListItem>
        </List>
      </VStack>
    );
  }

  function publicToPrivate() {
    return (
      <VStack space={2}>
        <Text>
          Keep in mind that when you make
          {channelDisplayName}
          a private channel:
        </Text>
        <List>
          <ListItem>No changes will be made to the channel’s history or members</ListItem>
          <ListItem>
            People who follow specific threads in this channel will still be able to see these threads, even if they are not a member of
            {channelDisplayName}
          </ListItem>
          <ListItem>
            Threads added to multiple channels will still be visible to people in other channels, even if they are not a member of
            {channelDisplayName}
          </ListItem>
        </List>
      </VStack>
    );
  }
});
