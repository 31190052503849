import { GetSelfSentThreadsApiArg } from '../../api/codegen';
import { getCurrentScreenParams, RouteInStackWithNoParametersDefined } from '@/infrastructure/navigation/getCurrentScreenParams';
import { rootNavigationContainerRef } from '@/infrastructure/navigation/navigators/root/RootNavigationContainerRef';

export function getSentParams(): GetSelfSentThreadsApiArg | undefined {
  const sentParams = getCurrentScreenParams(rootNavigationContainerRef.current?.getState(), 'Sent');

  if (sentParams) {
    return { pageToken: sentParams.page };
  }

  if (sentParams === RouteInStackWithNoParametersDefined) {
    return {};
  }
}
