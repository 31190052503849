import React from 'react';
import { useCurrentChannel } from '@/infrastructure/controllers/hooks/routes/useCurrentChannel';
import { ChannelColorIndicator } from '@/infrastructure/ui/channel-color-indicator/ChannelColorIndicator';

export const ChannelHeaderLeftColorIndicator = () => {
  const { channel } = useCurrentChannel();
  if (channel && !channel.isFolder) {
    return <ChannelColorIndicator channelId={channel.id} />;
  }
  return null;
};
