import Constants from 'expo-constants';
import { EnvirontmentVariables } from '@/domain/services/environment-variables/EnvirontmentVariables';
import { EnvironmentVariablesStoreInterface } from '@/domain/services/environment-variables/EnvironmentVariablesStoreInterface';

export class EnvironmentVariablesStore implements EnvironmentVariablesStoreInterface {
  get env(): EnvirontmentVariables {
    if (!Constants.expoConfig!.extra) {
      const envVars = Object.fromEntries(
        Object.getOwnPropertyNames(process.env)
          .map((key) => [key.replace('EXPO_PUBLIC_', ''), process.env[key]])
      );
      return envVars as any as EnvirontmentVariables;
    }
    return Constants.expoConfig!.extra as EnvirontmentVariables;
  }
}
