import { ComponentProps, JSX, JSXElementConstructor } from 'react';
import { getDisplayName } from '../../global/getDisplayName';
import { ShortcutTooltip } from '@/infrastructure/ui/components/ShortcutTooltip';

interface WithTooltipProps {
  tooltip?: [string, string?];
}

export function withTooltip<
  C extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>,
>(WrappedComponent: C) {
  type Props = ComponentProps<C>;
  function WithTooltip({ tooltip, ...props }: Props & WithTooltipProps) {
    const [hint, shortcut] = tooltip || [];
    const element = <WrappedComponent {...props as Props} />;
    if (hint) {
      return (
        <ShortcutTooltip hint={hint} shortcut={shortcut}>
          {element}
        </ShortcutTooltip>
      );
    }
    return element;
  }
  WithTooltip.displayName = `WithTooltip(${getDisplayName(WrappedComponent)})`;
  return WithTooltip;
}
