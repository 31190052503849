import React, { useCallback } from 'react';
import { AttachmentViewModel } from '@/adapters/view-models/AttachmentViewModel';
import { UploadedAttachment } from '@/adapters/view-models/AttachmentViewModel';
import { AddedAttachment } from '../AttachmentGallery';

export function useDeleteAttachmentCallback(
  defaultAttachmentViewModels: AttachmentViewModel[],
  setDefaultAttachments: React.Dispatch<React.SetStateAction<(UploadedAttachment | AttachmentViewModel)[]>>,
  triggerDefaultAttachmentsUpdate: (defaultAttachments: (UploadedAttachment | AttachmentViewModel)[]) => void,
  setAddedAttachments: React.Dispatch<React.SetStateAction<AddedAttachment[]>>,
  onPerformedAction: (() => void) | undefined,
  triggerAddedAttachmentsUpdate: (addedAttachments: AddedAttachment[]) => void
) {
  return useCallback((attachmentViewModel: AttachmentViewModel) => {
    onPerformedAction?.();
    const indexOfDefaultAttachment = defaultAttachmentViewModels.findIndex((defaultAttachment) => attachmentViewModel === defaultAttachment);

    if (indexOfDefaultAttachment !== -1) {
      setDefaultAttachments((prevAttachments) => {
        const result = prevAttachments.filter((_, index) => index !== indexOfDefaultAttachment);
        triggerDefaultAttachmentsUpdate(result);
        return result;
      });
    } else {
      setAddedAttachments((prevAttachments) => {
        const indexOfAddedAttachment = prevAttachments.findIndex((addedAttachment) => addedAttachment[0] === attachmentViewModel);
        const result = prevAttachments.filter((_, index) => index !== indexOfAddedAttachment);
        triggerAddedAttachmentsUpdate(result);
        return result;
      });
    }
  }, [defaultAttachmentViewModels, onPerformedAction, setAddedAttachments, setDefaultAttachments, triggerAddedAttachmentsUpdate, triggerDefaultAttachmentsUpdate]);
}
