import {
  BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta
} from '@reduxjs/toolkit/dist/query';
import { PromiseWithKnownReason } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { MaybeDrafted, PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { QueryFulfilledRejectionReason } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { RouteProp } from '@react-navigation/native';
import {
  declareMutation,
  declareMutationCompletion, isMutationCancelled,
} from '@/adapters/mutation-cancellation/mutationCancellation';
import { AppDispatch, RootState, UndoableAction } from '@/domain/state/store';
import { ApiClient, apiClient } from '../api';
import { CacheTagDescription } from '../cache-tag-providers/cache-tag-types';
import { rootNavigationContainerRef } from '@/infrastructure/navigation/navigators/root/RootNavigationContainerRef';
import { AppScreenStackParamList } from '@/infrastructure/navigation/navigators/app/AppScreenProps';
import { EndpointConfig, MutationTrackingService } from '../mutation-tracking/MutationTrackingService';
import { ThreadViewModel } from '../view-models/ThreadViewModel';
import { getAllMessagesParams } from './utils/getAllMessagesParams';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';
import { getInboxParams } from './utils/getInboxParams';
import { getSentParams } from './utils/getSentParams';
import { getSpamParams } from './utils/getSpamParams';
import { getSearchParams } from './utils/getSearchParams';
import { getSnoozedParams } from './utils/getSnoozedParams';
import { getStarredParams } from './utils/getStarredParams';
import { getScheduledParams } from '@/adapters/mutation-handlers/utils/getScheduledParams';

type EndpointsWithUseQuery = {
  [K in keyof typeof apiClient.endpoints]: typeof apiClient.endpoints[K] extends { useQuery: any } ? K : never
};

export type QueryEndpointNames = EndpointsWithUseQuery[keyof typeof apiClient.endpoints];
export type EndpointRequestType<EndpointName extends QueryEndpointNames> = Parameters<typeof apiClient.endpoints[EndpointName]['initiate']>[0];
export type EndpointResponseType<EndpointName extends QueryEndpointNames> = ReturnType<ReturnType<typeof apiClient.endpoints[EndpointName]['initiate']>> extends Promise<infer R> ? R extends { data: infer D } ? D : never : never;

export type Patch = ReturnType<typeof apiClient.util.updateQueryData> | ReturnType<typeof apiClient.util.upsertQueryData>;
export interface PatchWrapper<TEndpoint extends QueryEndpointNames = any> extends EndpointConfig {
  endpointName: TEndpoint;
  args: EndpointRequestType<TEndpoint>;
  patch: Patch;
}

export const PATCH_PREFIX_ID = 'patch-';
export type CacheTagInvalidationIntent = CacheTagDescription & {
  schedule?: {
    delayMs: number;
    uniqueKey: string;
  }
};

type CacheTagInvalidationSchedule = {
  timeoutId: NodeJS.Timeout;
  invalidationTag: Required<CacheTagInvalidationIntent>;
};

export type QueryResponse<TResponse> = PromiseWithKnownReason<{ data: TResponse; meta: FetchBaseQueryMeta | undefined; }, QueryFulfilledRejectionReason<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>>>;

export interface MutationHandlerProps<_TArg, _TResponse> {
  readonly apiClient: ApiClient;
  readonly getState: () => RootState;
  readonly dispatch: AppDispatch;
  readonly mutationTrackingService: MutationTrackingService;
  readonly skipUndoOnFailure?: boolean;
}

export abstract class MutationHandler<TArg, TResponse> {
  private static patchCount = 0;
  private static cacheTagInvalidationSchedules: CacheTagInvalidationSchedule[] = [];

  protected get state(): RootState {
    return this.getState() as RootState;
  }

  constructor(props: MutationHandlerProps<TArg, TResponse>) {
    return Object.assign(this, props);
  }

  private dispatchPatches(patches: Patch[]) {
    return patches.map((patch) => this.dispatch(patch));
  }

  public async applyMutationUpdates(patch: TArg, query: QueryResponse<TResponse>, requestId: string) {
    const patchId = `${PATCH_PREFIX_ID}${MutationHandler.patchCount++}`;
    const patchOrPatchWrappers = this.createOptimisticUpdatePatchWrappers(patch, patchId);
    const patchWrappers = patchOrPatchWrappers.filter((wrapper) => 'endpointName' in wrapper) as PatchWrapper<any>[];

    // Keep the order instead of filtering.
    const patchCollections: PatchCollection[] = this.dispatchPatches(
      patchOrPatchWrappers.map((patchOrPatchWrapper) => {
        if ('endpointName' in patchOrPatchWrapper) {
          return (patchOrPatchWrapper as PatchWrapper<any>).patch;
        }
        return patchOrPatchWrapper as Patch;
      })
    );

    const locks = this.mutationTrackingService.acquireEndpointLocks(patchWrappers.filter((wrapper) => wrapper.endpointName));

    declareMutation(
      requestId,
      patchCollections,
      patchWrappers,
      () => { this.handleInvalidationTags(patch); },
    );

    try {
      const { data, meta } = await query;
      const postCompletionPatches = this.dispatchPostCompletionPatches(patch, data, requestId, patchId);
      await this.markMutationAsCompleted(meta, requestId, postCompletionPatches);
    } catch (e) {
      // Cannot undo promises.
      if (!this.skipUndoOnFailure) {
        patchCollections.forEach((patchResult) => 'undo' in patchResult && patchResult.undo());
      }
    } finally {
      locks.forEach((lock) => lock.release());
    }
  }

  private dispatchPostCompletionPatches(patch: TArg, data: TResponse, requestId: string, patchId: string): PatchCollection[] {
    if (isMutationCancelled(requestId)) {
      return [];
    }

    const patchWrappers = this.createRequestCompletedPatchWrappers(patch, data, patchId)
      .map((wrapper) => ('patch' in wrapper ? wrapper.patch : wrapper));
    const postCompletionPatches = this.dispatchPatches(patchWrappers);
    // Only invalidate when the mutation is not canceled to avoid issues with re-fetching while the cancel command
    // hasn't been processed yet.
    this.handleInvalidationTags(patch);
    return postCompletionPatches;
  }

  private async markMutationAsCompleted(meta: FetchBaseQueryMeta | undefined, requestId: string, postCompletionPatches: PatchCollection[]) {
    const cancelToken = meta?.response?.headers.get('x-cancel-token');
    if (!cancelToken) {
      return;
    }

    await declareMutationCompletion(requestId, cancelToken, postCompletionPatches);
  }

  private handleInvalidationTags(patch: TArg) {
    const invalidationTags = this.generateInvalidationTags(patch);
    const immediateInvalidationTags = invalidationTags.filter((tag) => !tag.schedule);
    const delayedInvalidationTags = invalidationTags.filter((tag) => tag.schedule) as Required<CacheTagInvalidationIntent>[];
    this.dispatch(this.apiClient.util.invalidateTags(immediateInvalidationTags as any));
    this.handleScheduledInvalidationTags(delayedInvalidationTags);
  }

  private handleScheduledInvalidationTags(invalidationTags: Required< CacheTagInvalidationIntent>[]) {
    this.cancelSimilarScheduledInvalidationTags(invalidationTags);
    this.scheduleInvalidationTags(invalidationTags);
  }

  private scheduleInvalidationTags(invalidationTags: Required<CacheTagInvalidationIntent>[]) {
    const newSchedules = invalidationTags.map((tag) => {
      const timeoutId = setTimeout(() => {
        this.dispatch(this.apiClient.util.invalidateTags([tag as CacheTagDescription as any]));
        const scheduleIndex = MutationHandler.cacheTagInvalidationSchedules.findIndex((s) => s.timeoutId === timeoutId);
        if (scheduleIndex !== -1) {
          MutationHandler.cacheTagInvalidationSchedules.splice(scheduleIndex, 1);
        }
      }, tag.schedule.delayMs!);
      return { timeoutId, invalidationTag: tag };
    });

    MutationHandler.cacheTagInvalidationSchedules.push(...newSchedules);
  }

  private cancelSimilarScheduledInvalidationTags(invalidationTags: Required<CacheTagInvalidationIntent>[]) {
    const similarSchedules = MutationHandler.cacheTagInvalidationSchedules.filter((s) => invalidationTags.some((tag) => tag.schedule.uniqueKey === s.invalidationTag.schedule.uniqueKey));

    similarSchedules.forEach((s) => clearTimeout(s.timeoutId));
    MutationHandler.cacheTagInvalidationSchedules = MutationHandler.cacheTagInvalidationSchedules.filter((s) => !similarSchedules.includes(s));
  }

  protected updateQueryData<TEndpoint extends QueryEndpointNames>(endpointName: TEndpoint, args: EndpointRequestType<TEndpoint>, recipe: (draft: MaybeDrafted<EndpointResponseType<TEndpoint>>) => void) {
    const patch = this.apiClient.util.updateQueryData(endpointName, args as any, recipe as any);

    return {
      endpointName,
      args,
      patch,
    };
  }

  protected upsertQueryData<TEndpoint extends QueryEndpointNames>(endpointName: TEndpoint, args: EndpointRequestType<TEndpoint>, recipe: EndpointResponseType<TEndpoint>) {
    const patch = this.apiClient.util.upsertQueryData(endpointName, args as any, recipe as any);

    return {
      endpointName,
      args,
      patch,
    };
  }

  protected createOptimisticUpdatePatchWrappers(_patch: TArg, _patchId:string): (PatchWrapper<any> | Parameters<AppDispatch>[0] | UndoableAction)[] {
    return [];
  }

  protected createRequestCompletedPatchWrappers(_patch: TArg, _data: TResponse, _patchId: string): (PatchWrapper<any> | Parameters<AppDispatch>[0] | UndoableAction)[] {
    return [];
  }

  protected getSelfUser() {
    return this.apiClient.endpoints.getSelfUser.select()(this.state).data;
  }

  protected getSelfOrganization() {
    const orgs = this.apiClient.endpoints.getSelfOrganizations.select()(this.state).data?.organizations;

    if (orgs?.length) {
      return orgs[0];
    }
  }

  protected getThreadInCurrentChannel(threadId: string) {
    const threads = this.apiClient.endpoints.getChannelThreads.select(getCurrentChannelParams()!)(this.state).data?.threads;

    if (threads) {
      return threads.find((thread) => thread.id === threadId);
    }
  }

  protected getEventInOpenedInboxFromThreadId(threadId: string) {
    const threads = this.apiClient.endpoints.getSelfInboxEvents.select(getInboxParams()!)(this.state).data?.inboxItems.map((item) => item.thread);

    if (threads) {
      return threads.find((thread) => thread.id === threadId);
    }
  }

  protected getOpenedThread(threadId: string) {
    const thread = this.apiClient.endpoints.getThread.select({ threadId: this.getCurrentThreadId()! })(this.state).data;
    if (thread?.id === threadId) {
      return thread;
    }
  }

  protected getThreadInSnoozedFolderFromThreadId(threadId: string) {
    const snoozedThreads = this.apiClient.endpoints.getSelfSnoozedThreads.select(getSnoozedParams()!)(this.state).data?.threads;

    if (snoozedThreads) {
      return snoozedThreads.find((thread) => thread.id === threadId);
    }
  }

  protected getThreadInScheduledFolderFromThreadId(threadId: string) {
    const scheduledThreads = this.apiClient.endpoints.getSelfScheduledThreads.select(getScheduledParams()!)(this.state).data?.threads;

    if (scheduledThreads) {
      return scheduledThreads.find((thread) => thread.id === threadId);
    }
  }

  protected getThreadInSentFolderFromThreadId(threadId: string) {
    const sentThreads = this.apiClient.endpoints.getSelfSentThreads.select(getSentParams()!)(this.state).data?.threads;

    if (sentThreads) {
      return sentThreads.find((thread) => thread.id === threadId);
    }
  }

  protected getThreadInSpamFolderFromThreadId(threadId: string) {
    const sentThreads = this.apiClient.endpoints.getSelfSpamThreads.select(getSpamParams()!)(this.state).data?.threads;

    if (sentThreads) {
      return sentThreads.find((thread) => thread.id === threadId);
    }
  }

  protected getThreadInStarredFolderFromThreadId(threadId: string) {
    const starredThreads = this.apiClient.endpoints.getSelfStarredThreads.select(getStarredParams()!)(this.state).data?.threads;

    if (starredThreads) {
      return starredThreads.find((thread) => thread.id === threadId);
    }
  }

  protected getThreadInSearchResults(threadId: string) {
    const threads = this.apiClient.endpoints.getSelfFilteredThreads.select(getSearchParams()!)(this.state).data?.threads;

    if (threads) {
      return threads.find((thread) => thread.id === threadId);
    }
  }

  protected getThreadInAllMessagesFolderFromThreadId(threadId: string) {
    const threads = this.apiClient.endpoints.getSelfAllThreads.select(getAllMessagesParams()!)(this.state).data?.threads;

    if (threads) {
      return threads.find((thread) => thread.id === threadId);
    }
  }

  protected getThreadInCurrentChannelFromEventId(eventId: string) {
    const threads = this.apiClient.endpoints.getChannelThreads.select(getCurrentChannelParams()!)(this.state).data?.threads;

    if (threads) {
      return threads.find((thread) => thread.eventId === eventId);
    }
  }

  protected getThreadInOpenedInboxFromEventId(eventId: string) {
    const inboxItems = this.apiClient.endpoints.getSelfInboxEvents.select(getInboxParams()!)(this.state).data?.inboxItems;

    if (inboxItems) {
      const threads = inboxItems.map((item) => item.thread);
      return threads.find((thread) => thread.eventId === eventId);
    }
  }

  protected getOpenedThreadFromEventId(eventId: string) {
    const thread = this.apiClient.endpoints.getThread.select({ threadId: this.getCurrentThreadId()! })(this.state).data;
    if (thread?.eventId === eventId) {
      return thread;
    }
  }

  protected getThreadInSentFolderFromEventId(eventId: string) {
    const sentThreads = this.apiClient.endpoints.getSelfSentThreads.select(getSentParams()!)(this.state).data?.threads;

    if (sentThreads) {
      return sentThreads.find((thread) => thread.eventId === eventId);
    }
  }

  protected getThreadInSnoozedFolderFromEventId(eventId: string) {
    const snoozedThreads = this.apiClient.endpoints.getSelfSnoozedThreads.select(getSnoozedParams()!)(this.state).data?.threads;

    if (snoozedThreads) {
      return snoozedThreads.find((thread) => thread.eventId === eventId);
    }
  }

  protected getThreadInScheduledFolderFromEventId(eventId: string) {
    const scheduledThreads = this.apiClient.endpoints.getSelfScheduledThreads.select(getScheduledParams()!)(this.state).data?.threads;

    if (scheduledThreads) {
      return scheduledThreads.find((thread) => thread.eventId === eventId);
    }
  }

  protected getThreadInSpamFolderFromEventId(eventId: string) {
    const sentThreads = this.apiClient.endpoints.getSelfSpamThreads.select(getSpamParams()!)(this.state).data?.threads;

    if (sentThreads) {
      return sentThreads.find((thread) => thread.eventId === eventId);
    }
  }

  protected getThreadInStarredFolderFromEventId(eventId: string) {
    const starredThreads = this.apiClient.endpoints.getSelfStarredThreads.select(getStarredParams()!)(this.state).data?.threads;

    if (starredThreads) {
      return starredThreads.find((thread) => thread.eventId === eventId);
    }
  }

  protected getThreadInSearchResultsFromEventId(eventId: string) {
    const threads = this.apiClient.endpoints.getSelfFilteredThreads.select(getSearchParams()!)(this.state).data?.threads;

    if (threads) {
      return threads.find((thread) => thread.eventId === eventId);
    }
  }

  protected getThreadInAllMessagesFolderFromEventId(eventId: string) {
    const threads = this.apiClient.endpoints.getSelfAllThreads.select(getAllMessagesParams()!)(this.state).data?.threads;

    if (threads) {
      return threads.find((thread) => thread.eventId === eventId);
    }
  }

  protected getThreadInCurrentContext(threadId: string) {
    const thread = this.getOpenedThread(threadId)
      ?? this.getThreadInCurrentChannel(threadId)
      ?? this.getEventInOpenedInboxFromThreadId(threadId)
      ?? this.getThreadInSentFolderFromThreadId(threadId)
      ?? this.getThreadInSnoozedFolderFromThreadId(threadId)
      ?? this.getThreadInScheduledFolderFromThreadId(threadId)
      ?? this.getThreadInSearchResults(threadId)
      ?? this.getThreadInSpamFolderFromThreadId(threadId)
      ?? this.getThreadInStarredFolderFromThreadId(threadId)
      ?? this.getThreadInAllMessagesFolderFromThreadId(threadId);

    if (thread) {
      return { ...thread } as ThreadViewModel;
    }
  }

  protected getThreadInCurrentContextFromEventId(eventId: string) {
    const thread = this.getOpenedThreadFromEventId(eventId)
      ?? this.getThreadInOpenedInboxFromEventId(eventId)
      ?? this.getThreadInCurrentChannelFromEventId(eventId)
      ?? this.getThreadInSentFolderFromEventId(eventId)
      ?? this.getThreadInSnoozedFolderFromEventId(eventId)
      ?? this.getThreadInScheduledFolderFromEventId(eventId)
      ?? this.getThreadInSearchResultsFromEventId(eventId)
      ?? this.getThreadInSpamFolderFromEventId(eventId)
      ?? this.getThreadInStarredFolderFromEventId(eventId)
      ?? this.getThreadInAllMessagesFolderFromEventId(eventId);

    if (thread) {
      return { ...thread } as ThreadViewModel;
    }
  }

  protected getAllAccessibleChannels() {
    const selfChannels = this.apiClient.endpoints.getSelfChannels.select()(this.state).data?.channels ?? [];
    const orgChannels = this.apiClient.endpoints.getOrganizationChannels.select({ organizationId: this.getSelfOrganization()?.id! })(this.state).data?.channels ?? [];

    return [...selfChannels, ...orgChannels];
  }

  protected getOrganizationMembers() {
    const orgId = this.apiClient.endpoints.getSelfOrganizations.select()(this.state).data!.organizations[0].id;
    return this.apiClient.endpoints.getOrganizationMembers.select({ organizationId: orgId })(this.state).data!.users;
  }

  protected getCurrentThreadId(): string | undefined {
    const route = rootNavigationContainerRef.current?.getCurrentRoute() as RouteProp<AppScreenStackParamList, 'Thread'>;
    if (route.name === 'Thread') {
      return route.params?.threadId;
    }
  }

  protected generateInvalidationTags(_arg: TArg): CacheTagInvalidationIntent[] {
    return [];
  }
}

export interface MutationHandler<TArg, TResponse> extends MutationHandlerProps<TArg, TResponse> { }
