import {
  MarkThreadAsNotSpamApiArg, MarkThreadAsNotSpamApiResponse
} from '../api/codegen';
import { TEMPORARY_ID_PENDING_REFRESH } from './model-factories/mutation-constants';
import {
  CacheTagInvalidationIntent, MutationHandler, MutationHandlerProps, Patch
} from './MutationHandler';
import { createInboxItemViewModelFromThreadAddedToInbox } from '../view-models/InboxItemViewModel';
import { ThreadViewModel, setThreadViewModelInboxItemId, setThreadViewModelIsSpam } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { getSpamParams } from './utils/getSpamParams';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';
import { createAddThreadToInboxPatches } from './thread-patch-factory/factories/createAddThreadToInboxPatches';

export class MarkThreadAsNotSpamMutationHandler extends MutationHandler<MarkThreadAsNotSpamApiArg, MarkThreadAsNotSpamApiResponse> {
  constructor(props: MutationHandlerProps<MarkThreadAsNotSpamApiArg, MarkThreadAsNotSpamApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: MarkThreadAsNotSpamApiArg) {
    return [
      ...this.createAddToInboxPatches(patch),
      this.createRemoveThreadFromSpamPatch(patch),
      ...createThreadUpdatePatchesForThreadId(patch.threadId, (draftedThread: ThreadViewModel) => {
        this.updateThread(draftedThread, TEMPORARY_ID_PENDING_REFRESH);
      })
    ];
  }

  protected createRequestCompletedPatchWrappers(patch: MarkThreadAsNotSpamApiArg, data: MarkThreadAsNotSpamApiResponse, _patchId: string): Patch[] {
    return [
      ...createThreadUpdatePatchesForThreadId(patch.threadId, (draftedThread: ThreadViewModel) => {
        this.updateThread(draftedThread, data.id);
      })
    ];
  }

  private* createAddToInboxPatches(patch: MarkThreadAsNotSpamApiArg) {
    const thread = this.getThreadInCurrentContext(patch.threadId);

    if (thread) {
      this.updateThread(thread, TEMPORARY_ID_PENDING_REFRESH);
      return yield* createAddThreadToInboxPatches(createInboxItemViewModelFromThreadAddedToInbox(thread));
    }
  }

  private createRemoveThreadFromSpamPatch(patch: MarkThreadAsNotSpamApiArg) {
    return this.updateQueryData('getSelfSpamThreads', getSpamParams()!, (draftedThreads) => {
      const itemIndex = draftedThreads.threads.findIndex((item) => item.id === patch.threadId);

      if (itemIndex !== -1) {
        draftedThreads.threads.splice(itemIndex, 1);
      }
    });
  }

  private updateThread(thread: ThreadViewModel, inboxItemId: string) {
    setThreadViewModelIsSpam(thread, false);
    setThreadViewModelInboxItemId(thread, inboxItemId);
  }

  protected generateInvalidationTags(arg: MarkThreadAsNotSpamApiArg) {
    return [
      {
        type: 'Thread' as const,
        id: arg.threadId,
        schedule: {
          delayMs: 10000,
          uniqueKey: `ChannelThreadListManagement-${getCurrentChannelParams()}`,
        }
      } as CacheTagInvalidationIntent,
      {
        type: 'SelfAccount' as const,
        schedule: {
          delayMs: 5000,
          uniqueKey: 'InboxUnreadThreadCount',
        }
      },
      {
        type: 'InboxEventsList' as const,
      }

    ];
  }
}
