import { HStack, Pressable, Text } from 'native-base';
import {
  type NavigationProp, RouteProp, useNavigation, useRoute
} from '@react-navigation/native';
import { HeaderTitle } from '../../ui/HeaderTitle';
import { AppScreenStackParamList } from '../../navigation/navigators/app/AppScreenProps';
import { allUserInboxCategories, CATEGORIES, getCategoryFromSlug } from '@/adapters/view-models/ChannelViewModel';
import { useGetSelfAccount } from '@/infrastructure/controllers/hooks/api/useGetSelfAccount';
import { useHasEmailSync } from '@/infrastructure/hooks/useHasEmailSync';
import { useGetSelfOrganizations } from '@/infrastructure/controllers/hooks/api/useGetSelfOrganizations';
import type { RootStackParamList } from '@/infrastructure/navigation/navigators/root/RootStackProps';

const onlyPrimaryInbox = allUserInboxCategories.slice(0, 1);

export function InboxHeaderTitleController() {
  const navigation = useNavigation<NavigationProp<RootStackParamList, 'Inbox'>>();
  const route = useRoute<RouteProp<AppScreenStackParamList, 'Inbox'>>();
  const categorySlug = route.params?.category;
  const category = getCategoryFromSlug(categorySlug);

  const { currentData: organizations } = useGetSelfOrganizations();
  const { shouldHideEmailFeatures } = useHasEmailSync(organizations);
  const inboxCategories = shouldHideEmailFeatures ? onlyPrimaryInbox : allUserInboxCategories;
  const { currentData: account } = useGetSelfAccount();

  // todo: preload on hover? using usePrefetchChannelCallback hook

  return (
    <HStack marginLeft={6} space={10}>
      {inboxCategories.map((id) => {
        const title = id === 'CATEGORY_PRIMARY' ? 'Primary' : CATEGORIES[id].name;
        return (
          <Pressable
            key={id}
            onPress={() => {
              const params = id === 'CATEGORY_PRIMARY' ? undefined : { category: CATEGORIES[id].slug };
              navigation.reset({
                index: 0,
                routes: [{ name: 'Inbox', params }],
              });
            }}
          >
            <HeaderTitle
              color={category === id ? 'opacityPrimaryDark.900' : 'opacityPrimaryDark.500'}
              fontWeight={category === id ? undefined : '400'}
              borderBottomColor={category === id ? 'primary.200' : 'transparent'}
              borderBottomWidth={2}
            >
              {title}
              {Boolean(account?.numberOfUnreadEventsByCategory[id]) && (
                <Text ml={2} fontSize="sm" color="opacityPrimaryDark.500">
                  {account?.numberOfUnreadEventsByCategory[id]}
                </Text>
              )}
            </HeaderTitle>
          </Pressable>
        );
      })}
    </HStack>
  );
}
