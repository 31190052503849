import { arrayBufferToBase64url } from './arrayBufferToBase64url';

// The code could be shared between the frontend and the backend.
const HASH_SALT = 'server-file-name-salt';
export async function getFileHash(file: File): Promise<string> {
  const fileBuffer = await file.arrayBuffer();
  const base64urlString = arrayBufferToBase64url(fileBuffer) + HASH_SALT;
  const encoder = new TextEncoder();
  const data = encoder.encode(base64urlString);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

  return hashHex;
}
