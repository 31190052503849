import { AddThreadToInboxApiArg, AddThreadToInboxApiResponse } from '../api/codegen';
import { TEMPORARY_ID_PENDING_REFRESH } from './model-factories/mutation-constants';
import {
  CacheTagInvalidationIntent, MutationHandler, MutationHandlerProps, Patch
} from './MutationHandler';
import { createInboxItemViewModelFromThreadAddedToInbox } from '../view-models/InboxItemViewModel';
import { ThreadViewModel, setThreadViewModelInboxItemId } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';
import { getSnoozedParams } from './utils/getSnoozedParams';
import { createInboxPatches } from './thread-patch-factory/factories/createInboxPatches';
import { createAddThreadToInboxPatches } from './thread-patch-factory/factories/createAddThreadToInboxPatches';

export class AddThreadToInboxMutationHandler extends MutationHandler<AddThreadToInboxApiArg, AddThreadToInboxApiResponse> {
  constructor(props: MutationHandlerProps<AddThreadToInboxApiArg, AddThreadToInboxApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: AddThreadToInboxApiArg) {
    return [
      this.createRemoveThreadFromSnoozedFolderPatch(patch),
      ...this.createAddToInboxPatches(patch),
      ...createThreadUpdatePatchesForThreadId(patch.threadId, (draftedThread: ThreadViewModel) => {
        this.updateThread(draftedThread, TEMPORARY_ID_PENDING_REFRESH);
      })
    ];
  }

  protected createRequestCompletedPatchWrappers(patch: AddThreadToInboxApiArg, data: AddThreadToInboxApiResponse, _patchId: string): Patch[] {
    return [
      ...createThreadUpdatePatchesForThreadId(patch.threadId, (draftedThread: ThreadViewModel) => {
        this.updateThread(draftedThread, data.id);
      }),

      ...createInboxPatches((inboxItems) => {
        const relatedInboxItems = inboxItems.filter((item) => item.thread.id === patch.threadId);
        relatedInboxItems.forEach((item) => {
          item.id = data.id;
        });
      })
    ];
  }

  private* createAddToInboxPatches(patch: AddThreadToInboxApiArg) {
    const thread = this.getThreadInCurrentContext(patch.threadId);

    if (thread) {
      this.updateThread(thread, TEMPORARY_ID_PENDING_REFRESH);
      return yield* createAddThreadToInboxPatches(createInboxItemViewModelFromThreadAddedToInbox(thread));
    }
  }

  private createRemoveThreadFromSnoozedFolderPatch(patch: AddThreadToInboxApiArg) {
    return this.updateQueryData('getSelfSnoozedThreads', getSnoozedParams()!, (draftedInboxItems) => {
      const itemIndex = draftedInboxItems.threads.findIndex((item) => item.id === patch.threadId);

      if (itemIndex !== -1) {
        draftedInboxItems.threads.splice(itemIndex, 1);
      }
    });
  }

  private updateThread(thread: ThreadViewModel, inboxItemId: string) {
    setThreadViewModelInboxItemId(thread, inboxItemId);
  }

  protected generateInvalidationTags(arg: AddThreadToInboxApiArg) {
    return [
      {
        type: 'Thread' as const,
        id: arg.threadId,
        schedule: {
          delayMs: 10000,
          uniqueKey: `ChannelThreadListManagement-${getCurrentChannelParams()}`,
        }
      } as CacheTagInvalidationIntent,
      {
        type: 'SelfAccount' as const,
        schedule: {
          delayMs: 5000,
          uniqueKey: 'InboxUnreadThreadCount',
        }
      },
      {
        type: 'InboxEventsList' as const,
      },
      {
        type: 'SelfSnoozedThreadsList' as const,
      }
    ];
  }
}
