import { useMemo } from 'react';
import { ORGANIZATIONS_QUERY_OPTIONS } from '../../../api/query-parameters';
import { apiClient, useGetSelfOrganizationsQuery } from '@/adapters/api';

export const useGetSelfOrganizations = () => {
  const org = useGetSelfOrganizationsQuery(undefined, ORGANIZATIONS_QUERY_OPTIONS);

  const organizationId = org.currentData?.organizations?.[0]?.id;

  return useMemo(() => ({ ...org, organizationId, selfOrganization: org.currentData?.organizations?.[0] }), [org, organizationId]);
};

export const usePrefetchSelfOrganizations = () => apiClient.usePrefetch('getSelfOrganizations');
