import { buildApiClientWithViewModels } from './buildApiClientWithViewModels';

export type ApiClient = typeof apiClient;

export const apiClient = buildApiClientWithViewModels();

export const {
  useGetChannelParticipantsQuery,
  useGetChannelQuery,
  useGetChannelThreadsQuery,
  useGetOrganizationChannelsQuery,
  useGetOrganizationMembersQuery,
  useGetSelfAccountQuery,
  useGetSelfAllThreadsQuery,
  useGetSelfChannelsQuery,
  useGetSelfFilteredThreadsQuery,
  useGetSelfInboxEventsQuery,
  useGetSelfOrganizationsQuery,
  useGetSelfSentThreadsQuery,
  useGetSelfSnoozedThreadsQuery,
  useGetSelfTrashedThreadsQuery,
  useGetSelfSpamThreadsQuery,
  useGetSelfScheduledThreadsQuery,
  useGetSelfUserQuery,
  useGetSelfContactsQuery,
  useGetThreadMessagesQuery,
  useGetThreadQuery,
  useGetSelfStarredThreadsQuery,
} = apiClient;
