import { Box, Divider, VStack } from 'native-base';
import {
  RefObject, useCallback, useEffect, useRef
} from 'react';
import { ComposeArea, ComposeAreaHandles, ComposeAreaSubmitCallback } from '../compose';
import { ParticipantScreenSectionControllerHandles, ParticipantScreenSectionController } from '../../controllers/screen-sections/ParticipantScreenSectionController';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import MentionEventsProvider from '@/infrastructure/ui/rich-text-editor/mention/MentionEventsProvider';

import { selectThreadDraft } from '@/domain/state/drafts';
import { store } from '@/domain/state/store';
import { DropZone } from '../DropZone';

type ComposeScreenSectionProps = {
  submitCallback: ComposeAreaSubmitCallback;
  selectedParticipantsListChangedCallback: (selectedParticipants: ParticipantViewModel[]) => void;
  selectedParticipants: ParticipantViewModel[] | null;
  composeAreaRef: RefObject<ComposeAreaHandles>;
};

export function ComposeScreenSection({
  submitCallback: submitCallbackFromProps,
  selectedParticipantsListChangedCallback,
  composeAreaRef,
  selectedParticipants,
}: ComposeScreenSectionProps) {
  const participantSelectorRef = useRef<ParticipantScreenSectionControllerHandles>(null);

  const submitCallback: ComposeAreaSubmitCallback = useCallback(async (props) => {
    participantSelectorRef.current?.clear();
    return await submitCallbackFromProps(props);
  }, [submitCallbackFromProps]);

  useEffect(() => {
    const foundDraft = selectThreadDraft(store.getState(), 'compose');
    if (foundDraft) {
      participantSelectorRef.current?.setParticipants(foundDraft.participants);
    }
    selectedParticipantsListChangedCallback(foundDraft?.participants ?? []);
  }, [selectedParticipantsListChangedCallback]);

  return (
    <VStack
      flexGrow={1}
      flexShrink={1}
    >
      <Divider />
      <MentionEventsProvider>
        <DropZone attachFilesCallback={composeAreaRef.current?.attachFilesCallback} flexGrow={1} flexShrink={1} underlyingDivStyle={{ padding: 8 }}>
          <ParticipantScreenSectionController
            ref={participantSelectorRef}
            selectedParticipantsListChangedCallback={selectedParticipantsListChangedCallback}
            type="recipients"
            includeChannels
            includeUsers
            allowExternalUsers
          />
          <Box px={2}>
            <ComposeArea
              bodyPlaceholder="Type a message to create a new thread"
              submitCallback={submitCallback}
              showTitle
              freezeParticipants
              participants={selectedParticipants}
              draftKey="compose"
              ref={composeAreaRef}
            />
          </Box>
        </DropZone>
      </MentionEventsProvider>
    </VStack>
  );
}
