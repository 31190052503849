import { MaterialCommunityIcons } from '@expo/vector-icons';
import { AlertDialog, Button } from 'native-base';
import { useRef, useState } from 'react';
import { ComprehensiveThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { ItemActionButton, ItemActionButtonProps } from './ItemActionButton';
import { useGetSelfUser } from '../../controllers/hooks/api/useGetSelfUser';

type TrashActionButtonProps = Omit<ItemActionButtonProps, 'hint' | 'iconName' | 'iconCollection'> & {
  thread: ComprehensiveThreadViewModel;
};
export function TrashActionButton(props: TrashActionButtonProps) {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const cancelRef = useRef(null);
  const { currentData: currentUser } = useGetSelfUser();

  const handleClose = () => {
    setIsAlertVisible(false);
  };

  const submit = () => {
    setIsAlertVisible(false);
    props.onPress?.();
  };

  const onButtonPress = () => {
    const hasChannels = props.thread.channelIds.length > 0;
    const participants = props.thread.memberPictures.filter((member) => member.userId);
    const otherParticipantsExceptSelf = participants.filter((member) => member.userId !== currentUser?.id);
    const hasOtherUsers = otherParticipantsExceptSelf.length > 0;
    const othersHaveAccess = hasOtherUsers || hasChannels;

    if (!othersHaveAccess) {
      return submit();
    }

    setIsAlertVisible(true);
  };

  return (
    <>
      <ItemActionButton
        {...props}
        onPress={onButtonPress}
        hint="Trash"
        iconName="trash-can-outline"
        iconCollection={MaterialCommunityIcons}
      />
      <AlertDialog leastDestructiveRef={cancelRef} isOpen={isAlertVisible}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton onPress={handleClose} />
          <AlertDialog.Header>
            Move to trash
          </AlertDialog.Header>
          <AlertDialog.Body>
            This message will be deleted for all Upstream users.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button colorScheme="blueGray" variant="ghost" onPress={handleClose} ref={cancelRef}>
                Cancel
              </Button>
              <Button onPress={submit}>
                Move to trash
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  );
}
