import { Modal, Text } from 'native-base';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { create } from 'zustand';
import { DisplayShortcut } from '../shortcuts/DisplayShortcut';
import { closeCommandMenu, CommandItem, ProvideCommands } from '../CommandMenu';

import './ShortcutsModal.css';

interface ShortcutsDefinition {
  group: string;
  shortcuts: [string, string][];
}

const allShortcuts: ShortcutsDefinition[] = [
  {
    group: 'Compose & Send',
    shortcuts: [
      ['Compose', 'c'],
      ['Reply', 'r'],
      ['Reply All', 'a'],
      // ['Forward', '-'],
      ['Insert a link', 'mod+k'],
      ['Send', 'mod+enter'],
      ['Send later', 'mod+shift+L'],
      ['Send now', 'mod+shift+Z'],
      ['Attach files', 'mod+shift+A'],
    ],
  },
  {
    group: 'Thread',
    shortcuts: [
      ['Mark as read', 'shift+i'],
      ['Mark as unread', 'shift+u'],
      ['Snooze', 'b'],
      // ['Star/Unstar', 's'],
      ['Archive', 'e'],
      ['Follow/Unfollow', 'm'],
      ['Spam', '!'],
      // ['Delete', '#'],
      ['Select a conversation', 'x'],
      ['Select all conversations', 'mod+a'],
      ['Undo last action', 'mod+z or z'],
    ],
  },
  {
    group: 'Navigation',
    shortcuts: [
      ['Back to thread list', 'u or esc'],
      ['Open conversation', 'o or enter'],
      ['Previous message', 'p or ↑'],
      ['Next message', 'n or ↓'],
      ['Newer conversation', 'k'],
      ['Older conversation', 'j'],
      ['Go to next page', 'g,n'],
      ['Go to previous page', 'g,p'],
      ['Search', '/'],
      ['Close', 'esc'],
    ],
  },
  {
    group: 'Text Format',
    shortcuts: [
      ['Bold', 'mod+b'],
      ['Italics', 'mod+i'],
      ['Underline', 'mod+u'],
      ['Numbered list', 'mod+shift+7'],
      ['Bulleted list', 'mod+shift+8'],
      ['Quote', 'mod+shift+9'],
      ['Indent less', 'mod+['],
      ['Indent more', 'mod+]'],
      ['Remove formatting', 'mod+\\'],
    ],
  },
  {
    group: 'Go To',
    shortcuts: [
      ['Go to Inbox', 'g,i'],
      ['Go to Starred', 'g,s'],
      ['Go to Snoozed', 'g,b'],
      ['Go to Sent', 'g,t'],
      ['Go to Drafts', 'g,d'],
      ['Go to All Messages', 'g,a'],
    ],
  },
];

type ShortcutsGroup = typeof allShortcuts[number];

interface ShortcutsModalStore {
  open: boolean;
  setOpen: (open: boolean) => void;
  toggleOpen: () => void;
}

export const useShortcutsModalStore = create<ShortcutsModalStore>((set) => ({
  open: false,
  setOpen: (open: boolean) => set({ open }),
  toggleOpen: () => set((state) => ({ open: !state.open })),
}));

export function ShortcutsModal() {
  const { open, setOpen, toggleOpen } = useShortcutsModalStore();
  useHotkeys(['Shift+/', 'Shift+?', '?'], toggleOpen);
  return (
    <>
      <ProvideCommands id="shortcuts-modal">
        <CommandItem
          shortcut="?"
          onSelect={() => {
            closeCommandMenu();
            toggleOpen();
          }}
        >
          Shortcuts
        </CommandItem>
      </ProvideCommands>
      <Modal isOpen={open} onClose={() => setOpen(false)} size="xxl" closeOnOverlayClick>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Shortcuts</Modal.Header>
          <Modal.Body>
            <div className="shortcuts-grid">
              {allShortcuts.map((group) => (
                <ShortcutsGroup key={group.group} group={group} />
              ))}
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
}

function ShortcutsGroup({ group }: { group: ShortcutsGroup; }) {
  return (
    <div className="shortcuts-grid__group">
      <Text fontWeight={600}>{group.group}</Text>
      <table>
        <tbody>
          {group.shortcuts.map(([action, shortcut]) => (
            <tr key={action}>
              <td><Text>{action}</Text></td>
              <td>
                <DisplayShortcut shortcut={shortcut} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
