import { CancelCallback } from '@/infrastructure/ui/schedule-send/useCancelMessageCallback';
import { SendMessageNowApiArg } from '@/adapters/api/codegen';
import { MutationMetadata, MutationMetadataContext } from '@/adapters/mutation-cancellation/mutationCancellation';
import {
  buildCreateThreadMutationDescription,
  buildPostMessageMutationDescription
} from '@/infrastructure/controllers/UndoMutationDescriptions';
import { CreateMessageContext } from '@/adapters/mutation-cancellation/contexts/CreateMessageContext';

const UNDO_SEND_MESSAGE_TIMEOUT_MS = 4500;

type createMessageMutationMetadataProps = {
  type: 'thread' | 'message',
  cancelMessageCallback: CancelCallback,
  sendMessageNow: (args: SendMessageNowApiArg) => any,
  messageId: string,
  threadId: string,
  scheduledDate?: Date,
  context?: CreateMessageContext,
};

export function createMessageMutationMetadata(
  {
    type, cancelMessageCallback, sendMessageNow, messageId, threadId, scheduledDate, context,
  }: createMessageMutationMetadataProps
): MutationMetadata {
  return {
    initiator: 'user',
    description: type === 'message'
      ? buildPostMessageMutationDescription()
      : buildCreateThreadMutationDescription(),
    onBeforeCancel: (finalizeUndoCallback: () => void, context: MutationMetadataContext | undefined) => {
      // Executed before the optimistic update is reverted because reverting the mutation would remove the message from the store.
      const { pendingConfirmation } = cancelMessageCallback(messageId, 'undo', threadId, {
        finalizeUndoCallback,
        context: context?._type === 'create_message_context' ? context : undefined,
      });
      return pendingConfirmation;
    },
    cancellationTimeoutMs: scheduledDate ? 0 : UNDO_SEND_MESSAGE_TIMEOUT_MS,
    secondaryToastAction: {
      text: 'Send now',
      description: `${type === 'message' ? 'Message' : 'Thread'} sent now`,
      timeoutMs: scheduledDate ? 0 : UNDO_SEND_MESSAGE_TIMEOUT_MS,
      shortcut: {
        shortcut: 'mod+shift+Z',
        hint: 'Send now',
      },
      onClick: async () => {
        await sendMessageNow({ messageId });
      },
    },
    context,
  };
}
