import { ScrollArea as MantineScrollArea } from '@mantine/core';
import { ScrollAreaProps } from './types';
import './ScrollArea.web.css';

export const ScrollArea = (props: ScrollAreaProps) => {
  return (
    <MantineScrollArea
      type="scroll"
      scrollbarSize={10}
      scrollbars="y"
      classNames={{
        viewport: 'scroll-area-viewport',
      }}
      {...props}
    />
  );
};

export const ScrollAreaWeb = MantineScrollArea;
