import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { assertNever } from '../../assertNever';

export const participantsPerCategory = (participants: ParticipantViewModel[]) => {
  const participantPublicIds = (
    participants?.map((recipient) => {
      switch (recipient._type) {
        case 'user':
        case 'channel':
          return recipient.id;
        case 'picture':
        case 'external_user':
          return null;
        default:
          return assertNever(recipient);
      }
    })?.filter(Boolean) ?? []) as string[];
  const externalParticipantEmails = (
    participants?.map((recipient) => {
      switch (recipient._type) {
        case 'user':
        case 'channel':
        case 'picture':
          return null;
        case 'external_user':
          return recipient.email;
        default:
          return assertNever(recipient);
      }
    })?.filter(Boolean) ?? []) as string[];

  return { internal: participantPublicIds, external: externalParticipantEmails };
};
