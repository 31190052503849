import { VStack } from 'native-base';
import { useRef, useState } from 'react';
import { Animated } from 'react-native';
import React from 'react';
import { ComprehensiveThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { SideMenuButton } from './SideMenuButton';
import { ThreadStatusSideMenuButton, ThreadStatusSideMenuButtonProps } from './ThreadStatusSideMenuButton';
import {
  ThreadDueDateSideMenuButton,
  ThreadDueDateSideMenuButtonProps,
} from './ThreadDueDateSideMenuButton';
import { ThreadAssigneeSideMenuButton, ThreadAssigneeSideMenuButtonProps } from './ThreadAssigneeSideMenuButton';

export type ThreadOptionsSideMenuProps = {
  thread: ComprehensiveThreadViewModel;
  setThreadStatusCallback: ThreadStatusSideMenuButtonProps['setThreadStatusCallback'];
  updateThreadDueDateCallback: ThreadDueDateSideMenuButtonProps['updateThreadDueDateCallback'];
  updateThreadAssigneeCallback: ThreadAssigneeSideMenuButtonProps['updateThreadAssigneeCallback'];
};

export function ThreadOptionsSideMenu(props: ThreadOptionsSideMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const initialMenuWidth = 64;
  const menuWidth = useRef(new Animated.Value(initialMenuWidth)).current;
  const animationDuration = 250;
  const buttonPaddingLeft = 19 - (74 - initialMenuWidth) / 2;

  const toggleMenuState = () => {
    const toValue = isOpen ? initialMenuWidth : 180;

    setIsOpen(!isOpen);

    Animated.timing(menuWidth, {
      toValue,
      duration: animationDuration,
      useNativeDriver: false,
    }).start();
  };

  return (
    <Animated.View style={{
      width: menuWidth,
    }}
    >
      <VStack p={2} space={1}>

        <SideMenuButton paddingLeft={buttonPaddingLeft + 1} open={isOpen} onPress={toggleMenuState} animationDuration={animationDuration} iconName="arrow-forward-ios" rotateIcon text="Hide" tooltip={isOpen ? 'Hide' : 'Show menu'} />
        <ThreadAssigneeSideMenuButton paddingLeft={buttonPaddingLeft} open={isOpen} animationDuration={animationDuration} thread={props.thread} updateThreadAssigneeCallback={props.updateThreadAssigneeCallback} />
        <ThreadDueDateSideMenuButton paddingLeft={buttonPaddingLeft} open={isOpen} animationDuration={animationDuration} thread={props.thread} updateThreadDueDateCallback={props.updateThreadDueDateCallback} />
        <ThreadStatusSideMenuButton paddingLeft={buttonPaddingLeft} open={isOpen} animationDuration={animationDuration} thread={props.thread} setThreadStatusCallback={props.setThreadStatusCallback} />

      </VStack>
    </Animated.View>
  );
}
