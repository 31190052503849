import {
  DeleteThreadsApiArg,
  DeleteThreadsApiResponse
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { createThreadUpdatePatches } from './thread-patch-factory/createThreadUpdatePatches';
import { createInboxPatches } from './thread-patch-factory/factories/createInboxPatches';

export class DeleteThreadsMutationHandler extends MutationHandler<DeleteThreadsApiArg, DeleteThreadsApiResponse> {
  constructor(props: MutationHandlerProps<DeleteThreadsApiArg, DeleteThreadsApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: DeleteThreadsApiArg) {
    return [
      ...this.createRemoveThreadFromEveryFolderPatches(patch),
      ...this.createRemoveThreadFromInboxPatches(patch),
    ];
  }

  private createRemoveThreadFromInboxPatches(patch: DeleteThreadsApiArg) {
    return createInboxPatches((inboxItems) => {
      for (let i = inboxItems.length - 1; i >= 0; i--) {
        if (patch.threadIdsRequestBody.thread_ids.includes(inboxItems[i].thread.id)) {
          inboxItems.splice(i, 1);
        }
      }
    });
  }

  private createRemoveThreadFromEveryFolderPatches(patch: DeleteThreadsApiArg) {
    return createThreadUpdatePatches((draftedThreads) => {
      for (let i = draftedThreads.length - 1; i >= 0; i--) {
        if (patch.threadIdsRequestBody.thread_ids.includes(draftedThreads[i].id)) {
          draftedThreads.splice(i, 1);
        }
      }
    });
  }
}
