import { PatchWrapper } from '../MutationHandler';
import { ThreadViewModel } from '../../view-models/ThreadViewModel';
import { createSelfFilteredThreadsPatch } from './factories/createSelfFilteredThreadsPatch';
import { createSelfSentThreadsPatch } from './factories/createSelfSentThreadsPatch';
import { createThreadsListPatch } from './factories/createThreadsListPatch';
import { createThreadInboxPatches } from './factories/createThreadInboxPatches';
import { createSelfSpamThreadsPatch } from './factories/createSelfSpamThreadsPatch';
import { createSnoozedThreadsPatch } from './factories/createSnoozedThreadsPatch';
import { createSelfAllThreadsPatch } from './factories/createSelfAllThreadsPatch';
import { createTrashedThreadsPatch } from './factories/createTrashedThreadsPatch';
import { createStarredThreadsPatch } from './factories/createStarredThreadsPatch';
import {
  createScheduledThreadsPatch
} from '@/adapters/mutation-handlers/thread-patch-factory/factories/createScheduledThreadsPatch';

export function createThreadUpdatePatches(threadsUpdateHandler: (draftedThreads: ThreadViewModel[]) => void) {
  const patches: PatchWrapper<any>[] = [
    ...createThreadInboxPatches(threadsUpdateHandler),
    ...createThreadsListPatch(threadsUpdateHandler),
    createSelfSentThreadsPatch(threadsUpdateHandler),
    createSelfSpamThreadsPatch(threadsUpdateHandler),
    createSnoozedThreadsPatch(threadsUpdateHandler),
    createScheduledThreadsPatch(threadsUpdateHandler),
    createTrashedThreadsPatch(threadsUpdateHandler),
    createStarredThreadsPatch(threadsUpdateHandler),
    createSelfFilteredThreadsPatch(threadsUpdateHandler),
    createSelfAllThreadsPatch(threadsUpdateHandler),
  ];

  return patches;
}
