import { MutationHandler, MutationHandlerProps } from '@/adapters/mutation-handlers/MutationHandler';
import { DeleteMessageApiArg, DeleteMessageApiResponse } from '@/adapters/api/codegen';
import { createThreadUpdatePatches } from '@/adapters/mutation-handlers/thread-patch-factory/createThreadUpdatePatches';
import {
  createThreadUpdatePatchesForThreadId
} from '@/adapters/mutation-handlers/thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { updateEarliestScheduledDate } from '@/adapters/view-models/ThreadViewModel';

export class DeleteMessageMutationHandler extends MutationHandler<DeleteMessageApiArg, DeleteMessageApiResponse> {
  constructor(props: MutationHandlerProps<DeleteMessageApiArg, DeleteMessageApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: DeleteMessageApiArg) {
    return [
      ...this.createRemoveMessageAndEmptyThreadPatches(patch),
    ];
  }

  private* createRemoveMessageAndEmptyThreadPatches(patch: DeleteMessageApiArg) {
    yield this.createRemoveMessagePatch(patch);

    const {
      shouldDeleteThread,
      removeThreadFromScheduledFolder,
      scheduledMessageRemoved,
    } = this.computeWhichPatchesToApply(patch);

    if (shouldDeleteThread) {
      for (const deleteThreadPatch of this.createDeleteEmptyThreadPatches(patch)) {
        yield deleteThreadPatch;
      }
      return;
    }

    if (removeThreadFromScheduledFolder) {
      yield this.createRemoveThreadFromScheduledFolderPatch();
    }
    if (scheduledMessageRemoved) {
      const updateThreadPatches = createThreadUpdatePatchesForThreadId(
        this.getCurrentThreadId()!,
        (draftedThread) => {
          delete draftedThread.scheduledMessagesCreationDates[patch.messageId];
          updateEarliestScheduledDate(draftedThread);
        }
      );
      for (const updateThreadPatch of updateThreadPatches) {
        yield updateThreadPatch;
      }
    }
  }

  private computeWhichPatchesToApply(arg: DeleteMessageApiArg) {
    const messages = this.apiClient.endpoints.getThreadMessages.select({ threadId: this.getCurrentThreadId()! })(this.state);

    let scheduledMessagesCount = 0;
    let deletedMessageIsScheduled = false;
    let messageExists = false;
    for (const message of messages.data!.messages) {
      if (message.state === 'scheduled') {
        scheduledMessagesCount++;
      }
      if (message.id === arg.messageId) {
        messageExists = true;
        if (message.state === 'scheduled') {
          deletedMessageIsScheduled = true;
        }
      }
    }

    return {
      shouldDeleteThread: messages.data!.messages.length === 1 && messageExists,
      removeThreadFromScheduledFolder: scheduledMessagesCount === 1 && deletedMessageIsScheduled,
      scheduledMessageRemoved: deletedMessageIsScheduled,
    };
  }

  private createRemoveThreadFromScheduledFolderPatch() {
    return this.updateQueryData('getSelfScheduledThreads', {}, (draft) => {
      for (let i = 0; i < draft.threads.length; i++) {
        if (draft.threads[i].id === this.getCurrentThreadId()!) {
          draft.threads.splice(i, 1);
          break;
        }
      }
    });
  }

  private createDeleteEmptyThreadPatches(_: DeleteMessageApiArg) {
    return createThreadUpdatePatches((draftedThreads) => {
      for (let i = draftedThreads.length - 1; i >= 0; i--) {
        if (draftedThreads[i].id === this.getCurrentThreadId()!) {
          draftedThreads.splice(i, 1);
        }
      }
    });
  }

  private createRemoveMessagePatch(patch: DeleteMessageApiArg) {
    return this.updateQueryData('getThreadMessages', { threadId: this.getCurrentThreadId()! }, (draftedMessages) => {
      for (let i = draftedMessages.messages.length - 1; i >= 0; i--) {
        if (patch.messageId === draftedMessages.messages[i].id) {
          draftedMessages.messages.splice(i, 1);
          break;
        }
      }
    });
  }
}
