import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { ThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { apiClient } from '@/adapters/api';
import { store } from '@/domain/state/store';
import { ThreadsViewModel } from '@/adapters/view-models/ThreadsViewModel';

export function insertThreadInScheduledFolder(thread: ThreadViewModel, draft: MaybeDrafted<ThreadsViewModel>) {
  const messages = apiClient.endpoints.getThreadMessages.select({ threadId: thread.id })(store.getState()).data?.messages || [];
  const lastMessageActivity = messages.reduce((acc, message) => {
    if (message.state === 'scheduled' || message.rawDate < acc) {
      return acc;
    }
    return message.rawDate;
  }, 0);

  if (lastMessageActivity === 0) {
    if (messages.length === 0) {
      // New thread created with a scheduled message. Insert it at the top in any case.
      draft.threads.splice(0, 0, thread);
    } else {
      // Thread is only a sequence of scheduled messages, thus it must already exist within the list, and the history
      // index cannot have changed. Nothing to do.
    }
  } else {
    const insertionIndex = draft.threads.findIndex((thread) => thread.lastMessageDateTimestamp < lastMessageActivity);
    draft.threads.splice(insertionIndex >= 0 ? insertionIndex : draft.threads.length, 0, thread);
  }

  if (draft.threads.length > 25) {
    draft.threads.pop();
  }
}
