import {
  Icon, IPressableProps, Pressable, Text
} from 'native-base';
import {
  ComponentProps,
  ReactElement, useCallback, useEffect, useRef
} from 'react';
import { Animated } from 'react-native';
import { Tooltip } from '@/infrastructure/ui/components/Tooltip';

export type SideMenuButtonProps = ComponentProps<typeof Pressable> & {
  onPress: IPressableProps['onPress'];
  iconName?: string;
  iconColor?: string;
  animationDuration: number;
  rotateIcon?: boolean;
  text: string;
  tooltip?: string;
  open: boolean;
  paddingLeft: number;
  triggerProps?: any;
  tooltipDisabled?: boolean;
  icon?: ReactElement;
};

export function SideMenuButton(props: SideMenuButtonProps) {
  const textOpacity = useRef(new Animated.Value(0)).current;
  const rotateIcon = useRef(new Animated.Value(0)).current;

  const rotateIconInterpolation = rotateIcon.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg']
  });

  const toggleMenuState = useCallback((open: boolean) => {
    const opacityToValue = open ? 1 : 0;
    const rotateToValue = open && props.rotateIcon ? 1 : 0;

    Animated.parallel([
      Animated.timing(textOpacity, {
        toValue: opacityToValue,
        duration: props.animationDuration,
        useNativeDriver: false,
      }),
      Animated.timing(rotateIcon, {
        toValue: rotateToValue,
        duration: props.animationDuration,
        useNativeDriver: false,
      }),
    ]).start();
  }, [props.animationDuration, props.rotateIcon, rotateIcon, textOpacity]);

  useEffect(() => {
    toggleMenuState(props.open);
  }, [toggleMenuState, props.open]);

  return (
    <Tooltip label={props.tooltip ?? props.text} placement="right" openDelay={20} isDisabled={props.open || props.tooltipDisabled}>
      <Pressable {...props} rounded="xl" onPress={props.onPress} variant="menu" flexDirection="row" height="10" alignItems="center" justifyContent="flex-start" pl={`${props.paddingLeft}px`} py={1} {...props.triggerProps}>
        <Animated.View style={{
          transform: [{
            rotate: rotateIconInterpolation
          }]
        }}
        >
          {props.icon ?? <Icon name={props.iconName} color={props.iconColor ?? 'dark.700'} />}
        </Animated.View>
        <Animated.View style={{
          opacity: textOpacity,
          width: 200,
          height: 40
        }}
        >
          <Text position="absolute" lineBreakMode="head" numberOfLines={1} ellipsizeMode="tail" fontWeight="medium" width="100" marginTop="9px" marginLeft="12px">
            {props.text}
          </Text>
        </Animated.View>
      </Pressable>
    </Tooltip>
  );
}
