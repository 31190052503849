import { MaterialIcons } from '@expo/vector-icons';
import {
  Box, HStack, Icon, Menu, Pressable, Skeleton, Text
} from 'native-base';
import { useCallback } from 'react';
import { UserViewModel } from '@/adapters/view-models/UserViewModel';
import { CustomMenuItem } from '../menu/CustomMenuItem';
import { ParticipantAvatar } from '../ParticipantAvatars';
import { useShortcutsModalStore } from '../modals/ShortcutsModal';

export type AccountAvatarButtonProps = {
  user?: UserViewModel;
  isLoadingSelfUser: boolean;
  logoutCallback: () => void;
};

export function AccountAvatarButton(props: AccountAvatarButtonProps) {
  const shortcutsModal = useShortcutsModalStore();

  const trigger = useCallback((triggerProps: any) => (
    <Pressable {...triggerProps} alignSelf="center" _pressed={{ borderColor: 'transparent' }}>
      <AccountInfoContainer isLoadingSelfUser={props.isLoadingSelfUser} user={props.user} logoutCallback={props.logoutCallback} />
    </Pressable>
  ), [props.isLoadingSelfUser, props.logoutCallback, props.user]);

  return (
    <Box flexGrow={0} w={240} flexShrink={0} flexDir="row-reverse" mr={4}>
      <Menu
        shouldOverlapWithTrigger={false}
        placement="bottom"
        alignSelf="center"
        m={2}
        trigger={trigger}
      >
        <CustomMenuItem onPress={() => shortcutsModal.setOpen(true)}><Text>Shortcuts</Text></CustomMenuItem>
        <CustomMenuItem onPress={props.logoutCallback}><Text>Log out</Text></CustomMenuItem>
      </Menu>
    </Box>
  );
}

function AccountInfo(props: Required<Omit<AccountAvatarButtonProps, 'logoutCallback'>>) {
  return (
    <HStack alignItems="flex-end" flexGrow={0} alignSelf="center">
      <ParticipantAvatar pictureInfo={props.user.pictureInfo} size="sm" />
      <Box bgColor="tertiary.500" w={3} h={3} position="absolute" borderRadius="full" bottom={-1} right={-3} borderColor="gray.100" borderWidth={1} />
    </HStack>
  );
}

export function AccountInfoContainer(props: AccountAvatarButtonProps) {
  if (props.isLoadingSelfUser) {
    return <Skeleton borderRadius="full" size="7" startColor="menu.250" />;
  }

  if (!props.user) {
    return <Icon as={MaterialIcons} name="error-outline" size="lg" mx={1} alignSelf="center" color="dark.500" />;
  }

  return (
    <AccountInfo
      user={props.user!}
      isLoadingSelfUser={props.isLoadingSelfUser}
    />
  );
}
