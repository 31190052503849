import {
  Box, Divider, HStack, Spinner, VStack
} from 'native-base';
import { RefObject, useCallback, useMemo } from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { Status } from '@/adapters/api/codegen';
import { ComposeArea, ComposeAreaHandles, ComposeAreaSubmitCallback } from '../../compose';
import { MessagesListItem, ReactionSelectedCallback } from './ThreadMessageItem';
import { ComprehensiveMessageViewModel, createComprehensiveSendingMessageViewModel } from '@/adapters/view-models/MessageViewModel';
import { ComprehensiveThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { EmptyScreenSection } from '../../EmptyScreenSection';
import { ThreadScreenParticipantsHeaderController } from '../../../controllers/headers/ThreadScreenParticipantsHeaderController';
import { ThreadOptionsSideMenu } from '../../thread-menu/ThreadOptionsSideMenu';
import { ThreadScreenHeaderDescription } from './ThreadScreenHeaderDescription';
import { useCurrentThread } from '../../../controllers/hooks/routes/useCurrentThread';
import { ThreadScreenHeaderTitle } from './ThreadScreenHeaderTitle';
import { useSendingByThread } from '@/domain/state/drafts';
import { useGetSelfUser } from '../../../controllers/hooks/api/useGetSelfUser';
import { ThreadMessagesList } from './ThreadMessagesList';
import { useGetSelfOrganizations } from '@/infrastructure/controllers/hooks/api/useGetSelfOrganizations';
import { useGetSelfOrganizationMembers } from '@/infrastructure/controllers/hooks/api/useGetSelfOrganizationMembers';
import { DropZone } from '../../DropZone';

type ThreadScreenProps = {
  messages?: ComprehensiveMessageViewModel[];
  showSpinner: boolean;
  errorToDisplay?: string;
  submitCallback: ComposeAreaSubmitCallback;
  reactionSelectedCallback?: ReactionSelectedCallback;
  participants: ParticipantViewModel[] | null;
  setParticipants: (participants: ParticipantViewModel[]) => void;
  thread: ComprehensiveThreadViewModel;
  setThreadStatusCallback: (threadId: string, status: Status) => void;
  updateThreadDueDateCallback: (threadId: string, dueDate: Date | null) => void;
  updateThreadAssigneeCallback: (threadId: string, assignee: string | null) => void;
  didSubmitNewChannelsCallback: (participants: ParticipantViewModel[]) => void;
  didDeleteChannelsCallback: (participants: ParticipantViewModel[]) => void;
  scheduledMessageCancelledCallback: (messageId: string) => void;
  draftKey: string;
  composeAreaRef: RefObject<ComposeAreaHandles>;
};

export function ThreadScreenSection(props: ThreadScreenProps) {
  const { thread, messages } = props;
  const { currentData: user } = useGetSelfUser();
  const { isLoading } = useCurrentThread();
  const isLoadingForFirstTime = isLoading && !messages;
  const sendingMessages = useSendingByThread(thread.id);
  const { organizationId } = useGetSelfOrganizations();
  const { currentData: members } = useGetSelfOrganizationMembers(organizationId);

  const list: MessagesListItem[] = useMemo(() => {
    if (!props.messages || !user) {
      return [];
    }
    const messages = [
      ...(sendingMessages || []).map((message) => createComprehensiveSendingMessageViewModel(message, user.rawUser, members)),
      ...props.messages
    ];
    messages.sort((a: MessagesListItem, b: MessagesListItem): number => {
      return b.rawDate - a.rawDate;
    });

    return messages;
  }, [props.messages, sendingMessages, user, members]);

  const attachFilesCallback = useCallback((files: File[]) => {
    props.composeAreaRef.current?.attachFilesCallback(files);
  }, [props.composeAreaRef]);

  return (
    <Box flex={1} minHeight={300} borderTopWidth={0} borderTopColor="transparent" mt={-3}>
      <VStack>
        <HStack justifyContent="space-between" pl={3} mb={0} space={5}>
          <ThreadScreenHeaderTitle thread={thread} showLoader={isLoadingForFirstTime} />
          <ThreadScreenParticipantsHeaderController />
        </HStack>
        <Box px={3}>
          <ThreadScreenHeaderDescription
            threadId={thread.id}
            description={thread.description ?? ''}
            showLoader={isLoadingForFirstTime}
            isDisabled={thread.isTrashed}
          />
        </Box>
      </VStack>
      {props.showSpinner ? <Spinner size="lg" flexGrow={1} /> : <Divider display="flex" />}
      {!props.showSpinner && props.errorToDisplay && !props.messages
        ? (
          <EmptyScreenSection
            text={props.errorToDisplay}
            iconCollection={MaterialIcons}
            iconName="error-outline"
          />
        ) : null}
      {!props.showSpinner && props.messages ? (
        <HStack flexGrow={1} flexShrink={1}>
          <ThreadOptionsSideMenu
            thread={props.thread}
            setThreadStatusCallback={props.setThreadStatusCallback}
            updateThreadDueDateCallback={props.updateThreadDueDateCallback}
            updateThreadAssigneeCallback={props.updateThreadAssigneeCallback}
          />
          <VStack flexGrow={1} flexShrink={1} px={2} pl={0}>
            <DropZone attachFilesCallback={attachFilesCallback} flexGrow={1} flexShrink={1} underlyingDivStyle={{ paddingBottom: 8, paddingTop: 4 }}>
              <ThreadMessagesList
                messages={list}
                thread={thread}
                composeAreaRef={props.composeAreaRef}
                reactionSelectedCallback={!props.thread.isTrashed ? props.reactionSelectedCallback : undefined}
                scheduledMessageCancelledCallback={props.scheduledMessageCancelledCallback}
              />
              <ComposeArea
                readOnly={props.thread.isTrashed}
                ref={props.composeAreaRef}
                bodyPlaceholder={!props.thread.isTrashed ? 'Reply to this thread' : 'Take this thread out of the trash to reply'}
                submitCallback={props.submitCallback}
                showTitle={false}
                participants={props.participants}
                participantsChangedCallback={props.setParticipants}
                draftKey={props.draftKey}
              />
            </DropZone>
          </VStack>
        </HStack>
      ) : null}
    </Box>
  );
}
