import { Tooltip, TooltipProps } from '@/infrastructure/ui/components/Tooltip';
import { getTooltipLabelWithShortcut } from '@/infrastructure/ui/shortcuts/getTooltipLabelWithShortcut';

type ShortcutTooltipProps = Omit<TooltipProps, 'label'> & {
  shortcut?: string;
  hint?: string;
};

export function ShortcutTooltip(props: ShortcutTooltipProps) {
  const {
    children, hint, shortcut, ...rest
  } = props;

  if (!hint && !shortcut) {
    return children;
  }

  return (
    <Tooltip {...rest} label={getTooltipLabelWithShortcut(hint, shortcut)}>
      {children}
    </Tooltip>
  );
}
