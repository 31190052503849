import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import {
  UntrashThreadApiArg,
  UntrashThreadApiResponse
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps, PatchWrapper } from './MutationHandler';
import { ThreadViewModel, setThreadViewModelInboxItemId } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { ThreadsViewModel } from '../view-models/ThreadsViewModel';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';
import { createTrashedThreadsPatch } from './thread-patch-factory/factories/createTrashedThreadsPatch';
import { createInboxItemViewModelFromThreadAddedToInbox } from '../view-models/InboxItemViewModel';
import { createAddThreadToInboxPatches } from './thread-patch-factory/factories/createAddThreadToInboxPatches';
import { TEMPORARY_ID_PENDING_REFRESH } from './model-factories/mutation-constants';

export class UntrashThreadMutationHandler extends MutationHandler<UntrashThreadApiArg, UntrashThreadApiResponse> {
  constructor(props: MutationHandlerProps<UntrashThreadApiArg, UntrashThreadApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: UntrashThreadApiArg) {
    return [
      this.createRemoveThreadFromTrashPatch(patch),
      ...this.createAddToInboxPatches(patch),
      ...this.createAddThreadToOtherFoldersPatches(patch),
      ...createThreadUpdatePatchesForThreadId(patch.threadId, this.updateThread),
    ];
  }

  protected createRequestCompletedPatchWrappers(patch: UntrashThreadApiArg, data: UntrashThreadApiResponse): PatchWrapper<any>[] {
    return [
      ...createThreadUpdatePatchesForThreadId(patch.threadId, (draftedThread: ThreadViewModel) => {
        setThreadViewModelInboxItemId(draftedThread, data.inbox_event_id);
      })
    ];
  }
  private createRemoveThreadFromTrashPatch(patch: UntrashThreadApiArg) {
    return createTrashedThreadsPatch((draftedThreads) => {
      const itemIndex = draftedThreads.findIndex((item) => item.id === patch.threadId);

      if (itemIndex !== -1) {
        draftedThreads.splice(itemIndex, 1);
      }
    });
  }

  private* createAddToInboxPatches(patch: UntrashThreadApiArg) {
    const thread = this.getThreadInCurrentContext(patch.threadId);

    if (thread) {
      this.updateThread(thread);
      setThreadViewModelInboxItemId(thread, TEMPORARY_ID_PENDING_REFRESH);
      return yield* createAddThreadToInboxPatches(createInboxItemViewModelFromThreadAddedToInbox(thread));
    }
  }

  private createAddThreadToOtherFoldersPatches(patch: UntrashThreadApiArg) {
    const thread = this.getThreadInCurrentContext(patch.threadId);

    if (!thread) {
      return [];
    }

    this.updateThread(thread);

    return thread.channelIds.reduce((patches, channelId) => {
      patches.push(this.updateQueryData('getChannelThreads', { channelId }, (draftedThreads) => {
        const insertIndex = findIndexToInsertThread(draftedThreads);
        removeExistingThread(draftedThreads);
        draftedThreads.threads.splice(insertIndex, 0, thread);

        if (draftedThreads.threads.length > 25) {
          draftedThreads.threads.pop();
        }
      }));

      return patches;
    }, [] as PatchWrapper<any>[]);

    function findIndexToInsertThread(draftedThreads: MaybeDrafted<ThreadsViewModel>) {
      const dates = draftedThreads.threads
        .map((item) => new Date(item.lastActivity.date));

      const curentThreadLastActivityDate = thread ? new Date(thread.lastActivity.date) : new Date();
      const insertIndex = dates.findIndex((date) => date.getTime() < curentThreadLastActivityDate.getTime());
      const actualInsertIndex = insertIndex === -1 ? draftedThreads.threads.length : insertIndex;
      return actualInsertIndex;
    }

    function removeExistingThread(draftedThreads: MaybeDrafted<ThreadsViewModel>) {
      const existingThreadIndex = draftedThreads.threads.findIndex((item) => item.id === patch.threadId);
      if (existingThreadIndex !== -1) {
        draftedThreads.threads.splice(existingThreadIndex, 1);
      }
    }
  }

  private updateThread(thread: ThreadViewModel) {
    thread.isTrashed = false;
  }

  protected generateInvalidationTags(arg: UntrashThreadApiArg) {
    return [
      {
        type: 'Thread' as const,
        id: arg.threadId,
        schedule: {
          delayMs: 10000,
          uniqueKey: `ChannelThreadListManagement-${getCurrentChannelParams()}`,
        }
      },
      {
        type: 'SelfAccount' as const,
        schedule: {
          delayMs: 5000,
          uniqueKey: 'InboxUnreadThreadCount',
        }
      },
      {
        type: 'SelfTrashedThreadsList' as const,
      }
    ];
  }
}
