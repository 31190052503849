import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { Toast } from 'native-base';
import { createStandardErrors } from '@/adapters/other/createStandardError';

export const rtkQueryErrorLogger: Middleware = (_api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const isMutation = (action.meta?.arg as any)?.type === 'mutation';
    if (isMutation) {
      const error = createStandardErrors(action.payload as any)[0];
      Toast.show({
        title: error.displayMessage,
      });
    }
  }
  return next(action);
};
