import { emptySplitApi as api } from './emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    generateAttachmentPresignedUrl: build.mutation<
    GenerateAttachmentPresignedUrlApiResponse,
    GenerateAttachmentPresignedUrlApiArg
    >({
      query: (queryArg) => ({
        url: '/staged_attachments',
        method: 'POST',
        body: queryArg.stagedAttachmentDataRequestParameter,
      }),
    }),
    refreshAttachmentPresignedUrl: build.mutation<
    RefreshAttachmentPresignedUrlApiResponse,
    RefreshAttachmentPresignedUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/staged_attachments/${queryArg.stagedAttachmentId}/refresh`,
        method: 'PUT',
      }),
    }),
    deleteStagedAttachment: build.mutation<
    DeleteStagedAttachmentApiResponse,
    DeleteStagedAttachmentApiArg
    >({
      query: (queryArg) => ({
        url: `/staged_attachments/${queryArg.stagedAttachmentId}`,
        method: 'DELETE',
      }),
    }),
    getChannelThreads: build.query<
    GetChannelThreadsApiResponse,
    GetChannelThreadsApiArg
    >({
      query: (queryArg) => ({
        url: `/channels/${queryArg.channelId}/threads`,
        params: { limit: queryArg.limit, page_token: queryArg.pageToken },
      }),
    }),
    getChannel: build.query<GetChannelApiResponse, GetChannelApiArg>({
      query: (queryArg) => ({ url: `/channels/${queryArg.channelId}` }),
    }),
    deleteChannel: build.mutation<
    DeleteChannelApiResponse,
    DeleteChannelApiArg
    >({
      query: (queryArg) => ({
        url: `/channels/${queryArg.channelId}`,
        method: 'DELETE',
      }),
    }),
    updateChannel: build.mutation<
    UpdateChannelApiResponse,
    UpdateChannelApiArg
    >({
      query: (queryArg) => ({
        url: `/channels/${queryArg.channelId}`,
        method: 'PUT',
        body: queryArg.channelUpdateRequestBody,
      }),
    }),
    getChannelParticipants: build.query<
    GetChannelParticipantsApiResponse,
    GetChannelParticipantsApiArg
    >({
      query: (queryArg) => ({
        url: `/channels/${queryArg.channelId}/participants`,
      }),
    }),
    addChannelParticipants: build.mutation<
    AddChannelParticipantsApiResponse,
    AddChannelParticipantsApiArg
    >({
      query: (queryArg) => ({
        url: `/channels/${queryArg.channelId}/participants:add`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    removeChannelParticipants: build.mutation<
    RemoveChannelParticipantsApiResponse,
    RemoveChannelParticipantsApiArg
    >({
      query: (queryArg) => ({
        url: `/channels/${queryArg.channelId}/participants:remove`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    updateChannelNotificationLevel: build.mutation<
    UpdateChannelNotificationLevelApiResponse,
    UpdateChannelNotificationLevelApiArg
    >({
      query: (queryArg) => ({
        url: `/channels/${queryArg.channelId}/notification_level`,
        method: 'PATCH',
        body: queryArg.channelNotificationLevelUpdate,
      }),
    }),
    processGmailWatchNotification: build.mutation<
    ProcessGmailWatchNotificationApiResponse,
    ProcessGmailWatchNotificationApiArg
    >({
      query: (queryArg) => ({
        url: '/gmail-watch-notifications',
        method: 'POST',
        body: queryArg.gmailPushNotificationPostRequestBody,
      }),
    }),
    markEventAsRead: build.mutation<
    MarkEventAsReadApiResponse,
    MarkEventAsReadApiArg
    >({
      query: (queryArg) => ({
        url: `/events/${queryArg.inboxItemId}/mark_as_read`,
        method: 'PATCH',
      }),
    }),
    removeEventFromInbox: build.mutation<
    RemoveEventFromInboxApiResponse,
    RemoveEventFromInboxApiArg
    >({
      query: (queryArg) => ({
        url: `/events/${queryArg.inboxItemId}/remove_from_inbox`,
        method: 'PATCH',
      }),
    }),
    addMessageReaction: build.mutation<
    AddMessageReactionApiResponse,
    AddMessageReactionApiArg
    >({
      query: (queryArg) => ({
        url: `/messages/${queryArg.messageId}/add_reaction`,
        method: 'PATCH',
        body: queryArg.reactionRequestBody,
      }),
    }),
    removeMessageReaction: build.mutation<
    RemoveMessageReactionApiResponse,
    RemoveMessageReactionApiArg
    >({
      query: (queryArg) => ({
        url: `/messages/${queryArg.messageId}/remove_reaction`,
        method: 'PATCH',
        body: queryArg.reactionRequestBody,
      }),
    }),
    markMessagesAsRead: build.mutation<
    MarkMessagesAsReadApiResponse,
    MarkMessagesAsReadApiArg
    >({
      query: (queryArg) => ({
        url: '/messages:mark_as_read',
        method: 'PATCH',
        body: queryArg.messageIdsRequestBody,
      }),
    }),
    markMessagesAsUnread: build.mutation<
    MarkMessagesAsUnreadApiResponse,
    MarkMessagesAsUnreadApiArg
    >({
      query: (queryArg) => ({
        url: '/messages:mark_as_unread',
        method: 'PATCH',
        body: queryArg.messageIdsRequestBody,
      }),
    }),
    deleteMessage: build.mutation<
    DeleteMessageApiResponse,
    DeleteMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/messages/${queryArg.messageId}`,
        method: 'DELETE',
      }),
    }),
    sendMessageNow: build.mutation<
    SendMessageNowApiResponse,
    SendMessageNowApiArg
    >({
      query: (queryArg) => ({
        url: `/messages/${queryArg.messageId}/send_now`,
        method: 'PATCH',
      }),
    }),
    getOrganizationChannels: build.query<
    GetOrganizationChannelsApiResponse,
    GetOrganizationChannelsApiArg
    >({
      query: (queryArg) => ({
        url: `/organizations/${queryArg.organizationId}/channels`,
      }),
    }),
    createChannel: build.mutation<
    CreateChannelApiResponse,
    CreateChannelApiArg
    >({
      query: (queryArg) => ({
        url: `/organizations/${queryArg.organizationId}/channels`,
        method: 'POST',
        body: queryArg.channelCreateRequestBody,
      }),
    }),
    getOrganizationMembers: build.query<
    GetOrganizationMembersApiResponse,
    GetOrganizationMembersApiArg
    >({
      query: (queryArg) => ({
        url: `/organizations/${queryArg.organizationId}/members`,
      }),
    }),
    createOrganization: build.mutation<
    CreateOrganizationApiResponse,
    CreateOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: '/organizations',
        method: 'POST',
        body: queryArg.organizationCreateRequestBody,
      }),
    }),
    getSelfUser: build.query<GetSelfUserApiResponse, GetSelfUserApiArg>({
      query: () => ({ url: '/self' }),
    }),
    getSelfAccount: build.query<
    GetSelfAccountApiResponse,
    GetSelfAccountApiArg
    >({
      query: () => ({ url: '/self/account' }),
    }),
    getSelfChannels: build.query<
    GetSelfChannelsApiResponse,
    GetSelfChannelsApiArg
    >({
      query: () => ({ url: '/self/channels' }),
    }),
    getSelfOrganizations: build.query<
    GetSelfOrganizationsApiResponse,
    GetSelfOrganizationsApiArg
    >({
      query: () => ({ url: '/self/organizations' }),
    }),
    getSelfInboxEvents: build.query<
    GetSelfInboxEventsApiResponse,
    GetSelfInboxEventsApiArg
    >({
      query: (queryArg) => ({
        url: '/self/inbox_events',
        params: {
          limit: queryArg.limit,
          page_token: queryArg.pageToken,
          category: queryArg.category,
        },
      }),
    }),
    getSelfSentThreads: build.query<
    GetSelfSentThreadsApiResponse,
    GetSelfSentThreadsApiArg
    >({
      query: (queryArg) => ({
        url: '/self/sent_threads',
        params: { limit: queryArg.limit, page_token: queryArg.pageToken },
      }),
    }),
    getSelfTrashedThreads: build.query<
    GetSelfTrashedThreadsApiResponse,
    GetSelfTrashedThreadsApiArg
    >({
      query: (queryArg) => ({
        url: '/self/trashed_threads',
        params: { limit: queryArg.limit, page_token: queryArg.pageToken },
      }),
    }),
    getSelfSnoozedThreads: build.query<
    GetSelfSnoozedThreadsApiResponse,
    GetSelfSnoozedThreadsApiArg
    >({
      query: (queryArg) => ({
        url: '/self/snoozed_threads',
        params: { limit: queryArg.limit, page_token: queryArg.pageToken },
      }),
    }),
    getSelfSpamThreads: build.query<
    GetSelfSpamThreadsApiResponse,
    GetSelfSpamThreadsApiArg
    >({
      query: (queryArg) => ({
        url: '/self/spam_threads',
        params: { limit: queryArg.limit, page_token: queryArg.pageToken },
      }),
    }),
    getSelfStarredThreads: build.query<
    GetSelfStarredThreadsApiResponse,
    GetSelfStarredThreadsApiArg
    >({
      query: (queryArg) => ({
        url: '/self/starred_threads',
        params: { limit: queryArg.limit, page_token: queryArg.pageToken },
      }),
    }),
    getSelfScheduledThreads: build.query<
    GetSelfScheduledThreadsApiResponse,
    GetSelfScheduledThreadsApiArg
    >({
      query: (queryArg) => ({
        url: '/self/scheduled_threads',
        params: { limit: queryArg.limit, page_token: queryArg.pageToken },
      }),
    }),
    getSelfFilteredThreads: build.query<
    GetSelfFilteredThreadsApiResponse,
    GetSelfFilteredThreadsApiArg
    >({
      query: (queryArg) => ({
        url: '/self/filtered_threads',
        params: {
          thread_ids: queryArg.threadIds,
          query: queryArg.query,
          limit: queryArg.limit,
          page_token: queryArg.pageToken,
        },
      }),
    }),
    getSelfAllThreads: build.query<
    GetSelfAllThreadsApiResponse,
    GetSelfAllThreadsApiArg
    >({
      query: (queryArg) => ({
        url: '/self/all_threads',
        params: { limit: queryArg.limit, page_token: queryArg.pageToken },
      }),
    }),
    getSelfContacts: build.query<
    GetSelfContactsApiResponse,
    GetSelfContactsApiArg
    >({
      query: () => ({ url: '/self/contacts' }),
    }),
    getThread: build.query<GetThreadApiResponse, GetThreadApiArg>({
      query: (queryArg) => ({ url: `/threads/${queryArg.threadId}` }),
    }),
    updateThread: build.mutation<UpdateThreadApiResponse, UpdateThreadApiArg>({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}`,
        method: 'PUT',
        body: queryArg.threadUpdateRequestBody,
      }),
    }),
    getThreadMessages: build.query<
    GetThreadMessagesApiResponse,
    GetThreadMessagesApiArg
    >({
      query: (queryArg) => ({ url: `/threads/${queryArg.threadId}/messages` }),
    }),
    postMessage: build.mutation<PostMessageApiResponse, PostMessageApiArg>({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/messages`,
        method: 'POST',
        body: queryArg.messageCreateRequestBody,
      }),
    }),
    addThreadParticipants: build.mutation<
    AddThreadParticipantsApiResponse,
    AddThreadParticipantsApiArg
    >({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/add_participants`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    removeThreadParticipants: build.mutation<
    RemoveThreadParticipantsApiResponse,
    RemoveThreadParticipantsApiArg
    >({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/remove_participants`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    addThreadToInbox: build.mutation<
    AddThreadToInboxApiResponse,
    AddThreadToInboxApiArg
    >({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/add_to_inbox`,
        method: 'PATCH',
      }),
    }),
    deleteThreads: build.mutation<
    DeleteThreadsApiResponse,
    DeleteThreadsApiArg
    >({
      query: (queryArg) => ({
        url: '/threads:delete',
        method: 'PATCH',
        body: queryArg.threadIdsRequestBody,
      }),
    }),
    starThreads: build.mutation<StarThreadsApiResponse, StarThreadsApiArg>({
      query: (queryArg) => ({
        url: '/threads:star',
        method: 'PATCH',
        body: queryArg.threadIdsRequestBody,
      }),
    }),
    unstarThreads: build.mutation<
    UnstarThreadsApiResponse,
    UnstarThreadsApiArg
    >({
      query: (queryArg) => ({
        url: '/threads:unstar',
        method: 'PATCH',
        body: queryArg.threadIdsRequestBody,
      }),
    }),
    snoozeThread: build.mutation<SnoozeThreadApiResponse, SnoozeThreadApiArg>({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/snooze`,
        method: 'PATCH',
        body: queryArg.snoozeThreadRequestBody,
      }),
    }),
    markAllThreadMessagesAsRead: build.mutation<
    MarkAllThreadMessagesAsReadApiResponse,
    MarkAllThreadMessagesAsReadApiArg
    >({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/mark_all_messages_as_read`,
        method: 'PATCH',
      }),
    }),
    markThreadAsSpam: build.mutation<
    MarkThreadAsSpamApiResponse,
    MarkThreadAsSpamApiArg
    >({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/mark_as_spam`,
        method: 'PATCH',
      }),
    }),
    markThreadAsNotSpam: build.mutation<
    MarkThreadAsNotSpamApiResponse,
    MarkThreadAsNotSpamApiArg
    >({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/mark_as_not_spam`,
        method: 'PATCH',
      }),
    }),
    trashThread: build.mutation<TrashThreadApiResponse, TrashThreadApiArg>({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/trash`,
        method: 'PATCH',
      }),
    }),
    untrashThread: build.mutation<
    UntrashThreadApiResponse,
    UntrashThreadApiArg
    >({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/untrash`,
        method: 'PATCH',
      }),
    }),
    addThreadChannels: build.mutation<
    AddThreadChannelsApiResponse,
    AddThreadChannelsApiArg
    >({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/add_channels`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    removeThreadChannels: build.mutation<
    RemoveThreadChannelsApiResponse,
    RemoveThreadChannelsApiArg
    >({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/remove_channels`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    createThread: build.mutation<CreateThreadApiResponse, CreateThreadApiArg>({
      query: (queryArg) => ({
        url: '/threads',
        method: 'POST',
        body: queryArg.threadCreateRequestBody,
      }),
    }),
    sendThreadNow: build.mutation<
    SendThreadNowApiResponse,
    SendThreadNowApiArg
    >({
      query: (queryArg) => ({
        url: `/threads/${queryArg.threadId}/send_now`,
        method: 'PATCH',
      }),
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({
        url: '/users',
        method: 'POST',
        body: queryArg.userCreateRequestBody,
      }),
    }),
    createProviderConnection: build.mutation<
    CreateProviderConnectionApiResponse,
    CreateProviderConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/provider_connections`,
        method: 'POST',
        body: queryArg.providerConnectionCreateRequestBody,
      }),
    }),
    cancelCommand: build.mutation<
    CancelCommandApiResponse,
    CancelCommandApiArg
    >({
      query: (queryArg) => ({
        url: '/command:cancel',
        method: 'POST',
        body: queryArg.cancelRequestBody,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as rawApiClient };
export type GenerateAttachmentPresignedUrlApiResponse =
  /** status 201 Newly generated attachment presigned URL */ AttachmentUploadPresignedUrl;
export type GenerateAttachmentPresignedUrlApiArg = {
  stagedAttachmentDataRequestParameter: StagedAttachmentDataRequestParameter;
};
export type RefreshAttachmentPresignedUrlApiResponse = /** status 200  */
  | AttachmentUploadPresignedUrl
  | /** status 201 Refreshed attachment presigned URL */ AttachmentUploadPresignedUrl;
export type RefreshAttachmentPresignedUrlApiArg = {
  /** The ID of the staged attachment */
  stagedAttachmentId: string;
};
export type DeleteStagedAttachmentApiResponse =
  /** status 204 Staged attachment deleted */ void;
export type DeleteStagedAttachmentApiArg = {
  /** The ID of the staged attachment */
  stagedAttachmentId: string;
};
export type GetChannelThreadsApiResponse =
  /** status 200 Threads in the channel (including pagination data), ordered by last activity. */ Threads;
export type GetChannelThreadsApiArg = {
  /** Maximum number of items to return */
  limit?: number;
  /** Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`) */
  pageToken?: string;
  /** The ID of the channel */
  channelId: string;
};
export type GetChannelApiResponse = /** status 200 Channel data. */ Channel;
export type GetChannelApiArg = {
  /** The ID of the channel */
  channelId: string;
};
export type DeleteChannelApiResponse = /** status 204 Channel deleted. */ void;
export type DeleteChannelApiArg = {
  /** The ID of the channel */
  channelId: string;
};
export type UpdateChannelApiResponse =
  /** status 200 Updated channel data. */ Channel;
export type UpdateChannelApiArg = {
  /** The ID of the channel */
  channelId: string;
  channelUpdateRequestBody: ChannelUpdateRequestBody;
};
export type GetChannelParticipantsApiResponse =
  /** status 200 Participants of the channel. */ ConversationParticipant[];
export type GetChannelParticipantsApiArg = {
  /** The ID of the channel */
  channelId: string;
};
export type AddChannelParticipantsApiResponse =
  /** status 200 Updated list of participants of the channel. */ ConversationParticipant[];
export type AddChannelParticipantsApiArg = {
  /** The ID of the channel */
  channelId: string;
  /** IDs of users to add to the channel as participants. */
  body: string[];
};
export type RemoveChannelParticipantsApiResponse =
  /** status 200 Updated list of participants of the channel. */ ConversationParticipant[];
export type RemoveChannelParticipantsApiArg = {
  /** The ID of the channel */
  channelId: string;
  /** IDs of users to remove from the channel as participants. */
  body: string[];
};
export type UpdateChannelNotificationLevelApiResponse =
  /** status 204 Channel notification level updated. */ void;
export type UpdateChannelNotificationLevelApiArg = {
  /** The ID of the channel */
  channelId: string;
  channelNotificationLevelUpdate: ChannelNotificationLevelUpdate;
};
export type ProcessGmailWatchNotificationApiResponse =
  /** status 200 Gmail watch notification processed successfully */ void;
export type ProcessGmailWatchNotificationApiArg = {
  gmailPushNotificationPostRequestBody: GmailPushNotificationPostRequestBody;
};
export type MarkEventAsReadApiResponse =
  /** status 204 The inbox item has been marked as read. */ void;
export type MarkEventAsReadApiArg = {
  /** The ID of the inbox item */
  inboxItemId: string;
};
export type RemoveEventFromInboxApiResponse =
  /** status 204 The inbox item has been removed. */ void;
export type RemoveEventFromInboxApiArg = {
  /** The ID of the inbox item */
  inboxItemId: string;
};
export type AddMessageReactionApiResponse =
  /** status 204 Reaction has been added to the message. */ void;
export type AddMessageReactionApiArg = {
  /** The ID of the message */
  messageId: string;
  reactionRequestBody: ReactionRequestBody;
};
export type RemoveMessageReactionApiResponse =
  /** status 204 Reaction has been removed from the message. */ void;
export type RemoveMessageReactionApiArg = {
  /** The ID of the message */
  messageId: string;
  reactionRequestBody: ReactionRequestBody;
};
export type MarkMessagesAsReadApiResponse =
  /** status 204 All messages have been marked as read. */ void;
export type MarkMessagesAsReadApiArg = {
  messageIdsRequestBody: MessageIdsRequestBody;
};
export type MarkMessagesAsUnreadApiResponse =
  /** status 204 All messages have been marked as unread. */ void;
export type MarkMessagesAsUnreadApiArg = {
  messageIdsRequestBody: MessageIdsRequestBody;
};
export type DeleteMessageApiResponse = unknown;
export type DeleteMessageApiArg = {
  /** The ID of the message */
  messageId: string;
};
export type SendMessageNowApiResponse = unknown;
export type SendMessageNowApiArg = {
  /** The ID of the message */
  messageId: string;
};
export type GetOrganizationChannelsApiResponse =
  /** status 200 Channels of the organization. */ Channel[];
export type GetOrganizationChannelsApiArg = {
  organizationId: string;
};
export type CreateChannelApiResponse =
  /** status 201 Newly created channel. */ Channel;
export type CreateChannelApiArg = {
  organizationId: string;
  channelCreateRequestBody: ChannelCreateRequestBody;
};
export type GetOrganizationMembersApiResponse =
  /** status 200 Members of the organization. */ User[];
export type GetOrganizationMembersApiArg = {
  organizationId: string;
};
export type CreateOrganizationApiResponse =
  /** status 201 Newly created organization. */ Organization;
export type CreateOrganizationApiArg = {
  organizationCreateRequestBody: OrganizationCreateRequestBody;
};
export type GetSelfUserApiResponse =
  /** status 200 Information about the authenticated user. */ User;
export type GetSelfUserApiArg = void;
export type GetSelfAccountApiResponse =
  /** status 200 General data for the authenticated user. */ Account;
export type GetSelfAccountApiArg = void;
export type GetSelfChannelsApiResponse =
  /** status 200 Channels in which the authenticated user is a member. */ Channel[];
export type GetSelfChannelsApiArg = void;
export type GetSelfOrganizationsApiResponse =
  /** status 200 Organizations in which the authenticated user is a member. */ Organization[];
export type GetSelfOrganizationsApiArg = void;
export type GetSelfInboxEventsApiResponse =
  /** status 200 Inbox items for the authenticated user. */ InboxItems;
export type GetSelfInboxEventsApiArg = {
  /** Maximum number of items to return */
  limit?: number;
  /** Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`) */
  pageToken?: string;
  /** The category of the inbox items to retrieve. */
  category?:
  | 'CATEGORY_PRIMARY'
  | 'CATEGORY_PERSONAL'
  | 'CATEGORY_PROMOTIONS'
  | 'CATEGORY_SOCIAL'
  | 'CATEGORY_UPDATES'
  | 'CATEGORY_FORUMS';
};
export type GetSelfSentThreadsApiResponse =
  /** status 200 Threads for Sent folder. */ Threads;
export type GetSelfSentThreadsApiArg = {
  /** Maximum number of items to return */
  limit?: number;
  /** Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`) */
  pageToken?: string;
};
export type GetSelfTrashedThreadsApiResponse =
  /** status 200 Threads for Trashed folder. */ Threads;
export type GetSelfTrashedThreadsApiArg = {
  /** Maximum number of items to return */
  limit?: number;
  /** Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`) */
  pageToken?: string;
};
export type GetSelfSnoozedThreadsApiResponse =
  /** status 200 Threads for Snoozed folder. */ Threads;
export type GetSelfSnoozedThreadsApiArg = {
  /** Maximum number of items to return */
  limit?: number;
  /** Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`) */
  pageToken?: string;
};
export type GetSelfSpamThreadsApiResponse =
  /** status 200 Threads for Spam folder. */ Threads;
export type GetSelfSpamThreadsApiArg = {
  /** Maximum number of items to return */
  limit?: number;
  /** Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`) */
  pageToken?: string;
};
export type GetSelfStarredThreadsApiResponse =
  /** status 200 Threads for Starred folder. */ Threads;
export type GetSelfStarredThreadsApiArg = {
  /** Maximum number of items to return */
  limit?: number;
  /** Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`) */
  pageToken?: string;
};
export type GetSelfScheduledThreadsApiResponse =
  /** status 200 Threads for Scheduled folder. */ Threads;
export type GetSelfScheduledThreadsApiArg = {
  /** Maximum number of items to return */
  limit?: number;
  /** Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`) */
  pageToken?: string;
};
export type GetSelfFilteredThreadsApiResponse =
  /** status 200 Threads for the authenticated user, filtered by a query. */ Threads;
export type GetSelfFilteredThreadsApiArg = {
  /** IDs of the threads to filter by. */
  threadIds?: string[];
  /** Search query */
  query?: string;
  /** Maximum number of items to return */
  limit?: number;
  /** Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`) */
  pageToken?: string;
};
export type GetSelfAllThreadsApiResponse =
  /** status 200 All threads for the authenticated user. */ Threads;
export type GetSelfAllThreadsApiArg = {
  /** Maximum number of items to return */
  limit?: number;
  /** Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`) */
  pageToken?: string;
};
export type GetSelfContactsApiResponse =
  /** status 200 Contacts for the authenticated user. */ ConversationParticipant[];
export type GetSelfContactsApiArg = void;
export type GetThreadApiResponse = /** status 200 Thread data. */ Thread;
export type GetThreadApiArg = {
  /** The ID of the thread */
  threadId: string;
};
export type UpdateThreadApiResponse =
  /** status 200 Updated thread data. */ Thread;
export type UpdateThreadApiArg = {
  /** The ID of the thread */
  threadId: string;
  threadUpdateRequestBody: ThreadUpdateRequestBody;
};
export type GetThreadMessagesApiResponse =
  /** status 200 Messages in the thread, ordered by creation date (oldest first). */ Message[];
export type GetThreadMessagesApiArg = {
  /** The ID of the thread */
  threadId: string;
};
export type PostMessageApiResponse =
  /** status 201 Message has been posted to the thread. */ Message;
export type PostMessageApiArg = {
  /** The ID of the thread */
  threadId: string;
  messageCreateRequestBody: MessageCreateRequestBody;
};
export type AddThreadParticipantsApiResponse =
  /** status 200 Updated thread data, including the updated list of participants. */ Thread;
export type AddThreadParticipantsApiArg = {
  /** The ID of the thread */
  threadId: string;
  /** IDs of users to add to the thread as participants. */
  body: string[];
};
export type RemoveThreadParticipantsApiResponse =
  /** status 200 Updated thread data, including the updated list of participants. */ Thread;
export type RemoveThreadParticipantsApiArg = {
  /** The ID of the thread */
  threadId: string;
  /** IDs of users to remove from the thread as participants. */
  body: string[];
};
export type AddThreadToInboxApiResponse =
  /** status 201 Newly created inbox item. */ InboxItem;
export type AddThreadToInboxApiArg = {
  /** The ID of the thread */
  threadId: string;
};
export type DeleteThreadsApiResponse =
  /** status 204 The threads have been permanently deleted. */ void;
export type DeleteThreadsApiArg = {
  threadIdsRequestBody: ThreadIdsRequestBody;
};
export type StarThreadsApiResponse =
  /** status 204 The threads have been starred. */ void;
export type StarThreadsApiArg = {
  threadIdsRequestBody: ThreadIdsRequestBody;
};
export type UnstarThreadsApiResponse =
  /** status 204 The threads have been unstarred. */ void;
export type UnstarThreadsApiArg = {
  threadIdsRequestBody: ThreadIdsRequestBody;
};
export type SnoozeThreadApiResponse =
  /** status 204 The thread has been snoozed. */ void;
export type SnoozeThreadApiArg = {
  /** The ID of the thread */
  threadId: string;
  snoozeThreadRequestBody: SnoozeThreadRequestBody;
};
export type MarkAllThreadMessagesAsReadApiResponse =
  /** status 204 All messages in the thread have been marked as read. */ void;
export type MarkAllThreadMessagesAsReadApiArg = {
  /** The ID of the thread */
  threadId: string;
};
export type MarkThreadAsSpamApiResponse =
  /** status 204 The thread has been marked as spam. */ void;
export type MarkThreadAsSpamApiArg = {
  /** The ID of the thread */
  threadId: string;
};
export type MarkThreadAsNotSpamApiResponse =
  /** status 201 Newly created inbox item. */ InboxItem;
export type MarkThreadAsNotSpamApiArg = {
  /** The ID of the thread */
  threadId: string;
};
export type TrashThreadApiResponse =
  /** status 200 Updated thread data, including the updated trashed status. */ Thread;
export type TrashThreadApiArg = {
  /** The ID of the thread */
  threadId: string;
};
export type UntrashThreadApiResponse =
  /** status 200 Updated thread data, including the updated trashed status. */ Thread;
export type UntrashThreadApiArg = {
  /** The ID of the thread */
  threadId: string;
};
export type AddThreadChannelsApiResponse =
  /** status 200 Updated thread data, including the updated list of channels. */ Thread;
export type AddThreadChannelsApiArg = {
  /** The ID of the thread */
  threadId: string;
  /** IDs of channels to add to the thread. */
  body: string[];
};
export type RemoveThreadChannelsApiResponse =
  /** status 200 Updated thread data, including the updated list of channels. */ Thread;
export type RemoveThreadChannelsApiArg = {
  /** The ID of the thread */
  threadId: string;
  /** IDs of channels to remove from the thread. */
  body: string[];
};
export type CreateThreadApiResponse =
  /** status 201 Newly created thread. */ Thread;
export type CreateThreadApiArg = {
  threadCreateRequestBody: ThreadCreateRequestBody;
};
export type SendThreadNowApiResponse = unknown;
export type SendThreadNowApiArg = {
  /** The ID of the thread */
  threadId: string;
};
export type CreateUserApiResponse = /** status 201 Created user. */ User;
export type CreateUserApiArg = {
  userCreateRequestBody: UserCreateRequestBody;
};
export type CreateProviderConnectionApiResponse =
  /** status 201 Created provider connection. */ ProviderConnection;
export type CreateProviderConnectionApiArg = {
  userId: string;
  providerConnectionCreateRequestBody: ProviderConnectionCreateRequestBody;
};
export type CancelCommandApiResponse =
  /** status 204 The command has been cancelled. */ void;
export type CancelCommandApiArg = {
  cancelRequestBody: CancelRequestBody;
};
export type AttachmentUploadPresignedUrl = {
  headers: object;
  /** Temporary identifier for the attachment, to be passed when creating the thread or message. */
  staged_attachment_id: string;
  /** The URL to upload the attachment to (sending a PUT request with the attachment file as the request body). */
  upload_url: string;
  /** The URL to download the attachment from. */
  download_url: string;
  /** The expiry date of the presigned URL, after which the download URL is no longer valid.
    The upload URL has a shorter expiry date than the download URL. */
  expiry_date: string;
};
export type StagedAttachmentDataRequestParameter = {
  /** The size of the attachment in bytes, maximum 25MB. */
  file_size: number;
  /** The name of the attachment to be used when downloading it.
    Must be a valid filename without path separators. */
  download_file_name: string;
  /** The MIME type of the attachment. */
  mime_type: string;
};
export type PaginationMeta = {
  /** Page token for next page of results. */
  next_page_token: string | null;
  /** Page token for previous page of results. */
  previous_page_token: string | null;
  /** Number of items returned in this response. */
  returned_count: number;
  /** Total number of items available. */
  total_count: number;
  /** Index of the first item returned in this response (0-based). */
  start_index: number;
};
export type EmailParticipantResponse = {
  /** Name of the email participant. */
  name?: string;
  /** Email address of the participant. */
  email: string;
};
export type User = {
  /** The URL to the user's profile picture. */
  picture_url: string | null;
  /** The unique identifier of the user. */
  id: string;
  /** The display name of the user. */
  display_name: string;
  /** The email address of the user. */
  email: string;
  /** A list of features enabled for the user. */
  features: ('NO_EMAILS' | 'NOTIFICATION_SETTINGS' | 'MAINTENANCE_MODE')[];
};
export type ConversationParticipant = {
  email_participant?: EmailParticipantResponse;
  user?: User;
  /** The type of participant (user or email). */
  type: 'user' | 'email';
};
export type LastActivityResponse = {
  /** The participant who authored the last message or activity in the thread. */
  author: ConversationParticipant;
  /** A short snippet or summary of the last message or activity. */
  snippet: string;
  /** The date and time when the last activity occurred. */
  date: string;
  /** The unique identifier of the last message within the thread. */
  message_id: string;
};
export type ScheduledMessageCreationDateResponse = {
  /** The unique identifier of the scheduled message within the thread. */
  message_id: string;
  /** The date and time when the message should be sent. */
  scheduled_date: string;
};
export type Thread = {
  /** Information about the last activity in the thread. */
  last_activity: LastActivityResponse;
  /** Participants currently involved in the thread. */
  participants: ConversationParticipant[];
  /** Participants that will be emailed on the next message in the thread. */
  email_recipients_for_next_message: ConversationParticipant[] | null;
  /** The user assigned to this thread, if any. */
  assignee: User | null;
  /** A map of message ids with their creation dates that are scheduled by the current user. */
  scheduled_messages_creation_dates: ScheduledMessageCreationDateResponse[];
  /** The unique identifier of the thread. */
  id: string;
  /** The title of the thread, if any. */
  title: string | null;
  /** A description or initial message for the thread, if any. */
  description: string | null;
  /** The total number of messages contained within the thread. */
  number_of_messages: number;
  /** The number of messages within the thread that have not been read by the current user. */
  number_of_unread_messages: number;
  /** Indicates whether the thread has been marked as spam. */
  isSpam: boolean | null;
  /** Indicates whether the thread has been starred. */
  is_starred: boolean;
  /** The current status of the thread. */
  status: ('open' | 'in_progress' | 'review' | 'resolved' | 'closed') | null;
  /** The due date for the thread, if applicable. */
  due_date: string | null;
  /** The date until which the thread has been snoozed, if applicable. */
  snooze_end_date: string | null;
  /** The date when the thread was trashed, if applicable. */
  trashed_date: string | null;
  /** Unique identifiers for the channels this thread is associated with. */
  channel_ids: string[];
  /** The identifier of the associated inbox event, if any. */
  inbox_event_id?: string;
  /** Indicates whether the associated inbox event is unread. */
  inbox_event_is_unread?: boolean;
  /** The labels associated with the thread. */
  labels: string[];
};
export type Threads = {
  meta: PaginationMeta;
  data: Thread[];
};
export type Channel = {
  /** Unique identifier for the channel. */
  id: string;
  /** Name of the channel. */
  name: string;
  /** Number of unread threads in the channel. */
  number_of_unread_threads: number;
  /** Indicates if the channel is public. */
  is_public: boolean;
  /** Notification level for the channel. */
  notification_level: 'followed' | 'new_and_followed' | 'all';
  /** Number of members in the channel. */
  member_count: number;
};
export type ChannelUpdateRequestBody = {
  /** Name you want to give to the channel. */
  name: string;
  /** Whether the channel is public or not. */
  is_public: boolean;
};
export type ChannelNotificationLevelUpdate = {
  /** The new notification level for the channel. */
  notification_level: 'followed' | 'new_and_followed' | 'all';
};
export type GmailPushNotificationMessage = {
  /** Base64url-encoded JSON containing the notification data */
  data: string;
  /** This is a Cloud Pub/Sub message id, unrelated to Gmail messages. */
  messageId: string;
  /** This is the publish time of the message. */
  publishTime: string;
};
export type GmailPushNotificationPostRequestBody = {
  message: GmailPushNotificationMessage;
  /** The subscription name of the Gmail push notification. */
  subscription: string;
};
export type ReactionRequestBody = {
  /** Unique code representing the reaction. */
  reaction_code: string;
};
export type MessageIdsRequestBody = {
  /** Public IDs of the messages. */
  message_ids: string[];
};
export type ChannelCreateRequestBody = {
  /** Name you want to give to the channel. */
  name: string;
  /** Whether the channel is public or not. */
  is_public: boolean;
  /** ID of the channel. */
  id?: string;
};
export type Organization = {
  /** The unique identifier of the organization. */
  id: string;
  /** The name of the organization. */
  name: string;
  /** A list of features enabled for the organization. */
  features: ('NO_EMAILS' | 'NOTIFICATION_SETTINGS' | 'MAINTENANCE_MODE')[];
};
export type OrganizationCreateRequestBody = {
  /** Name of the organization */
  name: string;
  /** Domain of the organization */
  domain?: string;
};
export type UnreadEventsByCategory = {
  CATEGORY_PRIMARY: number;
  CATEGORY_PROMOTIONS?: number;
  CATEGORY_SOCIAL?: number;
  CATEGORY_UPDATES?: number;
  CATEGORY_FORUMS?: number;
};
export type Account = {
  /** Number of unread events in the account by category */
  number_of_unread_events_by_category: UnreadEventsByCategory;
  /** Number of unread events in the account. */
  number_of_unread_events: number;
  /** /**
    Number of unread spam threads in the account. */
  number_of_unread_spam_threads: number;
  /** Indicates if email sync is enabled for the account. */
  has_email_sync_enabled: boolean;
  /** Indicates if email sync is operational for the account. */
  is_email_sync_operational: boolean;
};
export type Reaction = {
  /** Unique code representing the reaction. */
  code: string;
};
export type MessageReaction = {
  /** Reaction associated with the message. */
  reaction: Reaction;
  /** UUIDs of users who have reacted. */
  user_ids: string[];
  /** The date when the reaction was last updated. */
  update_date: string;
};
export type InboxItemMessageReactionResponse = {
  /** The reactions that were added to the message. */
  reactions: MessageReaction[];
  /** A snippet of the message that was reacted to. */
  snippet: string;
};
export type InboxItem = {
  /** The thread that this event is for. */
  thread?: Thread;
  /** The reactions that were added to the message, only present if this is a thread-related event. */
  message_reactions?: InboxItemMessageReactionResponse[];
  /** The user who added themself to the thread, only present if type is self_added_as_participant. */
  user_who_added_self?: ConversationParticipant;
  /** The user who added the channel, only present if type is channel_added. */
  user_who_added_channel?: ConversationParticipant;
  /** The unique identifier of the inbox item. */
  id: string;
  /** The type of event that triggered the inbox item. */
  type:
  | 'self_added_as_participant'
  | 'reactions_added'
  | 'message_added'
  | 'added_to_inbox'
  | 'added_to_inbox_from_provider'
  | 'thread_created'
  | 'channel_added'
  | 'snooze_ended';
  /** The date and time of the latest event that popped this item into the inbox. */
  date: string;
  /** The date and time of the first event that popped this item into the inbox. */
  initial_date: string;
  /** Indicates whether the inbox item is unread. */
  is_unread: boolean;
  /** Unique identifier of the channel that the thread was added to, only present if type is channel_added. */
  channel_id?: string;
  /** The date and time when the snooze started, only present if type is snooze_ended. */
  snooze_start_date?: string;
};
export type InboxItems = {
  meta: PaginationMeta;
  data: InboxItem[];
};
export type ThreadUpdateRequestBody = {
  /** Status of the thread. */
  status?: ('open' | 'in_progress' | 'review' | 'resolved' | 'closed') | null;
  /** Title of the thread. */
  title?: string | null;
  /** Description of the thread. */
  description?: string | null;
  /** Due date of the thread. */
  due_date?: string | null;
  /** ID of the assignee. */
  assignee_id?: string | null;
};
export type Attachment = {
  /** The name of the attachment. */
  name: string;
  /** The name of the attachment on the server. */
  server_filename: string;
  /** The URL to download the attachment. */
  download_url: string;
  /** The size of the attachment in bytes. */
  size: number;
  /** The MIME type of the attachment. */
  mime_type: string;
};
export type Message = {
  reactions: MessageReaction[];
  attachments: Attachment[];
  sender: ConversationParticipant;
  /** Participants that will receive the message once sent.
    Only visible for scheduled and staged messages. */
  pending_participants: ConversationParticipant[];
  /** The unique identifier of the message. */
  id: string;
  /** The content of the message. */
  body: string;
  /** Indicates whether the message is unread. */
  is_unread: boolean;
  /** The date the message was created. */
  creation_date: string;
  /** The current state of the message. */
  state: 'scheduled' | 'staged' | 'committed';
};
export type CommittedAttachmentDataRequestParameter = {
  /** The name of the attachment on the server. */
  server_filename: string;
  /** The name of the attachment to be used when downloading it.
    Must be a valid filename without path separators. */
  download_file_name: string;
  /** The MIME type of the attachment. */
  mime_type: string;
};
export type MessageCreateRequestBody = {
  /** ID of the message. */
  id?: string;
  /** Body of the message. */
  message_body: string;
  /** Public IDs of the participants. */
  participant_public_ids?: string[];
  /** Emails of the external participants. */
  external_participant_emails?: string[];
  /** Scheduled date of the message. */
  scheduled_date?: string | null;
  /** Existing attachments to be included in the message, typically used when forwarding a thread or when resending a cancelled scheduled message that went back to the draft state. */
  committed_attachments?: CommittedAttachmentDataRequestParameter[];
  /** IDs of the staged attachments, obtained using the POST /staged_attachments endpoint. */
  staged_attachment_ids?: string[];
};
export type ThreadIdsRequestBody = {
  /** Public IDs of the threads. */
  thread_ids: string[];
};
export type SnoozeThreadRequestBody = {
  /** Timestamp of the date when snooze ends */
  snooze_end_date: number;
};
export type ThreadCreateRequestBody = {
  /** ID of the thread. */
  thread_id?: string;
  /** Title of the thread. */
  thread_title?: string;
  /** Body of the message. */
  message_body: string;
  /** ID of the message. */
  message_id?: string;
  /** IDs of the channels. */
  channel_ids: string[];
  /** IDs of the participants. */
  participant_ids: string[];
  /** Emails of the external participants. */
  external_participant_emails?: string[];
  /** Scheduled date of the message. */
  scheduled_date?: string | null;
  /** Existing attachments to be included in the message, typically used when forwarding a thread or when resending a cancelled scheduled message that went back to the draft state. */
  committed_attachments?: CommittedAttachmentDataRequestParameter[];
  /** IDs of the staged attachments, obtained using the POST /staged_attachments endpoint. */
  staged_attachment_ids?: string[];
};
export type UserCreateRequestBody = {
  /** Display name of the user. */
  display_name: string;
  /** First name of the user. */
  first_name?: string;
  /** Last name of the user. */
  last_name?: string;
  /** Auth server sub of the user. */
  auth_server_sub: string;
  /** Picture URL of the user. */
  picture_url?: string;
  /** Email of the user. */
  email: string;
};
export type ProviderConnection = {
  /** The unique identifier of the provider connection. */
  id: string;
  /** The type of the provider. */
  type: 'google';
  /** The unique identifier of the provider account. */
  provider_account_id: string;
  /** The email address of the provider account. */
  email: string;
};
export type ProviderConnectionCreateRequestBody = {
  /** Type of the provider. */
  type: 'google';
  /** Provider account ID. */
  provider_account_id: string;
  /** Email of the user. */
  email: string;
  /** Access token. */
  access_token: string;
  /** Refresh token. */
  refresh_token: string;
};
export type CancelRequestBody = {
  /** Token returned by the action to cancel. */
  cancel_token: string;
};
export const {
  useGenerateAttachmentPresignedUrlMutation,
  useRefreshAttachmentPresignedUrlMutation,
  useDeleteStagedAttachmentMutation,
  useGetChannelThreadsQuery,
  useGetChannelQuery,
  useDeleteChannelMutation,
  useUpdateChannelMutation,
  useGetChannelParticipantsQuery,
  useAddChannelParticipantsMutation,
  useRemoveChannelParticipantsMutation,
  useUpdateChannelNotificationLevelMutation,
  useProcessGmailWatchNotificationMutation,
  useMarkEventAsReadMutation,
  useRemoveEventFromInboxMutation,
  useAddMessageReactionMutation,
  useRemoveMessageReactionMutation,
  useMarkMessagesAsReadMutation,
  useMarkMessagesAsUnreadMutation,
  useDeleteMessageMutation,
  useSendMessageNowMutation,
  useGetOrganizationChannelsQuery,
  useCreateChannelMutation,
  useGetOrganizationMembersQuery,
  useCreateOrganizationMutation,
  useGetSelfUserQuery,
  useGetSelfAccountQuery,
  useGetSelfChannelsQuery,
  useGetSelfOrganizationsQuery,
  useGetSelfInboxEventsQuery,
  useGetSelfSentThreadsQuery,
  useGetSelfTrashedThreadsQuery,
  useGetSelfSnoozedThreadsQuery,
  useGetSelfSpamThreadsQuery,
  useGetSelfStarredThreadsQuery,
  useGetSelfScheduledThreadsQuery,
  useGetSelfFilteredThreadsQuery,
  useGetSelfAllThreadsQuery,
  useGetSelfContactsQuery,
  useGetThreadQuery,
  useUpdateThreadMutation,
  useGetThreadMessagesQuery,
  usePostMessageMutation,
  useAddThreadParticipantsMutation,
  useRemoveThreadParticipantsMutation,
  useAddThreadToInboxMutation,
  useDeleteThreadsMutation,
  useStarThreadsMutation,
  useUnstarThreadsMutation,
  useSnoozeThreadMutation,
  useMarkAllThreadMessagesAsReadMutation,
  useMarkThreadAsSpamMutation,
  useMarkThreadAsNotSpamMutation,
  useTrashThreadMutation,
  useUntrashThreadMutation,
  useAddThreadChannelsMutation,
  useRemoveThreadChannelsMutation,
  useCreateThreadMutation,
  useSendThreadNowMutation,
  useCreateUserMutation,
  useCreateProviderConnectionMutation,
  useCancelCommandMutation,
} = injectedRtkApi;
