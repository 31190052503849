import { extendTheme } from 'native-base';

const component: Parameters<typeof extendTheme>[0]['components']['IconButton'] = {
  baseStyle: {
    rounded: 'xl',
  },
  variants: {
    primary: {
      borderWidth: 2,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      _hover: {
        borderColor: 'transparent',
        backgroundColor: 'opacityPrimaryDark.50',
      },
      _pressed: {
        borderColor: 'transparent',
        backgroundColor: 'opacityPrimaryDarker.100',
      },
      _icon: {
        color: 'opacityPrimaryDark.700',
      }
    },
    'dark-no-border': {
      backgroundColor: 'transparent',
      borderWidth: 0,
      _hover: {
        _icon: {
          color: 'dark.700',
        }
      },
      _pressed: {
        _icon: {
          color: 'dark.500',
        }
      },
      _icon: {
        color: 'dark.900',
      }
    },
  }
};

export default component;
