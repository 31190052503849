import React from 'react';
import {
  Svg, Rect, Path
} from 'react-native-svg';
import { Status } from '@/adapters/api/codegen';

type StatusIconProps = {
  status: Status;
};

export function StatusIcon({ status }: StatusIconProps) {
  switch (status) {
    case 'open':
      return (
        <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <Rect width="16" height="16" rx="8" fill="#092022" fill-opacity="0.05" />
          <Rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#092022" stroke-opacity="0.2" />
        </Svg>
      );
    case 'in_progress':
      return (
        <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <Rect width="16" height="16" rx="8" fill="#FF8A44" />
          <Path d="M8.66667 2C9.21018 2.06846 9.74328 2.20942 10.2515 2.41992C10.9977 2.729 11.6757 3.18205 12.2468 3.75316C12.818 4.32427 13.271 5.00231 13.5801 5.74849C13.7906 6.25672 13.9315 6.78982 14 7.33333H11.5071C11.4604 7.11373 11.3935 6.89835 11.3072 6.68996C11.1217 6.24222 10.8499 5.83544 10.5073 5.49276C10.1646 5.15007 9.75777 4.87824 9.31006 4.6928C9.10164 4.60646 8.88625 4.53964 8.66667 4.49286V2Z" fill="white" />
        </Svg>
      );
    case 'review':
      return (
        <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <Rect width="16" height="16" rx="8" fill="#00B8E5" />
          <Path d="M8.66667 2C9.21018 2.06846 9.74328 2.20942 10.2515 2.41992C10.9977 2.729 11.6757 3.18205 12.2468 3.75316C12.818 4.32427 13.271 5.00231 13.5801 5.74849C13.7906 6.25672 13.9315 6.78982 14 7.33333H11.5071C11.4604 7.11373 11.3935 6.89835 11.3072 6.68996C11.1217 6.24222 10.8499 5.83544 10.5073 5.49276C10.1646 5.15007 9.75777 4.87824 9.31006 4.6928C9.10164 4.60646 8.88625 4.53964 8.66667 4.49286V2Z" fill="white" />
          <Path d="M8.66667 14C9.21018 13.9315 9.74328 13.7906 10.2515 13.5801C10.9977 13.271 11.6757 12.8179 12.2468 12.2468C12.818 11.6757 13.271 10.9977 13.5801 10.2515C13.7906 9.74328 13.9315 9.21018 14 8.66667H11.5071C11.4604 8.88627 11.3935 9.10165 11.3072 9.31004C11.1217 9.75778 10.8499 10.1646 10.5073 10.5072C10.1646 10.8499 9.75777 11.1218 9.31006 11.3072C9.10164 11.3935 8.88625 11.4604 8.66667 11.5071V14Z" fill="white" />
        </Svg>
      );
    case 'resolved':
      return (
        <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <Rect width="16" height="16" rx="8" fill="#9E9CF2" />
          <Path d="M7.33333 14C6.78982 13.9315 6.25672 13.7906 5.74848 13.5801C5.00229 13.271 4.32428 12.8179 3.75316 12.2468C3.18205 11.6757 2.72902 10.9977 2.41993 10.2515C2.20941 9.74328 2.06848 9.21018 2 8.66667H4.49288C4.53965 8.88627 4.60648 9.10165 4.69281 9.31004C4.87826 9.75778 5.15008 10.1646 5.49275 10.5072C5.83542 10.8499 6.24223 11.1218 6.68994 11.3072C6.89836 11.3935 7.11375 11.4604 7.33333 11.5071V14Z" fill="white" />
          <Path d="M8.66667 2C9.21018 2.06846 9.74328 2.20942 10.2515 2.41992C10.9977 2.729 11.6757 3.18205 12.2468 3.75316C12.818 4.32427 13.271 5.00231 13.5801 5.74849C13.7906 6.25672 13.9315 6.78982 14 7.33333H11.5071C11.4604 7.11373 11.3935 6.89835 11.3072 6.68996C11.1217 6.24222 10.8499 5.83544 10.5073 5.49276C10.1646 5.15007 9.75777 4.87824 9.31006 4.6928C9.10164 4.60646 8.88625 4.53964 8.66667 4.49286V2Z" fill="white" />
          <Path d="M8.66667 14C9.21018 13.9315 9.74328 13.7906 10.2515 13.5801C10.9977 13.271 11.6757 12.8179 12.2468 12.2468C12.818 11.6757 13.271 10.9977 13.5801 10.2515C13.7906 9.74328 13.9315 9.21018 14 8.66667H11.5071C11.4604 8.88627 11.3935 9.10165 11.3072 9.31004C11.1217 9.75778 10.8499 10.1646 10.5073 10.5072C10.1646 10.8499 9.75777 11.1218 9.31006 11.3072C9.10164 11.3935 8.88625 11.4604 8.66667 11.5071V14Z" fill="white" />
        </Svg>
      );
    case 'closed':
      return (
        <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <Rect width="16" height="16" rx="8" fill="#65E3BD" />
          <Path d="M5.65977 10.446L3.05352 7.83975L2.16602 8.721L5.65977 12.2147L13.1598 4.71475L12.2785 3.8335L5.65977 10.446Z" fill="#FAFBFB" />
        </Svg>
      );
    default:
      return (
        <Svg width="17" height="17" viewBox="0 0 17 17" fill="none">
          <Path d="M7.24676 1.67417V0.0500488C5.63068 0.210853 4.15932 0.854069 2.96937 1.82693L4.11108 2.97668C5.00354 2.28522 6.07289 1.81889 7.24676 1.67417ZM13.1322 1.82693C11.9422 0.854069 10.4709 0.210853 8.8548 0.0500488V1.67417C10.0287 1.81889 11.098 2.28522 11.9905 2.97668L13.1322 1.82693ZM14.4267 7.24603H16.0508C15.89 5.62995 15.2468 4.15859 14.2739 2.96864L13.1241 4.11035C13.8156 5.00281 14.2819 6.07216 14.4267 7.24603ZM2.97741 4.11035L1.82767 2.96864C0.854801 4.15859 0.211585 5.62995 0.0507812 7.24603H1.6749C1.81963 6.07216 2.28596 5.00281 2.97741 4.11035ZM1.6749 8.85407H0.0507812C0.211585 10.4701 0.854801 11.9415 1.82767 13.1315L2.97741 11.9817C2.28596 11.0973 1.81963 10.0279 1.6749 8.85407V8.85407ZM10.4628 8.05005C10.4628 6.71537 9.38545 5.63799 8.05078 5.63799C6.71611 5.63799 5.63872 6.71537 5.63872 8.05005C5.63872 9.38472 6.71611 10.4621 8.05078 10.4621C9.38545 10.4621 10.4628 9.38472 10.4628 8.05005ZM13.1241 11.9897L14.2739 13.1395C15.2468 11.9495 15.89 10.4701 16.0508 8.86211H14.4267C14.2819 10.0279 13.8156 11.0973 13.1241 11.9897ZM8.8548 14.4259V16.05C10.4709 15.8892 11.9422 15.246 13.1322 14.2732L11.9824 13.1234C11.098 13.8149 10.0287 14.2812 8.8548 14.4259ZM2.96937 14.2732C4.15932 15.246 5.63872 15.8892 7.24676 16.05V14.4259C6.07289 14.2812 5.00354 13.8149 4.11108 13.1234L2.96937 14.2732Z" fill="#3F5152" />
        </Svg>
      );
  }
}
