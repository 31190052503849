import React, { useCallback } from 'react';
import {
  Box, HStack, Icon, Pressable, Skeleton, Text,
} from 'native-base';
import { CHANNEL_ICON_SETS, ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { ChannelSelectedCallback } from './screen-sections/menu/MenuScreenSection';
import { useDelayedHoverProps } from '../hooks/useDelayedHoverProps';
import { CHANNEL_LINK_LEFT_PADDING } from './screen-sections/menu/Constants';
import { ChannelColorIndicator } from '@/infrastructure/ui/channel-color-indicator/ChannelColorIndicator';
import { useGetChannelParticipants } from '@/infrastructure/controllers/hooks/api/useGetChannelParticipants';
import { ParticipantAvatars } from '@/infrastructure/ui/ParticipantAvatars';

type ChannelListItemProps = ({
  showLoader: true;
  item: null;
} | {
  showLoader: false;
  selectedChannelId?: string;
  item: ChannelViewModel;
  channelSelectedCallback: ChannelSelectedCallback;
  channelHoveredCallback: ((channel: ChannelViewModel) => void);
});

export function ChannelListItem(props: ChannelListItemProps) {
  const isSelected = !props.showLoader && props.selectedChannelId === props.item.id;
  const channelNameTextColor = isSelected ? 'primary.900' : 'opacityPrimaryDark.800';
  const applyBadgeHighlightedStyle = !isSelected;
  const unreadBadgeBackgroundColor = applyBadgeHighlightedStyle ? 'opacityPrimaryDarker.700' : 'transparent';
  const unreadBadgeTextColor = applyBadgeHighlightedStyle ? 'white' : channelNameTextColor;

  // The currently selected channel gets a different icon, usually the non-outlined variant.
  const channelIconSet = CHANNEL_ICON_SETS[props.item?.iconSetName ?? 'publicChannel'];
  const iconCollection = isSelected ? channelIconSet.selected.collection : channelIconSet.default.collection;
  const iconName = isSelected ? channelIconSet.selected.name : channelIconSet.default.name;

  const channelHoveredCallbackProps = props.showLoader ? undefined : props.channelHoveredCallback;
  const channelSelectedCallbackProps = props.showLoader ? undefined : props.channelSelectedCallback;
  const item = props.showLoader ? undefined : props.item;
  const isUnread = Boolean(item?.unreadCount);

  const channelHoveredCallback = useCallback(() => {
    if (item) channelHoveredCallbackProps?.(item);
  }, [item, channelHoveredCallbackProps]);

  const { onHoverIn, onHoverOut } = useDelayedHoverProps(channelHoveredCallback);

  const channelSelectedCallback = useCallback(() => {
    if (item) channelSelectedCallbackProps?.(item);
  }, [channelSelectedCallbackProps, item]);

  return (
    <Pressable
      onPress={channelSelectedCallback}
      variant={isSelected ? 'menu-selected' : 'menu'}
      borderRadius={12}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      w="100%"
      alignSelf="center"
      disabled={props.showLoader}
    >
      {({ isHovered }) => {
        const isChannel = item && !item.isFolder;
        return (
          <>
            {isChannel && (
              <ChannelColorIndicator
                channelId={item.id}
                style={{ transform: `translateX(${(isSelected || isHovered) ? 0 : '-8px'})` }}
              />
            )}
            <HStack space={2} alignItems="center" h={8} px={2} pl={CHANNEL_LINK_LEFT_PADDING}>
              <Icon as={iconCollection} name={iconName ?? 'tag'} color={channelNameTextColor} size="sm" opacity={0.8} />
              <Skeleton isLoaded={!props.showLoader} rounded="full" width="80%" h="2" startColor="dark.200">
                <Text bold={isUnread} isTruncated flexGrow={1} color={channelNameTextColor} selectable={false}>
                  {item?.name}
                </Text>
                <Text
                  fontFamily="badge"
                  fontSize="10px"
                  color={unreadBadgeTextColor}
                  bold
                  rounded="6px"
                  bg={unreadBadgeBackgroundColor}
                  display={isUnread ? 'flex' : 'none'}
                  px={1}
                  minWidth={5}
                  height={4}
                  textAlign="center"
                  flexDir="row"
                  justifyContent="center"
                  alignItems="center"
                  selectable={false}
                >
                  {item?.unreadCount}
                </Text>
                {isChannel && !props.showLoader && (
                  <ChannelListItemParticipants channel={item} visible={isHovered || isSelected} />
                )}
              </Skeleton>
            </HStack>
          </>
        );
      }}
    </Pressable>
  );
}

function ChannelListItemParticipants({
  channel,
  visible,
}: {
  channel: ChannelViewModel;
  visible: boolean;
}) {
  const { currentData: participants } = useGetChannelParticipants(channel);
  if (!participants) return null;
  return (
    <Box
      position="absolute"
      right={2}
      opacity={visible ? 1 : 0}
      pointerEvents="none"
      // @ts-ignore
      _web={{ style: { transition: '80ms' } }}
    >
      <ParticipantAvatars participants={participants} maxNumberOfAvatars={3} size="2xs" />
    </Box>
  );
}
