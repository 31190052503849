import { useCallback } from 'react';
import { buildThreadFollowedMutationDescription } from '../UndoMutationDescriptions';
import { MutationMetadata, useWithMutationMetadata } from '@/adapters/mutation-cancellation/mutationCancellation';
import { useAddThreadParticipantsMutation, useRemoveThreadParticipantsMutation } from '@/adapters/api/codegen';
import { ComprehensiveThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { useGetSelfUser } from './api/useGetSelfUser';

export function useToggleSelfUserJoinThreadCallback() {
  const { currentData: selfUser } = useGetSelfUser();
  const [addParticipants] = useWithMutationMetadata(useAddThreadParticipantsMutation());
  const [removeParticipants] = useWithMutationMetadata(useRemoveThreadParticipantsMutation());

  const joinCallback = useCallback((thread: ComprehensiveThreadViewModel) => {
    const mutationMetadata: MutationMetadata = {
      initiator: 'user',
      description: buildThreadFollowedMutationDescription(thread.selfUserJoined),
    };

    if (!thread.selfUserJoined) {
      void addParticipants({
        threadId: thread!.id,
        body: [selfUser!.id]
      }, mutationMetadata);
    } else {
      void removeParticipants({
        threadId: thread!.id,
        body: [selfUser!.id]
      }, mutationMetadata);
    }
  }, [addParticipants, removeParticipants, selfUser]);

  return joinCallback;
}
