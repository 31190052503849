import { formatThreadDate } from '@/adapters/view-models/ThreadViewModel';
import { getParticipantDisplayName, ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { MutationDescription } from '@/adapters/mutation-cancellation/mutationCancellation';

const THREAD_RETURNED = 'Thread returned';

export function buildThreadChannelsMutationDescription(add: boolean, participants: ParticipantViewModel[]): MutationDescription {
  const participantCount = participants.length;

  if (participantCount === 1) {
    const participantName = getParticipantDisplayName(participants[0]);

    if (add) {
      return {
        action: `Thread added to #${participantName}`,
        undoneAction: `Thread removed from #${participantName}`,
      };
    }
    return {
      action: `Thread removed from #${participantName}`,
      undoneAction: `Thread returned to #${participantName}`,
    };
  }

  if (add) {
    return {
      action: `Thread added to ${participantCount} channels`,
      undoneAction: `Thread removed from ${participantCount} channels`,
    };
  }
  return {
    action: `Thread removed from ${participantCount} channels`,
    undoneAction: `Thread returned to ${participantCount} channels`,
  };
}

export function buildCreateThreadMutationDescription(): MutationDescription {
  return {
    action: 'Thread created',
    undoneAction: 'Thread deleted',
  };
}

export function buildPostMessageMutationDescription(): MutationDescription {
  return {
    action: 'Message sent',
    undoneAction: 'Message deleted',
  };
}

export function buildChannelParticipantMutationDescription(add: boolean, participants: ParticipantViewModel[]): MutationDescription {
  return buildParticipantMutationDescription('channel', add, participants);
}

export function buildThreadParticipantMutationDescription(add: boolean, participants: ParticipantViewModel[]): MutationDescription {
  return buildParticipantMutationDescription('thread', add, participants);
}

function buildParticipantMutationDescription(object: 'thread' | 'channel', add: boolean, participants: ParticipantViewModel[]): MutationDescription {
  const participantCount = participants.length;

  if (participantCount === 1) {
    const participantName = getParticipantDisplayName(participants[0]);

    return buildDescription(
      `${participantName} was added to this ${object}`,
      `${participantName} was removed from this ${object}`,
      !add
    );
  }

  return buildDescription(
    `${participantCount} participants were added to this ${object}`,
    `${participantCount} participants were removed from this ${object}`,
    !add
  );
}

export function buildChannelJoinedMutationDescription(joined: boolean): MutationDescription {
  return buildDescription('Channel joined', 'Channel left', !joined);
}

export function buildMarkAsSpamMutationDescription(spam: boolean): MutationDescription {
  return buildDescription('Marked as spam', 'Marked as not spam', !spam);
}

export function buildTrashMutationDescription(trashed: boolean): MutationDescription {
  const action = trashed
    ? 'Thread trashed'
    : buildMoveToInboxMutationDescription().action;
  return {
    action,
    undoneAction: THREAD_RETURNED,
  };
}

export function buildDeleteMutationDescription(): MutationDescription {
  return {
    action: 'Thread deleted',
    undoneAction: THREAD_RETURNED,
  };
}

export function buildStarMutationDescription(starred: boolean): MutationDescription {
  return buildDescription('Thread starred', 'Thread unstarred', !starred);
}

export function buildThreadFollowedMutationDescription(followed: boolean): MutationDescription {
  return buildDescription('Thread followed', 'Thread unfollowed', !followed);
}

export function buildThreadArchiveMutationDescription(): MutationDescription {
  return {
    action: 'Thread archived',
    undoneAction: THREAD_RETURNED,
  };
}

export function buildMarkAsReadMutationDescription(read: boolean): MutationDescription {
  return buildDescription('Marked as read', 'Marked as unread', !read);
}

export function buildMoveToInboxMutationDescription(): MutationDescription {
  return {
    action: 'Thread moved to inbox',
    undoneAction: THREAD_RETURNED,
  };
}

export function buildThreadSnoozedMutationDescription(snoozeEndDate: Date): MutationDescription {
  return {
    action: `Snoozed thread until ${formatThreadDate(snoozeEndDate.getTime(), true)}`,
    undoneAction: 'Thread unsnoozed',
  };
}

function buildDescription(action: string, undoneAction: string, invert: boolean): MutationDescription {
  if (invert) [action, undoneAction] = [undoneAction, action];
  return { action, undoneAction };
}
