import { type ComponentProps, forwardRef, type ReactNode } from 'react';
import { useId } from '@floating-ui/react';
import { Icon } from 'native-base';
import type { UserViewModel } from '@/adapters/view-models/UserViewModel';
import { CHANNEL_ICON_SETS, type ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';

// todo: move here and rename css ql-mention-popover => floating-list-picker

interface ItemProps {
  children: ReactNode;
  active: boolean;
}

export const ItemWrapper = forwardRef<HTMLDivElement, ItemProps & ComponentProps<'div'>>(({ children, active, ...rest }, ref) => {
  const id = useId();
  return (
    <div
      ref={ref}
      role="option"
      id={id}
      aria-selected={active}
      {...rest}
    >
      {children}
    </div>
  );
});

export const Item = ({ item }: { item: UserViewModel | ChannelViewModel; }) => {
  if (item._type === 'user') {
    return <UserItem user={item} />;
  }
  if (item._type === 'channel') {
    return <ChannelItem channel={item} />;
  }
  return null as never;
};

const UserItem = ({ user }: { user: UserViewModel; }) => {
  return (
    <>
      <span
        className="ql-mention-popover-item-avatar"
        style={{
          backgroundColor: user.pictureInfo?.backgroundColor,
          backgroundImage: `url(${user.pictureInfo?.url})`,
        }}
      >
        {/* <span>{user.pictureInfo?.initials}</span> */}
      </span>
      <span className="ql-mention-popover-item-name">{user.displayName}</span>
      <span className="ql-mention-popover-item-description">{user.email}</span>
    </>
  );
};

const ChannelItem = ({ channel }: { channel: ChannelViewModel; }) => {
  const channelIconSet = CHANNEL_ICON_SETS[channel.iconSetName ?? 'publicChannel'];
  const { collection, name } = channelIconSet.default;
  return (
    <>
      <span className="ql-mention-popover-item-avatar">
        {/* <span>#</span> */}
        <Icon as={collection} name={name ?? 'tag'} color="primary.900" size="xs" opacity={0.8} />
      </span>
      <span className="ql-mention-popover-item-name">{channel.name}</span>
      <span className="ql-mention-popover-item-description" data-no-mark>{channel.memberCount} members</span>
    </>
  );
};
