import { useCallback } from 'react';
import { formatDistance } from 'date-fns';
import { ComprehensiveThreadOrInboxItemViewModel, isInboxItemViewModel } from '../../../../../adapters/view-models/InboxItemViewModel';

export function useSnoozeElapsedTimeHint() {
  return useCallback((item: ComprehensiveThreadOrInboxItemViewModel) => {
    // An expired snooze in the inbox displays the time elapsed since snoozeStartDate as to remind the user of the time
    // the message spent snoozed. (This is opposed to the behavior of an active snooze where the snoozeEndDate is shown,
    // but this doesn't apply to the inbox as the thread is snoozed)
    if (isInboxItemViewModel(item) && item.snoozeStartDate) {
      const distance = getSnoozeStartDateDistance(item.snoozeStartDate);
      return `Snoozed ${distance} ago`;
    }
  }, []);
}
function getSnoozeStartDateDistance(rawDate: string) {
  const snoozeStartDate = new Date(rawDate);
  const currentDate = new Date();

  const distance = formatDistance(snoozeStartDate, currentDate, {
    includeSeconds: false,
  });

  return distance
    .replace('about ', '')
    .replace('minutes', 'min')
    .replace('minute', 'min');
}
