import React, { useCallback } from 'react';
import { AttachmentViewModel } from '@/adapters/view-models/AttachmentViewModel';
import { UploadedAttachment } from '@/adapters/view-models/AttachmentViewModel';
import { AddedAttachment } from '../AttachmentGallery';

export function useUploadAttachmentCallback(setAddedAttachments: React.Dispatch<React.SetStateAction<AddedAttachment[]>>, triggerAddedAttachmentsUpdate: (addedAttachments: AddedAttachment[]) => void) {
  return useCallback((attachment: UploadedAttachment, fileRef: File) => {
    setAddedAttachments((prevAttachments) => {
      const result: [AttachmentViewModel, File, UploadedAttachment | undefined][] = prevAttachments.map(([attachmentViewModel, file, uploadedAttachment]) => {
        if (file === fileRef) {
          return [attachmentViewModel, file, attachment];
        }

        return [attachmentViewModel, file, uploadedAttachment];
      });

      triggerAddedAttachmentsUpdate(result);

      return result;
    });
  }, [setAddedAttachments, triggerAddedAttachmentsUpdate]);
}
