import { Box, HStack, VStack } from 'native-base';
import { NativeSyntheticEvent, TextInput, TextInputKeyPressEventData } from 'react-native';
import { useLayoutEffect, useRef, useState } from 'react';
import {
  autoUpdate, flip, FloatingPortal, offset, shift, size, useFloating, useInteractions, useListNavigation,
} from '@floating-ui/react';
import Mark from 'mark.js';
import { ParticipantsList } from '../../ParticipantsList';
import { SelectedRecipient } from './SelectedRecipient';
import { ParticipantCallback } from '../../ParticipantListItem';
import type { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import type { UserViewModel } from '@/adapters/view-models/UserViewModel';
import type { ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { ScrollArea } from '@/infrastructure/ui/scroll-area/index';
import { Item, ItemWrapper } from '@/infrastructure/ui/floating-list-picker/FloatingListItem';
import { useMergeRefs } from '@/infrastructure/hooks/useMergeRefs';
import { AddParticipantsInput } from '@/infrastructure/ui/screen-sections/add-participants/AddParticipantsInput';

export type AddParticipantScreenSectionType = 'recipients' | 'add_people' | 'add_channels' | 'set_assignee';

type UserOrChannelViewModel = UserViewModel | ChannelViewModel;

export type ParticipantScreenSectionProps = {
  searchQuery: string;
  selectedRecipients: ParticipantViewModel[];
  removeRecipientCallback: ParticipantCallback;
  recipientFilterChangedCallback: ((text: string) => void) | undefined;
  filterInputRef: React.RefObject<TextInput>;
  filterIsFocusedCallback: (focused: boolean) => void;
  showFilteredRecipientsList: boolean;
  interactingWithFilteredRecipientsListCallback: ((isInteracting: boolean) => void) | undefined;
  users: UserOrChannelViewModel[];
  selectRecipientCallback: ParticipantCallback | undefined;
  includeChannels: boolean;
  includeUsers: boolean;
  singleParticipant?: boolean;
  clearAllParticipantsCallback?: () => void;
  clearAllText?: string;
  type: AddParticipantScreenSectionType;
  allowExternalUsers?: boolean;
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
  suggestionsOnTop?: boolean;
};

export function ParticipantScreenSection(props: ParticipantScreenSectionProps) {
  const query = props.searchQuery;
  const { labelText, placeholderText } = getTexts(props.includeChannels, props.includeUsers, props.type);

  const open = props.users.length > 0 && props.showFilteredRecipientsList;
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const listRef = useRef<Array<HTMLElement | null>>([]);

  const optionsList = props.users;

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: 4,
        crossAxis: -40,
      }),
      flip(),
      size({
        padding: 8,
        apply({ availableHeight, elements }) {
          elements.floating.style.maxHeight = `${availableHeight}px`;
        },
      }),
      shift({ padding: 8 }),
    ],
  });

  // @ts-ignore
  const textInputRef = useMergeRefs(props.filterInputRef, refs.setReference);

  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
    focusItemOnOpen: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([listNav]);

  useLayoutEffect(() => {
    const { current } = refs.floating;
    if (current && query) {
      const mark = new Mark(current);
      mark.mark(query, {
        exclude: ['[data-no-mark]'],
      });
      return () => mark.unmark();
    }
  }, [query, refs.floating]);

  const hidePreviousVersionSuggestions = true;
  const participantList = !hidePreviousVersionSuggestions && props.users.length > 0 && (
    <ParticipantsList
      py={1}
      display={props.showFilteredRecipientsList ? 'flex' : 'none'}
      flexGrow={1}
      flexShrink={1}
      interactingWithParticipantsListCallback={props.interactingWithFilteredRecipientsListCallback}
      users={props.users}
      selectParticipantCallback={props.selectRecipientCallback}
      clearAllText={props.clearAllText}
      clearAllParticipantsCallback={props.clearAllParticipantsCallback}
      allowExternalUsers={props.allowExternalUsers}
    />
  );

  return (
    <VStack flexGrow={1} flexShrink={0} flex={1}>
      {props.suggestionsOnTop ? participantList : null}
      <HStack flexShrink={0} space={2} p={2} px={4} alignItems="stretch">
        <Box color="gray.300" mr={2} mt={1}>{labelText}</Box>
        <HStack flexShrink={0} maxH="100%" maxW="90%" alignItems="center" flexWrap="wrap" flexGrow={1}>
          {!props.singleParticipant && props.selectedRecipients.map((recipient) => (
            <SelectedRecipient
              key={recipient.id}
              recipient={recipient}
              removeRecipientCallback={props.removeRecipientCallback}
            />
          ))}
          <AddParticipantsInput
            ml={0}
            pl={0}
            py={0}
            mr={2}
            my={1}
            placeholder={placeholderText}
            borderStyle="none"
            flexGrow={1}
            flexShrink={0}
            outlineColor="transparent"
            focusOutlineColor="transparent"
            backgroundColor="transparent"
            fontSize="sm"
            onChangeText={props.recipientFilterChangedCallback}
            ref={textInputRef}
            autoFocus
            onKeyPress={(e) => {
              switch (e.nativeEvent.key) {
                case 'Enter':
                case 'Tab':
                case ' ': {
                  if (activeIndex !== null && optionsList?.[activeIndex]) {
                    e.preventDefault();
                    props.selectRecipientCallback?.(optionsList?.[activeIndex]);
                  } else {
                    props.onKeyPress?.(e);
                  }
                  break;
                }
                default: {
                  props.onKeyPress?.(e);
                }
              }
            }}
            {...getReferenceProps({
              onFocus: () => {
                if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
                props.filterIsFocusedCallback(true);
              },
              onBlur: () => {
                timeoutRef.current = setTimeout(() => props.filterIsFocusedCallback(false), 100);
              },
            })}
          />
          <FloatingPortal>
            {open && (
              <div
                {...getFloatingProps({
                  className: 'ql-mention-popover',
                  ref: refs.setFloating,
                  style: floatingStyles,
                })}
              >
                <ScrollArea>
                  {optionsList?.map((item, index) => (
                    <ItemWrapper
                      className="ql-mention-popover-item"
                      {...getItemProps({
                        key: item.id,
                        ref(node) {
                          listRef.current[index] = node;
                        },
                        onPointerDown(e) {
                          // not to lose focus from the editor
                          e.preventDefault();
                        },
                        onClick(e) {
                          e.preventDefault();
                          props.selectRecipientCallback?.(item);
                        },
                      })}
                      active={activeIndex === index}
                    >
                      <Item item={item} />
                    </ItemWrapper>
                  ))}
                </ScrollArea>
              </div>
            )}
          </FloatingPortal>
        </HStack>
      </HStack>
      {props.suggestionsOnTop ? null : participantList}
    </VStack>
  );
}

function getTexts(includeChannels: boolean, includeUsers: boolean, type: AddParticipantScreenSectionType) {
  let placeholderText = '';
  if (includeChannels && includeUsers) {
    placeholderText = 'Type a name or channel';
  } else if (includeChannels) {
    placeholderText = 'Type a channel';
  } else {
    placeholderText = 'Type a name';
  }
  let labelText = '';
  switch (type) {
    case 'recipients': {
      labelText = 'To:';
      break;
    }
    case 'add_people': {
      labelText = 'People:';
      break;
    }
    case 'set_assignee': {
      labelText = 'Assignee:';
      break;
    }
    case 'add_channels': {
      labelText = 'Channels:';
      break;
    }
    default: {
      throw new Error(`Unknown type ${type}`);
    }
  }
  return { labelText, placeholderText };
}
