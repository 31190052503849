import { GetChannelThreadsCacheTagProvider } from '../cache-tag-providers/GetChannelThreadsCacheTagProvider';
import { GetChannelsCacheTagProvider } from '../cache-tag-providers/GetChannelsCacheTagProvider';
import { GetOrganizationChannelsCacheTagProvider } from '../cache-tag-providers/GetOrganizationChannelsCacheTagProvider';
import { GetSelfAccountCacheTagProvider } from '../cache-tag-providers/GetSelfAccountCacheTagProvider';
import { GetSelfInboxEventsCacheTagProvider } from '../cache-tag-providers/GetSelfInboxEventsCacheTagProvider';
import { GetThreadCacheTagProvider } from '../cache-tag-providers/GetThreadCacheTagProvider';
import { GetThreadMessagesCacheTagProvider } from '../cache-tag-providers/GetThreadMessagesCacheTagProvider';
import { GetSelfSentThreadsCacheTagProvider } from '../cache-tag-providers/GetSelfSentThreadsCacheTagProvider';
import { GetThreadsCacheTagProvider } from '../cache-tag-providers/GetThreadsCacheTagProvider';
import { GetSpamThreadsCacheTagProvider } from '../cache-tag-providers/GetSpamThreadsCacheTagProvider';
import { GetSnoozedThreadsCacheTagProvider } from '../cache-tag-providers/GetSnoozedThreadsCacheTagProvider';
import { GetAllThreadsCacheTagProvider } from '../cache-tag-providers/GetAllThreadsCacheTagProvider';
import { GetTrashedThreadsCacheTagProvider } from '../cache-tag-providers/GetTrashedThreadsCacheTagProvider';
import { GetStarredThreadsCacheTagProvider } from '../cache-tag-providers/GetStarredThreadsCacheTagProvider';

export const getChannelsCacheTagProvider = new GetChannelsCacheTagProvider();
export const getOrganizationChannelsCacheTagProvider = new GetOrganizationChannelsCacheTagProvider();
export const getSelfAccountCacheTagProvider = new GetSelfAccountCacheTagProvider();
export const getSelfInboxEventsCacheTagProvider = new GetSelfInboxEventsCacheTagProvider();
export const getThreadCacheTagProvider = new GetThreadCacheTagProvider();
export const getThreadMessagesCacheTagProvider = new GetThreadMessagesCacheTagProvider();

export const getThreadsCacheTagProvider = new GetThreadsCacheTagProvider();
export const getAllThreadsCacheTagProvider = new GetAllThreadsCacheTagProvider(getThreadsCacheTagProvider);
export const getChannelThreadsCacheTagProvider = new GetChannelThreadsCacheTagProvider(getThreadsCacheTagProvider);
export const getScheduledThreadsCacheTagProvider = new GetSnoozedThreadsCacheTagProvider(getThreadsCacheTagProvider);
export const getSelfSentThreadsCacheTagProvider = new GetSelfSentThreadsCacheTagProvider(getThreadsCacheTagProvider);
export const getSnoozedThreadsCacheTagProvider = new GetSnoozedThreadsCacheTagProvider(getThreadsCacheTagProvider);
export const getSpamThreadsCacheTagProvider = new GetSpamThreadsCacheTagProvider(getThreadsCacheTagProvider);
export const getStarredThreadsCacheTagProvider = new GetStarredThreadsCacheTagProvider(getThreadsCacheTagProvider);
export const getTrashedThreadsCacheTagProvider = new GetTrashedThreadsCacheTagProvider(getThreadsCacheTagProvider);
