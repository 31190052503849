import { Box, Icon, IconButton } from 'native-base';
import { AntDesign } from '@expo/vector-icons';
import { SelectedRecipientProps } from './SelectedRecipientProps';
import { CHANNEL_ICON_SETS, ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { PictureViewModel } from '@/adapters/view-models/PictureViewModel';
import { UserViewModel } from '@/adapters/view-models/UserViewModel';
import { MentionPill } from '@/infrastructure/ui/rich-text-editor/mention/MentionPill';
import type { MentionBlotData } from '@/infrastructure/ui/rich-text-editor/mention/types';

export function SelectedRecipient(props: SelectedRecipientProps) {
  const { recipient } = props;
  const channel = recipient as ChannelViewModel;
  const member = recipient as UserViewModel;
  const picture = recipient as PictureViewModel;
  const value = member.displayName ?? channel.name ?? picture.name;
  const pillData: MentionBlotData = { value };
  if (recipient._type === 'user') pillData.pictureInfo = member.pictureInfo;
  return (
    <MentionPill
      data={pillData}
      isRecipient
      leftIcon={recipient._type === 'channel' ? <ChannelPillIcon channel={channel} /> : undefined}
    >
      <IconButton
        onPress={() => props.removeRecipientCallback(recipient)}
        mr={-1}
        bgColor="transparent"
        _hover={{ bgColor: 'transparent', _icon: { color: 'muted.900' } }}
        size="xs"
        variant="solid"
        icon={<Icon as={AntDesign} name="close" color="muted.400" />}
        p={1}
        // @ts-ignore
        _web={{ style: { display: 'inline-flex' } }}
      />
    </MentionPill>
  );
}

function ChannelPillIcon({ channel }: { channel: ChannelViewModel }) {
  return (
    <Box
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      w="16px"
      h="16px"
      bg="primary.500"
      borderRadius="sm"
      mt="2px"
      // @ts-ignore
      _web={{ style: { verticalAlign: 'top' } }}
    >
      <Icon
        name={CHANNEL_ICON_SETS[channel.iconSetName].default.name}
        as={CHANNEL_ICON_SETS[channel.iconSetName].default.collection}
        color="white"
        size="xs"
      />
    </Box>
  );
}
