import { ConversationParticipant } from '../../api/codegen';

export function getConversationParticipantDisplayName(sender?: ConversationParticipant): string {
  if (!sender) {
    return 'No message';
  }
  if (sender.type === 'user') {
    return sender.user?.display_name ?? 'Unknown user';
  }
  const emailParticipant = sender.email_participant;
  if (emailParticipant?.name) {
    return `${emailParticipant.name} (${emailParticipant.email})`;
  }
  return emailParticipant?.email || 'Unknown user';
}

export function getConversationParticipantDisplayNameForInitials(sender: ConversationParticipant): string {
  if (sender.type === 'user') {
    return sender.user?.display_name ?? 'Unknown user';
  }
  const emailParticipant = sender.email_participant;
  return emailParticipant?.name || emailParticipant?.email || 'Unknown user';
}
