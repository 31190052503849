import React, {
  forwardRef, useCallback, useImperativeHandle, useRef, useState
} from 'react';
import { Text } from 'native-base';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { buildChannelJoinedMutationDescription } from '../UndoMutationDescriptions';
import {
  useAddChannelParticipantsMutation, useRemoveChannelParticipantsMutation
} from '@/adapters/api/codegen';
import { ParticipantsListModalController, ParticipantsListModalControllerHandles } from './ParticipantsListModalController';
import { ParticipantCallback, ParticipantListItem } from '../../ui/ParticipantListItem';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { useOpenChannelCallback } from '../hooks/useOpenChannelCallback';
import { usePrefetchChannelCallback } from '../hooks/usePrefetchChannelCallback';
import { ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { useGetSelfUser } from '../hooks/api/useGetSelfUser';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useGetSelfChannels } from '../hooks/api/useGetSelfChannels';
import { useGetOrganizationChannels } from '../hooks/api/useGetOrganizationChannels';
import { LeavePrivateChannelAlertDialog } from './LeavePrivateChannelAlertDialog';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';
import { getCurrentScreenParams } from '../../navigation/getCurrentScreenParams';
import { useWithMutationMetadata } from '@/adapters/mutation-cancellation/mutationCancellation';

export interface ManageChannelsModalControllerHandles {
  show: () => void;
}

export type ManageChannelsModalControllerHandlesProps = {};

export const ManageChannelsModalController = forwardRef<ManageChannelsModalControllerHandles, ManageChannelsModalControllerHandlesProps>((_props, ref) => {
  const { currentData: selfChannels } = useGetSelfChannels();
  const openChannelCallback = useOpenChannelCallback();
  const [addParticipants] = useWithMutationMetadata(useAddChannelParticipantsMutation());
  const [removeParticipants] = useWithMutationMetadata(useRemoveChannelParticipantsMutation());
  const { currentData: selfUser } = useGetSelfUser();
  const { organizationId } = useGetSelfOrganizations();
  const { currentData: organizationChannels } = useGetOrganizationChannels(organizationId);
  const navigation = useNavigation<NavigationProp<RootStackParamList, 'Channel'>>();
  const selectedChannelToLeave = useRef<ChannelViewModel | null>(null);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    show: participantsListModalControllerRef.current?.show!
  }));

  const openChannelCallbackFactory = useCallback(
    (channel: ParticipantViewModel) => {
      participantsListModalControllerRef.current?.close();

      // delay
      setTimeout(() => {
        openChannelCallback(channel as ChannelViewModel);
      }, 100);
    },
    [openChannelCallback]
  );

  const prefetchChannelCallback = usePrefetchChannelCallback();

  const participantsListModalControllerRef = React.useRef<ParticipantsListModalControllerHandles>(null);

  const leaveChannel = useCallback(() => {
    const channel = selectedChannelToLeave.current!;
    void removeParticipants({ channelId: channel.id, body: [selfUser!.id] }, {
      initiator: 'user',
      description: buildChannelJoinedMutationDescription(false),
    });
    setIsAlertVisible(false);

    const channelParams = getCurrentScreenParams(navigation.getState(), 'Channel');

    if (!channel.isPublic && channelParams?.channelId === channel.id) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'Inbox' }],
      });
    }
  }, [removeParticipants, selfUser, navigation]);

  const pressCallback = useCallback(
    (theChannel: ChannelViewModel) => {
      const hasJoinedChannel = selfChannels?.channels.some((channel) => channel.id === theChannel.id);
      if (hasJoinedChannel) {
        selectedChannelToLeave.current = theChannel;

        if (!theChannel?.isPublic) {
          setIsAlertVisible(true);
        } else {
          leaveChannel();
        }
      } else {
        void addParticipants({ channelId: theChannel.id, body: [selfUser!.id] }, {
          initiator: 'user',
          description: buildChannelJoinedMutationDescription(true),
        });
      }
    },
    [addParticipants, leaveChannel, selfChannels?.channels, selfUser]
  );

  const channelRenderItem = useCallback(
    ({ item }: { item: ParticipantViewModel; }) => {
      const hasJoinedChannel = selfChannels?.channels.some((channel) => channel.id === item.id);

      return (
        <ParticipantListItem
          recipient={item}
          selectParticipantCallback={openChannelCallbackFactory as ParticipantCallback}
          hoverParticipantCallback={prefetchChannelCallback as ParticipantCallback}
          sideElementFixedWidth={130}
          bold={hasJoinedChannel}
          actions={[{
            icon: { name: !hasJoinedChannel ? 'add-circle' : 'remove-circle-outline' },
            pressCallback: () => { pressCallback(item as ChannelViewModel); },
            text: hasJoinedChannel ? 'Leave' : 'Join',
          }]}
        >
          <Text px={2} fontSize="xs" bold>
            {hasJoinedChannel ? 'Joined' : ''}
          </Text>
        </ParticipantListItem>
      );
    },
    [openChannelCallbackFactory, prefetchChannelCallback, pressCallback, selfChannels?.channels]
  );

  return (
    <>
      <ParticipantsListModalController
        ref={participantsListModalControllerRef}
        participants={organizationChannels?.channels ?? []}
        renderItem={channelRenderItem}
        modalTitle="Channels"
      />

      <LeavePrivateChannelAlertDialog submitCallback={leaveChannel} visible={isAlertVisible} cancelCallback={() => setIsAlertVisible(false)} />
    </>
  );
});
