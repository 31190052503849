import React, {
  useImperativeHandle, forwardRef, useRef, ComponentProps
} from 'react';
import { ScrollView, Box } from 'native-base';

type AttachmentGalleryContainerProps = {
  children: React.ReactNode;
  displayAsScrollView: boolean;
  pl?: ComponentProps<typeof Box>['pl'];
  pt?: ComponentProps<typeof Box>['pt'];
};

export interface AttachmentGalleryContainerHandles {
  scrollToEnd: () => void;
}

export const AttachmentGalleryContainer = forwardRef<AttachmentGalleryContainerHandles, AttachmentGalleryContainerProps>(
  ({
    children, displayAsScrollView, ...rest
  }, ref) => {
    const scrollViewRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      scrollToEnd: () => {
        if (displayAsScrollView && scrollViewRef.current) {
          scrollViewRef.current.scrollToEnd();
        }
      },
    }));

    if (displayAsScrollView) {
      return (
        <ScrollView
          horizontal
          ref={scrollViewRef}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          {...rest}
        >
          {children}
        </ScrollView>
      );
    }

    return (
      <Box
        alignItems="flex-start"
        {...rest}
      >
        {children}
      </Box>
    );
  }
);
