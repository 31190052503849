import { ComponentProps } from 'react';
import { Icon as NativeBaseIcon } from 'native-base';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { CategoryLabel } from '@monorepo/utilities';
import { Channel, GetSelfInboxEventsApiArg } from '../api/codegen';

export const folderTypeValues = ['inbox', 'sent', 'drafts', 'spam', 'snoozed', 'all', 'trash', 'starred', 'scheduled'] as const;

export type FolderType = typeof folderTypeValues[number];

type ChannelOrFolder = Omit<Channel, 'notification_level' | 'member_count'> & {
  folderType?: FolderType;
  notification_level?: Channel['notification_level'];
  member_count?: number;
};

type Icon = {
  name: string;
  collection: ComponentProps<typeof NativeBaseIcon>['as'];
};

type IconSet = {
  default: Icon;
  selected: Icon;
};

export type CategorySlug = 'personal' | 'promotions' | 'social' | 'updates' | 'forums';
export type IconSetName = 'privateChannel' | 'publicChannel' | 'search' | FolderType | CategorySlug;

export const CHANNEL_ICON_SETS: Record<IconSetName, IconSet> = {
  inbox: {
    default: { name: 'inbox-outline', collection: MaterialCommunityIcons },
    selected: { name: 'inbox', collection: MaterialCommunityIcons },
  },
  personal: {
    default: { name: 'person-outline', collection: MaterialCommunityIcons },
    selected: { name: 'person', collection: MaterialCommunityIcons },
  },
  sent: {
    default: { name: 'send-outline', collection: MaterialCommunityIcons },
    selected: { name: 'send', collection: MaterialCommunityIcons },
  },
  drafts: {
    default: { name: 'file-outline', collection: MaterialCommunityIcons },
    selected: { name: 'file', collection: MaterialCommunityIcons },
  },
  spam: {
    default: { name: 'report-gmailerrorred', collection: MaterialIcons },
    selected: { name: 'report', collection: MaterialIcons },
  },
  all: {
    default: { name: 'forum-outline', collection: MaterialCommunityIcons },
    selected: { name: 'forum', collection: MaterialCommunityIcons },
  },
  privateChannel: {
    default: { name: 'lock-outline', collection: MaterialCommunityIcons },
    selected: { name: 'lock', collection: MaterialCommunityIcons },
  },
  publicChannel: {
    default: { name: 'tag', collection: MaterialIcons },
    selected: { name: 'tag', collection: MaterialIcons },
  },
  promotions: {
    default: { name: 'tag-outline', collection: MaterialCommunityIcons },
    selected: { name: 'tag', collection: MaterialCommunityIcons },
  },
  social: {
    default: { name: 'people-outline', collection: MaterialIcons },
    selected: { name: 'people', collection: MaterialIcons },
  },
  updates: {
    default: { name: 'info-outline', collection: MaterialIcons },
    selected: { name: 'info', collection: MaterialIcons },
  },
  forums: {
    default: { name: 'forum-outline', collection: MaterialCommunityIcons },
    selected: { name: 'forum', collection: MaterialCommunityIcons },
  },
  snoozed: {
    default: { name: 'clock-outline', collection: MaterialCommunityIcons },
    selected: { name: 'clock', collection: MaterialCommunityIcons },
  },
  search: {
    default: { name: 'magnify', collection: MaterialCommunityIcons },
    selected: { name: 'magnify', collection: MaterialCommunityIcons },
  },
  trash: {
    default: { name: 'trash-can-outline', collection: MaterialCommunityIcons },
    selected: { name: 'trash-can', collection: MaterialCommunityIcons },
  },
  starred: {
    default: { name: 'star-outline', collection: MaterialCommunityIcons },
    selected: { name: 'star', collection: MaterialCommunityIcons },
  },
  scheduled: {
    default: { name: 'send-clock-outline', collection: MaterialCommunityIcons },
    selected: { name: 'send-clock', collection: MaterialCommunityIcons },
  },
} as const;

export type Category = {
  name: string;
  iconSet: IconSet;
  slug: CategorySlug;
};

export const CATEGORIES: Record<CategoryLabel, Category> = {

  [CategoryLabel.PERSONAL]: {
    name: 'Personal',
    iconSet: CHANNEL_ICON_SETS.personal,
    slug: 'personal',
  },
  [CategoryLabel.PROMOTIONS]: {
    name: 'Promotions',
    iconSet: CHANNEL_ICON_SETS.promotions,
    slug: 'promotions',
  },
  [CategoryLabel.SOCIAL]: {
    name: 'Social',
    iconSet: CHANNEL_ICON_SETS.social,
    slug: 'social',
  },
  [CategoryLabel.UPDATES]: {
    name: 'Updates',
    iconSet: CHANNEL_ICON_SETS.updates,
    slug: 'updates',
  },
  [CategoryLabel.FORUMS]: {
    name: 'Forums',
    iconSet: CHANNEL_ICON_SETS.forums,
    slug: 'forums',
  },
} as const;

export type ChannelViewModel = {
  id: string;
  name: string;
  iconSetName: IconSetName;
  folderType?: FolderType;
  isFolder: boolean;
  unreadCount: number;
  memberCount: number;
  isPublic: boolean;
  notificationLevel?: Channel['notification_level'];
  _type: 'channel';
};

export function createChannelViewModel(channel: ChannelOrFolder): ChannelViewModel {
  const iconSetName = determineChannelIconSetName(channel);
  return {
    // ...channel,
    id: channel.id,
    name: channel.name,
    isPublic: channel.is_public,
    iconSetName,
    folderType: channel.folderType,
    isFolder: !!channel.folderType,
    unreadCount: channel.number_of_unread_threads,
    memberCount: channel.member_count ?? 0,
    notificationLevel: channel.notification_level,
    _type: 'channel',
  };
}

function ensureAllCategoriesPresent<T extends Record<CategoryLabel, unknown>>(_value: T): void { }
ensureAllCategoriesPresent(CATEGORIES);

// We could take enabled categories from user account/settings later
export const enabledInboxCategories = [
  CategoryLabel.PROMOTIONS,
  CategoryLabel.SOCIAL,
  CategoryLabel.UPDATES,
] as const satisfies GetSelfInboxEventsApiArg['category'][];

export const allUserInboxCategories = ['CATEGORY_PRIMARY', ...enabledInboxCategories] satisfies GetSelfInboxEventsApiArg['category'][];

export function isInboxCategory(id: string): id is CategoryLabel {
  return id in CATEGORIES;
}

export function getCategoryFromSlug(categorySlug: CategorySlug | undefined): Exclude<GetSelfInboxEventsApiArg['category'], undefined> {
  if (!categorySlug) {
    return 'CATEGORY_PRIMARY';
  }
  for (const [category, { slug }] of Object.entries(CATEGORIES)) {
    if (slug === categorySlug) {
      return category as CategoryLabel;
    }
  }
  return 'CATEGORY_PRIMARY';
}

function determineChannelIconSetName(channel: ChannelOrFolder): IconSetName {
  if (channel.folderType === 'inbox') {
    if (channel.id === 'inbox') {
      return 'inbox';
    }
    if (isInboxCategory(channel.id)) {
      return CATEGORIES[channel.id].slug;
    }
  }

  if (channel.folderType && folderTypeValues.includes(channel.folderType)) {
    return channel.folderType;
  }

  return determineRegularChannelIconSetName(channel.is_public);
}

export function determineRegularChannelIconSetName(isPublic: boolean): IconSetName {
  if (!isPublic) {
    return 'privateChannel';
  }

  return 'publicChannel';
}
