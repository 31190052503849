import { GetSelfStarredThreadsApiArg } from '../../api/codegen';
import { getCurrentScreenParams, RouteInStackWithNoParametersDefined } from '@/infrastructure/navigation/getCurrentScreenParams';
import { rootNavigationContainerRef } from '@/infrastructure/navigation/navigators/root/RootNavigationContainerRef';

export function getStarredParams(): GetSelfStarredThreadsApiArg | undefined {
  const starredParams = getCurrentScreenParams(rootNavigationContainerRef.current?.getState(), 'Starred');

  if (starredParams) {
    return { pageToken: starredParams.page };
  }

  if (starredParams === RouteInStackWithNoParametersDefined) {
    return {};
  }
}
