import {
  Box
} from 'native-base';
import { useCallback, useState } from 'react';
import { SendButton } from './SendButton';
import { SendLaterButton } from './SendLaterButton';

type SendButtonGroupProps = {
  enabled: boolean;
  onSend: (date?: Date) => void;
};

export function SendButtonGroup(props: SendButtonGroupProps) {
  const { onSend } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [isSendLaterPressed, setIsSendLaterPressed] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const onPointerEnterCallback = useCallback(() => setIsHovered(true), [setIsHovered]);
  const onPointerLeaveCallback = useCallback(() => setIsHovered(false), [setIsHovered]);
  const onSendWithoutDateCallback = useCallback(() => onSend(), [onSend]);

  const openStateChangeHandler = useCallback((newDatePickerOpenState: boolean) => {
    if (datePickerOpen === newDatePickerOpenState) {
      return;
    }
    setDatePickerOpen(newDatePickerOpenState);
  }, [setDatePickerOpen, datePickerOpen]);

  return (
    <Box flexDirection="row" onPointerEnter={onPointerEnterCallback} onPointerLeave={onPointerLeaveCallback} borderRadius="8px">
      <SendButton
        enabled={props.enabled}
        containerHovered={isHovered}
        onSendPressed={onSendWithoutDateCallback}
        datePickerOpen={datePickerOpen}
        isSendLaterPressed={isSendLaterPressed}
      />
      <SendLaterButton
        enabled={props.enabled}
        onSubmit={props.onSend}
        onOpenStateChange={openStateChangeHandler}
        datePickerOpen={datePickerOpen}
        onPressChange={setIsSendLaterPressed}
      />
    </Box>
  );
}
