import {
  Box,
  Button, Divider, HStack, Icon, IconButton, Text
} from 'native-base';
import {
  ForwardedRef, MutableRefObject, useCallback, useEffect, useImperativeHandle, useRef, useState
} from 'react';
import { StyleSheet } from 'react-native';
import { useHotkeys } from 'react-hotkeys-hook';
import { MutationMetadata } from './mutationCancellation';
import { usePreviousValue } from '@/infrastructure/hooks/usePreviousValue';
import { ShortcutTooltip } from '@/infrastructure/ui/components/ShortcutTooltip';

export type UndoMutationToastHandle = {
  actionCancelled: () => void;
};

export type UndoMutationToastProps = {
  onUndoPressed: () => void;
  onClosePressed: () => void;
  metadata: MutationMetadata;
  handleRef: ForwardedRef<UndoMutationToastHandle>;
};

export const UndoMutationToast = ({
  onUndoPressed, onClosePressed, metadata, handleRef
}: UndoMutationToastProps) => {
  const [toastHovered, setToastHovered] = useState(false);
  const [undoPressed, setUndoPressed] = useState(false);
  const [secondaryActionAvailable, setSecondaryActionAvailable] = useState(true);
  const [secondaryActionPressed, setSecondaryActionPressed] = useState(false);
  const [undoAvailable, setUndoAvailable] = useState(true);

  useImperativeHandle(handleRef, (): UndoMutationToastHandle => ({
    actionCancelled: () => {
      setUndoPressed(true);
    },
  }));

  const closeToastTimeout: MutableRefObject<ReturnType<typeof setTimeout> | undefined> = useRef(undefined);
  const lastUndoPressed = usePreviousValue(undoPressed) ?? undoPressed;

  // Toast rules:
  // - If the toast is hovered, don't close it.
  // - If the undo button is pressed, close it after 10 secs no matter what.
  // - Otherwise, close it after 10 secs without any interaction.
  // - The toast can only be shown for 60 secs max.
  useEffect(() => {
    if (undoPressed && lastUndoPressed) {
      return;
    }

    if (undoPressed || !toastHovered) {
      closeToastTimeout.current = setTimeout(() => {
        onClosePressed();
      }, 10000);

      // Once undo is pressed, the timer can't be stopped.
      if (undoPressed) return;
    }

    return () => {
      clearTimeout(closeToastTimeout.current);
    };
  }, [onClosePressed, toastHovered, undoPressed, lastUndoPressed]);

  useEffect(() => {
    return () => {
      clearTimeout(closeToastTimeout.current);
    };
  }, []);

  useEffect(() => {
    const globalTimeout = setTimeout(() => {
      onClosePressed();
    }, 60000);
    return () => {
      clearTimeout(globalTimeout);
    };
  }, [onClosePressed]);

  useEffect(() => {
    if (!metadata.secondaryToastAction?.timeoutMs) {
      return;
    }
    const secondaryActionTimeout = setTimeout(() => {
      setSecondaryActionAvailable(false);
    }, metadata.secondaryToastAction?.timeoutMs);
    return () => {
      clearTimeout(secondaryActionTimeout);
    };
  }, [setSecondaryActionAvailable, metadata.secondaryToastAction?.timeoutMs]);

  useEffect(() => {
    if (!metadata.cancellationTimeoutMs) {
      return;
    }
    const undoTimeout = setTimeout(() => {
      setUndoAvailable(false);
    }, metadata.cancellationTimeoutMs);
    return () => {
      clearTimeout(undoTimeout);
    };
  }, [setUndoAvailable, metadata.cancellationTimeoutMs]);

  let toastText = metadata.description!.action;
  if (secondaryActionPressed) {
    toastText = metadata.secondaryToastAction!.description;
  } else if (undoPressed) {
    toastText = metadata.description!.undoneAction;
  }

  const secondaryActionPressedCallback = useCallback(() => {
    setSecondaryActionPressed(true);
    metadata.secondaryToastAction!.onClick();
  }, [setSecondaryActionPressed, metadata.secondaryToastAction]);

  useHotkeys(metadata.secondaryToastAction?.shortcut?.shortcut || '', secondaryActionPressedCallback, {
    enabled: metadata.secondaryToastAction && secondaryActionAvailable && !undoPressed && !secondaryActionPressed,
  });

  return (
    <Box
      bgColor="white"
      borderRadius="8px"
      style={{ ...(toastHovered ? styles.hoveredToast : styles.toast) }}
      onPointerEnter={() => setToastHovered(true)}
      onPointerLeave={() => setToastHovered(false)}
      ml={4}
    >
      <HStack space={2} alignItems="center">
        <Text pr={4}>
          {toastText}
        </Text>
        <ShortcutTooltip
          hint="Undo"
          shortcut="mod+z"
          style={{ marginBottom: 6 }}
        >
          <Button
            onPress={() => { onUndoPressed(); }}
            variant="ghost"
            borderRadius={4}
            py="2px"
            px="8px"
            display={!undoAvailable || undoPressed || secondaryActionPressed || metadata.cancellable === false ? 'none' : ''}
            _text={{
              fontWeight: 500,
              color: 'primary.600',
              lineHeight: '20px',
            }}
            _hover={{ _text: { color: 'primary.700' } }}
            _pressed={{ _text: { color: 'primary.700' } }}
          >
            Undo
          </Button>
        </ShortcutTooltip>
        {metadata.secondaryToastAction && secondaryActionAvailable && !undoPressed && !secondaryActionPressed && toastHovered && (
          <>
            <Divider
              orientation="vertical"
              width="1px"
              height={4}
              bg="dark.100"
            />
            <ShortcutTooltip
              hint={metadata.secondaryToastAction.shortcut?.hint}
              shortcut={metadata.secondaryToastAction.shortcut?.shortcut}
              style={{ marginBottom: 6 }}
            >
              <Button
                onPress={secondaryActionPressedCallback}
                variant="ghost"
                borderRadius={4}
                py="2px"
                px="8px"
                _text={{
                  fontWeight: 500,
                  color: 'primary.600',
                  lineHeight: '20px',
                }}
                _hover={{ _text: { color: 'primary.700' } }}
                _pressed={{ _text: { color: 'primary.700' } }}
              >
                {metadata.secondaryToastAction.text}
              </Button>
            </ShortcutTooltip>
          </>
        )}
        <IconButton
          onPress={onClosePressed}
          size="10px"
          variant="ghost"
          borderRadius={4}
          width={6}
          height={6}
          color="dark.300"
          _hover={{ bgColor: 'gray.100', _icon: { color: 'dark.300' } }}
          _pressed={{ bgColor: 'dark.100', _icon: { color: 'dark.300' } }}
          _icon={{ color: 'dark.200' }}
          icon={<Icon name="close" />}
        />
      </HStack>
    </Box>
  );
};

const baseToast = {
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  paddingVertical: 8,
  paddingHorizontal: 16,
};

const styles = StyleSheet.create({
  toast: {
    ...baseToast,
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  hoveredToast: {
    ...baseToast,
    shadowOpacity: 0.15,
    shadowRadius: 16,
  }
});
