import { Organization } from '@/adapters/api/codegen';
import { useGetSelfOrganizations } from '../controllers/hooks/api/useGetSelfOrganizations';
import { useGetSelfUser } from '../controllers/hooks/api/useGetSelfUser';

type OrganizationFeature = Organization['features'][number];

export function useIsFeatureEnabled(featureName: OrganizationFeature) {
  const orgsQuery = useGetSelfOrganizations();
  const user = useGetSelfUser();
  const orgs = orgsQuery.currentData?.organizations ?? [];
  const enabledAtOrgLevel = orgs.some((organization) => organization.features.includes(featureName));
  const enabledAtUserLevel = user.currentData?.features.includes(featureName) ?? false;

  const enabled = enabledAtOrgLevel || enabledAtUserLevel;

  return enabled;
}
