import { type ComponentProps, type ComponentPropsWithRef, forwardRef } from 'react';
import { Input } from 'native-base';

type HTMLInputProps = ComponentProps<'input'>;
type NativeBaseInputProps = ComponentPropsWithRef<typeof Input>;

interface Props extends NativeBaseInputProps {
  onKeyDown?: HTMLInputProps['onKeyDown'];
}

/**
 * Hack to support `onKeyDown` event in native-base Input component.
 * Another way to do this would be to use DOM `<input>` element directly.
 * `onKeyDown` is required for floating ui to support list navigation.
 */
export const AddParticipantsInput = forwardRef<any, Props>((props, ref) => {
  const { onKeyDown, ...rest } = props;
  if (onKeyDown) {
    const { onKeyPress } = rest;
    rest.onKeyPress = (e) => {
      if (e.type === 'keydown') {
        onKeyDown(e as any);
      }
      onKeyPress?.(e);
    };
  }
  return <Input ref={ref ?? undefined} {...rest} />;
});
