import { MaterialIcons } from '@expo/vector-icons';
import { Icon } from 'native-base';
import button from './button';
import pressable from './pressable';
import iconButton from './icon-button';
import { customShadow } from '../../customShadow';
import { getModalStyle } from './modal';

// @ts-ignore - remove defaultProps before react19 https://react.dev/blog/2024/04/25/react-19-upgrade-guide#removed-proptypes-and-defaultprops
Icon.defaultProps = {
  as: MaterialIcons,
};

export const components = {
  Text: {
    defaultProps: {
      color: 'dark.800',
    },
  },
  Spinner: {
    defaultProps: {
      color: 'primary.500'
    }
  },
  Icon: {
    defaultProps: {
      color: 'dark.800',
      // as: MaterialIcons - gives console error for Modal.CloseButton
    },
  },
  Heading: {
    baseStyle: {
      fontWeight: '600',
    },
    sizes: {
      md: {
        fontSize: 'lg',
      },
    },
    defaultProps: {
      fontFamily: 'heading',
      color: 'dark.900',
    }
  },
  Divider: {
    defaultProps: {
      bgColor: 'opacityPrimaryDarker.50',
    }
  },
  Menu: {
    baseStyle: {
      rounded: 'lg',
      py: 1,
    },
    defaultProps: {
      backgroundColor: 'white',
      style: {
        ...customShadow,
      }
    }
  },
  MenuItem: {
    baseStyle: {
      rounded: 'md',
      mx: 1,
      px: 1,
      py: 1.5,
      backgroundColor: 'transparent',
      userSelect: 'none',
      _hover: {
        backgroundColor: 'primary.50',
      }
    },
    defaultProps: {
    },
  },
  MenuGroup: {
    baseStyle: {
      px: 4,
      py: 3,
      _title: {
        textTransform: 'none',
        color: 'dark.800',
        fontWeight: '600',
      },
    },
  },
  Avatar: {
    sizes: {
      xs: {
        borderRadius: '8px',
        _image: { borderRadius: '6px' },
      },
      '2xs': {
        height: '18px',
        width: '18px',
        _badgeSize: '1',
        _text: { fontSize: '8px' },
        borderRadius: '6px',
        _image: { borderRadius: '5px' },
      },
    },
    baseStyle: {
      borderRadius: '10px',
      _image: { borderRadius: '8px', }
    },
  },
  AvatarGroup: {
    baseStyle: {
      space: -2,
      _avatar: {
        borderWidth: 2,
      },
    },
    sizes: {
      '2xs': {
        _avatar: {
          borderWidth: 1,
        },
      },
    },
  },
  ...getModalStyle('Modal'),
  ...getModalStyle('AlertDialog'),
  Button: button,
  Pressable: pressable,
  IconButton: iconButton,
  Checkbox: {
    baseStyle: {
      borderWidth: 1,
      p: 0,
    },
    sizes: {
      sm: { _icon: { size: 2.5 } },
    },
  },
  Tooltip: {
    baseStyle: {
      // px: 2,
    },
  },
};
