import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import type {
  AppDispatch, RootState, UndoableAction, UndoableActionResult
} from './store'; // circular ./store ?
import { ComprehensiveThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { useAppSelector } from '@/infrastructure/state/hooks';
import { MessageCreateRequestBody, PostMessageApiArg } from '@/adapters/api/codegen';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { AttachmentViewModel, UploadedAttachment } from '@/adapters/view-models/AttachmentViewModel';

export const threadDraftIdPrefix = 'thread:';

type DraftContent = any; // ops from quilljs, or maybe better convert to HTML

// move to view-models?
export interface DraftViewModel {
  id: string;
  title: string;
  content: DraftContent;
  updatedAt: number;
  participants: ParticipantViewModel[];

  // These are optional to ensure compatibility with existing drafts, should we implement migration?
  committedAttachments?: AttachmentViewModel[];
  stagedAttachments?: UploadedAttachment[];
}

export type MessageCreateRequestBodyWithId = Omit<MessageCreateRequestBody, 'id'> & {
  id: string; // required here
};

export interface SendingViewModel {
  id: string;
  messageCreateRequestBody: MessageCreateRequestBodyWithId;
  resendCount?: number;
  stagedAttachments?: UploadedAttachment[];
  committedAttachments?: AttachmentViewModel[];
}

type ThreadId = string;

export type AddStagedAttachmentsInProgressPayload = UploadedAttachment[];
export type AddCommitedAttachmentsInProgressPayload = AttachmentViewModel[];

export interface DraftsState {
  version: number;
  drafts: DraftViewModel[];
  sendingByThread?: Record<ThreadId, SendingViewModel[]>;
  stagedAttachmentsInProgress?: Record<string, UploadedAttachment>;
  committedAttachmentsInProgress?: Record<string, AttachmentViewModel>;
}

export interface SendingPayload extends PostMessageApiArg {
  messageCreateRequestBody: MessageCreateRequestBodyWithId;
}

export interface DiscardFailedSendingPayload {
  threadId: string;
  messageId: string;
}

export function isSendingPayload(arg: PostMessageApiArg): arg is SendingPayload {
  // Making sure the messageCreateRequestBody has an id
  return Boolean(arg.messageCreateRequestBody.id);
}

export const draftsSlice = createSlice({
  name: 'drafts',
  initialState: {
    version: 1,
    drafts: [],
    sendingByThread: {},
  } as DraftsState,
  reducers: {
    saveDraft: (state, action: PayloadAction<DraftViewModel>) => {
      const draftIndex = state.drafts.findIndex((draft) => draft.id === action.payload.id);
      if (draftIndex !== -1) {
        state.drafts[draftIndex] = {
          ...state.drafts[draftIndex],
          ...action.payload,
        };
      } else {
        state.drafts.push(action.payload);
      }
    },
    deleteDraft: (state, action: PayloadAction<string>) => {
      state.drafts = state.drafts.filter((draft) => draft.id !== action.payload);
    },
    sending: (state, action: PayloadAction<SendingPayload>) => {
      const { threadId, messageCreateRequestBody } = action.payload;
      state.sendingByThread = state.sendingByThread || {};
      state.sendingByThread[threadId] = state.sendingByThread[threadId] || [];
      const sendingMessage = state.sendingByThread[threadId].find((sent) => sent.id === messageCreateRequestBody.id);
      const staedAttachmentsInProgress: Record<string, UploadedAttachment> = state.stagedAttachmentsInProgress || {};
      const committedAttachmentsInProgress: Record<string, AttachmentViewModel> = state.committedAttachmentsInProgress || {};

      if (sendingMessage) {
        sendingMessage.resendCount = (sendingMessage.resendCount || 0) + 1;
      } else {
        const stagedAttachments = action.payload.messageCreateRequestBody.staged_attachment_ids?.map((attachmentId) => staedAttachmentsInProgress[attachmentId]);
        const committedAttachments = action.payload.messageCreateRequestBody.committed_attachments?.map((committedAttachment) => committedAttachmentsInProgress[committedAttachment.server_filename]);

        if (stagedAttachments && state.stagedAttachmentsInProgress) {
          for (const attachment of stagedAttachments) {
            delete state.stagedAttachmentsInProgress[attachment.response.staged_attachment_id];
          }
        }

        if (committedAttachments && state.committedAttachmentsInProgress) {
          for (const attachment of committedAttachments) {
            delete state.committedAttachmentsInProgress[attachment.serverFilename!];
          }
        }

        state.sendingByThread[threadId].push({
          id: messageCreateRequestBody.id,
          messageCreateRequestBody,
          stagedAttachments,
          committedAttachments,
        });
      }
    },
    sendSuccess: (state, action: PayloadAction<SendingPayload>) => {
      const { threadId, messageCreateRequestBody } = action.payload;
      if (state.sendingByThread?.[threadId]) {
        state.sendingByThread[threadId] = state.sendingByThread[threadId].filter((sent) => sent.id !== messageCreateRequestBody.id);
      }
    },
    discardFailedSending: (state, action: PayloadAction<DiscardFailedSendingPayload>) => {
      const { threadId, messageId } = action.payload;
      if (state.sendingByThread?.[threadId]) {
        state.sendingByThread[threadId] = state.sendingByThread[threadId].filter((sent) => sent.id !== messageId);
      }
    },
    resetState: (state, prevState: PayloadAction<DraftsState>) => {
      state.version = prevState.payload.version;
      state.drafts = prevState.payload.drafts;
      state.sendingByThread = prevState.payload.sendingByThread;
    },
    addStagedAttachmentsInProgress: (state, action: PayloadAction<AddStagedAttachmentsInProgressPayload>) => {
      state.stagedAttachmentsInProgress = state.stagedAttachmentsInProgress || {};

      for (const attachment of action.payload) {
        state.stagedAttachmentsInProgress[attachment.response.staged_attachment_id] = attachment;
      }
    },
    addCommitedAttachmentsInProgress: (state, action: PayloadAction<AddCommitedAttachmentsInProgressPayload>) => {
      state.committedAttachmentsInProgress = state.committedAttachmentsInProgress || {};

      for (const attachment of action.payload) {
        state.committedAttachmentsInProgress[attachment.serverFilename!] = attachment;
      }
    },
  },
});

const internalDraftActions = draftsSlice.actions;

export const draftActions = {
  saveDraft: (arg: DraftViewModel): UndoableAction => (dispatch: AppDispatch, getState: () => RootState): UndoableActionResult => {
    const currentState = getState().drafts;
    dispatch(internalDraftActions.saveDraft(arg));
    return {
      undo: () => {
        dispatch(internalDraftActions.resetState(currentState));
      }
    };
  },
  deleteDraft: (arg: string): UndoableAction => (dispatch: AppDispatch, getState: () => RootState): UndoableActionResult => {
    const currentState = getState().drafts;
    dispatch(internalDraftActions.deleteDraft(arg));
    return {
      undo: () => {
        dispatch(internalDraftActions.resetState(currentState));
      }
    };
  },
  sending: (arg: SendingPayload): UndoableAction => (dispatch: AppDispatch, getState: () => RootState): UndoableActionResult => {
    const currentState = getState().drafts;
    dispatch(internalDraftActions.sending(arg));
    return {
      undo: () => {
        dispatch(internalDraftActions.resetState(currentState));
      }
    };
  },
  sendSuccess: (arg: SendingPayload): UndoableAction => (dispatch: AppDispatch, getState: () => RootState) : UndoableActionResult => {
    const currentState = getState().drafts;
    dispatch(internalDraftActions.sendSuccess(arg));
    return {
      undo: () => {
        dispatch(internalDraftActions.resetState(currentState));
      }
    };
  },
  discardFailedSending: (arg: DiscardFailedSendingPayload): UndoableAction => (dispatch: AppDispatch, getState: () => RootState): UndoableActionResult => {
    const currentState = getState().drafts;
    dispatch(internalDraftActions.discardFailedSending(arg));
    return {
      undo: () => {
        dispatch(internalDraftActions.resetState(currentState));
      }
    };
  },
};

export const { addStagedAttachmentsInProgress, addCommitedAttachmentsInProgress } = internalDraftActions;

const selectDraftsSlice = (state: RootState) => state[draftsSlice.name];
export const selectThreadDraft = (state: RootState, threadId: string) => selectDraftsSlice(state).drafts.find((draft) => draft.id === threadId);
export const selectSendingDraft = (state: RootState, threadId: string, messageId: string) => selectDraftsSlice(state).sendingByThread?.[threadId]?.find((draft) => draft.id === messageId);

export function useThreadDraft(thread: ComprehensiveThreadViewModel) {
  return useAppSelector((state) => selectThreadDraft(state, `${threadDraftIdPrefix}${thread.id}`));
}

export function useDrafts() {
  return useAppSelector((state) => selectDraftsSlice(state).drafts);
}

export function useThreadDrafts() {
  return useAppSelector((state) => selectDraftsSlice(state).drafts.filter(({ id }) => id.startsWith(threadDraftIdPrefix)));
}

export function useThreadDraftsCount() {
  return useAppSelector((state) => selectDraftsSlice(state)
    .drafts
    .filter(({ id }) => id.startsWith(threadDraftIdPrefix))
    .length);
}

export function useSendingByThread(threadId: string): SendingViewModel[] | undefined {
  return useAppSelector((state) => selectDraftsSlice(state).sendingByThread?.[threadId]);
}

export const draftsPersistTransform = createTransform(
  (inboundState: DraftsState) => {
    const { stagedAttachmentsInProgress: attachmentsInProgress, ...restState } = inboundState;
    return restState;
  },
  (outboundState: DraftsState) => {
    return {
      ...outboundState,
      stagedAttachmentsInProgress: {},
      committedAttachmentsInProgress: {}
    };
  },
  { whitelist: ['drafts'] }
);
