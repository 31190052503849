import { apiClient } from '../../api';
import { store } from '@/domain/state/store';

// function getSelfUser() {
//   return apiClient.endpoints.getSelfUser.select()(store.getState()).data;
// }

export function getSelfOrganization() {
  const orgs = apiClient.endpoints.getSelfOrganizations.select()(store.getState()).data?.organizations;

  if (orgs?.length) {
    return orgs[0];
  }
}
